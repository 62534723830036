import React, { useEffect } from "react"
import axios from "axios"
import {
  getSoilGuidelineTitle,
  getGWGuidelineTitle,
  getSubsoilGuidelineTitle,
  findGuidelineByMatrix
} from "./helpers/guidelinesDataRowHelpers"
import { useDataVaultContext, useDataVaultAPI } from "../../../contexts/dataVault"
import Tooltip from "../../dataVault/tooltip";

const equivalentUnits = {
  'mg/kg': 'µg/g',
  'µg/g': 'mg/kg'
}

const GuidelineDataRows = ({chemicalHeaders, showGuidelines, hasUniqueIds}) => {
  const { guidelinesData, siteData } = useDataVaultContext()
  const { setGuidelinesData } = useDataVaultAPI()
  const columnSpan = hasUniqueIds ? 4 : 3
  const hasMethods = chemicalHeaders.map(chemical => chemical?.analytical_method).filter(value => value).length > 0
  const hasGuidelinesByMatrix = (matrix) => generateGuidelineRows(guidelinesData, chemicalHeaders, matrix).some((row) => row.props.children !== '-')
  
  useEffect(() => {
    if (siteData?.calculating === false) {
      axios.get(`/sites/${siteData.site_info.id}/site_guidelines_data`)
        .then((response) => setGuidelinesData(response.data))
        .catch((error) => console.error(error))
    }
    
    return () => setGuidelinesData(null)
  }, [siteData])
  
  const generateGuidelineRows = (data, chemicalHeaders, matrix) => {
    return chemicalHeaders?.map((chemical, i) => {
      const matrixGuideline = findGuidelineByMatrix(data, chemical, matrix)
      const rowClass = 'text-center text-xs font-semibold'
      const guidelineVaries = matrixGuideline?.guideline?.length > 1
      
      if (matrixGuideline?.custom_guideline) return <td key={i} className={rowClass}>{matrixGuideline.custom_guideline.value}</td>
      if (guidelineVaries) {
        const guideline = matrixGuideline?.guideline
        const guidelineValues = siteData.samples.map(sample =>
          sample.sample_values.find(sampleValue =>
            sampleValue.chemical_id === chemical.id && sampleValue.unit === guideline[0].unit
          )?.guideline || null
        );
        const applicableGuidelines = guideline.filter(guideline => guidelineValues.includes(guideline.guideline_value))
        const allSameGuidelineValue = applicableGuidelines.every(guideline => guideline?.guideline_value === applicableGuidelines[0]?.guideline_value)
        
        if (applicableGuidelines.length === 1 || allSameGuidelineValue ) return <td key={i} className={rowClass}>{applicableGuidelines[0]?.guideline_value}</td>
        else {
          const dependentChemicals = [...new Set(applicableGuidelines.map(guideline => chemicalHeaders.find(chemical => chemical.id === guideline.dependent_chemical_id)?.name))]
          return <td key={i} className={rowClass}><Tooltip matrix={matrix} chemical={chemical} guidelines={applicableGuidelines} dependentChemicals={dependentChemicals}/></td>
        }
      }
      else {
        const guideline = matrixGuideline?.guideline
        if (guideline) {
          const applicableGuideline = guideline.find((guideline) => {
            return chemical.unit === guideline.unit || equivalentUnits[chemical.unit] === guideline.unit;
          });
          const guidelineValue = applicableGuideline?.guideline_value
          const hasRangedGuideline = guideline[0]?.guideline_value_min && guideline[0]?.guideline_value_max ? `${guideline[0]?.guideline_value_min} - ${guideline[0]?.guideline_value_max}` : null
          
          if (hasRangedGuideline) return <td key={i} className={rowClass}>{hasRangedGuideline}</td>
          return <td key={i} className={rowClass}>{guidelineValue || '-'}</td>
        }
        else return <td key={i} className={rowClass}>-</td>
      }
    })
  }

  return (
    <thead className={`sticky ${hasMethods ? 'top-14' : 'top-10'} left-0 z-20`}>
      {showGuidelines &&
        <>
          {
            hasGuidelinesByMatrix('Soil') && 
            <tr className="bg-gray-200 text-right">
              <td colSpan={columnSpan}></td>
              <td className= {`sticky left-0 z-30 text-xs bg-gray-200 font-semibold`} colSpan={3}>{getSoilGuidelineTitle(guidelinesData, 'Soil')}</td>
              {generateGuidelineRows(guidelinesData, chemicalHeaders, 'Soil')}
            </tr>
          }
          {
            hasGuidelinesByMatrix('Subsoil') && 
            <tr className="bg-gray-200 text-right">
              <td colSpan={columnSpan}></td>
              <td className= {`sticky left-0 z-30 text-xs bg-gray-200 font-semibold`} colSpan={3}>{getSubsoilGuidelineTitle(guidelinesData, 'Subsoil')}</td>
              {generateGuidelineRows(guidelinesData, chemicalHeaders, 'Subsoil')}
            </tr>
          }
          {
            hasGuidelinesByMatrix('Groundwater') && 
            <tr className="bg-gray-200 text-right">
              <td colSpan={columnSpan}></td>
              <td className= {`sticky left-0 z-30 text-xs bg-gray-200 font-semibold`} colSpan={3}>{getGWGuidelineTitle(guidelinesData, 'Groundwater')}</td>
              {generateGuidelineRows(guidelinesData, chemicalHeaders, 'Groundwater')}
            </tr>
          }
        </>
      }
    </thead>
  )
}

export default GuidelineDataRows
