import React from 'react'

const NoSample = () => {
  return (
    <div className='w-full h-full flex items-center justify-center'>
      <p className='p-2 border-2'> Select sample to view PCB chart </p>
    </div>
  )
}

export default NoSample
