import React from "react";

const DateSelectors = ({dateRange, setDateRange}) => {
  const handleStartDateChange = (e) => {
    setDateRange({...dateRange, start: e.target.value})
  };

  const handleEndDateChange = (e) => {
    setDateRange({...dateRange, end: e.target.value})
  };
  
  return (
    <>
      <div className="flex flex-col mr-2">
        <label className="sv-label">Start Date:</label>
        <input className="sv-filters-select" type="date" onChange={handleStartDateChange} value={dateRange.start}/>
      </div>
      <div className="flex flex-col mr-2">
        <label className="sv-label">End Date:</label>
        <input className="sv-filters-select" type="date" onChange={handleEndDateChange} value={dateRange.end}/>
      </div>
    </>
  )
};

export default DateSelectors;
