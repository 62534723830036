import React from "react";
import SampleDataRow from "../shared/table/sampleDataRow";
import TableHeaders from "../shared/table/tableHeaders";
import { tableDataSorter } from "../shared/table/tableFunctions";
import { useSaltPrintContext, useSaltPrintAPI } from "../../contexts/saltPrint";

const Table = () => {
  const { setHighlightSampleIds} = useSaltPrintAPI();
  const { chemicals, saltPrintSamples, filteredSamples, filteredChemical, highlightSampleIds, displayedSamples } = useSaltPrintContext();
  const hasUniqueIds = saltPrintSamples?.filter(sample => sample.sample.name).length > 0
  tableDataSorter(saltPrintSamples);
  const sampleData = filteredSamples || saltPrintSamples;
  const chemicalList = filteredChemical || chemicals;
  const displayedData = displayedSamples || sampleData;
  const sampleRows = displayedData?.map((sample) => {
    return (
      <SampleDataRow key={sample.id} sample={sample} chemicalHeaders={chemicalList} highlightSampleIds={highlightSampleIds} setHighlightSampleIds={setHighlightSampleIds} hasUniqueIds={hasUniqueIds} samples={saltPrintSamples} />
    );
  });
  
  return (
    <div className="h-full">
      <table className="dataVaultTable">
        <TableHeaders samples={saltPrintSamples} chemicals={chemicalList} hasUniqueIds={hasUniqueIds}/>
        <tbody>
          {sampleRows}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
