import React from "react";

const Contaminants = ({ contaminants }) => {
  const formatName = (contaminant) => {
    if (contaminant === 'pH') return contaminant
    if (contaminant === 'amines') return 'Amines and/or Glycols'
    return contaminant.charAt(0).toUpperCase() + contaminant.slice(1)
  }

  const formattedContaminants = contaminants.map(contaminant => formatName(contaminant)).join(", ")

  return <>
    <div className='my-4'>
      <h3 className="font-semibold">Selected Contaminants</h3>
      <div className='my-1'>
        <div>The contaminants you've selected include: <span className='font-semibold'>{formattedContaminants}</span></div>
      </div>
    </div>
  </>
}

export default Contaminants
