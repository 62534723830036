import React from 'react'
import HelpLabels from '../helpLabels'
import { usePahFingerprinting, usePahFingerprintingAPI } from '../../../../../contexts/pahFingerprinting'

const ExportSampleButton = () => {
  const { selectedSamples, sourceReferences } = usePahFingerprinting()
  const { toggleShowModal } = usePahFingerprintingAPI()
  const buttonColor = selectedSamples.length && 'bg-green-300 hover:bg-green-400'

  return (
    sourceReferences && 
    <>
      <HelpLabels label={'Preview Selected Samples'} direction={'right'}/>
      <button className={`flex items-center justify-center border border-black rounded h-7 p-1 m-1 text-xs font-semibold uppercase ${buttonColor}`}
        onClick={toggleShowModal}>
        Export {selectedSamples.length} Samples
      </button>
    </>
  )
}

export default ExportSampleButton
