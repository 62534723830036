import React, {useMemo} from 'react'
import ExportSampleList from './exportSampleList'
import { useDioxinsAndFuransContext, useDioxinsAndFuransAPI } from '../../contexts/dioxinsAndFurans'

const ExportModal = ({setPreview}) => {
  const { showExportModal, samples, sampleIdsForExport } = useDioxinsAndFuransContext()
  const { setShowExportModal, setSampleIdsForExport } = useDioxinsAndFuransAPI()
  const hasSelectedSamples = useMemo(() =>sampleIdsForExport.length > 0, [sampleIdsForExport])
  const previewButtonDisabled = !hasSelectedSamples && 'opacity-50'

  const handleSelectAll = () => {
    setSampleIdsForExport(samples.map(sample => sample.id))
  }

  const handleDeleteAll = () => {
    setSampleIdsForExport([])
  }

  const handlePreview = () => {
    setPreview(true)
    setShowExportModal(false)
  }

  return (
    showExportModal && <div className="fixed inset-0 flex items-center justify-center z-20">
      <div className="absolute inset-0" onClick={() => setShowExportModal(false)}></div>
      <div className="bg-white max-w-full h-auto rounded-lg shadow-lg z-50 overflow-y-auto flex justify-center items-center">
        <div className="relative w-full p-2 overflow-visible">
          <button className="border bg-gray-600 rounded-full w-8 h-8 flex items-center justify-center absolute top-3 right-3 -m-2 z-50" onClick={() => setShowExportModal(false)}>
            <i className='fa fa-times text-white font-extrabold'/>
          </button>
          <h1 className="text-lg font-semibold text-center">Export Samples</h1>
          <div className='max-h-96 flex flex-col overflow-y-auto p-2'>
            <ExportSampleList />
          </div>
          <div className='w-full flex justify-between mt-1'>
            <div>
              <button className='border rounded text-xm uppercase font-semibold p-1 bg-blue-300 text-xs' onClick={handleSelectAll}>Select All</button>
              {hasSelectedSamples && <button className='border rounded text-xm uppercase font-semibold p-1 bg-blue-300 text-xs' onClick={handleDeleteAll}>Delete All</button>}
            </div>
            <button className={`border rounded text-xm uppercase font-semibold p-1 bg-blue-300 text-xs ${previewButtonDisabled}`} onClick={handlePreview} disabled={!hasSelectedSamples}>Preview</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ExportModal
