import React from "react";

const MatrixDropdown = ({ matrix, setMatrix, siteData }) => {
  const handleMatrixChange = (e) => {
    setMatrix(e.target.value);
  };
  
  const matrices = Array.isArray(siteData) ? [...new Set(siteData.map((sample) => sample?.sample?.matrix.title || sample.matrix.title))] : [...new Set(siteData.samples.map((sample) => sample.matrix.title))];
  const matrixOptions = matrices.map((matrix) => (
    <option key={matrix} value={matrix}>
      {matrix}
    </option>
  ));

  return (
    <div className='bg-gray-200 p-2'>
      <label className='block text-gray-600 font-bold tracking-wider uppercase text-xs mb-1'>Matrix: </label>
      <select
        className='w-36 h-6 bg-gray-100 text-gray-800 rounded text-xs'
        value={matrix}
        onChange={handleMatrixChange}
      >
        <option value="">Select Matrix</option>
        {matrixOptions}
      </select>
    </div>
  );
}

export default MatrixDropdown;
