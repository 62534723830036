import React from 'react'

const Instructions = () => {
  return (
    <div className="absolute flex items-center justify-center bg-white left-2 -top-1 z-10 p-1 mt-3 rounded-md">
      <p className='text-xs uppercase mx-0 font-semibold'>Select samples using the polygon tool</p>
    </div>
  )
}

export default Instructions
