import React from "react";

const DepthSelectors = ({depthRange, setDepthRange, matrix}) => {
  const handleStartDepthChange = (e) => {
    setDepthRange({...depthRange, start: e.target.value})
  }

  const handleEndDepthChange = (e) => {
    setDepthRange({...depthRange, end: e.target.value})
  }

  const isGroundwater = matrix === 'Groundwater';

  return (
    <div className='p-2 flex flex-row'>
      <div className='flex flex-col mr-2'>
        <label className={`${isGroundwater ? 'text-gray-400' : 'text-gray-600'} font-bold tracking-wider uppercase text-xs mb-1`}>Start Depth:</label>
        <input
          className='w-16 h-6 bg-gray-100 text-gray-800 rounded'
          value={depthRange.start}
          type='number'
          onChange={handleStartDepthChange}
          disabled={isGroundwater}
        />
      </div>
      <div className='flex flex-col'>
        <label className={`${isGroundwater ? 'text-gray-400' : 'text-gray-600'} font-bold tracking-wider uppercase text-xs mb-1`}>End Depth:</label>
        <input
          className='w-16 h-6 bg-gray-100 text-gray-800 rounded'
          value={depthRange.end}
          type='number'
          onChange={handleEndDepthChange}
          disabled={isGroundwater}
        />
      </div>
    </div>
  );
};

export default DepthSelectors;
