import React from "react"
import { useDataVaultContext } from "../../../contexts/dataVault"

const GeometryInfoContainer = () => {
  const { polygonArea, showAnnotations, distance, rulerMode } = useDataVaultContext()
  const hasGeometryInfo = polygonArea || distance
  const displayGeometryInfo = !hasGeometryInfo && 'hidden'
  
  return(
    <div className={`absolute flex flex-col bg-white mr-1.5 bottom-10 left-12 z-20 p-1.5 mt-3 rounded-md ${displayGeometryInfo}`}>
      {showAnnotations && polygonArea && 
        <p className='sv-label'>Area: {polygonArea}
          <span className='normal-case'>m²</span>
        </p>
      }
      {rulerMode && distance && 
        <p className='sv-label'>Distance: {distance}
          <span className='normal-case'>m</span>
        </p>
      }
    </div>
  )
}

export default GeometryInfoContainer
