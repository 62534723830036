import React, { useEffect } from 'react'
import Select from "react-select";
import { useSiteAPI, useSiteContext } from "../../../contexts/site";

const WaterType = () => {
  const { site, waterType } = useSiteContext()
  const { setWaterType } = useSiteAPI()

  const WATER_TYPES = [
    { label: 'Marine', value: 'Marine' },
    { label: 'Freshwater', value: 'Freshwater' }
  ]

  useEffect(() => {
    if (site.water_type) {
      const existingWaterType = WATER_TYPES.find(waterType => waterType.value == site.water_type)
      setWaterType(existingWaterType)
    }
  }, [site])

  return (
    <div>
      <label className='font-semibold'>Water Type</label>
      <Select
        className={'w-1/6'}
        placeholder={'Water Type'}
        options={WATER_TYPES}
        value={waterType}
        onChange={selectedOption => setWaterType(selectedOption)}
      />
    </div>
  )
}

export default WaterType
