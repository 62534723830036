import React from 'react'
import SampleDropdown from '../../dioxinsAndFurans/sampleDropdown'
import ExportSampleButton from '../../dioxinsAndFurans/exportSampleButton'
import LayerMenuToggle from '../../dataVault/mapHelpers/layerToggles/layerMenuToggle'
import CoElutionButton from '../../pcb/coElution/coElutionButton'
import PahExportSampleButton from '../../reports/pahFingerprinting/review/modal/exportSampleButton'
import ModeToggle from '../../reports/pahFingerprinting/review/modeToggle'
import HelpToggle from '../../reports/pahFingerprinting/review/helpToggle'
import SourcesButton from '../../reports/pahFingerprinting/review/modal/sourcesButton'
import ExportModalButton from '../../pcb/export/exportModalButton'
import { useDataVaultAPI, useDataVaultContext } from '../../../contexts/dataVault'
import { useSaltPrintAPI, useSaltPrintContext } from '../../../contexts/saltPrint'
import { usePahFingerprinting, usePahFingerprintingAPI } from '../../../contexts/pahFingerprinting'
import { useDioxinsAndFuransContext, useDioxinsAndFuransAPI } from '../../../contexts/dioxinsAndFurans'
import { usePcbContext, usePcbAPI } from '../../../contexts/pcb'

export const FilterPanel = ({handleFilterClick, showFilterOptions, mapType, setShowFilterOptions}) => {
  const { toggleAnnotations, toggleRulerMode, toggleShowPopups, toggleLegends, setLayerMenuOpen } = useDataVaultAPI()
  const { togglePopups: togglePAHPrintPopups, toggleLegends: togglePAHLegends } = usePahFingerprintingAPI()
  const { togglePopups: toggleSaltPrintPopups, toggleLegends: toggleSaltPrintsLegend } = useSaltPrintAPI()
  const { togglePopups: toggleDioxinsAndFuransPopups, toggleLegends: toggleDioxinsAndFuransLegend } = useDioxinsAndFuransAPI()
  const { togglePopups: togglePcbPopups, toggleLegends: togglePcbLegends } = usePcbAPI()

  const { showPopups, showAnnotations, rulerMode, showLegends, layerMenuOpen } = useDataVaultContext()
  const { showPopups: showPopupsPAH, showLegends: showLegendsPAH } = usePahFingerprinting()
  const { showPopups : showPopupsSaltPrint, showLegends: showLegendsSaltPrints } = useSaltPrintContext()
  const { showPopups : showPopupsDioxinsAndFurans, showLegends: showLegendsDioxinsAndFurans } = useDioxinsAndFuransContext()
  const { showPopups: showPopupsPcb, showLegends: showLegendsPcb } = usePcbContext()
  
  const annotationsClass = showAnnotations ? 'bg-blue-200' : 'bg-white'
  const rulerClass = rulerMode ? 'bg-blue-200' : 'bg-white'
  const labelClass = showPopups || showPopupsPAH || showPopupsSaltPrint || showPopupsDioxinsAndFurans || showPopupsPcb ? 'bg-blue-200 hover:bg-blue-100' : 'bg-white hover:bg-gray-100'
  const filterClass = showFilterOptions ? 'bg-blue-200 hover:bg-blue-100' : 'bg-white hover:bg-gray-100'
  const legendClass = showLegends || showLegendsPAH || showLegendsSaltPrints || showLegendsDioxinsAndFurans || showLegendsPcb ? 'bg-blue-200 hover:bg-blue-100' : 'bg-white hover:bg-gray-100'
  const backgroundColor = mapType === 'dataVault' ? 'bg-transparent' : 'bg-gray-200'  
  
  const popupFunction = () => {
    if (mapType === 'dataVault') return toggleShowPopups()
    if (mapType === 'saltPrints') return toggleSaltPrintPopups()
    if (mapType === 'pah') return togglePAHPrintPopups()
    if (mapType === 'dioxinsAndFurans') return toggleDioxinsAndFuransPopups()
    if (mapType === 'pcb') return togglePcbPopups()
  }

  const legendFunction = () => {
    if (mapType === 'dataVault') return toggleLegends()
    if (mapType === 'saltPrints') return toggleSaltPrintsLegend()
    if (mapType === 'pah') return togglePAHLegends()
    if (mapType === 'dioxinsAndFurans') return toggleDioxinsAndFuransLegend()
    if (mapType === 'pcb') return togglePcbLegends()
  }

  const closeDataVaultFunctions = () => {
    if (mapType === 'dataVault') {
      if (showAnnotations) toggleAnnotations()
      if (showLegends) toggleLegends()
      if (layerMenuOpen) setLayerMenuOpen(false)
      if (setShowFilterOptions) setShowFilterOptions(false)
    }
  }
  
  return (
    <div className={`flex w-full h-8 py-1 ${backgroundColor}`}>
      <div className="w-3/4 flex items-center">
        <div className={`flex items-center justify-center rounded-md cursor-pointer h-7 w-7 ml-2 border border-black ${legendClass}`} title="Show Legends" 
          onClick={() => {
            closeDataVaultFunctions()
            if (!showLegends) legendFunction()
          }}
        >
          <i className="fa fa-list text-md"></i>
        </div>
        <div className={`flex items-center justify-center rounded-md cursor-pointer h-7 w-7 ml-1 border border-black ${labelClass}`} title="Show Labels" onClick={popupFunction}>
          <i className="fa fa-comment text-md"></i>
        </div>
        {mapType === 'dataVault' &&
          <>
            <div className={`flex items-center justify-center rounded-md cursor-pointer h-7 w-7 ml-1 border border-black ${rulerClass}`} title="Measure Distance" onClick={toggleRulerMode}>
              <i className="fa fa-ruler text-md"></i>
            </div>
            <div className={`flex items-center justify-center rounded-md cursor-pointer h-7 w-7 ml-1 border border-black ${annotationsClass}`} title="Show Annotations" 
              onClick={() => {
                closeDataVaultFunctions()
                if (!showAnnotations) toggleAnnotations()
              }}
            >
              <i className="fa fa-draw-polygon text-md"></i>
            </div>
            <LayerMenuToggle closeDataVaultFunctions={closeDataVaultFunctions}/>
          </>
        }
        <div className={`flex items-center justify-center rounded-md cursor-pointer h-7 w-7 ml-1 border border-black ${filterClass}`} title="Show Filters" 
          onClick={() => {
            closeDataVaultFunctions()
            handleFilterClick()
          }}
        >
          <i className="fa fa-filter text-md"></i>
        </div>
        {mapType === 'dioxinsAndFurans' && <SampleDropdown />}
        {mapType === 'pcb' && <CoElutionButton />}
        {mapType === 'pah' &&
          <>
            <ModeToggle />
            <SourcesButton />
          </>
        }
      </div>
      <div className='w-1/3 flex justify-end items-center'>
        {mapType === 'pah' && <><PahExportSampleButton /> <HelpToggle /></>}
        {mapType === 'dioxinsAndFurans' && <ExportSampleButton />}
        {mapType === 'pcb' && <ExportModalButton />}
      </div>
    </div>
  )
}

export default FilterPanel
