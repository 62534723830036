import React, { useState, createContext } from "react";

export const TierOneContext = createContext();

export const TierOneProvider = ({children}) => {
  let [site, setSite] = useState({
    siteIdentification: {
      name: '',
      land_use: '',
      grain_size: ''
    },
    siteDetails: {
      wellsite: '',
      boundary: '',
      organicSoil: '',
      sensitive: '',
      adjacentLandUse: '',
      onsite: '',
    },
    mandatoryGuidelines: {
      volatileContaminant: 'No',
      buildingFeature: 'No',
      sensitiveReceptor: 'No',
      stagnant: 'No',
      contaminatedWater: 'No',
      coarse: 'No',
      contaminationBedrock: 'No',
      contaminatedSource: 'No',
    },
    contaminants: []
});

  return (
    <TierOneContext.Provider value={[site, setSite]}>
        {children}
    </TierOneContext.Provider>
  );
};
