import React, {useState, createRef, useRef } from "react";
import { createPopper } from "@popperjs/core";
import useOutsideAlerter from "./useOutsideAlerter";

const Tooltip = ({ data }) => {
  const [tooltipShow, setTooltipShow] = useState(false);
  const btnRef = createRef();
  const tooltipRef = createRef();
  const wrapperRef = useRef(null);

  const popper = () => {
    return createPopper(btnRef.current, tooltipRef.current, {
      placement: "right",
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 10],
          },
        },
      ],
    });
  }

  const toggleTooltip = () => {
    popper()
    setTooltipShow(!tooltipShow);
  };

  useOutsideAlerter(wrapperRef, () => setTooltipShow(false));

  return (
    <>
    <div ref={wrapperRef}>
      <i className='fa fa-question-circle cursor-pointer'
         onClick={toggleTooltip}
         ref={btnRef}
      />
      <div
        className={`w-1/3 p-3  text-sm border border-gray-400 text-gray-600 shadow-lg bg-gray-100 block break-words rounded-xl ${!tooltipShow && 'hidden'}`}
        ref={tooltipRef}
      >
        <div className="">
          <p className='inline whitespace-pre-wrap'>{data.tooltip} </p>
          {data.link && <a className={'inline font-medium'} href={data.link} target="_blank" rel="noreferrer noopener">[{data.link}]</a>}
        </div>
      </div>
    </div>
    </>
  );
};

export default Tooltip
