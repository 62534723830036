import React from "react";

const ParameterRow = ({ title, symbol, unit, reference, state }) => {
  return <>
    <div className='flex flex-row my-1 px-2 text-sm w-full'>
      <p className='font-semibold w-1/2'>{title}</p>
      <div className='flex flex-row w-1/2 text-right'>
        <p className='w-1/6'>{symbol}</p>
        <p className='w-1/6'>{unit}</p>
        <p className='w-1/4'>{reference}</p>
        {state.site_specific && <p className='w-1/4 bg-yellow-400'>{state.value}</p>}
      </div>
    </div>
  </>
}

export default ParameterRow
