import React, { useState, useEffect } from "react";
import AttachmentModal from "./attachmentModal";
import AttachmentRows from "./attachmentRows";

const Attachments = ({ site, siteAttachments, sampleLocations }) => {
  const [showModal, setShowModal] = useState(false);
  const [sampleLocation, setSampleLocation] = useState("");
  const [attachments, setAttachments] = useState([]);
  
  useEffect(() => {
    setAttachments(siteAttachments);
  }, [siteAttachments]);
  
  const sampleLocationAttachmentsId = attachments.map((attachment) => attachment.sample_location_id).filter(Boolean);

  const sampleLocationOptions = sampleLocations.map((location) => {
    if (sampleLocationAttachmentsId.includes(location.id)) {
      return <option key={location.id} value={location.id}>{location.name}</option>
    }
    return null;
  });

  return (
    <>
      <div className="panel">
        <div className="panel-header">
          <h1>Attachments</h1>
        </div>
        <div className="panel-body">
          <label className="sv-label">Location</label>
          <select
            className="sv-select"
            value={sampleLocation}
            onChange={(e) => setSampleLocation(e.target.value)}
          >
            <option value="">Site-wide</option>
            {sampleLocationOptions}
          </select>
          <table className="table">
            <thead>
              <tr className="text-left">
                <th>Name</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <AttachmentRows
                attachments={attachments} 
                setAttachments={setAttachments} 
                setSampleLocation={setSampleLocation} 
                sampleLocation={sampleLocation} 
              />
            </tbody>
          </table>
          <button className="btn btn-primary" onClick={() => setShowModal(true)}>
            Add Attachment
          </button>
        </div>
      </div>
      <AttachmentModal
        site={site}
        sampleLocations={sampleLocations}
        showModal={showModal}
        setShowModal={setShowModal}
        setAttachments={setAttachments}
        attachments={attachments}
      />
    </>
  );
};

export default Attachments;
