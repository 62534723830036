import React from 'react'
import { useDataVaultContext, useDataVaultAPI } from '../../../../contexts/dataVault'

const SurfaceWaterToggle = () => {
  const { showWaterLayer } = useDataVaultContext()
  const { toggleWaterLayer } = useDataVaultAPI()
  
  return (
    <button className={`border w-full ${showWaterLayer ? 'bg-blue-300' : 'bg-gray-300'} text-xs rounded p-1 my-1 hover:${showWaterLayer ? 'bg-blue-200' : 'bg-gray-200'}`} onClick={() => toggleWaterLayer()}>AB - Surface Water</button>
  )
}

export default SurfaceWaterToggle
