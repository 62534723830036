import React from 'react';
import LegendItem from './legendItem';
import LegendList from './legendList';

const expandedLegendsData = [
  { color: 'bg-green-400', label: '< 1x Guideline' },
  { color: 'bg-gray-400', label: '> 1x Guideline Non-Detect' },
  { color: 'bg-yellow-300', label: '< 1x - 3x Guideline' },
  { color: 'bg-yellow-500', label: '< 3x - 10x Guideline' },
  { color: 'bg-red-500', label: '> 10x Guideline' },
]

const Legends = ({inDataVault}) => {
  const expandedLegend = expandedLegendsData.map((legend, i) => (
    <LegendItem key={i} legend={legend}/>
  ))
  
  return (
    <LegendList expanded={true} legendList={expandedLegend} inDataVault={inDataVault}/>
  )
};

export default Legends;
