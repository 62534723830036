import React from 'react';
import { useDioxinsAndFuransContext, useDioxinsAndFuransAPI } from '../../contexts/dioxinsAndFurans';

const ExportSampleList = () => {
  const { samples, sampleIdsForExport } = useDioxinsAndFuransContext()
  const { setSampleIdsForExport } = useDioxinsAndFuransAPI()
  const hasSelectedSamples = sampleIdsForExport.length > 0

  const handleDelete = (id) => {
    setSampleIdsForExport(sampleIdsForExport.filter(sampleId => sampleId !== id));
  }

  const sampleList = sampleIdsForExport.map(id => {
    const sample = samples.find(sample => sample.id === id);
    return (
      <tr key={sample.id} className='text-xs uppercase font-semibold text-center'>
        <td className='text-left'>{sample.sample_name_for_json}</td>
        <td>{sample.reference.name}</td>
        <td>{sample.reference.similarity}</td>
        <td><i className='fa fa-times text-red-500 hover:text-red-600 text-xs cursor-pointer' onClick={() => handleDelete(id)}/></td>
      </tr>
    )
  })

  return (
    hasSelectedSamples ?
    (
      <table className='dataVaultTable'>
        <thead>
          <tr>
            <th className='mx-1'>Sample Name</th>
            <th className='mx-1'>Reference Standard</th>
            <th className='mx-1'>Similarity</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {sampleList}
        </tbody>
      </table>
    )
    :
    <div className='w-96'>
      <p className='text-xs font-semibold text-center'>No Samples Selected</p>
    </div>
  )
}

export default ExportSampleList
