import React from 'react'
import { usePahFingerprinting, usePahFingerprintingAPI } from '../../../../../contexts/pahFingerprinting'
import HelpLabels from '../helpLabels'

const SourcesButton = ({}) => {
  const { sourceReferences } = usePahFingerprinting()
  const { setSourceModal } = usePahFingerprintingAPI()

  const buttonLabel = sourceReferences ? 'Manage Key Samples' : 'Set Key Samples'
  const buttonColor = !sourceReferences && 'bg-green-300 hover:bg-green-400'
  
  return (
    <>
      <button className={`flex items-center justify-center border border-black rounded h-7 p-1 m-1 text-xs font-semibold uppercase ${buttonColor}`}
        onClick={() => setSourceModal(true)}>
          {buttonLabel}
      </button>
      <HelpLabels label={'Assign Key Samples'} direction={'left'}/>
    </>
  )

}

export default SourcesButton
