const filterByMatrix = (geoJSON, siteData, matrix) => {
  const filteredGeoJSONMatrix = geoJSON?.features.reduce((updatedSiteData, feature) => {
    if (feature.properties.matrix === matrix) {
      updatedSiteData.features.push(feature);
    }
    return updatedSiteData;
  }, { "type": "FeatureCollection", "features": [] });

  const filteredSamplesMatrix = siteData?.samples.reduce((updatedGeoJSON, sample) => {
    if (sample.matrix.title === matrix) {
      updatedGeoJSON.samples.push(sample);
    }
    return updatedGeoJSON;
  }, {chemicals: siteData.chemicals, samples: [], siteInfo: siteData.site_info});

  return {filteredGeoJSONMatrix, filteredSamplesMatrix};
};

const filterByArea = (geoJSON, siteData, area) => {
  const filteredGeoJSONArea = geoJSON?.features.reduce((updatedGeoJSON, feature) => {
    if (feature.properties.sample_location_group_name === area) {
      updatedGeoJSON.features.push(feature);
    }
    return updatedGeoJSON;
  }, { "type": "FeatureCollection", "features": [] });

  const filteredSamplesArea = siteData?.samples.reduce((updatedSiteData, sample) => {
    if (sample.sample_location_group_name === area) {
      updatedSiteData.samples.push(sample);
    }
    return updatedSiteData;
  }, {chemicals: siteData.chemicals, samples: [], siteInfo: siteData.site_info});

  return {filteredGeoJSONArea, filteredSamplesArea};
};

const filterByParameter = (latestGeoJSON, latestSiteData, parameter) => {
  const filteredSamplesParameters = latestSiteData?.samples.reduce((updatedSiteData, sample) => {
    const matchedSample = sample.sample_values.find(sampleValue => sampleValue.chemical_id == parameter.id)
    if (matchedSample) updatedSiteData.samples.push(sample);
    return updatedSiteData;
    }, {chemicals: [parameter], samples: [], siteInfo: latestSiteData.site_info});

    const sampleIds = filteredSamplesParameters.samples.map(sample => sample.id);

    const filteredGeoJSONParameters = latestGeoJSON?.features.reduce((updatedGeoJSON, feature) => {
    if (sampleIds.includes(feature.properties.sample_id)) {
      updatedGeoJSON.features.push(feature);
    }
    return updatedGeoJSON;
    }, { "type": "FeatureCollection", "features": [] });

    return {filteredGeoJSONParameters, filteredSamplesParameters};
};

const filterByDate = (latestGeoJSON, latestSiteData, dateRange) => {
  const filteredSamplesDate = latestSiteData?.samples.reduce((updatedSiteSamples, sample) => {
    const sampleDate = sample.sample_date;

    if (dateRange.start && dateRange.end) {
      if (sampleDate >= dateRange.start && sampleDate <= dateRange.end) {
        updatedSiteSamples.samples.push(sample);
      }
    } else if (dateRange.start && !dateRange.end) {
      if (sampleDate >= dateRange.start) {
        updatedSiteSamples.samples.push(sample);
      }
    } else if (dateRange.end && !dateRange.start) {
      if (sampleDate <= dateRange.end) {
        updatedSiteSamples.samples.push(sample);
      }
    }

    return updatedSiteSamples;
  }, { chemicals: latestSiteData.chemicals, samples: [], siteInfo: latestSiteData.site_info });

  const sampleIds = filteredSamplesDate.samples.map(sample => sample.id);

  const filteredGeoJSONDate = latestGeoJSON?.features.reduce((updatedGeoJSON, feature) => {
    if (sampleIds.includes(feature.properties.sample_id)) {
      updatedGeoJSON.features.push(feature);
    }
    return updatedGeoJSON;
  }, { "type": "FeatureCollection", "features": [] });

  return {filteredGeoJSONDate, filteredSamplesDate};
};

const filterByDepth = (latestGeoJSON, latestSiteData, depthRange) => {
  const filteredSamplesDepth = latestSiteData?.samples.reduce((updatedSiteSamples, sample) => {
    const sampleDepth = sample.sample_depth;

    if (depthRange.start && depthRange.end) {
      if (sampleDepth >= depthRange.start && sampleDepth <= depthRange.end) {
        updatedSiteSamples.samples.push(sample);
      }
    } else if (depthRange.start && !depthRange.end) {
      if (sampleDepth >= depthRange.start) {
        updatedSiteSamples.samples.push(sample);
      }
    } else if (depthRange.end && !depthRange.start) {
      if (sampleDepth <= depthRange.end) {
        updatedSiteSamples.samples.push(sample);
      }
    }

    return updatedSiteSamples;
  }, { chemicals: latestSiteData.chemicals, samples: [], siteInfo: latestSiteData.site_info });

  const sampleIds = filteredSamplesDepth.samples.map(sample => sample.id);

  const filteredGeoJSONDepth = latestGeoJSON?.features.reduce((updatedGeoJSON, feature) => {
    if (sampleIds.includes(feature.properties.sample_id)) {
      updatedGeoJSON.features.push(feature);
    }
    return updatedGeoJSON;
  }, { "type": "FeatureCollection", "features": [] });

  return {filteredGeoJSONDepth, filteredSamplesDepth};
};

const filterByValue = (latestGeoJSON, latestSiteData, valueRange, parameter) => {
  const filteredSamplesValue = latestSiteData?.samples.reduce((updatedSiteSamples, sample) => {
    const matchedSample = sample.sample_values.find(sampleValue => sampleValue.chemical_id == parameter.id)
    const sampleValue = matchedSample.float_value;

    if (valueRange.start && valueRange.end) {
      if (sampleValue >= valueRange.start && sampleValue <= valueRange.end) updatedSiteSamples.samples.push(sample);
    } 
    
    if (valueRange.start) {
      if (sampleValue >= valueRange.start) updatedSiteSamples.samples.push(sample);
    } 
    
    if (valueRange.end) {
      if (sampleValue <= valueRange.end) updatedSiteSamples.samples.push(sample);
    }

    return updatedSiteSamples;
  }, { chemicals: latestSiteData.chemicals, samples: [], siteInfo: latestSiteData.site_info });

  const sampleIds = filteredSamplesValue.samples.map(sample => sample.id);

  const filteredGeoJSONValue = latestGeoJSON?.features.reduce((updatedGeoJSON, feature) => {
    if (sampleIds.includes(feature.properties.sample_id)) {
      updatedGeoJSON.features.push(feature);
    }
    return updatedGeoJSON;
  }, { "type": "FeatureCollection", "features": [] });

  return {filteredGeoJSONValue, filteredSamplesValue};
};

export const filterData = (geoJSON, siteData, matrix, area, parameter, dateRange, depthRange, valueRange) => {
  let updatedSamples = siteData; 
  let updatedGeoJSON = geoJSON;
  
  if (matrix) {
    const {filteredGeoJSONMatrix, filteredSamplesMatrix} = filterByMatrix(updatedGeoJSON, updatedSamples, matrix);

    updatedGeoJSON = filteredGeoJSONMatrix;
    updatedSamples = filteredSamplesMatrix;
  };

  if (area) {
    const { filteredGeoJSONArea, filteredSamplesArea } = filterByArea(updatedGeoJSON, updatedSamples, area);

    updatedGeoJSON = filteredGeoJSONArea;
    updatedSamples = filteredSamplesArea;
  };

  if (Object.entries(parameter).length !== 0) {
    const {filteredGeoJSONParameters, filteredSamplesParameters} = filterByParameter(updatedGeoJSON, updatedSamples, parameter);

    updatedGeoJSON = filteredGeoJSONParameters;
    updatedSamples = filteredSamplesParameters;
  };
  
  if (dateRange.start || dateRange.end) {
    const {filteredGeoJSONDate, filteredSamplesDate} = filterByDate(updatedGeoJSON, updatedSamples, dateRange);

    updatedGeoJSON = filteredGeoJSONDate;
    updatedSamples = filteredSamplesDate;
  };
  
  if (depthRange.start || depthRange.end) {
    const {filteredGeoJSONDepth, filteredSamplesDepth} = filterByDepth(updatedGeoJSON, updatedSamples, depthRange);
    
    updatedGeoJSON = filteredGeoJSONDepth;
    updatedSamples = filteredSamplesDepth;
  };
  
  if (valueRange.start || valueRange.end) {
    const {filteredGeoJSONValue, filteredSamplesValue} = filterByValue(updatedGeoJSON, updatedSamples, valueRange, parameter);
    
    updatedGeoJSON = filteredGeoJSONValue;
    updatedSamples = filteredSamplesValue;
  };
  
  return {updatedGeoJSON, updatedSamples};
};
