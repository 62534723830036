import React from 'react';

const ChangedUnitRow = ({ parameter, bottomBorder }) => {
  return <>
    <tr className={`border-dashed border-t border-gray-400 ${bottomBorder}`}>
      <td className='text-left p-2'>{parameter?.originalParameter}</td>
      <td className='text-left p-2'>{parameter?.newParameter}</td>
      <td className='p-2'>{parameter?.originalUnit}</td>
      <td className='p-2'>{parameter?.newUnit}</td>
    </tr>
    </>
}

export default ChangedUnitRow
