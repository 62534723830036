import React, { useState, useEffect } from 'react';
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import ExportButton from "./exportButton";
import HiddenCharts from "./hiddenCharts";

const Export = ({ samples, site }) => {
  const [chartData, setChartData] =  useState({})
  const [chartCount, setChartCount] = useState(0)

  useEffect(() => {
    setChartData(samples.map(sample => sample.id).reduce((acc,curr)=> (acc[curr]= '',acc),{}))
  }, [])

  return (<>
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
    />
    <ToastContainer />
    <HiddenCharts
      site={site}
      samples={samples}
      setChartData={setChartData}
      setChartCount={setChartCount}
    />
  </>)
}

export default Export

