import React from 'react'
import { usePahFingerprinting } from '../../../../contexts/pahFingerprinting'
import DoublePlotTable from '../annotationDoublePlotRatio/doublePlotTable'

const NoSampleSelected = () => {
  const { annotationMode, selectedSampleAnnotationLog } = usePahFingerprinting()
  const hasPolygonSelectedSamples = selectedSampleAnnotationLog.length > 0

  return (
    <div className={`w-full h-full px-2 overflow-y-auto`}>
        {!annotationMode && 
          <div className='h-full w-full flex items-center justify-center'>
            <p className='border-2 rounded p-4 uppercase text-lg font-semibold text-gray-500'>Select a sample to view histograms</p>
          </div>
        }
        {annotationMode && !hasPolygonSelectedSamples && 
          <div className='h-full w-full flex items-center justify-center'>
            <p className='border-2 rounded p-4 uppercase text-lg font-semibold text-gray-500'>Select samples for double ratio plot</p>
          </div>
        }
        {annotationMode && hasPolygonSelectedSamples && <DoublePlotTable/>}
    </div>
  )
}

export default NoSampleSelected
