import React from 'react';

const UploadErrorModal = ({ showModal, setShowModal}) => {
  return (
    <>
      {showModal &&
        <div className='modal-container fixed inset-0 flex items-center justify-center z-50'>
          <div className='modal-overlay absolute inset-0 bg-gray-900 opacity-50'></div>
          <div className='modal-content-container bg-white w-1/3 h-auto rounded shadow-lg z-50 overflow-y-auto flex justify-center items-center'>
            <div className='modal-content p-5'>
              <div className='flex flex-col justify-center items-center'>
                <h1 className='text-xl font-semibold text-center'>There was an error uploading your CSV</h1>
                <p>Please make sure your CSV follows the format shown below.</p>
                <table className='border-collapse m-2'>
                  <tbody>
                    <tr className='bg-gray-900 border-t border-gray-900 text-white'>
                      <td></td>
                      <td className='text-center w-40 border-l-2 border-r-2 border-gray-400'>A</td>
                      <td className='text-center w-40'>B</td>
                    </tr>
                    <tr>
                      <td className='bg-gray-900 border-t-2 border-b-2 border-gray-400 text-white text-center px-2'>1</td>
                      <td className='border-r-2 border-b-2 border-gray-400'>Parameter</td>
                      <td className='border-b-2 border-gray-400'></td>
                    </tr>
                    <tr>
                      <td className='bg-gray-900 border-b-2 border-gray-400 text-white text-center px-2'>2</td>
                      <td className='border-r-2 border-b-2 border-gray-400'>Units</td>
                      <td className='border-b-2 border-gray-400'></td>
                    </tr>
                    <tr>
                      <td className='bg-gray-900 border-b-2 border-gray-400 text-white text-center px-2'>3</td>
                      <td className='border-r-2 border-b-2 border-gray-400'>Analytical Method</td>
                      <td className='border-b-2 border-gray-400'></td>
                    </tr>
                    <tr>
                      <td className='bg-gray-900 border-b-2 border-gray-400 text-white text-center px-2'>4</td>
                      <td className='border-r-2 border-b-2 border-gray-400'>Detection Limit</td>
                      <td className='border-b-2 border-gray-400'></td>
                    </tr>
                    <tr>
                      <td className='bg-gray-900 text-white text-center px-2'>5</td>
                      <td className='border-r-2 border-gray-400'></td>
                      <td className='border-gray-400'></td>
                    </tr>
                  </tbody>
                </table>
                <div className='flex justify-center items-center'>
                  <button className='btn btn-primary' onClick={() => setShowModal(false)}>Close</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default UploadErrorModal
