import React from 'react'
import ModalWrapper from '../../../shared/modal/modalWrapper'
import HeaderTable from './headerTable'
import { numberToLetter, downloadCSV, abortUpload } from './helpers'

const EmptyHeaderModal = ({ isOpen, firstRow, collectionKVS, setIsOpen, siteUpload }) => {
  const emptyHeaders = collectionKVS.filter(label => label[0] === null)
  const emptyHeaderFirstRows = emptyHeaders.map(header => ({ column: numberToLetter(header[1] + 1), value: firstRow[header[1]] }))
  
  return (
    <ModalWrapper isOpen={isOpen}>
      <div className='p-2 flex flex-col'>
        <p className='block text-md font-semibold text-gray-600 uppercase my-1 text-center'>CSV has empty column headers</p>
        <HeaderTable emptyHeaderFirstRows={emptyHeaderFirstRows} />
        <p className='sv-label text-center'>Please update your CSV and include column headers for the above columns</p>
        <p className='sv-label text-center'>Empty columns will be ignored if the upload process is continued</p>
        <div className='flex justify-between'>
          <button className='btn bg-red-500 rounded text-white' onClick={() => abortUpload(siteUpload)}>Abort Upload</button>
          <div>
            <button className='btn btn-primary' onClick={() => downloadCSV(siteUpload)}>Download CSV</button>
            <button className='btn btn-primary' onClick={() => setIsOpen(prev => !prev)}>Continue</button>
          </div>
        </div>
        </div>
    </ModalWrapper>
  )
}

export default EmptyHeaderModal
