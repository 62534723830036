import React from "react";
import ParametersChangedModal from "../parameterSelectionModals/parametersChangedModal";
import ParameterSelectionModals from "../parameterSelectionModals/index";
import { useParameterSelectApi } from "../../../contexts/parameterSelection";
import ChemicalParameters from "./chemicalParameters";
import GroundwaterMetaData from "./groundwaterMetaData";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const ParameterSelectionBody = ({ samples, duplicatedSamplesCSV, site, deleteRoute, csvEmptyRows, siteUpload }) => {
  const { setShowModal } = useParameterSelectApi()
  
  return (
    <div className="h-full">
      <ToastContainer autoClose={50000} />
      <ParameterSelectionModals samples={samples} duplicatedSamplesCSV={duplicatedSamplesCSV} site={site} deleteRoute={deleteRoute} csvEmptyRows={csvEmptyRows} siteUpload={siteUpload} />
      <ParametersChangedModal />
      <ChemicalParameters />
      <GroundwaterMetaData />
      <button className='btn btn-default mb-2' onClick={() => setShowModal(true)}>Next &gt;</button>
    </div>
  )
}

export default ParameterSelectionBody
