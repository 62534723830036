import React, { createContext, useReducer, useMemo, useContext, useEffect } from 'react'
import axios from "axios";

/* Actions */
const SET_SOIL_USAGE = 'SET_SOIL_USAGE'
const SET_SUBSOIL_USAGE = 'SET_SUBSOIL_USAGE'
const SET_WATER_TYPE = 'SET_WATER_TYPE'
const SET_SOIL_PATHWAYS = 'SET_SOIL_PATHWAYS'
const SET_GROUNDWATER_PATHWAYS = 'SET_GROUNDWATER_PATHWAYS'
const TOGGLE_PATHWAY = 'TOGGLE_PATHWAY'
const SET_SUBSOIL_PATHWAYS = 'SET_SUBSOIL_PATHWAYS'
const SET_USAGE_OPTIONS = 'SET_USAGE_OPTIONS'
const SET_SOIL_PATHWAY_OPTIONS = 'SET_SOIL_PATHWAY_OPTIONS'
const SET_SUBSOIL_PATHWAY_OPTIONS = 'SET_SUBSOIL_PATHWAY_OPTIONS'
const SET_GROUNDWATER_PATHWAY_OPTIONS = 'SET_GROUNDWATER_PATHWAY_OPTIONS'

/* Initial States */
const initialState = (site, soilPathways, subsoilPathways, groundwaterPathways) => ({
  site: site,
  soilUsage: null,
  subsoilUsage: null,
  waterType: null,
  soilPathways: soilPathways?.map(pathway => pathway.id),
  subsoilPathways: subsoilPathways?.map(pathway => pathway.id),
  groundwaterPathways: groundwaterPathways?.map(pathway => pathway.id),
  usageOptions: [],
  soilPathwayOptions: [],
  subsoilPathwayOptions: [],
  groundwaterPathwayOptions: []
})

const newPathways = (inputArray, pathwayId) => {
  return inputArray?.includes(pathwayId) ? inputArray.filter(pathway => pathway !== pathwayId) : [ ...inputArray, pathwayId ]
}

/* Reducer */
const siteReducer = (originalState, action) => {
  let state = { ...originalState }

  switch (action.type) {
    case SET_SOIL_USAGE:
      Object.assign(state, { soilUsage: action.payload })
      if (!!originalState.soilUsage || !!originalState.subsoilUsage) return state
    case SET_SUBSOIL_USAGE:
      return { ...state, subsoilUsage: action.payload }
    case SET_WATER_TYPE:
      return { ...state, waterType: action.payload }
    case SET_SOIL_PATHWAYS:
      return { ...state, soilPathways: action.payload }
    case SET_SUBSOIL_PATHWAYS:
      return { ...state, subsoilPathways: action.payload }
    case SET_GROUNDWATER_PATHWAYS:
      return { ...state, groundwaterPathways: action.payload }
    case TOGGLE_PATHWAY:
      return { ...state, [action.payload.pathwayType]: newPathways(state[action.payload.pathwayType], action.payload.pathwayId) }
    case SET_USAGE_OPTIONS:
      return { ...state, usageOptions: action.payload }
    case SET_SOIL_PATHWAY_OPTIONS:
      return { ...state, soilPathwayOptions: action.payload }
    case SET_SUBSOIL_PATHWAY_OPTIONS:
      return { ...state, subsoilPathwayOptions: action.payload }
    case SET_GROUNDWATER_PATHWAY_OPTIONS:
      return { ...state, groundwaterPathwayOptions: action.payload }
    default:
      return state
  }
}

/* Contexts */
const SiteContext = createContext(initialState({}))
const SiteAPIContext = createContext({
  setSoilUsage: () => {},
  setSubsoilUsage: () => {},
  setWaterType: () => {},
  setSoilPathways: () => {},
  setSubsoilPathways: () => {},
  setGroundwaterPathways: () => {},
  setUsageOptions: () => {},
  setSoilPathwayOptions: () => {},
  setSubsoilPathwayOptions: () => {},
  setGroundwaterPathwayOptions: () => {}
})

/* Providers */
export const SiteProvider = ({ children, site, soilPathways, subsoilPathways, groundwaterPathways }) => {
  const [state, dispatch] = useReducer(siteReducer, initialState(site, soilPathways, subsoilPathways, groundwaterPathways))

  const api = useMemo(() => {
    const setSoilUsage = usage => dispatch({ type: SET_SOIL_USAGE, payload: usage })

    const setSubsoilUsage = usage => dispatch({ type: SET_SUBSOIL_USAGE, payload: usage })

    const setWaterType = waterType => dispatch({ type: SET_WATER_TYPE, payload: waterType })

    const setSoilPathways = pathways => dispatch({ type: SET_SOIL_PATHWAYS, payload: pathways })

    const setSubsoilPathways = pathways => dispatch({ type: SET_SUBSOIL_PATHWAYS, payload: pathways })

    const setGroundwaterPathways = pathways => dispatch({ type: SET_GROUNDWATER_PATHWAYS, payload: pathways })

    const togglePathway = pathwayType => pathwayId => dispatch({ type: TOGGLE_PATHWAY, payload: { pathwayId, pathwayType } })

    const setUsageOptions = options => dispatch({ type: SET_USAGE_OPTIONS, payload: options })

    const setSoilPathwayOptions = options => dispatch({ type: SET_SOIL_PATHWAY_OPTIONS, payload: options })

    const setSubsoilPathwayOptions = options => dispatch({ type: SET_SUBSOIL_PATHWAY_OPTIONS, payload: options })

    const setGroundwaterPathwayOptions = options => dispatch({ type: SET_GROUNDWATER_PATHWAY_OPTIONS, payload: options })

    return { setSoilUsage, setSubsoilUsage, setWaterType, setSoilPathways, setSubsoilPathways, setGroundwaterPathways, togglePathway, setUsageOptions, setSoilPathwayOptions, setSubsoilPathwayOptions, setGroundwaterPathwayOptions }
  }, [])

  useEffect(() => {
    if (site) {
      axios.get(`/jurisdictions/${site.jurisdiction_id}/usages.json`)
        .then(res => {
          const formattedData = res.data.map(usage => {
            return { label: usage.title, value: usage.id }
          })
          api.setUsageOptions(formattedData)
        })

      axios.get(`/jurisdictions/${site.jurisdiction_id}/soil_pathways.json`)
        .then(res => {
          api.setSoilPathwayOptions(res.data)
          if (state.soilPathways.length < 1) api.setSoilPathways(res.data.map(pathway => pathway.id))
        })

      axios.get(`/jurisdictions/${site.jurisdiction_id}/subsoil_pathways.json`)
        .then(res => {
          api.setSubsoilPathwayOptions(res.data)
          if (state.subsoilPathways.length < 1) api.setSubsoilPathways(res.data.map(pathway => pathway.id))
        })

      axios.get(`/jurisdictions/${site.jurisdiction_id}/groundwater_pathways.json`)
        .then(res => {
          api.setGroundwaterPathwayOptions(res.data)
          if (state.groundwaterPathways.length < 1) api.setGroundwaterPathways(res.data.map(pathway => pathway.id))
        })
    }
  }, [site])

  useEffect(() => {
    if (site.soil_and_groundwater_usage_id && state.usageOptions) {
      const existingSubsoilUsage = state.usageOptions.find(option => option.value == site.templated_guidelines_subsoil_usage_id)
      api.setSubsoilUsage(existingSubsoilUsage)
    }
  }, [site, state.usageOptions])

  useEffect(() => {
    if (site.soil_and_groundwater_usage_id && state.usageOptions) {
      const existingSubsoilUsage = state.usageOptions.find(option => option.value == site.soil_and_groundwater_usage_id)
      api.setSoilUsage(existingSubsoilUsage)
    }
  }, [site, state.usageOptions])


  return (
    <SiteAPIContext.Provider value={api}>
      <SiteContext.Provider value={state}>
        {children}
      </SiteContext.Provider>
    </SiteAPIContext.Provider>
  )
}

/* Custom Context Hooks */
export const useSiteContext = () => useContext(SiteContext)
export const useSiteAPI = () => useContext(SiteAPIContext)
