import axios from "axios";

export const getConstants = (site_id) => {
  try {
    return axios.get(`/sites/${site_id}/reports/double_ratio_plot/constants.json`)
  }
  catch (e) {
    console.error(e)
  }
}

export const getPdfReportData = (siteId, chartData) => {
  try {
    return axios({
      method: 'POST',
      url: `/sites/${siteId}/double_ratio_exports`,
      responseType: 'arraybuffer',
      data: chartData
    })
  }
  catch (e) {
    console.error(e)
  }
}

export const getDiagnosticRatioConstants = (siteId) => {
  try {
    return axios.get(`/sites/${siteId}/reports/double_ratio_plot/constants.json`)
  }
  catch (e) {
    console.error(e)
  }
}

export const getDiagnosticRatios = (siteId, sampleId) => {
  try {
    return axios({
      method: 'GET',
      url: `/sites/${siteId}/reports/double_ratio_plot/ratios?sample_id=${sampleId}`,
      params: { site_id: siteId, sample_id: sampleId }
    })
  }
  catch (e) {
    console.error(e)
  }
}

export const getConcentrationData = (siteId, sampleId) => {
  try {
    return axios.get(`/sites/${siteId}/reports/pah_fingerprinting/concentrations?sample_id=${sampleId}`)
  }
  catch (e) {
    console.error(e)
  }
}

export const createHistogramExport = (siteId, email, chartData) => {
  try {
    return axios({
      method: 'POST',
      url: `/sites/${siteId}/histogram_exports`,
      params: { email: email },
      responseType: 'arraybuffer',
      data: chartData
    })
  }
  catch (e) {
    console.error(e)
  }
}

export const getAlkylatedConstants = (siteId) => {
  try {
    return axios.get(`/sites/${siteId}/reports/double_ratio_plot/alkylated_constants.json`)
  }
  catch (e) {
    console.error(e)
  }
}

export const getAlkylatedContributionData = (siteId, sampleId) => {
  try {
    return axios.get(`/sites/${siteId}/reports/pah_fingerprinting/alkylated_concentrations?sample_id=${sampleId}`)
  }
  catch (e) {
    console.error(e)
  }
}


