import React, { useContext } from "react";
import { TierTwoContext } from "../tierTwoContext";

const SiteInformation = () => {
  const { siteInformation, landUse, grainSize } = useContext(TierTwoContext)

  return <>
    <div className='px-2'>
      <h2 className='flex flex-row justify-between items-center font-semibold bg-gray-700 text-white font-semibold px-2 mt-2 text-md py-1'>General Input Parameters:</h2>
      <table className='w-full text-sm px-2'>
        <tbody>
          <tr>
            <td className='font-semibold w-1/4'>Address/Legal Location/Site Name:</td>
            <td>{siteInformation}</td>
          </tr>
          <tr>
            <td className='font-semibold w-1/4'>Land Use Classification:</td>
            <td>{landUse}</td>
          </tr>
          <tr>
            <td className='font-semibold w-1/4'>Soil Type - Grain Size:</td>
            <td>{grainSize}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </>
}

export default SiteInformation
