import React, { useState } from 'react'
import axios from "axios";
import GuidelinesTable from "./guidelinesTable";
import DownloadTemplate from "./downloadTemplate";
import { useCustomGuidelinesAPI, useCustomGuidelinesContext } from "../../../contexts/customGuidelines";
import { addReferenceChemicalProperty } from "../../../helpers/customGuidelines";

const UploadGuidelines = () => {
  const [data, setData] = useState(null)
  const { site, newGuidelines } = useCustomGuidelinesContext()
  const { setNewGuidelines } = useCustomGuidelinesAPI()

  const handleFileInput = (e) => setData(e.target.files[0]);

  const parseCsv = (e) => {
    e.preventDefault()
    const formData = new FormData()
    formData.append('custom_guidelines', data);

    axios.post(`/sites/${site.id}/parse_custom_guidelines.json`, formData)
      .then(res => setNewGuidelines(addReferenceChemicalProperty(res.data)))
  }

  return <>
    <h3 className='font-semibold'>Upload Custom Guidelines</h3>
    <DownloadTemplate />
    <form className='flex flex-col mb-6' onSubmit={e => parseCsv(e)}>
      <input className="sv-file-input my-3" accept=".csv" type="file" onChange={handleFileInput} required/>
      <button
        type="submit"
        className="bg-blue-500 hover:bg-blue-700 text-white font-semibold py-1 px-4 border border-blue-700 rounded text-center w-28"
      >
        Upload
      </button>
    </form>
    {newGuidelines.length > 0 && <GuidelinesTable />}
  </>
}

export default UploadGuidelines
