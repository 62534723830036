import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Select from 'react-select'
import { defaultValue } from './helper'

const CustomSelect = ({ id, label, options, keyword, required, collectionKVS, handleInputChange }) => {
  const [metadataAliases, setMetadataAliases] = useState(null)
  const [defaultOption, setDefaultOption] = useState()
  const requiredLabel = required && <span className='text-xs text-gray-400'> (required)</span>
  
  useEffect(() => {
    axios.get(`/metadata_aliases`)
      .then(res => setMetadataAliases(res.data))
      .catch(err => console.log(err))
  }, [])
  
  useEffect(() => {
    if (metadataAliases) {
      const match = options.find(option => option.value === defaultValue(metadataAliases[keyword], collectionKVS))
      setDefaultOption(match)
    }
  }, [metadataAliases])
  
  useEffect(() => {
    handleInputChange({value: defaultOption?.value, id: id})
  }, [defaultOption])

  return (
    <>
      <p className='sv-label'>
        {label}
        {requiredLabel}
      </p>
      {defaultOption && 
        <Select
          className='w-full'
          defaultValue={defaultOption}
          options={options}
          onChange={(selectedOption) => handleInputChange({value: selectedOption.value, id: id})}
        />
      }
    </>
  );
}

export default CustomSelect
