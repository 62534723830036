import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Chart from './chart'
import ExportButton from './exportButton'
import { useDioxinsAndFuransAPI, useDioxinsAndFuransContext } from '../../contexts/dioxinsAndFurans'
import { generateChartOptions } from './chartHelpers/chartFunctions'

const SampleCharts = () => {
  const {site, selectedSample, highlightSampleIds, samples } = useDioxinsAndFuransContext()
  const { setSelectedSample, setHighlightSampleIds, setSelectedSampleMatchedReference, setSelectedSampleExceedanceInfo} = useDioxinsAndFuransAPI()
  const [data, setData] = useState(null)

  useEffect(() => {
    if (!highlightSampleIds.length) return
    const sample = samples.find(sample => sample.id === highlightSampleIds[0])
    setSelectedSample(sample)
  },[highlightSampleIds])

  useEffect(() => {
    axios.get(`/sites/${site.id}/dioxins_and_furans_sample_data?sample_id=${selectedSample.id}`)
      .then(res => {
        setData(res.data)
        setSelectedSampleMatchedReference(res.data.matched_reference)
        setSelectedSampleExceedanceInfo(res.data.exceedance_info)
      })
    setHighlightSampleIds([selectedSample.id])
  },[selectedSample])

  return (
    <div className='px-1'>
      <div className='flex py-1 mx-1'>
        <p className='font-semibold mr-1'>{selectedSample?.sample_name_for_json}</p>
        <ExportButton sampleId={selectedSample.id}/>
      </div>
      {data &&
        <div className='flex overflow-auto'>
          <Chart options={generateChartOptions(data?.sum_2378, '2,3,7,8 Sum', `Total Homologues: ${data.total_homologues}`)} />
          <Chart options={generateChartOptions(data?.relative_hom, 'Relative Homologues')} />
          <Chart options={generateChartOptions(data?.relative_teq, 'Relative TEQ', `Total Analytes: ${data.total_analyte}`)} />
          <Chart options={generateChartOptions(data?.total_hom, 'Total Homologues', `Total Dioxins and Furans: ${data.total_dioxins_and_furans}`)} />
          <Chart options={generateChartOptions(data?.unstandardized, 'Unstandardized')} />
          <Chart options={generateChartOptions(data?.mole_analyte, 'Mole Percent')} />
        </div>
      }
    </div>
  )
}

export default SampleCharts
