import React, { useContext } from "react";
import { TierOneContext } from "../tierOneContext";

const AdjacentLandUse = ({ title }) => {
  let [site, setSite] = useContext(TierOneContext);


  return (
    <div className='my-1'>
      <input 
        type="radio" 
        id={"adjacent-" + title} 
        name={"adjacent-" + title} 
        value={title}
        checked={site.siteDetails.adjacentLandUse === title}
        onChange={(event) => setSite((prevState) => ({...prevState, siteDetails: {...prevState.siteDetails, adjacentLandUse: event.target.value}}))}
      />
      <label htmlFor={"adjacent-" + title} className="ml-1">{title}</label>
    </div>
  )

}

export default AdjacentLandUse
