import React, { useState, useEffect, useMemo } from 'react'
import axios from 'axios'
import { usePcbContext, usePcbAPI } from '../../contexts/pcb'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import { pcbChartOptions } from './helpers/chartBuilder'
import NoSample from './noSample'
import ReferenceSelect from './referenceSelect'
import SampleExportButton from './export/sampleExportButton'

const Chart = () => {
  const { site, samples, highlightSampleIds, selectedSample, coElutionData, referenceSource } = usePcbContext()
  const { setSelectedSample, setHighlightSampleIds, setPcbCoelutedSample } = usePcbAPI()
  const [congeners, setCongeners] = useState(null)
  const [congenerGroups, setCongenerGroups] = useState(null)
  const [sumPcb, setSumPcb] = useState(null)
  const [referenceCongeners, setReferenceConeners] = useState(null)
  const [referenceCongenerGroups, setReferenceCongenerGroups] = useState(null)
  const [topReference, setTopReference] = useState(null)
  const [maxPcb, setMaxPcb] = useState(null)
  const [showXLabel, setShowXLabel] = useState(true)
  const [loading, setLoading] = useState(false)
  const dataLoaded = useMemo(() => sumPcb && congenerGroups && congeners && selectedSample && referenceSource && referenceCongeners && referenceCongenerGroups && topReference && maxPcb, [sumPcb, congenerGroups, congeners, selectedSample, referenceSource, referenceCongeners, referenceCongenerGroups, topReference, maxPcb])
  const resetChart = () => {
    setSumPcb(null)
    setCongenerGroups(null)
    setCongeners(null)
    setPcbCoelutedSample(null)
  }

  useEffect(() => {
    if (!highlightSampleIds.length) setSelectedSample(null)
    const sample = samples.find(sample => sample.id === highlightSampleIds[0])
    setSelectedSample(sample)
  },[highlightSampleIds])

  useEffect(() => {
    if (selectedSample?.id) {
      setLoading(true)
      axios.get(`/sites/${site.id}/pcb_fingerprinting_charts?sample_id=${selectedSample?.id}&reference_source=${referenceSource}`)
        .then(res => {
          resetChart()
          const sampleData = res.data.sample
          setCongenerGroups(sampleData.pcb_group_contributions)
          setCongeners(sampleData.chart_data)
          setPcbCoelutedSample(res.data.sample_raw_data)

          const referenceData = res.data.reference
          setReferenceConeners(referenceData.chart_data)
          setReferenceCongenerGroups(referenceData.pcb_group_contributions)

          setSumPcb(res.data.sample_sum_pcb)
          setTopReference(res.data.cosine_theta)

          const maxSamplePcb = Math.max(...sampleData.chart_data.map(congener => congener.value))
          const maxReferencePcb = Math.max(...referenceData.chart_data.map(congener => congener.value))
          setMaxPcb(Math.ceil(Math.max(maxSamplePcb, maxReferencePcb)))
        })
        .catch(err => console.log(err))
        .finally(() => setLoading(false))
      setHighlightSampleIds([selectedSample.id])
    } else {
      resetChart()
    }
  },[selectedSample, coElutionData, referenceSource])

  const histograms = (title, congenerGroups, congeners, showXLabel, maxPcb, inPreview) => {
    return (
      <div>
        <HighchartsReact
          highcharts={Highcharts}
          options={pcbChartOptions(title, congenerGroups, congeners, showXLabel, maxPcb, inPreview)}
        />
      </div>
    )
  }

  return (
    <div className='flex overflow-auto h-full'>
      {loading ?
        <div className='w-full h-full flex items-center justify-center'>
          <p className='p-2 text-xl font-semibold uppercase text-gray-500'><i className='fa fa-spinner fa-spin'/> Loading...</p>
        </div>
        :
        (dataLoaded ? (
          <div className='flex flex-col w-full'>
            <div className='flex items-center justify-between mb-1'>
              <div className='flex items-center'>
                <p className='ml-2 mr-1 font-semibold'>{selectedSample.sample_name_for_json}</p>
                <SampleExportButton sampleId={selectedSample.id}/>
              </div>
              <div className='flex items-center'>
                <ReferenceSelect />
                <label className='ml-2 text-xs font-semibold mr-1'>Show Labels:</label>
                <input type='checkbox' className='mr-2' checked={showXLabel} onChange={() => setShowXLabel(!showXLabel)} />
              </div>
            </div>
            {histograms(`Sum PCB: ${sumPcb.toFixed(2)}`, congenerGroups, congeners, showXLabel, maxPcb, false)}
            {histograms(`${topReference.name} - ${topReference.similarity}% similarity`, referenceCongenerGroups, referenceCongeners, showXLabel, maxPcb, false)}
          </div>
        )
        : 
        <NoSample />
        )
      }
    </div>
  )
}

export default Chart
