import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import axios from 'axios';
import { buildCongenerSeries, buildParentSeries, buildPahChart } from '../helpers/buildExtendedPahChart';
import { handleCogradData } from '../helpers/chartHelpers';

const BarChart = ({ sample, site, noTitle, labSource }) => {
  const [categories, setCategories] = useState([]);
  const [data, setData] = useState([]);
  const [chartOptions, setChartOptions] = useState({});
  const [totalConcentration, setTotalConcentration] = useState();

  useEffect(() => {
    axios
      .get(`/sites/${site.id}/reports/pah_fingerprinting/alkylated_concentrations?sample_id=${sample.id}&lab_source=${labSource}`)
      .then(res => {
        const categoryList = res.data.chemical_group.map(item => item.label);
        const data = buildParentSeries(res.data.chemical_group).concat(buildCongenerSeries(res.data.chemical_group))
        const processedData = labSource === 'COGRAD' ? handleCogradData(data) : data
        setData(processedData)
        setCategories(categoryList);
        setTotalConcentration(res.data.total_concentration);
      })
      .catch(err => {
        console.log(err);
      });
  }, [labSource]);

  useEffect(() => {
    if (data.length > 0 && categories.length > 0) {
      const title = noTitle ? '' : `${sample.sample_name_for_json} </br> SUM PAH: ${totalConcentration} mg/Kg`
      setChartOptions(buildPahChart(title , 'Contribution (%)', data, categories, '%'))
    }
  }, [data, categories, totalConcentration]);

  return (
    <div>
      {categories && categories.length > 0 && chartOptions &&
        <HighchartsReact highcharts={Highcharts} options={chartOptions} /> 
      }
    </div>
  );
};

export default BarChart;
