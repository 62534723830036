import React from "react";
import { downloadCSV, deleteUpload } from "./helperFunctions";

const TableRows = ({ site, upload, hasSamples, uploads, setUploads }) => {
  return (
    <>
      <tr key={upload.id} className="text-sm">
        <td className="text-left">{upload.name}</td>
        <td>{upload?.row_count?.imported}</td>
        <td>{upload.element_import ? 'Yes' : 'No'}</td>
        <td>
          {
            hasSamples && upload.element_import ? (
              <a href={`/sites/${site.id}/site_uploads/${upload.id}/preview`} className="text-blue-500 underline">
                Map Chemicals
              </a>) 
              : 
              null
          }
        </td>
        <td>
          <a href={`/sites/${site.id}/site_uploads/${upload.id}/parameter_selection`} className="text-blue-500 underline">
            View Parameters
          </a>
        </td>
        <td className="flex justify-center items-center my-1">
          <button className='btn-action primary mr-1' onClick={() => downloadCSV(upload, site, 'site_uploads')} title="Download File"><i className="fa fa-download text-gray-700 cursor-pointer"/> Download</button>
          <button className='btn-action delete' onClick={() => deleteUpload(upload, site, 'site_uploads', uploads, setUploads)} title="Delete File"><i className="fa fa-trash-alt text-red-500 cursor-pointer" /> Delete</button>
        </td>
      </tr>
    </>
  );
};

export default TableRows;
