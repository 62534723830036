import React, {useState} from 'react';

const InvalidCSVModal = ({duplicatedSamplesCSV, abortUpload, siteTitle}) => {
  const [showMessage, setShowMessage] = useState(false)
  
  const duplicatedSamples = duplicatedSamplesCSV.map((sample, i) => (
    <tr key={i}>
      <td>{sample.sample_name}</td>
      <td>{sample.lines.join(",")}</td>
    </tr>
  ));
  
  const message = (
    <div className='text-center'>
      <p className='my-1 text-sm font-semibold'>Statvis detects duplicate samples based on matrix:</p>
      <p className='my-1 text-xs'><span className='font-bold'>Groundwater:</span> Sample Location and Sample Date</p>
      <p className='my-1 text-xs'><span className='font-bold'>Soil:</span> Sample Location, Depth and Sample Date</p>
    </div>
  );
  
  const handleExport = () => {
    let csvContent = "Sample Name,CSV Rows\n";
    duplicatedSamplesCSV.forEach((sample) => {
      const sampleRow = `"${sample.sample_name}","${sample.lines.join(",")}"\n`;
      csvContent += sampleRow;
    });
  
    const element = document.createElement("a");
    const file = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
    element.href = URL.createObjectURL(file);
    element.download = `${siteTitle}_duplicate_samples.csv`;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };
  
  return (
    <>
      <div className='modal-container fixed inset-0 flex items-center justify-center z-50'>
        <div className='modal-overlay absolute inset-0 bg-gray-900 opacity-50'></div>
        <div className='modal-content-container bg-white min-w-3/12 h-auto rounded shadow-lg z-50 overflow-y-auto flex justify-center items-center'>
          <div className='modal-content p-5'>
            <div className='flex flex-col justify-center items-center'>
              <h1 className='text-xl font-semibold text-center'>Invalid CSV File</h1>
              <p className='mb-1'>{duplicatedSamplesCSV.length} Duplicate {duplicatedSamplesCSV.length > 1 ? 'samples' : 'sample'} in CSV detected:</p>
              <div className='overflow-y-scroll max-h-36'>
                <table className='table'>
                  <thead>
                    <tr>
                      <th>Sample Name</th>
                      <th>CSV Row</th>
                    </tr>
                  </thead>
                  <tbody>
                    {duplicatedSamples}
                  </tbody>
                </table>
              </div>
              <p className='mt-3 mb-1 text-sm'>Please update your CSV and upload again. 
                <span 
                  className='text-gray-500 ml-1 cursor-pointer text-sm font-semibold hover:text-gray-700'
                  onClick={() => setShowMessage(!showMessage)}>
                  Learn more...
                </span>
              </p>
              {showMessage && message}
              <div className='flex justify-center items-center'>
                <button className='btn btn-default' onClick={abortUpload}>Abort Upload</button>
                <button className='btn btn-primary mr-2' onClick={handleExport}>Download Table</button>
              </div>
            </div>
          </div>
        </div>
      </div>
  
    </>
  )
}

export default InvalidCSVModal
