import React, { useState, useEffect } from "react"
import axios from "axios"
import ValueSelectors from "./filterHelpers/valueSelectors"
import DateSelectors from "./filterHelpers/dateSelectors"
import SampleGroupDropdown from "./filterHelpers/sampleGroupDropdown"
import ParameterDropdown from "./filterHelpers/parameterDropdown"
import { filterChartData, filterHighlightedSamples } from "./filterHelpers/filterFunctions"
import { useGroundwaterAPI, useGroundwaterContext } from "../../contexts/groundwater"
import { applyColor } from "./helpers"

const Selectors = ({ parameters }) => {
  const { setCurrentParameter, setHighlightedChartData, setHighlightedTableData, setHighlightedGeojson, setChart, setHighlightSampleIds, togglePopups, toggleLegends } = useGroundwaterAPI()
  const { locationIds, siteId, tableData, chart, geojson, parameter, highlightSampleIds, showLegends, showPopups } = useGroundwaterContext()
  const [showFilterOptions, setShowFilterOptions] = useState(false)
  const [reload, setReload] = useState(false)
  const [dateRange, setDateRange] = useState({start: '', end: ''})
  const [valueRange, setValueRange] = useState({start: '', end: ''})
  const [sampleGroup, setSampleGroup] = useState('')
  const hasHighlightedSamples = highlightSampleIds.length > 0
  
  const legendClass = showLegends ? 'bg-blue-200 hover:bg-blue-100' : 'bg-white hover:bg-gray-100'
  const popupClass = showPopups ? 'bg-blue-200 hover:bg-blue-100' : 'bg-white hover:bg-gray-100'
  const filterClass = showFilterOptions ? 'bg-blue-200 hover:bg-blue-100' : 'bg-white hover:bg-gray-100'
  
  useEffect(() => {
    if (hasHighlightedSamples) {
      const { displayedChartData, displayedTableData, displayedGeojson } = filterHighlightedSamples(chart, tableData, geojson, highlightSampleIds)
      
      setHighlightedChartData(displayedChartData)
      setHighlightedTableData(displayedTableData)
      setHighlightedGeojson(displayedGeojson)
    } else {
      setHighlightedChartData({})
      setHighlightedTableData([])
      setHighlightedGeojson(null)
    }
    
  }, [highlightSampleIds])
  
  useEffect(() => {
    if (hasHighlightedSamples) {
      const filteredChartData = filterChartData(chart, sampleGroup, valueRange)
      setChart(filteredChartData)
    }
  },[chart])
  
  const getChartData = () => {
    const urlParams = new URLSearchParams({
      chemical_id: parameter.id,
      start_date: dateRange.start,
      end_date: dateRange.end,
      location_ids: locationIds.join(","),
    })

    axios.get(`/sites/${siteId}/groundwater_chart_data?${urlParams.toString()}`)
    .then((response) => {
      setCurrentParameter(parameter)
      setHighlightSampleIds([])
      if (sampleGroup || valueRange.start || valueRange.end) {
        setChart(filterChartData(applyColor(response.data.chartData), sampleGroup, valueRange))
      } else {
        setChart(applyColor(response.data.chartData))
      }
    })
    .catch((error) => console.log(error))
  }
  
  const handleClear = () => {
    setHighlightSampleIds([])
    setDateRange({ start: "", end: "" })
    setSampleGroup("")
    setValueRange({ start: "", end: "" })
    setReload(true)
  }

  useEffect(() => {
    if (reload) {
      getChartData()
      setReload(false)
    }
  }, [reload])
  
  const showPanel = (
    <div className="flex justify-between items-center bg-gray-300 text-center text-xs h-6 py-4 ">
      <div className="w-1/3 pl-2 flex">
        <div className={`flex items-center justify-center rounded-md cursor-pointer h-7 w-7 mr-1 border border-black ${legendClass}`} onClick={toggleLegends} title="Show Legends">
          <i className="fa fa-list text-lg"></i>
        </div>
        <div className={`flex items-center justify-center rounded-md cursor-pointer h-7 w-7 mr-1 border border-black ${popupClass}`} onClick={togglePopups} title="Show Labels">
          <i className="fa fa-comment text-lg"></i>
        </div>
        <div className={`flex items-center justify-center rounded-md cursor-pointer h-7 w-7 mr-1 border border-black hover:bg-gray-100 ${filterClass}`} onClick={()=> setShowFilterOptions(!showFilterOptions)} title="Show Labels">
          <i className="fa fa-filter text-lg"></i>
        </div>
        <ParameterDropdown parameters={parameters} getChartData={getChartData}/></div>
      <div className="w-1/3"></div>
      <div className="w-1/3"></div>
    </div>
  )

  return (
    <>
      {showFilterOptions &&
        <div className="absolute top-0 z-30 w-full bg-gray-200">
          {showPanel}
          <div className="flex justify-center pb-1">
            <SampleGroupDropdown sampleGroup={sampleGroup} setSampleGroup={setSampleGroup} tableData={tableData}/>
            <DateSelectors dateRange={dateRange} setDateRange={setDateRange}/>
            <ValueSelectors valueRange={valueRange} setValueRange={setValueRange}/>
            <button className="btn btn-small bg-gray-600 hover:bg-gray-500 text-gray-100 hover:text-white rounded" onClick={getChartData}>Filter</button>
            <button className="btn btn-small bg-gray-600 hover:bg-gray-500 text-gray-100 hover:text-white rounded" onClick={handleClear}>Reset</button>
          </div>
        </div>
      }
      {showPanel}
    </>
  )
}

export default Selectors
