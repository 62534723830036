import React, { createContext, useReducer, useMemo, useContext, useEffect } from 'react'

/* Actions */
const UPDATE_STATE = 'UPDATE_STATE'
const TOGGLE_SHOW_POPUPS = 'TOGGLE_SHOW_POPUPS'
const TOGGLE_ANNOTATIONS = 'TOGGLE_ANNOTATIONS'
const TOGGLE_RULER_MODE = 'TOGGLE_RULER_MODE'
const TOGGLE_LEGENDS = 'TOGGLE_LEGENDS'
const TOGGLE_WATER_LAYER = 'TOGGLE_WATER_LAYER'
const TOGGLE_SURFICIAL_GEOLOGY_LAYER = 'TOGGLE_SURFICIAL_GEOLOGY_LAYER'
const TOGGLE_AER_PIPELINE_LAYER = 'TOGGLE_AER_PIPELINE_LAYER'

/* Initial States */
const initialState = () => ({
    bounds: null,
    geoJSON: null,
    siteData: null,
    geospatialFeatures: null,
    guidelinesData: null,
    mapType: 'dataVault',
    showTable: true,
    tableFullscreen: false,
    displayedGeojson: null,
    displayedSamples: null,
    filteredGeoJSON: null,
    filteredSamples: null,
    showPopups: false,
    popups: [],
    showAnnotations: false,
    mapDrawOpen: false,
    geoPopups: {},
    loading: true,
    highlightSampleIds: [],
    polygonArea: null,
    rulerMode: false,
    distance: null,
    showLegends: false,
    showWaterLayer: false,
    layerMenuOpen: false,
    showSurficialGeologyLayer: false,
    showAerPipelineLayer: false
})

/* Reducer */
const dataVaultReducer = (state, action) => {
    switch (action.type) {
        case UPDATE_STATE:
            return { ...state, [action.field]: action.value }
        case TOGGLE_SHOW_POPUPS:
            return { ...state, showPopups: !state.showPopups }
        case TOGGLE_ANNOTATIONS:
            return { ...state, showAnnotations: !state.showAnnotations }
        case TOGGLE_RULER_MODE:
            return { ...state, rulerMode: !state.rulerMode }
        case TOGGLE_LEGENDS:
            return { ...state, showLegends: !state.showLegends }
        case TOGGLE_WATER_LAYER:
            return { ...state, showWaterLayer: !state.showWaterLayer }
        case TOGGLE_SURFICIAL_GEOLOGY_LAYER:
            return { ...state, showSurficialGeologyLayer: !state.showSurficialGeologyLayer }
        case TOGGLE_AER_PIPELINE_LAYER:
            return { ...state, showAerPipelineLayer: !state.showAerPipelineLayer }
        default:
            return state
    }
}

/* Contexts */
const DataVaultContext = createContext(initialState)
const DataVaultAPIContext = createContext({
    updateState: () => {},
    setGeoJSON: () => {},
    setSiteData: () => {},
    setGeospatialFeatures: () => {},
    setGuidelinesData: () => {},
    setShowTable: () => {},
    setFilteredGeoJSON: () => {},
    setFilteredSamples: () => {},
    setDisplayedGeojson: () => {},
    setDisplayedSamples: () => {},
    toggleShowPopups: () => {},
    setPopups: () => {},
    setMapDrawOpen: () => {},
    setLoading: () => {},
    setHighlightSampleIds: () => {},
    setTableFullscreen: () => {},
    setPolygonArea: () => {},
    toggleRulerMode: () => {},
    setDistance: () => {},
    toggleLegends: () => {},
    toggleWaterLayer: () => {},
    setLayerMenuOpen: () => {},
    toggleSurficialGeologyLayer: () => {},
    toggleAerPipelineLayer: () => {}
})

/* Providers */
export const DataVaultProvider = ({children, initialData}) => {
    const [state, dispatch] = useReducer(dataVaultReducer, initialState(initialData))

    const api = useMemo(() => {
        const updateState = (field, value) => dispatch({ type: UPDATE_STATE, field, value })
        const setGeoJSON = (newGeoJSON) => updateState('geoJSON', newGeoJSON)
        const setSiteData = (newSiteData) => updateState('siteData', newSiteData)
        const setGeospatialFeatures = (newGeospatialFeatures) => updateState('geospatialFeatures', newGeospatialFeatures)
        const setGuidelinesData = (newGuidelinesData) => updateState('guidelinesData', newGuidelinesData)
        const setBounds = (newBounds) => updateState('bounds', newBounds)
        const setShowTable = (newShowTable) => updateState('showTable', newShowTable)
        const setFilteredGeoJSON = (newFilteredGeoJSON) => updateState('filteredGeoJSON', newFilteredGeoJSON)
        const setFilteredSamples = (newFilteredSamples) => updateState('filteredSamples', newFilteredSamples)
        const setDisplayedGeojson = (newDisplayedGeojson) => updateState('displayedGeojson', newDisplayedGeojson)
        const setDisplayedSamples = (newDisplayedSamples) => updateState('displayedSamples', newDisplayedSamples)
        const setPopups = (newPopups) => updateState('popups', newPopups)
        const setMapDrawOpen = (newMapDrawOpen) => updateState('mapDrawOpen', newMapDrawOpen)
        const setLoading = (newLoading) => updateState('loading', newLoading)
        const setHighlightSampleIds = (newHighlightSampleIds) => updateState('highlightSampleIds', newHighlightSampleIds)
        const setTableFullscreen = (newTableFullscreen) => updateState('tableFullscreen', newTableFullscreen)
        const setPolygonArea = (newPolygonArea) => updateState('polygonArea', newPolygonArea)
        const setDistance = (newDistance) => updateState('distance', newDistance)
        const toggleShowPopups = () => dispatch({ type: TOGGLE_SHOW_POPUPS })
        const toggleAnnotations = () => dispatch({ type: TOGGLE_ANNOTATIONS })
        const toggleRulerMode = () => dispatch({ type: TOGGLE_RULER_MODE })
        const toggleLegends = () => dispatch({ type: TOGGLE_LEGENDS })
        const toggleWaterLayer = () => dispatch({ type: TOGGLE_WATER_LAYER })
        const setLayerMenuOpen = (newLayerMenuOpen) => updateState('layerMenuOpen', newLayerMenuOpen)
        const toggleSurficialGeologyLayer = () => dispatch({ type: TOGGLE_SURFICIAL_GEOLOGY_LAYER })
        const toggleAerPipelineLayer = () => dispatch({ type: TOGGLE_AER_PIPELINE_LAYER })

        return { updateState, setGeoJSON, setSiteData, setGeospatialFeatures, setGuidelinesData, setShowTable, setDisplayedGeojson, setDisplayedSamples, setFilteredGeoJSON, setFilteredSamples, toggleShowPopups, setPopups, toggleAnnotations, setMapDrawOpen, setBounds, setLoading, setHighlightSampleIds, setTableFullscreen, setPolygonArea, toggleRulerMode, setDistance, toggleLegends, toggleWaterLayer, setLayerMenuOpen, toggleSurficialGeologyLayer, toggleAerPipelineLayer}
    }, [])
    

    return (
        <DataVaultAPIContext.Provider value={api}>
          <DataVaultContext.Provider value={state}>
            {children}
          </DataVaultContext.Provider>
        </DataVaultAPIContext.Provider>
    )
}

/* Custom Context Hooks */
export const useDataVaultContext = () => useContext(DataVaultContext)
export const useDataVaultAPI = () => useContext(DataVaultAPIContext)
