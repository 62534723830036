import React, { useState } from "react"
import axios from "axios"

const GeoInputPopup = ({closePopup, feature, siteId, createPopup, map, coordinates, setGeoSpatialFeatures, setGeoPopups, toast}) => {
  const [label, setLabel] = useState('')

  const featureData = {
    name: feature.id,
    feature_type: feature.geometry.type,
    coordinates: feature.geometry.coordinates,
    label: label || null,
    site_id: siteId
  }

  const postFeatureData = () => {
    axios.post('/geospatial_features', { geospatial_feature: featureData })
      .then((response) => {
        const newFeatureData = {id: response.data.id, ...featureData}
        setGeoSpatialFeatures(prev => [...prev, newFeatureData])
        toast('Feature Created')
      })
      .catch((error) => console.log(error))
  }

  const submitHandler = () => {
    closePopup()
    postFeatureData()

    if (label) {
      const labelPopup = createPopup(map, coordinates, `<div class='w-auto h-auto flex items-center bg-gray-300/75'><span class='p-0'>${label}</p></div>`)
      setGeoPopups(prev => ({...prev, [feature.id]: labelPopup}))
    }
  }

  return (
    <div className='flex'>
      <input type="text" id="popup-input" className='w-full p-1 text-gray-700 appearance-none focus:outline-none border border-gray-200 focus:shadow-inner m-0 rounded' placeholder="Enter label or leave blank" onChange={(e) => setLabel(e.target.value)}/>
      <button id="popup-submit" className='border border-gray-500 rounded ml-1 p-1 bg-gray-200 hover:bg-gray-100' onClick={submitHandler}>Submit</button>
    </div>
  )
}

export default GeoInputPopup
