import React, { useState } from "react"
import Select from "react-select";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const AssignFromElement = ({ site, userSites, elementImportId, matrices }) => {
  const [selectedSite, setSelectedSite] = useState(null)
  const [selectedMatrix, setSelectedMatrix] = useState(null)

  const matrixOptions = matrices.map(matrix => {
    return { value: matrix.id, label: matrix.title }
  })

  const siteOptions = userSites.map(site => {
    return { value: site.id, label: site.title }
  })

  const continueWithNewSite = () => {
    if (!selectedMatrix) {
      toast.error("Please select a matrix to continue")
    } else {
      axios.post(`/sites/create_element_site_upload.json`, { element_import_id: elementImportId, matrix_id: selectedMatrix.value})
        .then(_ => window.location.href = `/sites/${site.id}/edit`)
    }
  }

  const assignDataToSite = () => {
    if (!selectedMatrix) {
      toast.error("Please select a matrix to continue")
    } else {
      axios.post('/sites/migrate_element_site.json', { site_id: selectedSite.value, element_import_id: elementImportId, matrix_id: selectedMatrix.value })
        .then(res => window.location.href = `/sites/${selectedSite.value}/site_uploads/${res.data.siteUploadId}/preview`)
    }
  }

  return <>
    <ToastContainer />
    <h1>Element Import Tool</h1>
    <h2 className="font-semibold my-3">Please select a matrix:</h2>
    <Select
      className='w-1/5'
      options={matrixOptions}
      onChange={event => setSelectedMatrix(event)}
    />
    <h2 className="font-semibold my-3">Please choose one of the following:</h2>
    <div className="flex flex-col gap-y-3">
      <div className='flex flex-col w-2/5'>
        <p>Create a new site in Statvis:</p>
        <button
          className="btn btn-primary text-center w-2/5"
          onClick={continueWithNewSite}
        >
          Continue
        </button>
      </div>
      <p className="font-semibold">OR</p>
      <p>Assign data to an existing site in Statvis:</p>
      <div className='flex flex-col w-2/5'>
        <Select
          options={siteOptions}
          onChange={event => setSelectedSite(event)}
          className="text-xs"
          styles={{
            singleValue: (provided, _) => ({
              ...provided,
              whiteSpace: 'normal',
            }),
          }}
        />
        <button
          className="btn btn-primary text-center w-2/5"
          onClick={assignDataToSite}
        >
          Assign
        </button>
      </div>
    </div>
  </>
}

export default AssignFromElement
