import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HCMore from 'highcharts/highcharts-more'
HCMore(Highcharts)

const Chart = ({ options }) => {
  return (
    <div className='border border-gray-700 mx-1'>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  )
}

export default Chart
