import React from "react";

const MandatoryGuidelines = ({ mandatoryGuidelines }) =>{
  return <>
    <div className='my-4'>
      <h3 className="font-semibold">Mandatory Tier 2 Guidelines</h3>
      <div className='flex flex-row justify-between my-1'>
        <span>Source of volatile contaminants present within 30cm of a building foundation? </span>
        <span className="font-semibold">{mandatoryGuidelines.volatileContaminant}</span>
      </div>
      <div className='flex flex-row justify-between my-1'>
        <span>Unique building features, including earthen floors or unsually low air exchange rates? </span>
        <span className="font-semibold">{mandatoryGuidelines.buildingFeature}</span>
      </div>
      <div className='flex flex-row justify-between my-1'>
        <span>Sensitive receptors that are present but not accounted for in the Tier 1 land use description? </span>
        <span className="font-semibold">{mandatoryGuidelines.sensitiveReceptor}</span>
      </div>
      <div className='flex flex-row justify-between my-1'>
        <span>Groundwater flow to stagnant water bodies? </span>
        <span className="font-semibold">{mandatoryGuidelines.stagnant}</span>
      </div>
      <div className='flex flex-row justify-between my-1'>
        <span>Soil or groundwater contamination present within 10m of a surface water body </span>
        <span className="font-semibold">{mandatoryGuidelines.contaminatedWater}</span>
      </div>
      <div className='flex flex-row justify-between my-1'>
        <span>Very coarse textured materials enhancing groundwater or vapour transport? </span>
        <span className="font-semibold">{mandatoryGuidelines.coarse}</span>
      </div>
      <div className='flex flex-row justify-between my-1'>
        <span>Contamination in fractured bedrock?</span>
        <span className="font-semibold">{mandatoryGuidelines.contaminationBedrock}</span>
      </div>
      <div className='flex flex-row justify-between my-1'>
        <span>Contaminant source length parallel to groundwater flow greater than 10 meters? </span>
        <span className="font-semibold">{mandatoryGuidelines.contaminatedSource}</span>
      </div>
    </div>
  </>
}

export default MandatoryGuidelines
