import React, { useMemo } from 'react'
import BaseMap from '../map/baseMap'
import { usePcbContext, usePcbAPI } from '../../contexts/pcb'

const BaseMapContainer = () => {
  const { geojson, highlightSampleIds, showLegends, showPopups, mapType, filteredGeojson } = usePcbContext()
  const { setHighlightSampleIds } = usePcbAPI()
  const currentGeojson = useMemo(() => filteredGeojson || geojson, [filteredGeojson, geojson])

  return (
    <BaseMap 
      geojson={currentGeojson} 
      highlightSampleIds={highlightSampleIds} 
      setHighlightSampleIds={setHighlightSampleIds} 
      showLegends={showLegends} 
      showPopups={showPopups} 
      mapType={mapType}
    />
  )
}

export default BaseMapContainer
