/** This helper initialized the datasource for the map.
*
* @returns the geojson if the map is for a site page, or a merged geojson if the map is for the index page.
*/
export function initializeDatasource(mapType, geojson) {
  if (mapType === 'index') {
    // This consolidates the features of each feature collection into one for the index page
    const mergedFeatureCollections = geojson.reduce((acc, fc) => {
      // Return if a site doesn't have any sample_locations
      if (!fc) return acc;
      fc.features.forEach(feature => acc.features.push(feature));
      return acc;
    }, { "type": "FeatureCollection", "features": [] });
    return mergedFeatureCollections
  }
  return geojson;
}
