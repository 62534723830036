import React from "react";
import axios from "axios";

const PrintPdf = ({ guideline_answers, site_information }) => {
  const exportPdf = () => {
    axios({
      method: 'GET',
      url: '/guidelines/tier_one_guidelines/pdf_export',
      responseType: 'arraybuffer',
      params: { guideline_answers: guideline_answers, site_information: site_information }
    }).then((response) => {
      let blob = new Blob([response.data], { type: 'application/pdf' })
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'TierOneGuidelines.pdf'
      link.click()
    }).catch((err) => console.log(err))
  }

  return <>
    <div className='container mt-4'>
      <button className="my-3 w-40 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={exportPdf}>
        Print Output
      </button>
    </div>
  </>
}

export default PrintPdf
