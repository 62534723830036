import React, { useContext } from "react";
import { TierOneContext } from "../tierOneContext";
import GeneralInformationTable from "./generalInformationTable";
import Contaminants from "./contaminants";
import MandatoryGuidelines from "./mandatoryGuidelines";
import SiteDetails from "./siteDetails";
import { hasMandatoryGuideline } from "../../helpers"

const Answers = ({ setShowAnswers }) => {
  let [site, setSite] = useContext(TierOneContext);

  const isMandatory = hasMandatoryGuideline(site.mandatoryGuidelines)

  const submitFormData = () => {
    let confirmation = confirm('Are you sure you want to submit these answers?')

    if (confirmation) {
      const params = `boundary=${site.siteDetails.boundary}&sensitive=${site.siteDetails.sensitive}&wellsite=${site.siteDetails.wellsite}&organic=${site.siteDetails.organicSoil}&onsite=${site.siteDetails.onsite}&disposed=${site.siteDetails.disposed}&contaminants=${site.contaminants}&mandatory=${isMandatory}&name=${site.siteIdentification.name}&land_use=${site.siteIdentification.land_use}&grain_size=${site.siteIdentification.grain_size}`

      const url = "/guidelines/tier_one_guidelines/guidelines?" + params

      window.location.href = url
    }
  }

  return <>
    <div className='container ml-4 w-2/3'>
      <div className='flex flex-row justify-between'>
        <h1>Please review your answers</h1>
        <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold p-1 border border-blue-700 rounded w-24"
            onClick={() => setShowAnswers(false)}
          >
            Edit
        </button>
      </div>
      <GeneralInformationTable site={site} />
      {isMandatory == 'No' && <SiteDetails siteDetails={site.siteDetails} />}
      <MandatoryGuidelines mandatoryGuidelines={site.mandatoryGuidelines} />
      {isMandatory == 'No' && <Contaminants contaminants={site.contaminants} />}
      <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded w-24 float-right"
          onClick={submitFormData}
        >
          Submit
      </button>
    </div>
  </>
}

export default Answers
