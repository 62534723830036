import React, { useEffect } from 'react'

const Chemical = ({ chemical, index, setChemicals }) => {
  useEffect(() => {
    setChemicals(prevState => {
      const updatedChemicals = [...prevState]
      updatedChemicals[index]['order'] = index + 1
      return updatedChemicals
    })
  }, [index])

  return <>
    <div
      className={`p-2 rounded shadow-sm cursor-move border border-gray-300 my-1`}
    >
      <p>{index + 1}. {chemical.name}</p>
    </div>
  </>
}

export default Chemical
