import React, { useEffect } from 'react'
import Select from "react-select";
import { useSiteContext, useSiteAPI } from "../../../contexts/site";

const SoilAndGroundwaterUsage = () => {
  const { usageOptions, soilUsage } = useSiteContext()
  const { setSoilUsage } = useSiteAPI()

  return (
    <div className='w-1/4'>
      <label className='font-semibold'>Soil & Groundwater Land Use</label>
      <Select
        placeholder={'Soil & Groundwater Land Use'}
        options={usageOptions}
        value={soilUsage}
        onChange={selectedOption => setSoilUsage(selectedOption)}
      />
    </div>
  )
}

export default SoilAndGroundwaterUsage
