import React, { useEffect, useState } from "react";
import Select from "react-select";
import axios from "axios";
import { useParameterSelectApi } from "../../contexts/parameterSelection";

const MetaDataSelect = ({ title, options, field, keyword }) => {
  const [metadataAliases, setMetadataAliases] = useState(null)
  const [defaultOption, setDefaultOption] = useState(null)
  const { setGroundwaterMetaData } = useParameterSelectApi()
  
  useEffect(() => {
    axios.get(`/metadata_aliases`)
      .then(res => setMetadataAliases(res.data))
      .catch(err => console.log(err))
  }, [])
  
  useEffect(() => {
    if (metadataAliases) {
      const match = options.find(option => metadataAliases[keyword]?.includes(option.label))
      setDefaultOption(match || { label: '-', value: "" })
    }
  }, [metadataAliases])
  
  useEffect(() => {
    setGroundwaterMetaData(field, defaultOption?.value)
  }, [defaultOption])
  
  return <>
    {options && defaultOption &&
      <div className="input w-full">
        <label className="sv-label">{title}</label>
        <Select
          className='w-full'
          defaultValue={defaultOption}
          options={options}
          onChange={(selectedOption) => setGroundwaterMetaData(field, selectedOption.value)}
        />
      </div>
    }

  </>
}

export default MetaDataSelect
