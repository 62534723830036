import React from "react";

const SampleGroupDropdown = ({ sampleGroup, setSampleGroup, tableData }) => {
  const sampleGroups = [...new Set(tableData.map((sample) => sample.group))].filter((group) => group !== null);;
  const sampleGroupOptions = sampleGroups.map((group) => (
    <option key={group} value={group}>
      {group}
    </option>
  ))
  const hasGroupOptions = sampleGroupOptions.length > 0
  const labelColor = hasGroupOptions ? 'text-gray-600' : 'text-gray-400'
  
  return (
    <div className="flex flex-col mr-2">
    <label className={`font-bold tracking-wider uppercase text-xs my-1 ${labelColor}`}>Sample Group: </label>
    <select
      className="sv-filters-select"
      value={sampleGroup}
      onChange={(e) => setSampleGroup(e.target.value)}
      disabled={!hasGroupOptions}
    >
      <option value="">Select Sample Group</option>
      {sampleGroupOptions}
    </select>
  </div>
  )
}

export default SampleGroupDropdown
