export const filterChartData = (chartData, sampleGroup, valueRange) => {
  const filteredDatasets = { datasets: [] };

  chartData.datasets.forEach((set) => {
    const data = set.data.filter((sampleData) => {
      let isSampleGroupMatch = true;
      let isValueRangeMatch = true;

      if (sampleGroup) {
        isSampleGroupMatch = sampleData.sample_group === sampleGroup;
      }

      if (valueRange.start || valueRange.end) {
        if (valueRange.start && valueRange.end) {
          isValueRangeMatch = sampleData.y >= valueRange.start && sampleData.y <= valueRange.end;
        } else if (valueRange.start) {
          isValueRangeMatch = sampleData.y >= valueRange.start;
        } else if (valueRange.end) {
          isValueRangeMatch = sampleData.y <= valueRange.end;
        }
      }

      return isSampleGroupMatch && isValueRangeMatch;
    });

    if (data.length > 0) {
      filteredDatasets.datasets.push({ ...set, data });
    }
  });

  return filteredDatasets;
};

export const filterHighlightedSamples = (chart, tableData, geojson, highlightSampleIds) => {
  const displayedChartData = { datasets: [] };
  const displayedTableData = [];
  const displayedGeojson = {
    type: "FeatureCollection",
    features: [],
  };
  
  chart?.datasets.forEach((set) => {
    const data = set.data.filter((sampleData) => {
      return highlightSampleIds.includes(sampleData.sample_id);
    });
    
    if (data.length > 0) {
      displayedChartData.datasets.push({ ...set, data });
    }
  });
  
  tableData.forEach((dataRow) => {
    if (highlightSampleIds.includes(dataRow.id)) {
      displayedTableData.push(dataRow);
    }
  });
  
  geojson.features.forEach((feature) => {
    if (highlightSampleIds.includes(feature.properties.sample_id)) {
      displayedGeojson.features.push(feature);
    }
  });
  
  return { displayedChartData, displayedTableData, displayedGeojson };
};
