import React, { useEffect } from "react"
import { useGroundwaterContext, useGroundwaterAPI } from "../../../contexts/groundwater"

const ParameterDropdown = ({ parameters, getChartData }) => {
  const { setParameter } = useGroundwaterAPI()
  const { parameter } = useGroundwaterContext()
  const parameterOptions = parameters.map((param) => (
    <option key={param.id} value={param.id}>
      {param.name}
    </option>
  ))
  
  const handleParameterChange = (e) => {
    const selectedParameter = parameters.find(parameter => parameter.id === parseInt(e.target.value))
    setParameter(selectedParameter)
  }
  
  useEffect(() => {
    if (parameter) getChartData()
  }, [parameter])
  
  return (
    <>
       <div className="flex items-center mr-2 pr-2">
          <label className="sv-label">Parameter: </label>
          <select
            className="w-1/2 p-1 text-gray-700 focus:outline-none border border-gray-200 focus:shadow-inner m-0 rounded text-xs ml-1"
            value={parameter.id}
            onChange={handleParameterChange}
          >
            {parameterOptions}
          </select>
        </div>
    </>
  )
}

export default ParameterDropdown
