import React from "react";

const SiteDetails = ({ siteDetails }) => {
  return <>
    <div className='my-4'>
      <h3 className="font-semibold">Site Details</h3>
      <div className='flex flex-row justify-between my-1'>
        <span>Is this a wellsite? </span>
        <span className="font-semibold">{siteDetails.wellsite}</span>
      </div>
      <div className='flex flex-row justify-between my-1'>
        <span>Does the site cross straddle Provincial boundaries? </span>
        <span className="font-semibold">{siteDetails.boundary}</span>
      </div>
      <div className='flex flex-row justify-between my-1'>
        <span>Is peat present at the site? </span>
        <span className="font-semibold">{siteDetails.organicSoil}</span>
      </div>
      <div className='flex flex-row justify-between my-1'>
        <span>Is there a more sensitive land use within 30m? </span>
        <span className="font-semibold">{siteDetails.sensitive }</span>
      </div>
      {siteDetails.sensitive === 'Yes' && <div className='flex flex-row justify-between my-1'>
        <span>Sensitive Land Use: </span>
        <span className="font-semibold">{siteDetails.adjacentLandUse}</span>
      </div>}
    </div>
  </>
}

export default SiteDetails
