import React from "react";

const SiteInformationInput = ({ title, placeholder, state, setState}) => {

  return <>
    <div className='flex flex-col px-2'>
      <label>{title}: </label>
      <input
        placeholder={placeholder}
        className='px-2 border rounded-md border-gray-300 w-1/3'
        value={state}
        onChange={event => setState(event.target.value)}
      />
    </div>
  </>
}

export default  SiteInformationInput
