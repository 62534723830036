import React, { useEffect, useState, useRef } from 'react'
import axios from 'axios'
import Highcharts from "highcharts"
import HC_more from "highcharts/highcharts-more"
import label from "highcharts/modules/series-label"
import HighchartsReact from "highcharts-react-official"
import HC_exporting from 'highcharts/modules/exporting'
import { generateChartOptions } from './chartHelpers/chartFunctions'
import { useDioxinsAndFuransContext, useDioxinsAndFuransAPI } from '../../contexts/dioxinsAndFurans'

HC_more(Highcharts)
label(Highcharts)
HC_exporting(Highcharts)

const ExportSampleItem = ({sampleId, siteId}) => {
  const sum2378Ref = useRef(null)
  const relativeHomRef = useRef(null)
  const relativeTeqRef = useRef(null)
  const totalHomRef = useRef(null)
  const unstandardizedRef = useRef(null)
  const moleAnalyteRef = useRef(null)

  const { samples, exportData } = useDioxinsAndFuransContext()
  const { setExportData } = useDioxinsAndFuransAPI()
  const [data, setData] = useState(null)
  const sample = samples.find(sample => sample.id === sampleId)

  useEffect(() => {
    axios.get(`/sites/${siteId}/dioxins_and_furans_sample_data?sample_id=${sampleId}`)
      .then(res => setData(res.data))
  },[])

  useEffect(() => {
    if (!data) return
    const sampleExportData = {
      sample_id: sampleId,
      sample_name_for_json: sample.sample_name_for_json,
      reference: sample.reference,
      sum_2378: sum2378Ref.current.chart.getSVG(),
      relative_hom: relativeHomRef.current.chart.getSVG(),
      relative_teq: relativeTeqRef.current.chart.getSVG(),
      total_hom: totalHomRef.current.chart.getSVG(),
      unstandardized: unstandardizedRef.current.chart.getSVG(),
      mole_analyte: moleAnalyteRef.current.chart.getSVG(),
    }
    setExportData([...exportData, sampleExportData])
  },[data])

  return (
    data &&
    <div className= 'border border-b-black rounded p-1 mt-2 bg-blue-100 hover:bg-blue-300 overflow-auto'>
      <div className='text-center'>
        <p className='font-semibold'>{sample.sample_name_for_json}</p>
        <p className='text-xs font-semibold'>{sample?.reference?.similarity} similar to {sample?.reference?.name}</p>
      </div>
      <div className='mx-2 p-2 flex overflow-x-auto'>
        <div className='border mr-1'>
          <HighchartsReact ref={sum2378Ref} highcharts={Highcharts} options={generateChartOptions(data?.sum_2378, '2,3,7,8 Sum', `Total Homologues: ${data.total_homologues}`, 350)}/>
        </div>
        <div className='border mr-1'>
          <HighchartsReact ref={relativeHomRef} highcharts={Highcharts} options={generateChartOptions(data?.relative_hom, 'Relative Homologues', '', 350)}/>
        </div>
        <div className='border mr-1'>
          <HighchartsReact ref={relativeTeqRef} highcharts={Highcharts} options={generateChartOptions(data?.relative_teq, 'Relative TEQ', `Total Analytes: ${data.total_analyte}`, 350)}/>
        </div>
        <div className='border mr-1'>
          <HighchartsReact ref={totalHomRef} highcharts={Highcharts} options={generateChartOptions(data?.total_hom, 'Total Homologues', `Total Dioxins and Furans: ${data.total_dioxins_and_furans}`, 350)}/>
        </div>
        <div className='border mr-1'>
          <HighchartsReact ref={unstandardizedRef} highcharts={Highcharts} options={generateChartOptions(data?.unstandardized, 'Unstandardized', '', 350)}/>
        </div>
        <div className='border'>
          <HighchartsReact ref={moleAnalyteRef} highcharts={Highcharts} options={generateChartOptions(data?.mole_analyte, 'Mole Percent', '', 350)}/>
        </div>
      </div>
    </div>
  )
}

export default ExportSampleItem
