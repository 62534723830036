import React, { useEffect, useState } from 'react';
import ParameterSelect from "./parameterSelect";
import MatrixSelect from "./matrixSelect";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import ModalWrapper from "./modalWrapper";
import { useCustomGuidelinesAPI, useCustomGuidelinesContext } from "../../../../contexts/customGuidelines";
import { findChemicalIndex } from "../../../../helpers/customGuidelines"

const NewGuidelineModal = ({ showModal, setShowModal }) => {
  const [parameter, setParameter] = useState(null)
  const [matrix, setMatrix] = useState(null)
  const [value, setValue] = useState(null)
  const [unit, setUnit] = useState(null)
  const [warning, setWarning] = useState(false)
  const [guideline, setGuideline] = useState(null)

  const { site, customGuidelines } = useCustomGuidelinesContext()
  const { addGuideline } = useCustomGuidelinesAPI()


  const createGuideline = () => {
    if (parameter && matrix && value) {
      axios.post(`/sites/${site.id}/custom_guidelines.json`,  { custom_guideline: { site_id: site.id, chemical_id: parameter.value, matrix_id: matrix.value, value: value } })
        .then(res => {
          toast.success('Custom guideline successfully created!')
          addGuideline(res.data)
          // Reset states because they persist on modal close
          setShowModal(false)
          setParameter(null)
          setMatrix(null)
          setUnit(null)
          setWarning(false)
          setGuideline(null)
        })
    } else {
      return toast.error('Please fill out all fields')
    }
  }

  useEffect(() => {
    if (parameter && matrix) {
      axios.get(`/sites/${site.id}/find_unit.json`, { params: { parameter_id: parameter.value, matrix_id: matrix.value }}).then(res => setUnit(res.data.name))

      const chemicalIndex = findChemicalIndex(customGuidelines, parameter.label)

      let guidelineValue

      if (chemicalIndex > -1) {
        if (matrix.label == 'Soil' && customGuidelines[chemicalIndex]['soil_guideline']?.value) guidelineValue = customGuidelines[chemicalIndex]['soil_guideline']['value']

        if (matrix.label == 'Subsoil' && customGuidelines[chemicalIndex]['subsoil_guideline']?.value) guidelineValue = customGuidelines[chemicalIndex]['subsoil_guideline']['value']

        if (matrix.label == 'Groundwater' && customGuidelines[chemicalIndex]['groundwater_guideline']?.value) guidelineValue = customGuidelines[chemicalIndex]['groundwater_guideline']['value']
      }

      setGuideline(guidelineValue)

      if (!isNaN(guidelineValue)) setWarning(true)
    }
  }, [parameter, matrix])

  return (
    <>
      <ToastContainer />
      <ModalWrapper isOpen={showModal} >
        <div className='flex flex-col justify-center items-center py-5 px-12 gap-y-2'>
          <h1 className='text-xl font-semibold text-center'>Add New Custom Guideline</h1>
          <p className='text-center'>Please first select a parameter and matrix to see the guideline unit</p>
          <ParameterSelect site={site} setParameter={setParameter} />
          <MatrixSelect setMatrix={setMatrix} />
          <label>Value {parameter && matrix && `(${unit})`}</label>
          <input
            className='w-full border border-gray-300 h-10 rounded-md px-3'
            onChange={(e) => setValue(e.target.value)}
          />
          {warning && <p className='text-red-500 text-center font-semibold'>A guideline for the following parameter and matrix selection already exists, clicking add will overwrite the existing guideline {guideline && <span>({guideline} {unit})</span>}</p>}
          <div className='flex justify-center items-center'>
            <button
              className='btn btn-default'
              onClick={() => setShowModal(false)}
            >
              Close
            </button>
            <button
              onClick={createGuideline}
              className='btn btn-primary'
            >
              Add
            </button>
          </div>
        </div>
      </ModalWrapper>
    </>
  )
}

export default NewGuidelineModal
