import React from 'react'
import UploadGuidelines from "./uploadGuidelines"
import ExistingGuidelines from "./existingGuidelines"
import { CustomGuidelinesProvider } from "../../contexts/customGuidelines";

const CustomGuidelines = ({ site, existingGuidelines }) => {
  return (
    <CustomGuidelinesProvider site={site} existingGuidelines={existingGuidelines}>
      <h1 className='-mt-5 mb-8'>Site Custom Guidelines</h1>
      <UploadGuidelines />
      <ExistingGuidelines />
    </CustomGuidelinesProvider>
  )
}

export default CustomGuidelines
