import React from 'react'

const Pathway = ({ pathway, checked, togglePathway }) => {
  const label = pathway?.receptor ? pathway.receptor + "-" + pathway.title : pathway.title

  return (
    <div className='flex gap-x-2'>
      <input
        type="checkbox"
        checked={checked}
        id={pathway.id}
        name={pathway.title}
        onChange={(e) => togglePathway(pathway.id)}
      />
      <label>{label}</label>
    </div>
  )
}

export default Pathway
