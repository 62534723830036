import React, {useState} from "react";

const SearchInput = ({setFilteredSamples, siteData, geoJSON, setFilteredGeoJSON}) => {
  const [searchQuery, setSearchQuery] = useState("");
  
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    
    const queryResults = siteData.samples.filter((sample) => {
      const serializedSample = {
        matrix: sample.matrix.title, 
        name: sample.sample_location.name, 
        area: sample.sample_location_group_name,
        date: sample.sample_date,
        time: sample.sample_time, 
        depth: sample.sample_depth, 
        status: sample.sample_location.status, 
        latitude: sample.sample_location.latitude, 
        longitude: sample.sample_location.longitude
      }
      
      const searchQueryLowerCase = e.target.value.toLowerCase();
      
      return Object.values(serializedSample).some((value) => {
        if (typeof value === 'string' || typeof value === 'number') {
          const valueLowerCase = value.toString().toLowerCase();
          return valueLowerCase.includes(searchQueryLowerCase);
        }
        return false;
      });
    });
    const updatedSamples = {...siteData, samples: queryResults};
    const sampleIds = queryResults.map((sample) => sample.id);
    const updatedGeoJSON = {...geoJSON, features: geoJSON?.features?.filter((feature) => sampleIds.includes(feature.properties.sample_id))};
    
    setFilteredGeoJSON(updatedGeoJSON);
    setFilteredSamples(updatedSamples);
  };
  
  const icon = searchQuery ? "fa-times" : "fa-search";
  const iconStyle = searchQuery ? "cursor-pointer hover:text-red-400" : "";
  
  const handleClearSearch = () => {
    if (searchQuery) {
      setSearchQuery("");
      setFilteredGeoJSON(geoJSON);
      setFilteredSamples(siteData);
    }
  };
  
  return (
    <div className="relative border border-gray-500 rounded-md w-7/12 ml-2">
      <input className="w-full bg-white text-gray-900 p-1 rounded-lg" type="text" value={searchQuery} onChange={handleSearchChange} placeholder="Search Samples" />
      <i className={`fa ${icon} absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-500 ${iconStyle}`} onClick={handleClearSearch}></i>
    </div>
  );
}

export default SearchInput;
