import React from 'react';
import axios from "axios";
import { toast } from "react-toastify";
import { useCustomGuidelinesContext } from "../../../contexts/customGuidelines";

const Guideline = ({ guideline, deleteCallback }) => {
  const { site } = useCustomGuidelinesContext()

  const onClick = () => {
    if (window.confirm("Are you sure you want to delete this guideline?")) {
      axios.delete(`/sites/${site.id}/custom_guidelines/${guideline.id}.json`)
        .then(res => {
          toast.success(res.data)
          deleteCallback()
        })
    }
  }

  return <td className=''>
    <p className='inline-block w-16'>{guideline?.value}</p>
    {guideline && 
      <button className='btn-action delete' onClick={onClick} title="Delete Guideline">
        <i className="fa fa fa-trash-alt text-red-500 cursor-pointer" onClick={onClick}/> Delete
      </button>
    }
  </td>
}

export default Guideline
