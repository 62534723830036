import React from "react";
import SearchInput from "../filterHelpers/searchInput";
import { useDataVaultContext } from "../../../contexts/dataVault"

const TableControls = ({tableFullscreen, tableDownHandler, tableUpHandler, searchProps, showGuidlineHandler}) => {
  const { showTable, siteData, guidelinesData } = useDataVaultContext();
  const showDefaultTable = showTable && !tableFullscreen;
  const fullScreenTable = showTable && tableFullscreen;
  const hideTable = !showTable;
  const searchInput = <SearchInput siteData={siteData} {...searchProps} />
  const hasGuidelines = guidelinesData?.guidelines?.length > 0

  const fullScreenTableElement = (
    fullScreenTable && <div className="flex items-center justify-center w-full">
      <button className='cursor-pointer mx-1 bg-gray-300 hover:bg-gray-200 text-xs rounded p-1 font-bold uppercase w-full' onClick={tableDownHandler}>
        <i className="fa fa-arrow-down" />
      </button>
    </div>
  );

  const hideTableElement = (
    hideTable && <div className="flex items-center justify-center w-full">
      <button className='cursor-pointer mx-1 bg-gray-300 hover:bg-gray-200 text-xs rounded p-1 font-bold uppercase w-full' onClick={tableUpHandler}>
        <i className='fa fa-arrow-up'/>
      </button>
    </div>
  );

  const defaultTableElement = (
    showDefaultTable && <div className='flex items-center justify-center'>
      <button className='cursor-pointer mx-1 bg-gray-300 hover:bg-gray-200 text-xs rounded p-1.5 font-bold uppercase' onClick={tableUpHandler}>
        <i className='fa fa-arrow-up'/> <span className='hidden sm:inline'>Fullscreen</span>
      </button>
      <button className='cursor-pointer mx-1 bg-gray-300 hover:bg-gray-200 text-xs rounded p-1.5 font-bold uppercase' onClick={tableDownHandler}>
        <i className='fa fa-arrow-down'/> <span className='hidden sm:inline'>Hide Table</span>
      </button>
    </div>
  );

  const guidelineShowElement = (() => {
    if (!siteData.site_info.apply_guidelines) {
      return (
        <div className='flex items-center justify-center'>
          <i className="fa fa-exclamation-triangle mr-1" />
          <span className='text-xs mr-1 uppercase font-semibold'>Guidelines not applied to this site</span>
        </div>
      );
    } else if (hasGuidelines && guidelinesData) {
      return (
        <div className='flex items-center justify-center'>
          <label className="sv-label mr-1">Show Guidelines</label>
          <input type="checkbox" className="form-checkbox h-4 w-4 text-gray-800 mr-2" onChange={showGuidlineHandler} />
        </div>
      );
    } else if (!hasGuidelines && guidelinesData) {
      return (
        <div className='flex items-center justify-center'>
          <i className="fa fa-exclamation-triangle mr-1" />
          <span className='text-xs mr-1 uppercase font-semibold'>No Guidelines Found</span>
        </div>
      );
    }

    return (
      <div className='flex items-center justify-center'>
        <i className="fa fa-spinner fa-spin mr-1" />
      </div>
    )
  })()

  return (
    <>
      <div className="bg-gray-100 flex items-center justify-center text-align text-xs h-6 py-4 relative z-40">
        <div className="w-1/3">
          {searchInput}
        </div>
        <div className='w-1/3 flex items-center justify-center'>
          {fullScreenTableElement}
          {hideTableElement}
          {defaultTableElement}
        </div>
        <div className='w-1/3 flex items-center justify-end'>
          {guidelineShowElement}
        </div>
      </div>
    </>
  )
}

export default TableControls;
