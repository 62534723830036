import React, { useEffect, useReducer, useState } from "react";
import Select from "react-select";
import axios from "axios";
import { toast } from "react-toastify";

const ChemicalRow = ({ parameter, setParameters, toggleSelection, checked }) => {
  const [options, setOptions] = useState([])
  const [input, setInput] = useState()
  const [selected, setSelected] = useState(null)
  const [isHovered, handleHeaderHover] = useReducer((state) => !state, false)

  useEffect(() => {
    setSelected(null)
  }, [parameter])

  useEffect(() => {
    if (input) {
      axios.get(`/chemicals/autocomplete_chemical_name?q=${input}`).then(res => {
        const formattedData = res.data.map(chemical => {
          return { label: chemical.name, value: chemical.id }
        })
        setOptions(formattedData)
      })
    }
  }, [input])


  const removeParameter = () => {
    axios.delete(`/unmapped_chemicals/${parameter.id}`)
      .then(res => setParameters(res.data))
  }

  const ignoreParameter = () => {
    const postIgnoredParameters = axios.post('/ignored_parameters', { name: parameter.name })
    const deleteUnmappedChemicals = axios.delete(`/unmapped_chemicals/${parameter.id}`)

    Promise.all([postIgnoredParameters, deleteUnmappedChemicals])
      .then(([postResponse, deleteResponse]) => {
        toast.success(postResponse.data)
        setParameters(deleteResponse.data)
      })
      .catch(error => {
        console.error('Error:', error)
      });
  }

  const addAlias = () => {
    axios.put(`/chemicals/${selected.value}/add_alias`, { alias: parameter.name, unmapped_chemical_id: parameter.id } )
      .then(res => {
        setParameters(prevState => prevState.filter(param => param.id != parameter.id))
        toast.success(res.data.message)
      })
      .catch(err => toast.error(err.response.data))
  }

  return <>
    <tr
      className='border-dashed border-t border-gray-400 px-3 text-sm'
      onMouseEnter={handleHeaderHover}
      onMouseLeave={handleHeaderHover}
    >
      <td className='px-3'>
        <input
          type="checkbox"
          checked={checked}
          id={parameter.id}
          name={parameter.title}
          onChange={(e) => toggleSelection(parameter.id)}
        />
      </td>
      <td
        className={`px-2 ${isHovered ? "break-words whitespace-normal" : "truncate"}`}
      >
        {parameter.name}
      </td>
      <td className='py-2'>
        <Select
          placeholder={'Enter the chemical name...'}
          options={options}
          value={selected}
          isClearable={true}
          onInputChange={(inputString) => setInput(inputString)}
          onChange={(option) => setSelected(option)}
        />
      </td>
      <td
        className={`text-center text-blue-600 underline px-2 ${isHovered ? "break-words whitespace-normal" : "truncate"}`}
      >
        <a
          href={`/sites/${parameter.site?.id}/uploads`}
          target='_blank'
        >
          {parameter.site?.title} (ID: {parameter.site?.id})
        </a>
      </td>
      <td
        className={`text-center px-2 ${isHovered ? "break-words whitespace-normal" : "truncate"}`}
      >
        {parameter.filename} (ID: {parameter.site_upload?.id})</td>
      <td className='text-center'>
        <button
          onClick={addAlias}
          className='btn btn-default'
        >
          Add Alias
        </button>
        <button
          className='btn btn-default'
          onClick={removeParameter}
        >
          Remove
        </button>
        <button
          className='btn btn-default'
          onClick={ignoreParameter}
        >
          Ignore
        </button>
      </td>
    </tr>
  </>
}

export default ChemicalRow
