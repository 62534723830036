import React, { useEffect, useState } from 'react'
import Select from "react-select"
import { sortPetroleumData } from "../../shared/sortPetroleumData"
import Option from "../../shared/option"
import { usePahFingerprinting, usePahFingerprintingAPI } from "../../../../contexts/pahFingerprinting"
import { getDiagnosticRatioConstants } from "../../../../api/pahFingerprintingApi"
import { referenceList } from "../helpers/sampleHelpers"

const ReferenceValues = () => {
  const [constants, setConstants] = useState([])
  const [constantsList, setConstantsList] = useState([])
  const { site } = usePahFingerprinting()
  const { onAdditionalDataChange } = usePahFingerprintingAPI()

  useEffect(() => {
    getDiagnosticRatioConstants(site.id).then(res => setConstants(res.data))
  }, [])

  useEffect(() => {
    if (constants) {
      setConstantsList(sortPetroleumData(referenceList(constants)))
    }
  }, [constants])

  const updateGraphs = (selectedOptions) => {
    const doubleRatioPlotOptions = selectedOptions.map( (option) => {
      const markerSymbol = option.type === 'petrogenic' ? 'circle' : 'square'

      return {
        name: option.label,
        data: [[option.value.plotValues.x, option.value.plotValues.y]],
        marker: {
          symbol: markerSymbol,
        },
      }
    })

    doubleRatioPlotOptions.sort((a, b) => {
      const markerSymbolA = a.marker.symbol
      const markerSymbolB = b.marker.symbol
      return markerSymbolA.localeCompare(markerSymbolB)
    })

    onAdditionalDataChange(doubleRatioPlotOptions)
  }

  const selectCustomStyles = {
    option: (provided, state) => ({
      ...provided,
      '&:hover': {
        backgroundColor: '#E2E8F0',
        color: 'black'
      },
      fontSize: '0.8rem',
      paddingTop: 3,
      paddingBottom: 3,
    }),
    multiValue: (provided) => ({
      ...provided,
      fontSize: '0.8rem',
    }),
  }

  return (
    <div className='flex flex-col mb-2'>
      <p>Standard Reference Values</p>
      <Select
        isMulti
        placeholder='Select reference values'
        components={{ Option }}
        options={constantsList}
        className="basic-multi-select"
        classNamePrefix="select"
        onChange={selectedOptions => updateGraphs(selectedOptions)}
        closeMenuOnSelect={false}
        styles={selectCustomStyles}
      />
    </div>
  )
}

export default ReferenceValues
