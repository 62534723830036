import React from "react";

const RatioRow = ({ ratio, max, min }) => {
  return (
    <tr>
      <td>{ratio}</td>
      <td className='mx-2 text-center'>
        <span>Ratio {min}</span>
      </td>
      <td className='mx-2 text-center'>
        <span>Ratio {max}</span>
      </td>
    </tr>
  )
}

export default RatioRow
