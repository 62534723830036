import React from 'react'
import ExportSampleList from './exportSampleList'
import { usePahFingerprinting, usePahFingerprintingAPI } from '../../../../../contexts/pahFingerprinting'

const ExportModal = () => {
  const { showExportModal, selectedSamples, samples } = usePahFingerprinting()
  const { toggleShowModal, onSampleSelect, toggleShowPreview, setAnnotationMode } = usePahFingerprintingAPI()
  const hasSelectedSamples = selectedSamples.length > 0
  const previewButtonDisabled = hasSelectedSamples ? '' : 'opacity-50 cursor-not-allowed'

  const handleSelectAll = () => {
    onSampleSelect(samples)
  }

  const handleDeleteAll = () => {
    onSampleSelect([])
  }

  const handlePreview = () => {
    toggleShowModal()
    toggleShowPreview()
    setAnnotationMode(false)
  }

  return (
    showExportModal && <div className="fixed inset-0 flex items-center justify-center z-40">
      <div className="absolute inset-0 bg-gray-100 opacity-40" onClick={toggleShowModal}></div>
      <div className="bg-white max-w-full h-auto rounded-lg shadow-lg z-50 overflow-y-auto flex justify-center items-center">
        <div className="relative w-full p-2 overflow-visible">
          <button className="border bg-gray-600 rounded-full w-8 h-8 flex items-center justify-center absolute top-3 right-3 -m-2 z-50" onClick={toggleShowModal}>
            <i className='fa fa-times text-white font-extrabold'/>
          </button>
          <h1 className="text-lg font-semibold text-center">Export Samples</h1>
          <div className='max-h-64 flex flex-col overflow-y-auto'>
            <ExportSampleList />
          </div>
          <div className='w-full flex justify-between mt-1'>
            <div>
              <button className='border rounded text-xm uppercase font-semibold p-1 bg-blue-300 text-xs' onClick={handleSelectAll}>Select All</button>
              {hasSelectedSamples && <button className='border rounded text-xm uppercase font-semibold p-1 bg-blue-300 text-xs' onClick={handleDeleteAll}>Delete All</button>}
            </div>
            <button className={`border rounded text-xm uppercase font-semibold p-1 bg-blue-300 text-xs ${previewButtonDisabled}`} onClick={handlePreview} disabled={!hasSelectedSamples}>Preview</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ExportModal
