import React, { useState, useContext } from "react";
import { TierOneContext } from "../tierOneContext"
import QuestionYesNo from "../shared/questionYesNo";
import ValidateSubmitButton from "../shared/validateSubmitButton";
import Tooltip from "../shared/tooltip";
import { hasMandatoryGuideline } from "../../helpers";

const MandatoryGuidelines = ({ step, setStep, setShowAnswers }) => {
  let [site, setSite] = useContext(TierOneContext);
  let [message, setMessage] = useState('');

  const hasError = () => {
    if (Object.values(site.mandatoryGuidelines).filter( value => value !== '').length < Object.keys(site.mandatoryGuidelines).length) {
      setMessage('Please answer all questions');
      return true
    }

    return false
  }

  const headerData = {
    tooltip: 'For more information on the items below see section 5.1.6 of the Tier 1 guidelines here:',
    link: 'https://open.alberta.ca/publications/1926-6243'
  }

  const isMandatory = hasMandatoryGuideline(site.mandatoryGuidelines)

  return (
    <>
      <div className='flex flex-row gap-x-4 items-baseline'>
        <h1>Conditions Making Tier 2 Mandatory</h1>
        <Tooltip data={headerData} />
      </div>
    <ValidateSubmitButton
      message={message}
      step={step}
      setStep={setStep}
      setShowAnswers={setShowAnswers}
      hasError={() => hasError()}
      isMandatory={isMandatory}
    >
      <div className="my-8">
        <p>Do any of the mandatory Tier 2 Guidelines apply?</p>
        <QuestionYesNo type='volatile' />
        <QuestionYesNo type='building' />
        <QuestionYesNo type='receptors' />
        <QuestionYesNo type='stagnant' />
        <QuestionYesNo type='contaminated-water' />
        <QuestionYesNo type='coarse' />
        <QuestionYesNo type='bedrock' />
        <QuestionYesNo type='source' />
      </div>
    </ValidateSubmitButton>
    </>
  )

}

export default MandatoryGuidelines
