import React, { useEffect } from 'react'
import Filter from '../shared/filters/filter'
import { useDioxinsAndFuransContext, useDioxinsAndFuransAPI } from '../../contexts/dioxinsAndFurans'
import { filterByLocation } from '../shared/filters/filterFunctions'

const FilterContainer = () => {
  const { mapType, geojson, samples, chemicals, highlightSampleIds } = useDioxinsAndFuransContext()
  const { setFilteredGeojson, setFilteredSamples, setFilteredChemical, setDisplayedGeojson, setDisplayedSamples } = useDioxinsAndFuransAPI()

  useEffect(() => {
    const hasHighlightedSamples = highlightSampleIds.length > 0
    if (hasHighlightedSamples) {
      const { filteredSamplesLocation } = filterByLocation(geojson, samples, highlightSampleIds)
      setDisplayedSamples(filteredSamplesLocation)
    } else {
      setDisplayedSamples(null)
    }
  },[highlightSampleIds])

  return (
    <Filter
      geoJSON={geojson}
      mapType={mapType}
      siteData={samples}
      chemicals={chemicals}
      setFilteredGeoJSON={setFilteredGeojson}
      setFilteredSamples={setFilteredSamples}
      setFilteredChemical={setFilteredChemical}
    />
  )
}

export default FilterContainer
