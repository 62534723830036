import React from 'react';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { createHistogramExport } from "../../../../api/pahFingerprintingApi";

const ExportButton = ({ site, samples, email, chartData, loading, chartCount }) => {
  const exportHistograms = () => {
    toast.success('Your export is being generated')
    createHistogramExport(site.id, email, chartData)
      .then(_ => toast.success('Export successful! Email being sent shortly.'))
  }

  const disabled = loading || !email.length > 0
  return (
    <button
      className={
        "w-1/5 text-center font-bold uppercase p-3 shadow-lg rounded block leading-normal " +
        `${disabled ? 'cursor-not-allowed bg-gray-200 text-gray-800' : 'cursor-pointer text-white bg-blue-300 '}`
      }
      disabled={disabled}
      onClick={_ => exportHistograms()}
    >
      <div className='flex flex-row gap-x-3 w-full justify-around'>
        {loading ?
          <div>Loading {chartCount}/{(samples.length * 2)} charts</div>
          :
          <div>Export!</div>
        }
      </div>
    </button>
  )
}

export default ExportButton
