import React, { useRef, useEffect, useState } from 'react';
import mapboxgl from '!mapbox-gl';
import { ToastContainer, toast, Zoom } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import * as turf from '@turf/turf';
import showFunctions from './mapHelpers/showFunctions';
import layerFunctions from './mapHelpers/layerFunctions';
import cursorFunctions from './mapHelpers/cursorFunctions';
import Legends from './mapHelpers/legends';
import HomeZoom from './mapHelpers/homeZoom';
import GeometryInfoContainer from './mapHelpers/geometryInfoContainer';
import { removeOldPopups, buildPopups, showUnclusteredPointPopup, removeUnclusteredPopup, showClusteredPopup } from './mapHelpers/popupsLabelFunctions';
import { annotationFunctions, cleanPopups, initializeMapDraw, removeMapDraw, getPolygonArea, getPointsDistance } from './mapHelpers/annotationFunctions';
import { fitBounds } from './mapHelpers/helperFunctions'
import { useDataVaultContext, useDataVaultAPI } from '../../contexts/dataVault';
import { surfaceWaterLayerHandler, surficialGeologyLayerHandler, aerPipelineHandler } from './mapHelpers/layerToggles/helpers';

// CONSTANTS
const [CANADA_LATTITUDE, CANADA_LONGITUDE, DEFAULT_ZOOM] = [56.1304, -106.3468, 3];

const DataVaultMap = () => {
  const { geoJSON, bounds, siteData, geospatialFeatures, showTable, displayedGeojson, filteredGeoJSON, showPopups, popups, showAnnotations, mapDrawOpen, highlightSampleIds, rulerMode, showLegends, showWaterLayer, showSurficialGeologyLayer, showAerPipelineLayer } = useDataVaultContext()
  const { setPopups, setMapDrawOpen, setHighlightSampleIds, setPolygonArea, setDistance, setLayerMenuOpen } = useDataVaultAPI()
  mapboxgl.accessToken = process.env.MAPBOX_API_KEY;
  let [latitude, longitude, defaultZoom] = [CANADA_LATTITUDE, CANADA_LONGITUDE, DEFAULT_ZOOM];

  const mapContainer = useRef(null);
  const map = useRef(null);
  const mapDrawRef = useRef(null)

  const [geoPopups, setGeoPopups] = useState({})
  const [geoSpatialFeatures, setGeoSpatialFeatures] = useState(geospatialFeatures)
  const [mapLoaded, setMapLoaded] = useState(false)

  if (bounds) {
    ({ lng: longitude, lat: latitude } = bounds.getCenter());
  }

  useEffect(() => {
    if (map.current) return;
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      projection: 'mercator',
      style: 'mapbox://styles/paulfuel/clqyb8tqo00fn01ob0k5aen2d',
      center: [longitude, latitude],
      zoom: defaultZoom,
    });

    map.current.addControl(new mapboxgl.NavigationControl({showCompass: false}), 'bottom-left')

    map.current.on('load', () => {
      layerFunctions(map.current, geoJSON, filteredGeoJSON)
      fitBounds(map.current, bounds)
      showFunctions(map.current, setHighlightSampleIds)
      getPolygonArea(map.current, turf, setPolygonArea)
      setMapLoaded(true)

      map.current.on('click', () => {
        setLayerMenuOpen(false)
      })
    });

    return () => map.current.remove();
  }, []);

  useEffect(() => {
    const layers = ['surface-water-subset-0', 'surface-water-subset-1', 'surface-water-subset-2', 'alberta-surficial-geology-b0ojv3', 'alberta-boundary-2e153n', 'aer-pipelines-layer-09kz6l']
    if (mapLoaded) {
      layers.forEach(layer => {
        map.current.setLayoutProperty(layer, 'visibility', 'none');
      })
    }
  }, [mapLoaded])

  useEffect(() => {
    if (mapLoaded) {
      surfaceWaterLayerHandler(map.current, showWaterLayer)
      surficialGeologyLayerHandler(map.current, showSurficialGeologyLayer)
      aerPipelineHandler(map.current, showAerPipelineLayer)
    }
  }, [showSurficialGeologyLayer, showWaterLayer, showAerPipelineLayer])

  useEffect(() => {
    if (map.current) {
      cursorFunctions(map.current, showAnnotations, rulerMode)
    }
  }, [showAnnotations, rulerMode])

  useEffect(() => {
    if (map.current && showAnnotations) {
      initializeMapDraw(map.current, mapDrawRef, setMapDrawOpen)
      cleanPopups(geoPopups)
      setGeoPopups(annotationFunctions(map.current, mapDrawRef.current, siteData.site_info, geoSpatialFeatures, setGeoSpatialFeatures, setGeoPopups, toast))
    } else if (map.current && !showAnnotations) {
      removeMapDraw(map.current, mapDrawRef, mapDrawOpen, setMapDrawOpen)
      cleanPopups(geoPopups)
      setGeoPopups({})
    }
  }, [showAnnotations, geoSpatialFeatures])

  useEffect(() => {
    getPointsDistance(map.current, turf, rulerMode, setDistance)
  }, [rulerMode])

  useEffect(() => {
    if (map.current && geoJSON) {
      removeOldPopups(popups, setPopups);
      const popupData = filteredGeoJSON || geoJSON;
      setPopups(buildPopups(map.current, popupData, showPopups));
    }
  }, [showPopups, filteredGeoJSON]);

  useEffect(() => {
    if (map.current && geoJSON) {
      const mapSource = map.current.getSource('sampleLocations')
      if (mapSource) {
        const dataToSet = displayedGeojson ? displayedGeojson : filteredGeoJSON || geoJSON
        mapSource.setData(dataToSet)
      }
    }
  }, [filteredGeoJSON, geoJSON, showTable, displayedGeojson]);

  useEffect(() => {
    if (map.current && !showTable) map.current.resize()
  }, [showTable]);

  useEffect(() => {
    if (map.current && highlightSampleIds.length && geoJSON) {

      const mapFeatures = geoJSON.features.filter(feature => highlightSampleIds.includes(feature.properties.sample_id));
      if (mapFeatures.length !== 0) {
        map.current.flyTo({
          center: mapFeatures[0].geometry.coordinates,
        });

        const isUnclustered = mapFeatures.length === 1
        if (isUnclustered){
          showUnclusteredPointPopup(map.current, mapFeatures[0]);
        } else {
          showClusteredPopup(map.current, mapFeatures);
        }
      }
    } else if (map.current && !highlightSampleIds.length && geoJSON) {
      removeUnclusteredPopup();
    }
  }, [highlightSampleIds]);


  return (
    <div className='relative flex-1'>
      <ToastContainer limit={1} autoClose={500} transition={Zoom} hideProgressBar={true} closeButton={false} className= 'mt-28 mr-7 text-center h-auto' style={{width: 'fit-content'}}/>
      {showLegends && <Legends inDataVault={true}/>}
      <HomeZoom map={map.current} bounds={bounds}/>
      <GeometryInfoContainer/>
      <div className='relative h-full'>
        <div ref={mapContainer} className='relative h-full' />
      </div>
    </div>
  );
}

export default DataVaultMap;
