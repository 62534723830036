import React, { useEffect, useRef, useState } from 'react'
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import buildPahChart from "../helpers/buildPahChart"
import HC_more from "highcharts/highcharts-more";
import label from "highcharts/modules/series-label";
import HC_exporting from "highcharts/modules/exporting";
import { getConcentrationData } from "../../../../api/pahFingerprintingApi";
import { formatSeriesData } from "../helpers/histogramExportHelpers";

HC_more(Highcharts);
label(Highcharts);
HC_exporting(Highcharts)

const Chart = ({ site, sample, getChartInfo, setChartCount, type, units, field }) => {
  const [categories, setCategories] = useState()
  const [data, setData] = useState()
  const [chartOptions, setChartOptions] = useState()

  const chartRef = useRef(null)

  useEffect(() => {
    getConcentrationData(site.id, sample.id)
    .then(res => setData(formatSeriesData(res.data, field)))
  }, [])

  useEffect(() => {
    data && setCategories(data.map(item => item.name))
  }, [data])

  useEffect(() => {
    if (data && categories) {
      setChartOptions(buildPahChart(sample.sample_name_for_json, `${type} (${units})`, data, categories, units))
    }
  }, [data, categories])

  useEffect(() => {
    if (categories?.length > 0 && chartOptions) {
      getChartInfo(chartRef.current.chart.getSVG(), sample.id, type) && setChartCount(prevState => prevState + 1)
    }
  }, [categories, chartOptions])

  return (
    <>
      {categories?.length > 0 && chartOptions && (
        <HighchartsReact
          ref={chartRef}
          highcharts={Highcharts}
          options={chartOptions}
        />
      )}
    </>
  )
}

export default Chart
