import React from "react";

const DisplayChartButton = ({ saltPrint, seeChartHandler }) => {
  return (
    <div className="sticky top-0 bg-white z-20 flex justify-between items-center px-4">
      <h3 className="text-lg font-semibold">{saltPrint.title}</h3>
      <button className="btn btn-default btn-small" onClick={seeChartHandler}>See All Charts</button>
    </div>
  )
};

export default DisplayChartButton;
