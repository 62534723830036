const tableauColors = [
  "#4E79A7", "#F28E2B", "#E15759", "#76B7B2", 
  "#59A14F", "#EDC948", "#B07AA1", "#FF9DA7", 
  "#9C755F", "#BAB0AB", "#FFBE7D", "#000000",
  "#17BECF", "#7F7F7F", "#CFCFCF", "#CE1256",
  "#C0C0C0", 
]

const applyColorScheme = (dataset, index) => {
  const colorIndex = index % tableauColors.length
  const color = tableauColors[colorIndex] + "CC"
  return { ...dataset, borderColor: color, backgroundColor: color, radius: 3, borderWidth: 1 }
}

export const applyColor = (data) => ({ datasets: data?.datasets.map(applyColorScheme) })
