import React, { useState, useEffect } from 'react'
import axios from "axios";
import SampleLocation from "./sampleLocation";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


const New = ({ site }) => {
  const [title, setTitle] = useState('')
  const [sampleLocations, setSampleLocations] = useState([])
  const [assignedSamples, setAssignedSamples] = useState([])

  useEffect(() => {
    axios.get(`/sites/${site.id}/sample_locations.json`)
      .then(res => {
        console.log(res.data)
        setSampleLocations(res.data)
      })
  }, [])

  const createGroup = () => {
    if (title.length < 1) {
      return toast.error("Please add a title first")
    }

    return axios.post(`/sites/${site.id}/sample_location_groups.json`, { sample_location_group: { title: title, site_id: site.id, sample_location_ids: assignedSamples } })
      .then(res => {
        window.location.href = res.data.path
      })
  }

  const toggleSelection = (id) => {
    if (assignedSamples.includes(id)) {
      setAssignedSamples(prevState => prevState.filter(value => value != id))
    } else {
      setAssignedSamples(prevState => [...prevState, id])
    }
  }

  const sampleLocationsList = sampleLocations?.map(sampleLocation => <SampleLocation key={sampleLocation.id} sampleLocation={sampleLocation} toggleSelection={toggleSelection} />)

  return (
    <div>
      <ToastContainer />
      <div className='flex justify-between'>
        <h1>New Sample Location Group</h1>
        <div>
          <button
            className='btn btn-primary'
            onClick={createGroup}
          >
            Create
          </button>
          <button
            className='btn btn-default'
            onClick={() => window.location.href = `/sites/${site.id}/sample_location_groups`}
          >
            Back
          </button>
        </div>
      </div>
      <label>Title:</label>
      <input
        id='title'
        className="focus:outline-none border border-gray-200 focus:shadow-inner m-0 rounded p-1 m-2"
        value={title}
        onChange={e => setTitle(e.target.value)}
      />
      {sampleLocations.length > 0 &&
        <div className='mt-4'>
          <div className='flex justify-between mb-1'>
            <div>
              <h3 className='font-semibold'>Assign Samples</h3>
              <p>* Note: locations with existing groups will be re-assigned to newly created group if checked</p>
            </div>
            <p className='bg-blue-500 border rounded-md p-4 text-center text-white font-semibold'>{assignedSamples?.length || 0} Selected </p>
          </div>
          <table className='w-full table'>
            <thead>
            <tr>
              <th></th>
              <th>Location Name</th>
              <th># of Samples</th>
              <th>Matrix</th>
              <th>Max Depth</th>
              <th>Group</th>
            </tr>
            </thead>
            <tbody>
            {sampleLocationsList}
            </tbody>
          </table>
        </div>
      }
    </div>

  )
}
export default New
