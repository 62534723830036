import React, { useContext } from "react";
import { TierOneContext } from "../tierOneContext";
import Select from "react-select";

const SiteIdentificationDropdown = ({ title, field, options }) => {
  const [site, setSite] = useContext(TierOneContext);

  return <>
    <div className='flex flex-col'>
      <label>{title}:</label>
      <Select
        name={field}
        id={field}
        className='my-2 w-1/2'
        options={options}
        onChange={(event) => setSite((prevState) => ({...prevState, siteIdentification: {...prevState.siteIdentification, [field]: event.value}}))}
      />
    </div>
  </>
}

export default SiteIdentificationDropdown
