import React from "react"

const ModalContent = () => {
  return (
    <>
      <div className="my-4">
        <p>
          By accessing and using our services, you acknowledge and agree to our terms and conditions. While our platform provides valuable tools and resources, it is important to understand that these tools are intended to support your decision-making process and are not a substitute for professional judgment.
        </p>
      </div>
      <div>
        <p>
          To proceed with using the service we require you to provide your email address. Providing your email will allow us to send you updates when there are changes to the Guidelines.
        </p>
      </div>
    </>
  )
}

export default ModalContent
