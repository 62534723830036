import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import ModalWrapper from "../existingGuidelines/addGuideline/modalWrapper";
import ExistingGuidelines from "./existingGuidelines";

const NewGuidelinesModal = ({ showModal, setShowModal, existingGuidelines, createGuidelines }) => {
  return (
    <>
      <ModalWrapper isOpen={showModal} >
        <div className='flex flex-col justify-center items-center py-5 px-12 gap-y-6'>
          <h3 className='text-lg font-semibold'>The following guidelines will be overwritten if you proceed:</h3>
          <ExistingGuidelines existingGuidelines={existingGuidelines} />
          <div className='flex justify-center items-center'>
            <button
              className='btn btn-default'
              onClick={() => setShowModal(false)}
            >
              Abort
            </button>
            <button
              onClick={createGuidelines}
              className='btn btn-primary'
            >
              Proceed
            </button>
          </div>
        </div>
      </ModalWrapper>
    </>
  )
}

export default NewGuidelinesModal
