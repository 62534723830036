import React from "react";

const SummaryRow = ({ uploads, showUploads, setShowUploads }) => {
  const successfulUploads = uploads.filter(upload => upload.row_count.imported == upload.row_count.total ).length
  const errorUploads = uploads.filter(upload => upload.row_count.imported != upload.row_count.total ).length

  const latestUpload = uploads[0]

  const uploadedRows = latestUpload.row_count.imported
  const totalRows = latestUpload.row_count.total

  const uploadStyling = (upload) => {
    if (upload.row_count.imported === upload.row_count.total) {
      return 'text-green-500'
    }

    return 'text-red-500'
  }

  return (
    <div className='flex flex-row justify-between items-center'>
      <p>Your latest upload <strong>{latestUpload.name}</strong>, uploaded at <strong>{latestUpload.upload_date}</strong>, imported <strong><span className={uploadStyling(latestUpload)}>{uploadedRows}/{totalRows} rows</span></strong> of data</p>
      <div className='flex flex-col text-xs gap-y-1'>
        <div className='text-green-500 flex flex-row gap-x-2 items-baseline'>
          <i className='fa fa-check-circle' />
          <p>{successfulUploads} successful uploads</p>
        </div>
        <div className='text-red-500 flex flex-row gap-x-2 items-baseline'>
          <i className='fa fa-exclamation-triangle' />
          <p>{errorUploads} uploads have errors</p>
        </div>
      </div>
      <div className="flex items-center">
        <button
          className='w-28 bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 text-sm rounded m-1'
          onClick={() => setShowUploads(!showUploads)}
        >
          {showUploads ? 'Close' : 'View Log'}
        </button>
      </div>
    </div>
  )
}

export default SummaryRow
