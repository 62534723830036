import axios from "axios"

export const getPdfReportData = (siteId, chartData) => {
  try {
    return axios({
      method: 'POST',
      url: `/sites/${siteId}/double_ratio_exports`,
      responseType: 'arraybuffer',
      data: chartData
    })
  }
  catch (e) {
    console.error(e)
  }
}

const downloadReport = (reportData) => {
  const blob = new Blob([reportData], { type: 'application/pdf' })
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.download = 'Report.pdf'
  link.click()
}

export const generatePdfReport = (data, site, getPdfReportData) => {
  const encodedExportData = data.map(data => {
    return { 
      sample_id: data.sample_id, 
      histogram: encodeURIComponent(data.histogram), 
      ratio_plot: encodeURIComponent(data.ratio_plot), 
      standard_histogram: encodeURIComponent(data.standard_histogram), 
      alkylated_histogram: encodeURIComponent(data.alkylated_histogram), 
      alkylated_standard_histogram: encodeURIComponent(data.alkylated_standard_histogram),
      reference_histogram1: encodeURIComponent(data.reference_histogram1),
      reference_histogram2: encodeURIComponent(data.reference_histogram2),
      reference_histogram3: encodeURIComponent(data.reference_histogram3),
      alkylated_reference_histogram1: encodeURIComponent(data.alkylated_reference_histogram1),
      alkylated_reference_histogram2: encodeURIComponent(data.alkylated_reference_histogram2),
      alkylated_reference_histogram3: encodeURIComponent(data.alkylated_reference_histogram3),
    }
  })

  getPdfReportData(site.id, encodedExportData).then((response) => downloadReport(response.data))
}

export const setExportData = (referenceHistogramRefs, selectedSample, sampleHistogramRef, ratioChartOptions, isAlkylated, alkylatedHistogramRef, alkylatedReferenceHistogramRefs, hasRatioPlot, ratioPlotRef) => {
  const referenceHistogramSVGs = referenceHistogramRefs.map(ref => ref.current.chart.getSVG());

  return {
    sample_id: selectedSample.id,
    histogram: sampleHistogramRef.current.chart.getSVG(),
    ratio_plot: hasRatioPlot && ratioChartOptions && ratioPlotRef.current.chart.getSVG(),
    reference_histogram1: referenceHistogramSVGs[0],
    reference_histogram2: referenceHistogramSVGs[1],
    reference_histogram3: referenceHistogramSVGs[2],
    alkylated_histogram: isAlkylated && alkylatedHistogramRef.current.chart.getSVG(),
    alkylated_reference_histogram1: isAlkylated && alkylatedReferenceHistogramRefs[0].current.chart.getSVG(),
    alkylated_reference_histogram2: isAlkylated && alkylatedReferenceHistogramRefs[1].current.chart.getSVG(),
    alkylated_reference_histogram3: isAlkylated && alkylatedReferenceHistogramRefs[2].current.chart.getSVG(),
  }
}
