import React, { useMemo } from 'react'
import ChemicalRow from './table/chemicalRow'
import CoeluteRow from './table/coeluteRow'
import SampleHeaders from './table/sampleHeaders'
import { usePcbContext, usePcbAPI } from '../../contexts/pcb'
import { formatReferenceName } from './helpers/referenceSourceHelper'

const Table = () => {
  const { setSelectedSample, setHighlightSampleIds } = usePcbAPI()
  const { samples, chemicals, filteredSamples, filteredChemical, displayedSamples, selectedSample, pcbCoelutedSample, referenceSource } = usePcbContext()
  const samplesData = useMemo(() => displayedSamples || filteredSamples || samples, [displayedSamples, filteredSamples, samples])
  const chemicalList = useMemo(() => filteredChemical || chemicals,[filteredChemical, chemicals])

  const tableHeaders = useMemo(() => {
    return samplesData.map((sample) => <SampleHeaders key={sample.id} sample={sample} selectedSampleId={selectedSample?.id} setSelectedSample={setSelectedSample} setHighlightSampleIds={setHighlightSampleIds}/>)
  }, [samplesData, selectedSample, setSelectedSample])

  const tableRows = useMemo(() => {
    return chemicalList.map((chemical) => <ChemicalRow key={chemical.id} chemical={chemical} samplesData={samplesData} />)
  }, [chemicalList, samplesData])

  const coeluteTableRows = useMemo(() => {
    return pcbCoelutedSample && Object.keys(pcbCoelutedSample)?.map(keys => <CoeluteRow key={keys} chemical={keys} value={pcbCoelutedSample[keys]} />)
  }, [samplesData, pcbCoelutedSample])

  return (
    <div className="h-full overflow-auto">
      <table className='border table-auto w-full whitespace-nowrap bg-white relative'>
        <thead>
          <tr className='sticky top-0 z-10 bg-white'>
            <th className='sticky left-0 bg-white z-10'>
              Parameter
              {selectedSample && <div className='text-xs text-center font-semibold text-gray-600'>Coeluted with {formatReferenceName(referenceSource)} </div>}
            </th>
            {tableHeaders}
          </tr>
        </thead>
        <tbody>
          {selectedSample && coeluteTableRows}
          {(!selectedSample || !coeluteTableRows) && tableRows}
        </tbody>
      </table>
    </div>
  )
}

export default Table
