import React from "react"

const LegendItem = ({legend}) => {
  
  return (
    <li>
      <div className="flex items-center">
        <div className={`w-3.5 h-3.5 mr-1 ${legend.color}`}></div>
        <span className='text-xs font-semibold tracking-tight'>{legend.label}</span>
      </div>
    </li>
  )
}

export default LegendItem
