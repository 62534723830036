import React, {useState} from "react";
import axios from "axios";
import OverwriteConfirmationModal from "./overwriteConfirmationModal";
import InvalidCSVModal from "./invalidCSVModal";
import EmptyRowModal from "./emptyRowModal";
import { useParameterSelectContext } from "../../../contexts/parameterSelection";

const Index = ({ samples, duplicatedSamplesCSV, csvEmptyRows }) => {
  const { site, deleteRoute, siteUpload } = useParameterSelectContext()
  const [showOverwriteModal, setShowOverwriteModal] = useState(samples.length > 0);
  const [showInvalidCSVModal, setShowInvalidCSVModal] = useState(duplicatedSamplesCSV.length > 0);
  const [showEmptyRowModal, setShowEmptyRowModal] = useState(csvEmptyRows.length > 0)
  
  const abortUpload = () => {
    axios.delete(deleteRoute)
    .then(() => window.location.href = `/sites/${site.id}`)
    .catch((error) => console.log(error))
  }
  
  return (
    <>
      {showEmptyRowModal && !showInvalidCSVModal &&
        <EmptyRowModal
          csvEmptyRows={csvEmptyRows}
          abortUpload={abortUpload}
          siteUpload={siteUpload}
        />
      }
      {showOverwriteModal && !showInvalidCSVModal &&
        <OverwriteConfirmationModal
          samples={samples}
          abortUpload={abortUpload}
        />
      }
      {showInvalidCSVModal &&
        <InvalidCSVModal
          duplicatedSamplesCSV={duplicatedSamplesCSV}
          abortUpload={abortUpload}
          siteTitle={site.title}
        />
      }
    </>
  );
}

export default Index
