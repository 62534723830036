import React from "react";
import Select from "react-select";

const SampleRows = ({sample, chemicals, isDisabled, typeOptions, onSampleTypeChangeHandler}) => {
  return (
    <tr key={sample.id} className={isDisabled(sample) ? 'text-gray-400' : ''}>
        <td>{sample?.sample_location.name}</td>
        <td>{sample.sample_location_group_name}</td>
        <td>{sample.sample_date}</td>
        <td>{sample.sample_depth}</td>
        {chemicals.map((chemical, i) => {
          const chemicalValue = sample.sample_values.find(value => value.chemical_id === chemical.id);
          return <td key={i}>{chemicalValue?.float_value || '-'}</td>
        })}
        <td> 
          <Select
            menuPortalTarget={document.body}
            menuPosition={'fixed'}
            styles={{ container: base => ({...base, width: '300px'})}}
            options={typeOptions}
            value={typeOptions.find(option => option.value === sample.type)}
            onChange={(option) => onSampleTypeChangeHandler(sample.id, option)}
            isDisabled={isDisabled(sample)}
          />
        </td>
      </tr>
  );
};

export default SampleRows;
