import React, { createContext, useReducer, useMemo, useEffect, useContext } from 'react'

/* Actions */
const UPDATE_STATE = 'UPDATE_STATE'
const TOGGLE_POPUPS = 'TOGGLE_POPUPS'
const TOGGLE_LEGENDS = 'TOGGLE_LEGENDS'

/* Initial States */
const initialState = (initialData) => ({
  originalGeoJSON: initialData.initialGeoJSON,
  geojson : initialData.initialGeoJSON,
  saltPrintSamples: initialData.initialSaltPrintSamples,
  saltPrint: initialData.saltPrint,
  chemicals: initialData.chemicals,
  site: initialData.site,
  mapType: 'saltPrints',
  filteredSamples: null,
  filteredChemical: null,
  highlightSampleIds: [],
  displayedSamples: null,
  displayedGeojson: null,
  showPopups: false,
  showLegends: false,
})

/* Reducer */
const saltPrintReducer = (state, action) => {
    switch (action.type) {
        case UPDATE_STATE:
            return { ...state, [action.field]: action.value }
        case TOGGLE_POPUPS:
            return { ...state, showPopups: !state.showPopups }
        case TOGGLE_LEGENDS:
            return { ...state, showLegends: !state.showLegends }
        default:
            return state
    }
}

/* Contexts */
const SaltPrintContext = createContext(initialState)
const SaltPrintAPI = createContext({
    updateState: () => {},
    setGeoJSON: () => {},
    setSaltPrintSamples: () => {},
    setFilteredSamples: () => {},
    setFilteredChemical: () => {},
    setHighlightSampleIds: () => {},
    setDisplayedSamples: () => {},
    setDisplayedGeojson: () => {},
    togglePopups: () => {},
    toggleLegends: () => {},
})

/* Providers */
export const SaltPrintProvider = ({children, initialData}) => {
    const [state, dispatch] = useReducer(saltPrintReducer, initialState(initialData))

    const api = useMemo(() => {
        const updateState = (field, value) => dispatch({ type: UPDATE_STATE, field, value });
        const setGeoJSON = (geojson) => updateState('geojson', geojson);
        const setSaltPrintSamples = (saltPrintSamples) => updateState('saltPrintSamples', saltPrintSamples);
        const setFilteredSamples = (filteredSamples) => updateState('filteredSamples', filteredSamples);
        const setFilteredChemical = (filteredChemical) => updateState('filteredChemical', filteredChemical);
        const setHighlightSampleIds = (highlightSampleIds) => updateState('highlightSampleIds', highlightSampleIds)
        const setDisplayedSamples = (displayedSamples) => updateState('displayedSamples', displayedSamples)
        const setDisplayedGeojson = (displayedGeojson) => updateState('displayedGeojson', displayedGeojson)
        const togglePopups = () => dispatch({ type: TOGGLE_POPUPS })
        const toggleLegends = () => dispatch({ type: TOGGLE_LEGENDS })
        return { setGeoJSON, setSaltPrintSamples, setFilteredSamples, setFilteredChemical, setHighlightSampleIds, setDisplayedGeojson, setDisplayedSamples, togglePopups, toggleLegends}
    }, []);
    
    useEffect(() => {
      dispatch({ type: UPDATE_STATE, field: 'saltPrintSamples', value: initialData.saltPrintSamples })
    }, [initialData])

    return (
        <SaltPrintAPI.Provider value={api}>
            <SaltPrintContext.Provider value={state}>
                {children}
            </SaltPrintContext.Provider>
        </SaltPrintAPI.Provider>
    )
}

/* Custom Context Hooks */
export const useSaltPrintContext = () => useContext(SaltPrintContext)
export const useSaltPrintAPI = () => useContext(SaltPrintAPI)
