import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

const DownloadAllButton = ({ siteId, siteTitle }) => {
  const [status, setStatus] = useState("idle");

  const handleDownload = () => {
    setStatus("preparing");
    toast.info("Preparing download...", {
      autoClose: false,
      toastId: "download-toast",
    });

    axios
      .get(`/sites/${siteId}/data.xlsx`, {
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${siteTitle}_master_data.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
        setStatus("downloaded");
        toast.update("download-toast", {
          render: "Download complete!",
          type: toast.TYPE.SUCCESS,
          autoClose: 3000,
        });
        setTimeout(() => setStatus("idle"), 3000);
      })
      .catch((error) => {
        console.error("Download failed:", error);
        setStatus("error");
        toast.update("download-toast", {
          render: "Download failed",
          type: toast.TYPE.ERROR,
          autoClose: 3000,
        });
        setTimeout(() => setStatus("idle"), 3000);
      });
  };

  const buttonClasses = {
    idle: "bg-blue-600 hover:bg-blue-700",
    preparing: "bg-yellow-500",
    downloaded: "bg-green-500",
    error: "bg-red-500",
  };

  const buttonText = {
    idle: (
      <>
        <i className="fas fa-download mr-2"></i>Download All
      </>
    ),
    preparing: (
      <>
        <i className="fas fa-spinner fa-spin mr-2"></i>Preparing...
      </>
    ),
    downloaded: "Downloaded",
    error: "Error",
  };

  return (
    <button
      onClick={handleDownload}
      disabled={status !== "idle"}
      className={`${buttonClasses[status]} text-white px-2 py-1 rounded-md flex items-center text-sm transition-colors duration-300 font-semibold`}
    >
      <span>{buttonText[status]}</span>
    </button>
  );
};

export default DownloadAllButton;
