import React, { useEffect } from 'react';
import Review from "./review"
import NewPreview from "./preview"
import { usePahFingerprinting, usePahFingerprintingAPI } from "../../../contexts/pahFingerprinting";

const PahFingerprintingBody = ({ sampleData, siteInfo, geoJSON, chemicals, sourceReferences }) => {
  const { onDataInitialization } = usePahFingerprintingAPI()
  const { headerTab, showPreview } = usePahFingerprinting()

  useEffect(() => {
    if (siteInfo) {
      onDataInitialization(siteInfo, sampleData, geoJSON, chemicals, sourceReferences)
    }
  }, [])

  return (
    <div style={{height: '98%'}}>
      {headerTab == 1 && showPreview && <NewPreview/>}
      {headerTab == 1 && !showPreview && <Review/>}
    </div>
  )
}

export default PahFingerprintingBody
