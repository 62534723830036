import React, { useEffect, useState } from "react";
import axios from "axios";

const ChemicalForm = ({ chemical, groups = [], onSubmit }) => {
  const [formData, setFormData] = useState({
    name: "",
    cas_string: "",
    group_id: "",
    aliases: [],
    units_attributes: [],
  });
  const [defaultUnits, setDefaultUnits] = useState([]);

  useEffect(() => {
    if (chemical) {
      setFormData({
        name: chemical.name || "",
        cas_string: chemical.cas_string || "",
        group_id: chemical.group_id || "",
        aliases: chemical.aliases || [],
        units_attributes: chemical.units || [],
      });
    } else {
      axios
        .get("/guidelines/data", {
          params: {
            jurisdiction: "alberta",
            matrix: "soil",
          },
        })
        .then((response) => {
          setDefaultUnits(response.data.data);
        })
        .catch((error) => {
          console.error("Error fetching default units:", error);
        });
    }
  }, [chemical]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleUnitChange = (index, value) => {
    setFormData((prev) => ({
      ...prev,
      units_attributes: prev.units_attributes.map((unit, idx) =>
        idx === index ? { ...unit, name: value } : unit
      ),
    }));
  };

  const handleAliasAdd = () => {
    setFormData((prev) => ({
      ...prev,
      aliases: [...prev.aliases, ""],
    }));
  };

  const handleAliasChange = (index, value) => {
    setFormData((prev) => ({
      ...prev,
      aliases: prev.aliases.map((alias, idx) =>
        idx === index ? value : alias
      ),
    }));
  };

  const handleAliasRemove = (index) => {
    setFormData((prev) => ({
      ...prev,
      aliases: prev.aliases.filter((_, idx) => idx !== index),
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      {/* Name */}
      <div>
        <label
          htmlFor="name"
          className="block text-sm font-medium text-gray-700"
        >
          Name
        </label>
        <input
          type="text"
          name="name"
          id="name"
          value={formData.name}
          onChange={handleChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
          required
        />
      </div>

      {/* CAS */}
      <div>
        <label
          htmlFor="cas_string"
          className="block text-sm font-medium text-gray-700"
        >
          CAS
        </label>
        <input
          type="text"
          name="cas_string"
          id="cas_string"
          value={formData.cas_string}
          onChange={handleChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
        />
      </div>

      {/* Group */}
      <div>
        <label
          htmlFor="group_id"
          className="block text-sm font-medium text-gray-700"
        >
          Group
        </label>
        <select
          name="group_id"
          id="group_id"
          value={formData.group_id}
          onChange={handleChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
        >
          <option value="">Select a group</option>
          {groups.map((group) => (
            <option key={group.id} value={group.id}>
              {group.title}
            </option>
          ))}
        </select>
      </div>

      {/* Aliases */}
      <div>
        <label className="block text-sm font-medium text-gray-700">
          Aliases
        </label>
        {formData.aliases.map((alias, index) => (
          <div key={index} className="flex items-center mt-2">
            <input
              type="text"
              value={alias}
              onChange={(e) => handleAliasChange(index, e.target.value)}
              className="flex-grow rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
            />
            <button
              type="button"
              onClick={() => handleAliasRemove(index)}
              className="ml-2 px-2 py-1 bg-red-500 text-white rounded hover:bg-red-600"
            >
              Remove
            </button>
          </div>
        ))}
        <button
          type="button"
          onClick={handleAliasAdd}
          className="mt-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          Add Alias
        </button>
      </div>

      {/* Submit */}
      <div className="form-actions" style={{ margin: "2rem 0" }}>
        <button
          type="submit"
          className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          {chemical ? "Update Chemical" : "Create Chemical"}
        </button>
      </div>
    </form>
  );
};

export default ChemicalForm;
