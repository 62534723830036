import React from "react";
import { downloadCSV, deleteUpload } from "./helperFunctions";

const LocationUploadRows = ({ locationUpload, site, locationUploads, setLocationUploads }) => {
  const fileName = locationUpload.data.url.split('/').pop();
  
  return (
    <tr key={locationUpload.id}>
      <td className='text-left'>{fileName}</td>
      <td className='text-right'>
        <button className='btn-action primary mr-1' onClick={() => downloadCSV(locationUpload, site, 'location_uploads')} title="Download File"><i className="fa fa-download text-gray-700 cursor-pointer"/> Download</button>
        <button className='btn-action delete' onClick={() => deleteUpload(locationUpload, site, 'location_uploads', locationUploads, setLocationUploads)} title="Delete File"><i className="fa fa-trash-alt text-red-500 cursor-pointer" /> Delete</button>
      </td>
    </tr>
  );
};

export default LocationUploadRows;
