import React from "react";
import ReceptorTable from "./receptorTable";

const GuidelineTable = ({ title, guidelines, unit }) => {

  const receptorsList = guidelines.receptors.map((receptor) => {
    return(<ReceptorTable title={receptor.title} pathways={receptor.pathways} chemicals={guidelines.chemicals} unit={unit}/>)
  })

  return (<>
    <div>
      <h2 className='font-semibold my-3 px-2'>{title}</h2>
      {receptorsList}
    </div>
  </>)
}

export default GuidelineTable
