import React, { useEffect, useState } from 'react'
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import axios from "axios";
import buildPahChart from "../helpers/buildPahChart";

const BarChart = ({ site, sample }) => {
  let [categories, setCategories] = useState()
  let [data, setData] = useState()
  let [chartOptions, setChartOptions] = useState()

  useEffect(() => {
    axios.get(`/sites/${site.id}/reports/pah_fingerprinting/concentrations?sample_id=${sample.id}`).then(res => {
      let seriesData = res.data.map(item => {
        if (item.less_than) {
          return { name: item.name, y: item.effective_value, color: '#fcbc5b' }
        } else {
          return { name: item.name, y: item.effective_value }
        }
      })
      setData(seriesData)
    })
  }, [])

  useEffect(() => {
    data && setCategories(data.map(item => item.name))
  }, [data])

  useEffect(() => {
    data && categories && setChartOptions(buildPahChart(sample.sample_name_for_json, 'Concentration (mg/kg)', data, categories, 'mg/kg'))
  }, [categories])

  return categories && categories.length > 0 && chartOptions ? <>
    <HighchartsReact
      highcharts={Highcharts}
      options={chartOptions} />
  </> : <></>
}

export default BarChart
