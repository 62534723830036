import React, { useState, useEffect } from "react";
import axios from "axios";
import Chemical from "./chemical";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const Group = ({ group }) => {
  const [chemicals, setChemicals] = useState([])
  const [show, setShow] = useState(false)

  useEffect(() => {
    if (show && chemicals.length === 0) {
      axios.get("/chemicals/by_group", { params: { group_id: group.id } })
        .then(res => {
          setChemicals(res.data)
        })
        .catch(err => console.log(err))
    }
  }, [show])

  const updateChemicals = () => {
    axios.put("/chemicals/update_order", { chemicals: chemicals } )
      .then(res => toast.success(res.data))
      .catch(err => console.log(err))
  }

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) return
    if (source.index == destination.index) return

    const updatedChemicals = [...chemicals]
    const sourceIndex = source.index
    const destinationIndex = destination.index
    const [removedStore] = updatedChemicals.splice(sourceIndex, 1)
    updatedChemicals.splice(destinationIndex, 0, removedStore)

    return setChemicals(updatedChemicals)
  }
  
  return <>
    <ToastContainer />
    <div>
      <div
        className='flex flex-row justify-between items-center font-semibold border-b border-gray-600 font-semibold px-2 mt-2 cursor-pointer'
        onClick={() => setShow(!show)}
      >
        <h2>{group.title}</h2>
        <div
          className='text-2xl'
        >{show ? <span>&uarr;</span> : <span>&darr;</span>}</div>
      </div>
      {show && <>
        {chemicals.length > 0 ?
        <>
          <div className='flex flex-row justify-between my-2 px-2'>
            <div>
              <p>* Hover and drag a chemical to change the order</p>
              <p>* Press the update when completed</p>
            </div>
            <button
              onClick={updateChemicals}
              className='bg-blue-500 hover:bg-blue-700 text-white font-semi py-1 px-4 rounded'
            >
              Update Order
            </button>
          </div>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={String(group.id)} type={'group '}>
              {provided => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {chemicals.map((chemical, index) => (
                    <Draggable draggableId={String(chemical.id)} key={chemical.id} index={index}>
                      {(provided) => (
                        <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                          <Chemical
                            key={chemical.id}
                            chemical={chemical}
                            index={index}
                            setChemicals={setChemicals}
                          />
                        </div>
                      )}
                    </Draggable>
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </>
        :
        <div className='my-2 p-2'>There are no chemicals in this group</div>}
      </>}
    </div>
  </>
}

export default Group
