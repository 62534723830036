export const getClusterChildrenAsync = (map, clusterId) => {
  return new Promise((resolve, reject) => {
    map.getSource('sampleLocations').getClusterLeaves(clusterId, 100, 0, (error, features) => {
      if (error) reject(error);
      resolve(features);
    });
  });
};

export const setColorToClusters = (map, clusters, results) => {
  const clusterMap = new Map();
  results.forEach((features, index) => {
    const cluster = clusters[index];
    const uniqueColors = [...new Set(features.map(feature => feature.properties.color))];
    let clusterColor = '#90EE90';
    
    if (uniqueColors.includes('#E2E8F0'))
      clusterColor = '#E2E8F0';
    if (uniqueColors.includes('#fee090'))
      clusterColor = '#FDFD99';
    if (uniqueColors.includes('#fc8d59'))
      clusterColor = '#ffb347';
    if (uniqueColors.includes('#d73027'))
      clusterColor = '#DF6165';
      
    const clusterId = cluster.id;
    if (!clusterMap.has(clusterId)) {
      clusterMap.set(clusterId, { id: clusterId, color: clusterColor });
    }
  });
  
  const paintUpdates = [];
  clusterMap.forEach(paintProp => {
    const { id, color } = paintProp;
    if (map.getLayer('clusters') && map.getLayer('clusters').id === 'clusters') {
      paintUpdates.push(['==', ['id'], id], color);
    }
  });

  map.setPaintProperty('clusters', 'circle-color', ['case', ...paintUpdates, '#FDFD99']);
};

export const fitBounds = (map, bounds) => {
  if (!bounds) return
  map.fitBounds(bounds, {
    padding: {top: 200, bottom: 200, left: 200, right: 200}
  })
}
