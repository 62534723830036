import React, { useEffect, useState } from 'react'
import 'react-toastify/dist/ReactToastify.css';
import { downloadZip } from "client-zip"
import axios from "axios";
import Chart from "./chart";

const Export = ({ site, saltPrint }) => {
  const [saltPrintSamples, setSaltPrintSamples] = useState(null)
  const [charts, setCharts] = useState([])
  const [selectedSamples, setSelectedSamples] = useState([])

  useEffect(() => {
    axios.get(`/sites/${site.id}/salt_prints/${saltPrint.id}/salt_print_samples.json`)
      .then(res => setSaltPrintSamples(res.data))
  }, [])

  const toPngBytes = (dataUrl) => {
    const base64 = dataUrl.substring("data:image/png;base64,".length)
    return Uint8Array.from(window.atob(base64), (v) => v.charCodeAt(0));
  }

  const zipDownload = async () => {
    const selectedCharts = charts.filter(chart => selectedSamples.includes(chart.sample.id))
    const files = selectedCharts.map((chart) => {
      const chartName = chart.sample.sample.sample_location.name +  '_' + chart.sample.sample.sample_depth + '.png'
      return { name: chartName, lastModified: new Date(), input: toPngBytes(chart.chartData) }
    })

    const blob = await downloadZip(files).blob()

    const link = document.createElement("a")
    link.href = URL.createObjectURL(blob)
    link.download = `${site.title}_salt_prints.zip`
    link.click()
    link.remove()
  }

  const saltPrintCharts = saltPrintSamples?.map(saltPrintSample => <Chart key={saltPrintSample.id} saltPrintSample={saltPrintSample} setCharts={setCharts} selectedSamples={selectedSamples} setSelectedSamples={setSelectedSamples} />)

  return <>
    {saltPrintSamples && <>
      <div className='flex flex-row justify-between my-2'>
        <div>
          <h1>{site.title} - {saltPrint.title}</h1>
          <h3>* Please click on a salt print to add it to the export</h3>
        </div>
        <div className='flex flex-row justify-end gap-x-3'>
          <button
            className={"text-center font-bold uppercase p-3 shadow-lg rounded block leading-normal cursor-pointer text-white bg-blue-400"}
            onClick={() => setSelectedSamples(saltPrintSamples.map(sample => sample.id))}
          >
            Select All
          </button>
          {selectedSamples.length > 0 ?
            <button
              className={"text-center font-bold uppercase p-3 shadow-lg rounded block leading-normal cursor-pointer text-white bg-blue-400"}
              onClick={zipDownload}
            >
              Export {selectedSamples.length} Chart{selectedSamples.length > 1 && 's'}
            </button>
            :
            <button
              className={"text-center font-bold uppercase p-3 shadow-lg rounded block leading-normal cursor-not-allowed text-white bg-gray-300"}
            >
              Export
            </button>
          }

        </div>
      </div>
      <div className='grid grid-cols-3 gap-x-2 gap-y-2'>
        {saltPrintCharts}
      </div>
    </>}
  </>
}

export default Export
