import React from 'react'
import mapboxgl from 'mapbox-gl'
import ReactDOM from 'react-dom'
import axios from 'axios'
import GeoInputPopup from './geoInputPopup'

const FEATURE_TYPES = {
  POINT: 'Point',
  LINE_STRING: 'LineString',
  POLYGON: 'Polygon',
}

const createPopup = (map, coordinates, html) => {
  return new mapboxgl.Popup({ closeOnClick: false, closeButton: false })
    .setLngLat(coordinates)
    .setHTML(html)
    .addTo(map)
}

const getShapePopupCoordinates = (feature) => {
  const { geometry, feature_type } = feature
  switch (geometry?.type || feature_type) {
    case FEATURE_TYPES.POINT:
      return geometry?.coordinates || feature.coordinates
    case FEATURE_TYPES.POLYGON:
      return geometry?.coordinates[0][0] || feature.coordinates[0][0]
    case FEATURE_TYPES.LINE_STRING:
      return geometry?.coordinates[0] || feature.coordinates[0]
  }
}

const deleteFeatureData = (featureId, toast) => {
  axios.delete(`/geospatial_features/${featureId}`)
  .then(_ => toast('Feature Deleted'))
  .catch((error) => console.log(error))
}

const updateFeatureData = (featureId, featureData, toast) => {
  axios.put(`/geospatial_features/${featureId}`, { geospatial_feature: featureData })
  .then((_) => toast('Feature Updated'))
  .catch((error) => console.log(error))
}


export const convertToFeatureCollection = (features) => ({
  type: "FeatureCollection",
  features: features.map((feature) => ({
    id: feature.name,
    type: "Feature",
    geometry: {
      type: feature.feature_type,
      coordinates: feature.coordinates,
    },
    properties: {
      label: feature.label,
    },
  })),
})

export const addPopupsForFeatures = (map, geospatialFeatures) => {
  const popupsList = {}
  geospatialFeatures.forEach((feature) => {
    if (!feature.label) return
    const coordinates = getShapePopupCoordinates(feature)
    const popupHTML = `<div class='w-auto h-auto flex items-center bg-gray-300/75'><span class='p-0'>${feature.label}</span></div>`

    const popup = createPopup(map, coordinates, popupHTML)
    popupsList[feature.name] = popup
  })

  return popupsList
}

export const onDrawCreate = (e, map, siteId, setGeoSpatialFeatures, setGeoPopups, toast) => {
  const feature = e.features[0]
  const popupCoordinates = getShapePopupCoordinates(feature)
  const container = document.createElement('div')
  const closePopup = () => inputPopup.remove()
  ReactDOM.render(
    <GeoInputPopup
      closePopup={closePopup}
      feature={feature}
      siteId={siteId}
      createPopup={createPopup}
      map={map}
      coordinates={popupCoordinates}
      setGeoSpatialFeatures={setGeoSpatialFeatures}
      setGeoPopups={setGeoPopups}
      toast={toast}
    />,
    container
  )
  const inputPopup = new mapboxgl.Popup({ closeOnClick: false, closeButton: false })
    .setLngLat(popupCoordinates)
    .setDOMContent(container)
    .addTo(map)
}

export const onDrawDelete = (e, geoSpatialFeatures, setGeoSpatialFeatures, toast) => {
  const featureId = e.features[0].id
  const featureData = geoSpatialFeatures.find((feature) => feature.name === featureId)

  if (featureData) {
    deleteFeatureData(featureData.id, toast)
    setGeoSpatialFeatures(geoSpatialFeatures.filter((feature) => feature.name !== featureId))
  }
}

export const onDrawUpdate = (e, geoSpatialFeatures, setGeoSpatialFeatures, toast) => {
  const featureId = e.features[0].id
  const featureData = geoSpatialFeatures.find((feature) => feature.name === featureId)

  if (featureData) {
    const updatedFeatureData = {
      ...featureData,
      coordinates: e.features[0].geometry.coordinates,
    }

    updateFeatureData(featureData.id, updatedFeatureData, toast)
    setGeoSpatialFeatures(geoSpatialFeatures.map((feature) => (feature.name === featureId ? updatedFeatureData : feature)))
  }
}

export const removeRulerFunctions = (map, setDistance) => {
  if (map._listeners.click) {
    const drawLineListenerIndex = map._listeners.click.findIndex((listener) => listener.name === 'drawLineString')
    map._listeners.click.splice(drawLineListenerIndex, 1)
  }

  if (map.getSource('line-distance')) {
    map.removeLayer('line-distance-layer')
    map.removeLayer('point-distance-layer')
    map.removeSource('line-distance')
  }

  setDistance(null)
}

export const addRulerFunctions = (map, distanceGeojson, drawLineString) => {
  map.addSource('line-distance', {
    type: 'geojson',
    data: distanceGeojson,
  })

  map.addLayer({
    id: 'line-distance-layer',
    type: 'line',
    source: 'line-distance',
    paint: {
      'line-color': 'yellow',
      'line-width': 2,
    },
  })

  map.addLayer({
    id: 'point-distance-layer',
    type: 'circle',
    source: 'line-distance',
    paint: {
      'circle-radius': 4,
      'circle-color': 'yellow',
    },
  })

  map.on('click', drawLineString)
}
const startDraw = (distanceGeojson, clickedPoint) => {
  const startPoint = clickedPoint
  distanceGeojson.features = [startPoint]
  return startPoint
}

const continueDraw = (turf, startPoint, distanceGeojson, lineString, clickedPoint, setDistance) => {
  if (!lineString) {
    lineString = turf.lineString([
      startPoint.geometry.coordinates,
      clickedPoint.geometry.coordinates,
    ])
  } else {
    lineString.geometry.coordinates.push(clickedPoint.geometry.coordinates)
  }

  const distanceInMeters = turf.length(lineString, { units: 'kilometers' }) * 1000
  distanceGeojson.features = [lineString, clickedPoint]
  setDistance(distanceInMeters.toFixed(2))
  return lineString
}

export const createDrawLineString = (turf, distanceGeojson, setDistance, map) => {
  let lineString = null
  let startPoint = null

  const drawLineString = (e) => {
    const { lng, lat } = e.lngLat
    const clickedPoint = turf.point([lng, lat])

    if (!startPoint) {
      startPoint = startDraw(distanceGeojson, clickedPoint)
    } else {
      lineString = continueDraw(turf, startPoint, distanceGeojson, lineString, clickedPoint, setDistance)
    }

    map.getSource('line-distance').setData(distanceGeojson)
  }

  return drawLineString
}
