import React from 'react';
import { usePahFingerprintingAPI } from "../../../../contexts/pahFingerprinting";

const tabHeader = ({ title, headerField, tab, tabNumber }) => {
  const { onHeaderChange } = usePahFingerprintingAPI()

  const tabStyling = () => {
    if (tab == tabNumber) {
      return " text-gray-800 bg-blue-300"
    }

    return " text-header bg-gray-100"
  }

  const disableBack = () => {
    if (headerField == 'headerTab') {
      if (tabNumber != tab + 1) {
        return true
      }
    }

    return false
  }

  return(
    <button
      className={"flex items-center justify-center h-5 text-xs text-center w-1/4 font-bold uppercase p-2 shadow-lg rounded focus:outline-none leading-normal cursor-default border border-gray-700 mx-1" + tabStyling()}
      disabled={disableBack()}
      onClick={e => {
        e.preventDefault()
        onHeaderChange(headerField, tabNumber)
      }}
    >
      {title}
    </button>
  )
}

export default tabHeader

