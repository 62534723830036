import React from 'react'
import Pathway from "./pathway";
import { useSiteAPI, useSiteContext } from "../../../contexts/site";

const GroundwaterPathways = () => {
  const { groundwaterPathways, groundwaterPathwayOptions } = useSiteContext()
  const { togglePathway } = useSiteAPI()

  return <div className='flex flex-col gap-y-3'>
    <h3 className='font-semibold'>Groundwater Site-Specific Factors</h3>
    {groundwaterPathwayOptions.map(pathway => <Pathway key={pathway.id} pathway={pathway} checked={groundwaterPathways?.includes(pathway.id)} togglePathway={togglePathway('groundwaterPathways')} />)}
  </div>
}

export default GroundwaterPathways
