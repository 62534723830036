import React, { useEffect, useState } from 'react'
import GuidelineRow from "./guidelineRow";
import axios from "axios";
import { useCustomGuidelinesAPI, useCustomGuidelinesContext } from "../../../contexts/customGuidelines";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import NewGuidelinesModal from "./newGuidelinesModal";
import { formattedExistingGuidelines } from "../../../helpers/customGuidelines";

const GuidelinesTable = () => {
  const [showModal, setShowModal] = useState(false)
  const [existingGuidelines, setExistingGuidelines] = useState([])
  const { site, newGuidelines, customGuidelines } = useCustomGuidelinesContext()
  const { updateGuidelines, setNewGuidelines } = useCustomGuidelinesAPI()

  useEffect(() => {
    if (newGuidelines.length > 0 && customGuidelines.length > 0) {
      setExistingGuidelines(formattedExistingGuidelines(newGuidelines, customGuidelines))
    }

  }, [newGuidelines, customGuidelines])

  const createCustomGuidelines = () => {
    axios.post(`/sites/${site.id}/create_custom_guidelines.json`, { custom_guidelines: newGuidelines })
      .then(res => {
        toast.success("Guidelines successfully created!")
        updateGuidelines(res.data)
        setNewGuidelines([])
      })
  }

  const onClick = () => {
    return existingGuidelines.length > 0 ? setShowModal(true) : createCustomGuidelines()
  }

  return <>
    <ToastContainer />
    <p>* The system will assume that your custom guidelines are in the same units as the data for your site</p>
    <table className='mt-4 border-collapse bg-white'>
      <thead className='bg-gray-100 border-b border-gray-200 text-gray-600 font-bold tracking-wider'>
        <tr>
          <th className='px-3 text-left w-1/4'>Uploaded Parameter</th>
          <th className='w-96 text-left'>Statvis Parameter</th>
          <th className='w-16'>Soil Units</th>
          <th className='w-36'>Surface Soil Guideline</th>
          <th className='w-28'>Subsoil Guideline</th>
          <th className='w-32'>Groundwater Units</th>
          <th className='w-36'>Groundwater Guideline</th>
        </tr>
      </thead>
      <tbody>
        {newGuidelines?.map((guideline, index) => <GuidelineRow key={index} guideline={guideline} />)}
      </tbody>
    </table>
    <p className='my-3 px-2'>* No guidelines will be created for any uploaded parameters that do not have a STATVIS parameter selected</p>
    <button
      onClick={onClick}
      className="bg-blue-500 hover:bg-blue-700 text-white font-semibold py-1 px-4 border border-blue-700 rounded text-center w-60 my-2"
    >
      Create Guidelines
    </button>
    <NewGuidelinesModal showModal={showModal} setShowModal={setShowModal} existingGuidelines={existingGuidelines} createGuidelines={createCustomGuidelines} />
  </>
}

export default GuidelinesTable
