import React from "react";

const ValueSelectors = ({valueRange, setValueRange, parameter}) => {
  const hasSelectedParameter = !Object.entries(parameter).length > 0;
  
  const handleStartValueChange = (e) => {
    setValueRange({...valueRange, start: e.target.value})
  };

  const handleEndValueChange = (e) => {
    setValueRange({...valueRange, end: e.target.value})
  };

  return (
    <div className='flex bg-gray-200 p-2'>
      <div className='flex flex-col mr-2'>
        <label className={`${hasSelectedParameter ? 'text-gray-400' : 'text-gray-600'} font-bold tracking-wider uppercase text-xs mb-1`}>Start Value:</label>
        <input className='w-16 h-6 bg-gray-100 text-gray-800 p-1 rounded' 
          value={valueRange.start} 
          type='number' 
          onChange={handleStartValueChange}
          disabled={hasSelectedParameter}
        />
      </div>
      <div className='flex flex-col'>
        <label className={`${hasSelectedParameter ? 'text-gray-400' : 'text-gray-600'} font-bold tracking-wider uppercase text-xs mb-1`}>End Value:</label>
        <input className='w-16 h-6 bg-gray-100 text-gray-800 p-1 rounded' 
          value={valueRange.end} 
          type='number' 
          onChange={handleEndValueChange} 
          disabled={hasSelectedParameter}
        />
      </div>
    </div>
  );
}

export default ValueSelectors;
