import React from "react";
import Group from "./group";

const Order = ({ groups }) => {

  return <>
    <h1>Admin Chemical Order Tool</h1>
    <p>* Click a header to expand group and view chemicals</p>
    {groups.map(group => <Group key={group.id} group={group} />)}
  </>
}

export default Order
