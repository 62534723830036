import { useContext } from "react";
import { TierOneContext } from "../tierOneContext";

const useQuestionData = (type) => {
  let [site, setSite] = useContext(TierOneContext);

  switch(type) {
    case 'volatile':
      return {
        question: 'Source of volatile contaminants present within 30cm of a building foundation?',
        tooltip: 'Within this distance, the model used to assess vapour intrusion to buildings is not reliable, and Tier 1 guidelines are not applicable.',
        name: 'volatile-contaminants',
        value: site.mandatoryGuidelines.volatileContaminant,
        stateUpdate: (event) => {
          setSite((prevState) => ({...prevState, mandatoryGuidelines: {...prevState.mandatoryGuidelines, volatileContaminant: event.target.value}}))
        }
      };
    case 'building':
      return {
        question: 'Unique building features, including earthen floors or unusually low air exchange rates?',
        tooltip: 'Building features such as earthen floors or unusually low air exchange rates are not considered in the Tier 1 model, and a Tier 2 approach is required.',
        name: 'building-features',
        value: site.mandatoryGuidelines.buildingFeature,
        stateUpdate: (event) => {
          setSite((prevState) => ({...prevState, mandatoryGuidelines: {...prevState.mandatoryGuidelines, buildingFeature: event.target.value}}))
        }
      };
    case 'receptors':
      return {
        question: 'Sensitive receptors that are present but not accounted for in the Tier 1 land use description?',
        tooltip: 'Consider the receptors that would be present under the Tier 1 land or water use category. If a site has a receptor that would not be accounted for under a generic scenario then a Tier 2 or site-specific risk assessment approach is required. An example would be native grassland. The Tier 1 guidelines were developed based on traditional agricultural crops that are less sensitive to disturbance than native prairie. For more detail on this specific example see the “Native Prairie Protocol for reclamation certification of salt-affected wellsites.”',
        name: 'receptors',
        value: site.mandatoryGuidelines.sensitiveReceptor,
        stateUpdate: (event) => {
          setSite((prevState) => ({...prevState, mandatoryGuidelines: {...prevState.mandatoryGuidelines, sensitiveReceptor: event.target.value}}))
        }
      }
    case 'stagnant':
      return {
        question: 'Groundwater flow to stagnant water bodies?',
        tooltip: 'A stagnant water body is defined as a water body without significant outflow, and where the main pathway of water loss is via evaporation. Stagnant water bodies will tend to concentrate discharging persistent groundwater contaminants through evaporation. If outflow is suspected via groundwater and no obvious surface outflow is present, a groundwater investigation will be needed to provide confirmation. \n\nWhen there is the potential for a contaminant in groundwater to discharge to a stagnant surface water body, a Tier 2 mass balance assessment of the likely concentrations of that contaminant in the stagnant water body over the anticipated lifetime of the groundwater discharge is required.',
        name: 'stagnant',
        value: site.mandatoryGuidelines.stagnant,
        stateUpdate: (event) => {
          setSite((prevState) => ({...prevState, mandatoryGuidelines: {...prevState.mandatoryGuidelines, stagnant: event.target.value}}))
        }
      }
    case 'contaminated-water':
      return {
        question: 'Soil or groundwater contamination present within 10 m of a surface water body?',
        tooltip: 'Tier 1 soil and groundwater remediation guidelines for the protection of aquatic life assume a minimum separation of 10 m between the point that the soil or groundwater concentration is measured, and the discharge point. Accordingly, Tier 1 guidelines only apply to soil or groundwater located at least 10 m from the nearest surface water body that is capable of supporting an aquatic ecosystem. Within this distance, a Tier 2 approach is required, or in the case of groundwater guidelines, the corresponding surface water freshwater aquatic life guideline may be applied directly to groundwater quality. The 10 m offset distance must take into account potential for seasonal fluctuations in the water level. This may require information on the flood risk area. For more information, see the Tier 2 document.',
        name: 'contaminated-water',
        value: site.mandatoryGuidelines.contaminatedWater,
        stateUpdate: (event) => {
          setSite((prevState) => ({...prevState, mandatoryGuidelines: {...prevState.mandatoryGuidelines, contaminatedWater: event.target.value}}))
        }
      }
    case 'coarse':
      return {
        question: 'Very coarse textured materials enhancing groundwater or vapor transport?',
        tooltip: 'Groundwater velocity is a function of both hydraulic conductivity and hydraulic gradient and assumed to be 1x10-5 m/s and 0.028 m/m, respectively, for Tier 1 guideline derivation. The resulting Darcy groundwater velocity is 3 x 10-7 m/s. \n\nThe rate of vapour transport through coarse soil is largely governed by vapour permeability which is assumed to be 6x10-8 cm2 for Tier 1 guideline derivation. \n\nIf the Darcy groundwater velocity exceeds 3x10-7 m/s, or vapour permeability is greater than 6x10-8 cm2 at a site, a Tier 2 approach is required. In these cases, the Tier 2 guideline must incorporate groundwater or vapour inhalation as potentially dominant exposure pathway(s).',
        name: 'coarse',
        value: site.mandatoryGuidelines.coarse,
        stateUpdate: (event) => {
          setSite((prevState) => ({...prevState, mandatoryGuidelines: {...prevState.mandatoryGuidelines, coarse: event.target.value}}))
        }
      }
    case 'bedrock':
      return {
        question: 'Contamination in fractured bedrock?',
        tooltip: 'The Tier 1 remediation guidelines were developed for unconsolidated soil material, therefore the presence of bedrock may require a Tier 2 re-evaluation. However, the guidelines may be applied to contaminants in contact with bedrock if the bedrock is likely to behave conservatively as one of the two soil textures. The actual texture of the bedrock material will often be less influential on contaminant movement than the degree of bedrock weathering and fracturing. Professional judgment must be applied in determining whether coarse or fine soil guidelines are the most appropriate, given the expected contaminant mobility within the bedrock. For instance, a weathered shale material may not automatically require a Tier 2 re-evaluation but it may require use of a coarse soil guideline due to the presence of minor fractures within the matrix. \n\nTier 2 re-evaluation is required where flow paths in the fractured bedrock cannot reasonably be expected to behave similarly to those in an aggregated soil medium. When the fracture length exceeds approximately 2 cm, flow paths in the fractured bedrock may be different than those in an aggregated soil medium. Under such conditions, groundwater transport in fractured bedrock is outside the scope of the calculations for Tier 1 guidelines and a Tier 2 or Exposure Control approach must be taken.',
        name: 'bedrock',
        value: site.mandatoryGuidelines.contaminationBedrock,
        stateUpdate: (event) => {
          setSite((prevState) => ({...prevState, mandatoryGuidelines: {...prevState.mandatoryGuidelines, contaminationBedrock: event.target.value}}))
        }
      }
    case 'source':
      return {
        question: 'When the length of such a source is greater than 10m parallel to the direction of groundwater flow, Tier 1 guidelines may be applied to the site if contaminant delineation shows that the source volume is less than 300m³.',
        tooltip: 'For information on source length see Section 5.1.5 of the Alberta Tier 1 Soil and Groundwater Remediation Guidelines: ',
        link: 'https://open.alberta.ca/publications/1926-6243',
        name: 'source',
        value: site.mandatoryGuidelines.contaminatedSource,
        stateUpdate: (event) => {
          setSite((prevState) => ({...prevState, mandatoryGuidelines: {...prevState.mandatoryGuidelines, contaminatedSource: event.target.value}}))
        }
      }
    case 'organic': 
      return {
        question: 'Is peat present at the site?',
        tooltip: 'Organic soils are defined in the Canadian System of Soil Classification found here:',
        link: 'https://sis.agr.gc.ca/cansis/taxa/cssc3/chpt02.html#organic',
        name: 'organic',
        value: site.siteDetails.organicSoil,
        stateUpdate: (event) => {
          setSite((prevState) => ({...prevState, siteDetails: {...prevState.siteDetails, organicSoil: event.target.value}}))
        }
      }
    case 'wellsite':
      return {
        question: 'Is this a wellsite?',
        tooltip: 'Affects guidelines within 5 m of a wellhead, and unique APECs like drilling waste disposal areas.',
        name: 'wellsite',
        value: site.siteDetails.wellsite,
        stateUpdate: (event) => {
          setSite((prevState) => ({...prevState, siteDetails: {...prevState.siteDetails, wellsite: event.target.value}}))
        }
      }
    case 'boundary':
      return {
        question: 'Does the site cross Provincial boundaries (e.g., pipelines or powerlines)',
        tooltip: 'The most common site types that cross Provincial boundaries are linear facilities like pipelines and powerlines. When a site crosses a Provincial boundary, it is regulated by the Canada Energy Regulator (CER).',
        name: 'boundary',
        value: site.siteDetails.boundary,
        stateUpdate: (event) => {
          setSite((prevState) => ({...prevState, siteDetails: {...prevState.siteDetails, boundary: event.target.value}}))
        }
      }
    case 'sensitive':
      return {
        question: 'Is there a more sensitive land use within 30m?',
        tooltip: 'An example of this would be a commercial property like a gas station with residential properties adjacent to it or an industrial plant site with agricultural property adjacent to it.',
        name: 'sensitive',
        value: site.siteDetails.sensitive,
        stateUpdate: (event) => {
          setSite((prevState) => ({...prevState, siteDetails: {...prevState.siteDetails, sensitive: event.target.value}}))
        }
      }
    case 'onsite':
      return {
        question: 'Was drilling waste disposed of onsite?',
        name: 'onsite',
        tooltip: 'For guidance on minimum requirements for Phase 2 environmental site assessments (ESAs) and confirmatory investigations see the Environmental Site Assessment Standard.',
        link: 'https://open.alberta.ca/publications/alberta-environmental-site-assessment-standard',
        value: site.siteDetails.onsite,
        stateUpdate: (event) => {
          setSite((prevState) => ({...prevState, siteDetails: {...prevState.siteDetails, onsite: event.target.value}}))
        }
      }
    default:
      return {};
  }
}

export default useQuestionData; 
