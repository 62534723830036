import React, { createContext, useReducer, useMemo, useEffect, useContext } from 'react'

/* Actions */
const UPDATE_STATE = 'UPDATE_STATE'
const TOGGLE_POPUPS = 'TOGGLE_POPUPS'
const TOGGLE_LEGENDS = 'TOGGLE_LEGENDS'

/* Initial States */
const initialState = (initialData) => ({
    currentParameter: initialData.initialParameter,
    parameter: initialData.initialParameter,
    siteId: initialData.siteId,
    locationIds: initialData.locationIds,
    highlightedChartData: {},
    highlightedTableData: [],
    highlightedGeojson: null,
    chart: initialData.initializedChartData,
    geojson: initialData.geoJSON,
    tableData: [],
    highlightSampleIds: [],
    showPopups: false,
    showLegends: false,
})

/* Reducer */
const groundwaterReducer = (state, action) => {
    switch (action.type) {
        case UPDATE_STATE:
            return { ...state, [action.field]: action.value }
        case TOGGLE_POPUPS:
            return { ...state, showPopups: !state.showPopups }
        case TOGGLE_LEGENDS:
            return { ...state, showLegends: !state.showLegends }
        default:
            return state
    }
}

/* Contexts */
const GroundwaterContext = createContext(initialState)
const GroundwaterApiContext = createContext({
    updateState: () => {},
    setCurrentParameter: () => {},
    setHighlightedChartData: () => {},
    setHighlightedTableData: () => {},
    setHighlightedGeojson: () => {},
    setChart: () => {},
    setTableData: () => {},
    setGeojson: () => {},
    setParameter: () => {},
    setHighlightSampleIds: () => {},
    togglePopups : () => {},
    toggleLegends: () => {},
})

/* Providers */
export const GroundwaterProvider = ({children, initialData}) => {
    const [state, dispatch] = useReducer(groundwaterReducer, initialState(initialData))

    const api = useMemo(() => {
        const updateState = (field, value) => dispatch({ type: UPDATE_STATE, field, value });
        const setCurrentParameter = (newParameter) => updateState('currentParameter', newParameter);
        const setHighlightedChartData = (newChartData) => updateState('highlightedChartData', newChartData);
        const setHighlightedTableData = (newTableData) => updateState('highlightedTableData', newTableData);
        const setHighlightedGeojson = (newGeoJSON) => updateState('highlightedGeojson', newGeoJSON);
        const setChart = (newChartData) => updateState('chart', newChartData);
        const setTableData = (newTableData) => updateState('tableData', newTableData);
        const setGeojson = (newGeoJSON) => updateState('geojson', newGeoJSON);
        const setParameter = (newParameter) => updateState('parameter', newParameter);
        const setHighlightSampleIds = (newSampleIds) => updateState('highlightSampleIds', newSampleIds);
        const togglePopups = () => dispatch({ type: TOGGLE_POPUPS })
        const toggleLegends = () => dispatch({ type: TOGGLE_LEGENDS })

        return { updateState, setCurrentParameter, setHighlightedChartData, setHighlightedTableData, setHighlightedGeojson, setChart, setTableData, setGeojson, setParameter, setHighlightSampleIds, togglePopups, toggleLegends }
    }, []);

    return (
        <GroundwaterApiContext.Provider value={api}>
            <GroundwaterContext.Provider value={state}>
                {children}
            </GroundwaterContext.Provider>
        </GroundwaterApiContext.Provider>
    )
}

/* Custom Context Hooks */
export const useGroundwaterContext = () => useContext(GroundwaterContext)
export const useGroundwaterAPI = () => useContext(GroundwaterApiContext)
