import React from "react";

const SearchFilter = ({ filter, setFilter }) => {
  const handleInputChange = (e) => {
    setFilter(e.target.value);
  };
  const handleClearClick = () => {
    setFilter("");
  };
  const icon = filter ? "fa-times" : "fa-search";
  const iconStyle = filter ? "cursor-pointer hover:text-red-400" : "";

  return (
    <div className="relative border border-gray-500 rounded-md w-1/4 ml-2">
      <input
        className="w-full bg-white text-gray-900 p-1 rounded-lg"
        type="text"
        value={filter || ""}
        onChange={handleInputChange}
        placeholder="Search"
      />
      <i
        className={`fa ${icon} absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-500 ${iconStyle}`}
        onClick={handleClearClick}
      />
    </div>
  );
};

export default SearchFilter;
