import React, { useEffect, useState, useRef, useMemo } from "react";
import { Radar } from "react-chartjs-2";

const plugin = {
  id: 'customCanvasBackgroundColor',
  beforeDraw: (chart, args, options) => {
    const { ctx } = chart;
    ctx.save();
    ctx.globalCompositeOperation = 'destination-over';
    ctx.fillStyle = options.color || '#99ffff';
    ctx.fillRect(0, 0, chart.width, chart.height);
    ctx.restore();
  }
};

const Chart = ({ saltPrintSample, setCharts, selectedSamples, setSelectedSamples }) => {
  const [data, setData] = useState(null)
  const [options, setOptions] = useState(null)
  const [loading, setLoading] = useState(null)
  const [selected, setSelected] = useState(false)
  const chartRef = useRef(null)


  useEffect(() => {
    if (saltPrintSample) {
      setData(saltPrintSample.radar_data)
    }
  }, [saltPrintSample])

  const title = (sample) => {
    const location = sample.sample.sample_location.name
    const depth = sample.sample.sample_depth && `Depth: ${sample.sample.sample_depth}m`
    const date = sample.sample.sample_date && `Date: ${sample.sample.sample_date}`

    return [location, depth, date].filter(n => n).join(" ")
  }

  useEffect(() => {
    if (data) {
      setOptions({ 
        scale: { ticks: { beginAtZero: false, max: 100 } },
        plugins: {
          customCanvasBackgroundColor: {
            color: 'white',
          },
          title: {
            display: true,
            text: title(saltPrintSample),
          },
        },
        animation: {
          onComplete: function done() {
            setLoading(true)
          }
        },
      })
    }
  }, [data])

  useEffect(() => {
    if (loading) {
      setCharts(prevState => [...prevState, { sample: saltPrintSample, chartData: chartRef.current.toBase64Image() }] )
    }
  }, [loading])

  const radarChart =  useMemo(() => {
    if (chartRef && data && options && plugin) {
      return <Radar
        ref={chartRef}
        data={data}
        options={options}
        plugins={[plugin]}
      />
    }
  }, [chartRef, data, options, plugin])

  const toggleSelection = () => {
    if (selectedSamples.includes(saltPrintSample.id)) {
      setSelectedSamples(prevState => prevState.filter(sample => sample != saltPrintSample.id))
      setSelected(false)
    } else {
      setSelectedSamples(prevState => [...prevState, saltPrintSample.id])
      setSelected(true)
    }
  }

  useEffect(() => {
    if (selectedSamples.includes(saltPrintSample.id)) setSelected(true)
  }, [selectedSamples])

  return <>
    <div className={`cursor-pointer hover:bg-blue-400 rounded-lg py-4 px-4 ${selected ? 'bg-blue-300' : 'bg-blue-50'}`} onClick={toggleSelection} >
      <h3 className='font-semibold text-center'>{saltPrintSample.sample.sample_location.name} @ {saltPrintSample.sample.sample_depth}</h3>
      <div className='panel-body flex flex-col p-4'>
        {data && radarChart}
      </div>
    </div>
  </>
}

export default Chart

