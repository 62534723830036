import React, { useMemo } from 'react'
import ExportSampleItem from './exportSampleItem'
import { useDioxinsAndFuransContext, useDioxinsAndFuransAPI } from '../../contexts/dioxinsAndFurans'
import { generatePdfReport } from './exportHelpers/exportFunctions'

const PreviewPage = ({setPreview}) => {
  const { site, sampleIdsForExport, exportData } = useDioxinsAndFuransContext()
  const { setShowExportModal, setExportData } = useDioxinsAndFuransAPI()
  const sampleItems = useMemo(() => sampleIdsForExport.map(sampleId => <ExportSampleItem sampleId={sampleId} siteId={site.id} key={sampleId} />), [sampleIdsForExport, site])
  const isLoaded = sampleIdsForExport.length === exportData.length
  const buttonClass = useMemo(() => isLoaded ? 'bg-gray-300 hover:bg-gray-400 cursor-pointer' : 'bg-gray-100 cursor-not-allowed', [isLoaded])
  const buttonLabel = useMemo(() => isLoaded ? 'Export' : <i className="fa fa-spinner fa-spin"/>, [isLoaded])

  const handleBack = () => {
    setPreview(false)
    setShowExportModal(true)
    setExportData([])
  }

  return (
    <>
      <div className='overflow-auto'>
        <div className='sticky top-0 flex justify-between px-2 z-20 bg-white border border-black py-1'>
          <button className='border-gray-400 rounded p-1 text-xs bg-gray-300 hover:bg-gray-400 uppercase font-semibold cursor-pointer w-24' onClick={handleBack}>Back</button>
          <h1 className='font-semibold uppercase'>Preview Samples</h1>
          <button className={`${buttonClass} rounded p-1 text-xs bg-gray-300 hover:bg-gray-400 uppercase font-semibold w-24`} onClick={() =>  generatePdfReport(exportData, site)}>{buttonLabel}</button>
        </div>
        {sampleItems}
      </div>
    </>
  )
}

export default PreviewPage
