import React from 'react'
import Select from 'react-select'
import { usePahFingerprinting, usePahFingerprintingAPI } from '../../../../contexts/pahFingerprinting'

const LabSelect = () => {
  const { labSource } = usePahFingerprinting()
  const { setLabSource } = usePahFingerprintingAPI()

  const options = [
    { label: 'References with 75 Parameters', value: 'SGS' },
    { label: 'References with 48 Parameters', value: 'COGRAD' }
  ]

  const customStyles = {
    menuPortal: base => ({ ...base, zIndex: 9999 }),
    menu: provided => ({ ...provided, position: 'absolute', fontSize: '10px' }),
    singleValue: provided => ({ ...provided, fontSize: '12px'})
  };

  return (
    <>
      <p className='text-xs font-semibold'>Reference Standard List:</p>
      <Select
        className='mx-1'
        options={options}
        defaultValue={options.find(option => option.value === labSource)}
        styles={customStyles}
        menuPortalTarget={document.body}
        onChange={option => setLabSource(option.value)}
      />
    </>
  );
};

export default LabSelect
