import React from "react"
import { displayHeader, headerWidth } from "../../../helpers/adminGuidelines";
import ParameterRow from "./parameterRow";
import { useAdminGuidelinesContext } from "../../../contexts/adminGuidelines";

const GuidelinesTable = () => {
  const { filteredData, headers } = useAdminGuidelinesContext()

  return (
    <div className='overflow-x-scroll'>
      <table className='table-fixed w-full text-center'>
        <thead>
        <tr>
          {headers?.map((header, index) => {
            return <th key={index} className={`w-${headerWidth(header)} border border-gray-600 capitalize`}>{displayHeader(header)}</th>
          })}
        </tr>
        </thead>
        <tbody>
        {filteredData?.map((row, index) => <ParameterRow key={index} data={row} />)}
        </tbody>
      </table>
    </div>
  )
}

export default GuidelinesTable
