import React, {useEffect} from 'react'
import CustomSelect from './customSelect'
import DepthLabel from './depthLabel'

const DepthSelects = ({ options, collectionKVS, handleInputChange, setShowStartDepth, showStartDepth }) => {

  useEffect(() => {
    const hasStartDepth = options.some(option => option.label === "Start Depth");
    if (hasStartDepth) {
      setShowStartDepth(true);
    }
  }, [options, setShowStartDepth]); 

  const endDepthWidth = showStartDepth ? 'w-1/2' : 'w-full'
  const endDepthLabel = showStartDepth ? 'End Depth' : 'Depth - accepts single value (e.g. 2.0) or ranges (e.g. 1.0-2.5)'

  return (
    <>
      <DepthLabel setShowStartDepth={setShowStartDepth} showStartDepth={showStartDepth}/>
      <div className='flex'>
        {showStartDepth &&
          <div className='w-1/2'>
            <CustomSelect id='start_depth_index' label='Start Depth' options={options} keyword='StartDepth' collectionKVS={collectionKVS} required={false} handleInputChange={handleInputChange}/>
          </div>
        }
        <div className={endDepthWidth}>
          <CustomSelect id='depth_index' label={endDepthLabel} options={options} keyword='Depth' collectionKVS={collectionKVS} required={true} handleInputChange={handleInputChange}/>
        </div>
      </div>
    </>
  )
}

export default DepthSelects
