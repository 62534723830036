import axios from 'axios'

const getPdfReportData = (siteId, chartData) => {
  try {
    return axios({
      method: 'POST',
      url: `/sites/${siteId}/pcb_fingerprinting_exports`,
      responseType: 'arraybuffer',
      data: chartData
    })
  }
  catch (e) {
    console.error(e)
  }
}

const downloadReport = (reportData) => {
  const blob = new Blob([reportData], { type: 'application/pdf' })
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.download = 'Report.pdf'
  link.click()
}

export const generatePdfReport = (data, summaryChart, site) => {
  const encodedExportData = data.map(data => {
    return { 
      sample_id: data.sample_id,
      reference: data.reference,
      sample_pcb: encodeURIComponent(data.sample_pcb),
      reference_pcb: encodeURIComponent(data.reference_pcb),
      congener_contribution: encodeURIComponent(data.congener_contribution),
    }
  })

  const pdfData = {samples: encodedExportData, summary: encodeURIComponent(summaryChart)}
  getPdfReportData(site.id, pdfData).then((response) => downloadReport(response.data))
}
