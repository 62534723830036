import React from "react";
import BaseMap from "../map/baseMap";
import { useSaltPrintContext, useSaltPrintAPI } from "../../contexts/saltPrint";

const BaseMapContainer = ({ showChart }) => {
  const { geojson, mapType, highlightSampleIds, displayedGeojson, showPopups, showLegends } = useSaltPrintContext();
  const { setHighlightSampleIds } = useSaltPrintAPI();
  const displayedGeoJSON = displayedGeojson || geojson;
  
  return (
    <BaseMap 
      geojson={displayedGeoJSON} 
      highlightSampleIds={highlightSampleIds} 
      setHighlightSampleIds={setHighlightSampleIds} 
      showChart={showChart}
      mapType={mapType}
      showPopups={showPopups}
      showLegends={showLegends}
    />
  )
};

export default BaseMapContainer;
