import React from "react"
import { AdminGuidelinesProvider } from "../../../contexts/adminGuidelines";
import GuidelinesBody from "./guidelinesBody";

const Guidelines = () => {
  return (
    <AdminGuidelinesProvider >
      <GuidelinesBody />
    </AdminGuidelinesProvider>
  )
}

export default Guidelines
