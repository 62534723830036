import React, { useState, useEffect } from "react";
import ChemicalForm from "./chemicalForm";
import axios from "axios";
import { toast } from "react-toastify";

const EditChemical = ({ chemicalId }) => {
  const [chemical, setChemical] = useState(null);
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [chemicalResponse, groupsResponse] = await Promise.all([
          axios.get(`/chemicals/${chemicalId}.json`),
          axios.get("/groups.json"),
        ]);
        setChemical(chemicalResponse.data);
        setGroups(groupsResponse.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Failed to load chemical data");
        setLoading(false);
      }
    };

    fetchData();
  }, [chemicalId]);

  const handleSubmit = async (formData) => {
    try {
      const response = await axios.patch(
        `/chemicals/${chemicalId}.json`,
        { chemical: formData },
        {
          headers: { Accept: "application/json" },
        }
      );
      if (response.data) {
        toast.success("Chemical updated successfully");
        window.location.href = "/chemicals";
      } else {
        throw new Error("Failed to update chemical");
      }
    } catch (error) {
      console.error("Error updating chemical:", error);
      toast.error("Failed to update chemical");
    }
  };

  const handleAliasChange = (aliases) => {
    setChemical((prev) => ({ ...prev, aliases }));
  };

  if (loading) return <div className="text-center mt-8">Loading...</div>;
  if (!chemical)
    return (
      <div className="text-center mt-8 text-red-600">Chemical not found.</div>
    );

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold text-gray-800 mb-8">
        Edit chemical: {chemical.name}
      </h1>
      <ChemicalForm
        chemical={chemical}
        groups={groups}
        onSubmit={handleSubmit}
        onAliasChange={handleAliasChange}
      />
    </div>
  );
};

export default EditChemical;
