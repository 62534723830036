import React from "react";

export const landUses = [{ label: 'Natural Areas', value: 'Natural Areas' }, { label: 'Agricultural', value: 'Agricultural' }, { label: 'Residential', value: 'Residential' }, { label: 'Commercial', value: 'Commercial' }, { label: 'Industrial', value: 'Industrial' }]

export const soilTypes = [{ label: 'Fine-Grained', value: 'Fine Grain' }, { label: 'Coarse-Grained', value: 'Coarse Grain' }]

export const formatTitle = (title) => {
  if (title.includes("_")) return title.replace("_", " ")
  if (title === 'lsd') return 'LSD'

  return title
}

export const createSymbol = (normalSymbol, subscriptSymbol) => {
  return (<>
    <span>{normalSymbol}</span>
    <sub>{subscriptSymbol}</sub>
  </>)
}

export const createUnit = (topNormal, topSuper, bottomNormal, bottomSuper) => {
  return (<>
    <span>{topNormal}</span>
    {topSuper && <sup>{topSuper}</sup>}
    <span>/</span>
    <span>{bottomNormal}</span>
    <sup>{bottomSuper}</sup>
  </>)
}

export const hasMandatoryGuideline = (mandatoryGuidelines) => {
  return Object.values(mandatoryGuidelines).filter(value => value == 'Yes').length > 0 ? 'Yes' : 'No'
}

export const isFineGrain = (grainSize) => {
  return grainSize === 'Fine Grain'
}
