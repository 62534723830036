export const defaultValue = (aliases, collectionKVS) => {
  const lowercasedAliases = aliases.map(alias => alias.toLowerCase())
  const value = collectionKVS.find(label => lowercasedAliases.includes(label[0]?.toLowerCase()))
  return value ? value[1] : ""
}

export const soilCondition = (formData) => {
  const hasDate = formData['sample_date_index'] !== ''
  const hasLocation = formData['sample_location_name_index'] !== ''
  const hasDepth = formData['depth_index'] !== ''
  
  return hasDate && hasLocation && hasDepth
}

export const groundwaterCondition = (formData) => {
  const hasDate = formData['sample_date_index'] !== ''
  const hasLocation = formData['sample_location_name_index'] !== ''
  
  return hasDate && hasLocation
}
