import React from "react";

const NoData = ({message}) => {
  return (
    <div className="h-full flex items-center justify-center mb-4">
      <div className="flex items-center justify-center mb-4 p-4">
        <span className="text-3xl text-gray-400 font-semibold">
          <i className="fa fa-exclamation-triangle" /> {message}
        </span>
      </div>
    </div>
  );
}

export default NoData;
