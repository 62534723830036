import React from 'react'
import CoElution from './coElution/index'
import PreviewPage from './previewPage'
import ReviewPage from './reviewPage'
import { usePcbContext } from '../../contexts/pcb'

const HomePage = () => {
  const { showCoElution, showPreview } = usePcbContext()

  return (
    <>
      {showPreview ? <PreviewPage/> : !showCoElution ? <ReviewPage/> : <CoElution/>}
    </>
  )
}

export default HomePage
