export const submitSamples = (currentSamples, onSubmit) => {
  if (currentSamples.length === 0 ) {
    return alert("You have not picked any samples");
  } else {
    return onSubmit()
  }
}

export const referenceList = (constantsData) => {
  return constantsData.map(constant => {
    const histogramValues = constant.contribution_data.map(data => (
        { name: data.name, y: Math.round(data.contribution * 100) }
      ))

    const plotValues = { x: constant.x, y: constant.y }

    return ({
      value: { histogramValues, plotValues },
      label: constant.name,
      id: constant.id,
      type: constant.type
    })
  })
}
