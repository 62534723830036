import React from "react";

const ChartToggle = ({ showChart, setShowChart }) => {
  return (
    <div className="w-6 h-52 bg-chart-tab z-20 absolute right-0 bottom-9 rounded-l-lg rotate-180 flex justify-center items-center text-sm font-semibold uppercase cursor-pointer hover:bg-blue-200" 
      style={{ writingMode: 'vertical-rl' }}
      onClick={() => setShowChart(!showChart)}>
      <i className={`fa fa-chevron-${showChart ? 'right':'left'} mb-2`} />
      Visualization
    </div>
  )
};

export default ChartToggle;
