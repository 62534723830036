import React, { useRef, useEffect, useMemo } from 'react'
import ExportButton from './exportButton'

const SampleHeaders = ({sample, selectedSampleId, setSelectedSample}) => {
  const headerRef = useRef(null)

  useEffect(() => {
    if (sample.id === selectedSampleId && headerRef.current) {
      headerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'end' })
    }
  }, [selectedSampleId, sample, headerRef])
  
  const sampleDepth = useMemo(() => sample.sample_depth ? `@ ${sample.sample_depth}` : '', [sample])
  const highlight = useMemo(() => sample.id === selectedSampleId && 'bg-blue-300 px-1 rounded-lg' || '', [sample, selectedSampleId])
  const handleClick = () => setSelectedSample(sample)

  return (
    <th key={sample.id} ref={headerRef} className='hover:bg-blue-100 cursor-pointer overflow-x-auto'>
      <div className='text-xs mx-3' onClick={handleClick}>
        <div className={highlight}>{sample.sample_location.name}</div>
        <div className='text-gray-500'>{`${sample.matrix.title} ${sampleDepth} - ${sample.sample_date}`}</div>
        <div className='text-gray-500'>{sample.sample_location_group_name}</div>
      </div>
      <div className='p-1 flex flex-col items-center justify-center'>
        <ExportButton sampleId={sample.id}/>
      </div>
    </th>
  )
}

export default SampleHeaders
