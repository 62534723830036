import React from 'react'
import SurfaceWaterToggle from './surfaceWaterToggle'
import SurficialGeologyToggle from './surficialGeologyToggle'
import AerPipelineToggle from './aerPipelineToggle'

const LayerMenu = () => {
  return (
    <div className="absolute top-7 left-20 flex flex-col items-center justify-center bg-white p-1.5 mt-3 rounded-md cursor-pointer hover:bg-gray-100 overflow-y-auto max-h-32">
      <SurfaceWaterToggle />
      <SurficialGeologyToggle />
      <AerPipelineToggle />
    </div>
  )
}

export default LayerMenu
