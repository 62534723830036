import React, { useState } from 'react';
import LocationUploadRows from './locationUploadRows';

const LocationTable = ({ site, siteLocationUploads }) => {
  const [locationUploads, setLocationUploads] = useState(siteLocationUploads);
  const hasLocationUploads = locationUploads.length > 0;
  const locationUploadRows = locationUploads.map((locationUpload) => <LocationUploadRows key={locationUpload.id} locationUpload={locationUpload} site={site} locationUploads={locationUploads} setLocationUploads={setLocationUploads} />);

  return (
    <>
      {hasLocationUploads && <div className='panel mt-3'>
        <div className='panel-header'>
          <h1>Location Uploads</h1>
        </div>
        <div className='panel-body'>
          <table className='table'>
            <thead>
              <tr>
                <th className='text-left'>File Name</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {locationUploadRows}
            </tbody>
          </table>
        </div>
      </div>}
    </>
  );
};

export default LocationTable;
