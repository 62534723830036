import React from 'react'

const DepthLabel = ({ setShowStartDepth, showStartDepth }) => {
  return (
    <div className='flex items-center'>
      <p className='sv-label'>Enter separated start and end depth</p>
      <input type='checkbox' className='ml-1' checked={showStartDepth} onChange={() => setShowStartDepth(prev => !prev)} />
    </div>
  )
}

export default DepthLabel
