import React, { createContext, useContext, useMemo, useReducer } from 'react'
import { INITIALIZE_SAMPLE_DATA, CHANGE_HEADER, SET_CONSTANTS, TOGGLE_COMPARE, SET_CURRENT_SAMPLES, SELECT_SAMPLES, SET_REFERENCE_LIST, SET_REFERENCE_DATA, SET_ADDITIONAL_DATA, SET_ADDITIONAL_HISTOGRAM_DATA, SET_EXPORT_DATA, TOGGLE_ANALYSIS, FILTER_DATA, SET_DISPLAYED_DATA, UPDATE_DISPLAYED_SAMPLES, TOGGLE_MODE, SET_ALKYLATED_CONSTANTS, SET_ALKYLATED_REFERENCE_DATA, TOGGLE_POPUPS, TOGGLE_LEGENDS, TOGGLE_SHOW_MODAL, TOGGLE_PREVIEW, SET_LAB_SOURCE, SET_ANNOTATION_MODE, SET_SELECTED_SAMPLE_ANNOTATION, SET_SELECTED_SAMPLE_ANNOTATION_LOG, SET_SOURCE_MODAL, SET_SOURCE_REFERENCES, TOGGLE_HELPER_TEXTS } from "../actions";
import { initialPahFingerprintingState, initialPahFingerprintingApiState, pahFingerprintingReducer } from "../reducers"

/* Contexts */
const PahFingerprintingContext = createContext(initialPahFingerprintingState)
const PahFingerprintingAPIContext = createContext(initialPahFingerprintingApiState)

/* Context Provider Component */
export const PahFingerprintingProvider = ({ children }) => {
  const [state, dispatch] = useReducer(pahFingerprintingReducer, initialPahFingerprintingState)

  const api = useMemo(() => {

    const onDataInitialization = (site, samples, geoJSON, chemicals, sourceReferences) => {
      dispatch({ type: INITIALIZE_SAMPLE_DATA, site, samples, geoJSON, chemicals, sourceReferences })
    }

    const onHeaderChange = (field, value) => {
      dispatch({ type: CHANGE_HEADER, field: field, value: value})
    }

    const onGetConstants = (constants) => {
      dispatch({ type: SET_CONSTANTS, constants })
    }

    const toggleCompare = () => {
      dispatch({ type: TOGGLE_COMPARE })
    }

    const updateCurrentSamples = (currentSamples) => {
      dispatch({ type: SET_CURRENT_SAMPLES, currentSamples })
    }

    const onSampleSelect = (selectedSamples) => {
      dispatch({ type: SELECT_SAMPLES, selectedSamples })
    }

    const onGetReferenceList = (referenceList) => {
      dispatch({ type: SET_REFERENCE_LIST, referenceList })
    }

    const onReferenceChange = (referenceData) => {
      dispatch({ type: SET_REFERENCE_DATA, referenceData })
    }

    const onAdditionalDataChange = (additionalData) => {
      dispatch({ type: SET_ADDITIONAL_DATA, additionalData })
    }

    const onAdditionalHistogramDataChange = (additionalHistogramData) => {
      dispatch({ type: SET_ADDITIONAL_HISTOGRAM_DATA, additionalHistogramData })
    }
    const onGetExportData = (exportData) => {
      dispatch({ type: SET_EXPORT_DATA, exportData })
    }

    const toggleAnalysis = () => {
      dispatch({ type: TOGGLE_ANALYSIS })
    }
    
    const onFilterData = (filteredSamples, filteredGeoJSON, filteredChemical) => {
      dispatch({ type: FILTER_DATA, filteredSamples, filteredGeoJSON, filteredChemical })
    }
    
    const onSetDisplayedData = (displayedSamples, displayedGeojson) => {
      dispatch({ type: SET_DISPLAYED_DATA, displayedSamples, displayedGeojson })
    }
    
    const onSampleSearch = (searchSamples) => {
      dispatch({ type: UPDATE_DISPLAYED_SAMPLES, searchSamples })
    }
    
    const toggleMode = (mode) => {
      dispatch({ type: TOGGLE_MODE, mode })
    }
    
    const onGetAlkylatedConstants = (alkylatedConstants) => {
      dispatch({ type: SET_ALKYLATED_CONSTANTS, alkylatedConstants })
    }
    
    const onSetAlkylatedReferenceData = (alkylatedData) => {
      dispatch({ type: SET_ALKYLATED_REFERENCE_DATA, alkylatedData })
    }
    
    const togglePopups = () => {
      dispatch({ type: TOGGLE_POPUPS })
    }
    
    const toggleLegends = () => {
      dispatch({ type: TOGGLE_LEGENDS })
    }
    
    const toggleShowModal = () => {
      dispatch({ type: TOGGLE_SHOW_MODAL })
    }

    const toggleShowPreview = () => {
      dispatch({ type: TOGGLE_PREVIEW })
    }

    const setLabSource = (labSource) => {
      dispatch({ type: SET_LAB_SOURCE, labSource })
    }
    
    const setAnnotationMode = (annotationMode) => {
      dispatch({ type: SET_ANNOTATION_MODE, annotationMode })
    }

    const setSelectedSampleAnnotation = (selectedSampleAnnotation) => {
      dispatch({ type: SET_SELECTED_SAMPLE_ANNOTATION, selectedSampleAnnotation })
    }

    const setSelectedSampleAnnotationLog = (selectedSampleAnnotationLog) => {
      dispatch({ type: SET_SELECTED_SAMPLE_ANNOTATION_LOG, selectedSampleAnnotationLog })
    }
    
    const setSourceModal = (sourceModal) => {
      dispatch({ type: SET_SOURCE_MODAL, sourceModal })
    }
    
    const setSourceReferences = (sourceReferences) => {
      dispatch({ type: SET_SOURCE_REFERENCES, sourceReferences })
    }
    
    const toggleHelperTexts = () => {
      dispatch({ type: TOGGLE_HELPER_TEXTS })
    }

    return { onDataInitialization, onHeaderChange, onGetConstants, toggleCompare, updateCurrentSamples, onSampleSelect, onGetReferenceList, onReferenceChange, onAdditionalDataChange, onAdditionalHistogramDataChange, onGetExportData, toggleAnalysis, onFilterData, onSetDisplayedData, onSampleSearch, toggleMode, onGetAlkylatedConstants, onSetAlkylatedReferenceData, togglePopups, toggleLegends, toggleShowModal, toggleShowPreview, setLabSource, setAnnotationMode, setSelectedSampleAnnotation, setSelectedSampleAnnotationLog, setSourceModal, setSourceReferences, toggleHelperTexts }
  }, [])

  return (
    <PahFingerprintingAPIContext.Provider value={api}>
      <PahFingerprintingContext.Provider value={state}>
        {children}
      </PahFingerprintingContext.Provider>
    </PahFingerprintingAPIContext.Provider>
  )
}

/* Custom Context Hooks */
export const usePahFingerprinting = () => useContext(PahFingerprintingContext)
export const usePahFingerprintingAPI = () => useContext(PahFingerprintingAPIContext)
