import React from "react";
import RatioRow from "./ratioRow";

const RatioDescriptionTable = () => {
  return (
    <div className='w-2/3 text-xs my-2 border-2 p-1 rounded'>
      <h3 className='font-semibold'>Ratio Information</h3>
      <table className="dataVaultTable">
        <thead>
        <tr>
          <th>Diagnostic Ratio</th>
          <th>Petrogenic</th>
          <th>Pyrogenic</th>
        </tr>
        </thead>
        <tbody>
        <RatioRow ratio={'Fluoranthene/Pyrene'} max={'> 1.0'} min={'< 0.5'}/>
        <RatioRow ratio={'Phenanthrene/Anthracene'} max={'< 10'} min={'> 10'} />
        <RatioRow ratio={'Benzo(a)anthracene/Chrysene'} max={'> 1.0'} min={'< 1.0'}/>
        <RatioRow ratio={'Indeno(1,2,3-cd)pyrene/Benzo(g,h,i)perylene'} max={'> 0.75'} min={'< 0.75'}/>
        </tbody>
      </table>
    </div>
  )
}

export default RatioDescriptionTable
