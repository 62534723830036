import React from 'react'
import { getChemicalValue } from '../shared/table/tableFunctions'

const ChemicalRow = ({chemical, samplesData}) => {
  const data = samplesData.map((sample) => {
    const chemicalValue = getChemicalValue(sample, chemical)
    return (
      <td key={sample.id} className='text-xs text-center'>{chemicalValue}</td>
    )
  })

  return (
    <tr key={chemical.id} className='text-xs font-semibold uppercase border border-dashed border-gray-200'>
      <td className='sticky left-0 bg-white border'><span className='mx-1'>{chemical.name}</span></td>
      <td className='normal-case text-xs text-center'>{chemical.unit || '-'}</td>
      {data}
    </tr>
  )
}

export default ChemicalRow
