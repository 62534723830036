import mapboxgl from '!mapbox-gl';
import { getClusterChildrenAsync, setColorToClusters } from '../../dataVault/mapHelpers/helperFunctions';
import marker from '/app/assets/images/marker.png';

export const layerHelper = (map, mapType) => {
  if(mapType !== 'index') {
    map.addLayer({
      id: 'clusters',
      type: 'circle',
      source: 'sampleLocations',
      filter: ['has', 'point_count'],
      paint: {
        'circle-radius': 9,
        'circle-color': '#FDFD99',
        'circle-stroke-color': 'white',
      },
    });
    
    map.addLayer({
      id: 'cluster-count',
      type: 'symbol',
      source: 'sampleLocations',
      filter: ['has', 'point_count'],
      layout: {
        'text-field': '{point_count}',
        'text-size': 9,
      },
      paint: {
        'text-color': 'black',
      },
    });
    
    map.addLayer({
      id: 'unclustered-point',
      type: 'circle',
      source: 'sampleLocations',
      filter: ['!', ['has', 'point_count']],
      paint: {
        'circle-radius': ['coalesce', ['get', 'size'], 5],
        'circle-stroke-width': 1,
        'circle-stroke-color': ['coalesce', ['get', 'color'], '#00ff59'],
        'circle-color': ['coalesce', ['get', 'color'], '#00ff59'],
        'circle-opacity': 0.9,
      }
    });
    
    map.on('render', async () => {
     
      const clusters = map?.queryRenderedFeatures({ layers: ['clusters'] });
      if (clusters.length) {
        const promises = clusters.map(cluster => {
          const clusterId = cluster.id;
          return getClusterChildrenAsync(map, clusterId);
        });
        Promise.all(promises)
          .then(results => {
            setColorToClusters(map, clusters, results);
          })
          .catch(err => {
            if (err.message === 'No Cluster with the specified id') return
          });
      }
    });
    
    map.on('mousemove', 'unclustered-point', function (e) {
      map.getCanvas().style.cursor = 'pointer';
    })

    map.on('mouseleave', 'unclustered-point', function (e) {
      map.getCanvas().style.cursor = 'grab';
    })

    map.on('mousemove', 'clusters', function (e) {
      map.getCanvas().style.cursor = 'pointer';
    })

    map.on('mouseleave', 'clusters', function (e) {
      map.getCanvas().style.cursor = 'grab';
    })
    
  } else {   
      map.loadImage(marker, (error, image) => {
        if (error) {
          console.error('Failed to load custom marker image:', error);
          return;
        }
        
        map.addImage('custom-marker', image);
        map.addLayer({
          id: 'sites-layer',
          type: 'symbol',
          source: 'sampleLocations',
          filter: ['!', ['has', 'point_count']],
          layout: {
            'icon-image': 'custom-marker',
            'icon-size': 0.3,
          },
        });
        
        map.addLayer({
          id: 'sites-clusters',
          type: 'symbol',
          source: 'sampleLocations',
          filter: ['has', 'point_count'],
          layout: {
            'icon-image': 'custom-marker',
            'icon-size': 0.3,
            'text-field': '{point_count}',
            'text-size': 10,
            'text-offset': [0, -0.4],
          },
        });
      });

    const popup = new mapboxgl.Popup({closeButton: false});

    // When a click event occurs on a feature in the places-layer layer, open a popup with the name of the site
    map.on('mousemove', 'sites-layer', function (e) {
      map.getCanvas().style.cursor = 'pointer';
      const coordinates = e.features[0].geometry.coordinates.slice();
      const siteName = e.features[0].properties.site_title;
      popup.setLngLat(coordinates)
      .setHTML(`<h2 class='font-bold'>${siteName}</h2>`)
      .addTo(map);
    });

    map.on('mouseleave', 'sites-layer', (e) => {
      popup.remove();
      map.getCanvas().style.cursor = 'grab';
    });

    map.on('click', 'sites-layer', (e) => {
      const siteId = e.features[0].properties.site_id;
      window.location.href = `/sites/${siteId}`;
    });
  }
};
