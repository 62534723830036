import React, { useMemo } from "react"

const DependentGuidelineRow = ({ guideline }) => {
  const displayedRange = useMemo(() => guideline.dependent_end ? guideline.dependent_start + ' - ' + guideline.dependent_end : ">" + guideline.dependent_start, [guideline]);

  return (
    <tr>
      <td>{displayedRange}</td>
      <td>{guideline.guideline_value}</td>
    </tr>
  )
}

export default DependentGuidelineRow
