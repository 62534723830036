import React from "react";
import useQuestionData from "./useQuestionData";
import Tooltip from "./tooltip";
import RadioInput from "./radioInput";

const QuestionYesNo = ({ type }) => {
  const typeObject = useQuestionData(type);
  
  return (
    <div className='flex flex-row items-baseline my-4'>
      <div className='flex flex-row justify-between w-2/3'>
        <p>{typeObject.question}</p>
        {typeObject.tooltip && <Tooltip data={typeObject} />}
      </div>
      <RadioInput type={typeObject} value={'Yes'} />
      <RadioInput type={typeObject} value={'No'} />
    </div>
  )

}

export default QuestionYesNo
