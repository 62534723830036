import React from 'react'
import ExportButton from '../review/modal/exportButton'
import { usePahFingerprinting } from '../../../../contexts/pahFingerprinting'

const DoublePlotTable = () => {
  const { samples, selectedSampleAnnotationLog } = usePahFingerprinting()

  const sampleList = selectedSampleAnnotationLog.map(sample => (
    <tr key= {sample.id} className='text-xs text-center border-dashed border-t border-gray-200 px-3'>
      <td className='text-left'>{sample.name}</td>
      <td>{sample.depth}</td>
      <td>{sample.date}</td>
      <td>{sample.data?.ratio_1.value || 'N/A'}</td>
      <td>{sample.data?.ratio_4.value || 'N/A'}</td>
      <td>{sample.data?.overall_result}</td>
      <td><ExportButton selectedSample={samples.find(s => s.id === sample.id)}/></td>
    </tr>
  ))

  return (
    <table className='border-collapse table-auto w-full whitespace-nowrap bg-white relative'>
      <thead className=' bg-gray-100 sticky top-0 border-b border-black text-gray-600 font-bold tracking-wider text-sm'>
        <tr>
          <th>Sample Location Name</th>
          <th>Sample Depth</th>
          <th>Sample Date</th>
          <th>IcdP/Bghip</th>
          <th>FLRN/PYR</th>
          <th>Overall Result</th>
          <th>Export</th>
        </tr>
      </thead>
      <tbody>
        {sampleList}
      </tbody>
    </table>
  )
}

export default DoublePlotTable
