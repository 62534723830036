import React from "react"

const UploadErrorRow = ({ errorRow }) => {

  return(<>
    <tr>
      <td className='text-left'>{errorRow.row}</td>
      <td className='px-3'>{errorRow.error}</td>
    </tr>
  </>)
}

export default UploadErrorRow
