const drawInlineSVG = (ctx, rawSVG, callback) => {
  const svg = new Blob([rawSVG], {type:"image/svg+xml;charset=utf-8"}),
    domURL = self.URL || self.webkitURL || self,
    url = domURL.createObjectURL(svg),
    img = new Image;

  img.onload = function () {
    ctx.drawImage(this, 0, 0);
    domURL.revokeObjectURL(url);
    callback(this);
  };

  img.src = url;
}

export const canvasToPng = (svg, callback) => {
  const canvas = document.createElement('canvas')
  canvas.setAttribute('width', '1000px');
  canvas.setAttribute('height', '1000px');
  const ctx = canvas.getContext('2d');

  drawInlineSVG(ctx, svg, function() {
    // richard 2023-03 should we call canvas.remove() after getting the data url but before invoking callback?
    callback(canvas.toDataURL())
  });
}

export const formatSeriesData = (seriesData, field) => {
  return seriesData.map(item => {
    if (item.less_than) {
      return { name: item.name, y: item[field], color: '#fcbc5b' }
    } else {
      return { name: item.name, y: item[field] }
    }
  })
}

export const Lazy = (data) => {
  return {
    take(n) {
      function* internalTake(data) {
        for (const item of data) {
          if (n > 0) {
            yield item;
          }
          if (n < 2) {
            return;
          }
          n--
        }
      }
      return Lazy(internalTake(data));
    },
    chunk(n) {
      function* internalChunk(idata) {
        let buffer = [];
        for (const item of idata) {
          buffer.push(item);
          if (buffer.length >= n) {
            yield buffer;
            buffer = [];
          }
        }
        if (buffer.length > 0) {
          yield buffer;
        }
      }
      return Lazy(internalChunk(data))
    },
    flat() {
      function* internalFlat(idata) {
        for (const items of idata) {
          for (const item of items) {
            yield item;
          }
        }
      }
      return Lazy(internalFlat(data));
    },
    first() {return this.take(1).toArray()[0]},
    filter(filterFn) {
      function* internalFilter(data) {
        for (const item of data) {
          if (filterFn(item)) {
            yield item;
          }
        }
      }
      return Lazy(internalFilter(data));
    },
    [Symbol.iterator]() {
      return data[Symbol.iterator]()
    },
    map(mapFn) {
      function* internalMap(data) {
        for (const item of data) {
          yield mapFn(item);
        }
      }
      return Lazy(internalMap(data));
    },
    unwrap() {return data},
    toArray() {
      return [...data];
    },
  };
}
