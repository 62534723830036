import React from "react";
import { useDataVaultContext, useDataVaultAPI } from "../../../contexts/dataVault";

const LabelControl = ({setShowPopups}) => {
  const { showPopups } = useDataVaultContext()
  const { toggleShowPopups } = useDataVaultAPI()
  return (
    <div className='flex items-center'>
      <label htmlFor="show-popups-checkbox" className='text-xs text-black font-semibold tracking-tight'>Show All Labels: </label>
      <input
        className='ml-2 h-4 w-4 cursor-pointer'
        id="show-popups-checkbox"
        type="checkbox"
        checked={showPopups}
        onChange={toggleShowPopups}
      />
    </div>
  )
}

export default LabelControl
