import React, { useState } from "react";
import api from "./api";

const Edit = ({ userId, email, currentPlan }) => {
  const [plan, setPlan] = useState(currentPlan);
  const [error, setError] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    api
      .put(`/users/${userId}`, {
        user: { plan },
      })
      .then(() => {
        window.location.href = "/users";
      })
      .catch((error) => {
        setError("Failed to update user. Please try again.");
        console.error("Failed to update user", error);
      });
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6 text-center text-gray-800">
        Editing Plan for {email}
      </h1>
      <div className="max-w-md mx-auto bg-white rounded-lg shadow-xl overflow-hidden">
        <form onSubmit={handleSubmit} className="p-6 space-y-6">
          <div>
            <label
              className="block text-sm font-medium text-gray-700 mb-2"
              htmlFor="email"
            >
              Email
            </label>
            <input
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              id="email"
              type="email"
              value={email}
              disabled
            />
          </div>
          <div>
            <label
              className="block text-sm font-medium text-gray-700 mb-2"
              htmlFor="plan"
            >
              Plan
            </label>
            <select
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              id="plan"
              value={plan}
              onChange={(e) => setPlan(e.target.value)}
            >
              <option value="compare">Compare</option>
              <option value="essentials">Essentials</option>
              <option value="advanced">Advanced</option>
            </select>
          </div>
          {error && <div className="text-red-500 text-sm">{error}</div>}
          <div className="flex items-center justify-between">
            <button
              type="submit"
              className="w-full bg-indigo-600 text-white font-bold py-2 px-4 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Update User
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Edit;
