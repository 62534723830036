import { getClusterChildrenAsync, setColorToClusters } from "./helperFunctions";

const layerFunctions = (map, geoJSON, filteredGeoJSON) => {
  map.addSource('sampleLocations', {
    type: 'geojson',
    data: filteredGeoJSON || geoJSON,
    cluster: true,
    clusterRadius: 10,
    clusterMaxZoom: 18,
  })

  map.addLayer({
    id: 'clusters',
    type: 'circle',
    source: 'sampleLocations',
    filter: ['has', 'point_count'],
    paint: {
      'circle-radius': 9,
      'circle-color': '#FDFD99',
      'circle-stroke-color': 'white',
    },
  })
  
  map.addLayer({
    id: 'cluster-count',
    type: 'symbol',
    source: 'sampleLocations',
    filter: ['has', 'point_count'],
    layout: {
      'text-field': '{point_count}',
      'text-size': 9,
    },
    paint: {
      'text-color': 'black',
    },
  })
  
  map.addLayer({
    id: 'unclustered-point',
    type: 'circle',
    source: 'sampleLocations',
    filter: ['!', ['has', 'point_count']],
    paint: {
      'circle-radius': ['coalesce', ['get', 'size'], 5],
      'circle-stroke-width': 1,
      'circle-stroke-color': ['coalesce', ['get', 'color'], '#00ff59'],
      'circle-color': ['coalesce', ['get', 'color'], '#00ff59'],
      'circle-opacity': 0.9,
    }
  })

  map.on('render', async () => {
    const clusters = map.queryRenderedFeatures({ layers: ['clusters'] });
    if (clusters.length) {
      const promises = clusters.map(cluster => {
        const clusterId = cluster.id;
        return getClusterChildrenAsync(map, clusterId);
      });
      Promise.all(promises)
        .then(results => {
          setColorToClusters(map, clusters, results);
        })
        .catch(err => {
          if (err.message === 'No cluster with the specified id') return;
        })
    }
  })
  
}

export default layerFunctions
