import React from 'react'
import LayerMenu from './layerMenu'
import { useDataVaultContext, useDataVaultAPI } from '../../../../contexts/dataVault'

const LayerMenuToggle = ({ closeDataVaultFunctions }) => {
  const { layerMenuOpen } = useDataVaultContext()
  const { setLayerMenuOpen } = useDataVaultAPI()
  const menuClass = layerMenuOpen ? 'bg-blue-200' : 'bg-white'
  const handleClick = () => {
    closeDataVaultFunctions()
    setLayerMenuOpen(!layerMenuOpen)
  }
  return (
    <>
      <div className={`flex items-center justify-center rounded-md cursor-pointer h-7 w-7 ml-1 border border-black bg-white ${menuClass}`} title="Show Layers" onClick={handleClick} >
        <i className="fa fa-layer-group text-md"></i>
      </div>
      {layerMenuOpen && <LayerMenu />}
    </>
  )
}

export default LayerMenuToggle
