import React, { useContext, useEffect, useState } from "react";
import GuidelineTable from "./guidelineTable";
import { TierTwoContext } from "../tierTwoContext";
import axios from "axios";
import SiteInformation from "./siteInformation";
import InputParameters from "./inputParameters";

const Guidelines = () => {
  const { landUse, grainSize, sourceLength, sourceWidth, surfaceFoundationDistance, subsoilFoundationDistance, contaminationThickness, groundwaterDepth, surfaceWaterDistance, hydraulicConductivity, hydraulicGradient, organicCarbonFraction, bulkDensity, moisturePorosity, vapourPorosity, contaminationDepth } = useContext(TierTwoContext)
  let [guidelines, setGuidelines] = useState();

  let guidelineParams = {
    land_use: landUse,
    grain_size: grainSize,
    source_length: sourceLength.value,
    source_length_specific: sourceLength.site_specific,
    source_width: sourceWidth.value,
    source_width_specific: sourceWidth.site_specific,
    surface_soil_distance: surfaceFoundationDistance.value,
    surface_soil_distance_specific: surfaceFoundationDistance.site_specific,
    sub_soil_distance: subsoilFoundationDistance.value,
    sub_soil_distance_specific: subsoilFoundationDistance.site_specific,
    contamination_thickness: contaminationThickness.value,
    contamination_thickness_specific: contaminationThickness.site_specific,
    groundwater_depth: groundwaterDepth.value,
    groundwater_depth_specific: groundwaterDepth.site_specific,
    surface_water_distance: surfaceWaterDistance.value,
    surface_water_distance_specific: surfaceWaterDistance.site_specific,
    hydraulic_gradient: hydraulicGradient.value,
    hydraulic_gradient_specific: hydraulicGradient.site_specific,
    hydraulic_conductivity: hydraulicConductivity.value,
    hydraulic_conductivity_specific: hydraulicConductivity.site_specific,
    organic_carbon_fraction: organicCarbonFraction.value,
    organic_carbon_fraction_specific: organicCarbonFraction.site_specific,
    bulk_density: bulkDensity.value,
    bulk_density_specific: bulkDensity.site_specific,
    moisture_porosity: moisturePorosity.value,
    moisture_porosity_specific: moisturePorosity.site_specific,
    vapour_porosity: vapourPorosity.value,
    vapour_porosity_specific: vapourPorosity.site_specific,
    contamination_depth: contaminationDepth.value,
    contamination_depth_specific: contaminationDepth.site_specific
  }

  useEffect(() => {
    axios({
      method: 'GET',
      url: '/guidelines/tier_two_guidelines/phc.json',
      params: guidelineParams
    }).then((response) => {
      setGuidelines(response.data)
    }).catch((err) => console.log(err))
  }, [])

  return (<>
    <h1 className='my-4'>Alberta Tier 2 Remediation Guidelines</h1>
    <h2 className='font-semibold my-3 px-2'>Site Information:</h2>
    <SiteInformation />
    <InputParameters />
    <h2 className='font-semibold my-3 px-2'>Applicable Guidelines:</h2>
    <div className='flex flex-col px-2'>
      <p>*NGR = No Guideline Required</p>
    </div>
    {guidelines && <>
      <GuidelineTable
        key={'surface-soil'}
        title={`Surface Soil Guidelines (0 to 3.0 mbgs) for ${landUse} Land Use and ${grainSize} Soil`}
        guidelines={guidelines.soil}
        unit={'mg/kg'}
      />
      <GuidelineTable
        key={'subsoil'}
        title={`Subsoil Guidelines (>3.0 mbgs) for ${landUse} Land Use and ${grainSize} Soil`}
        guidelines={guidelines.subsoil}
        unit={'mg/kg'}
      />
      <GuidelineTable
        key={'groundwater'}
        title={`Groundwater Guidelines for ${landUse} Land Use and ${grainSize} Soil`}
        guidelines={guidelines.groundwater}
        unit={'mg/L'}
      />
    </>}
  </>)
}

export default Guidelines
