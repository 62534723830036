import React from 'react';
import axios from 'axios';
import SaltPrintSample from './saltPrintSample';
import { useSaltPrintContext, useSaltPrintAPI } from '../../contexts/saltPrint';

const SaltPrintSamples = () => {
  const { site, saltPrint, saltPrintSamples, filteredSamples, highlightSampleIds, displayedSamples } = useSaltPrintContext();
  const { setSaltPrintSamples, setHighlightSampleIds } = useSaltPrintAPI();
  const deleteSaltPrintSample = (saltPrintSample) => {
    axios.delete(`/sites/${site.id}/salt_prints/${saltPrint.id}/salt_print_samples/${saltPrintSample.id}`)
      .then((_response) => {
        const newSaltPrintSamples = saltPrintSamples.filter((sample) => sample.id !== saltPrintSample.id);
        setSaltPrintSamples(newSaltPrintSamples);
      });
  };
  
  const saltPrints = filteredSamples || saltPrintSamples;
  const displayedChartData = displayedSamples || saltPrints;
  const saltPrintSampleList = displayedChartData?.map((saltPrintSample) => {
    const highlight = highlightSampleIds.includes(saltPrintSample.id) ? true : false;
    return (
      <SaltPrintSample
        key={saltPrintSample.id}
        saltPrintSample={saltPrintSample}
        deleteSaltPrintSample={deleteSaltPrintSample}
        site={site}
        saltPrint={saltPrint}
        setHighlightSampleIds={setHighlightSampleIds}
        highlight={highlight}
      />
    );
  });

  return (
    <div className='max-h-full p-3'>
      <div className='grid grid-cols-3 gap-4'>
        {saltPrintSamples && saltPrintSampleList}
      </div>
    </div>
  );
};

export default SaltPrintSamples;
