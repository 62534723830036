import React, {useState} from 'react'
import { createSoilSampleRows, createGroundwaterSampleRows, abortUpload, exportCSV, exportTable } from './helpers'
import ModalWrapper from '../../shared/modal/modalWrapper'

const EmptyRowModal = ({ csvEmptyRows, siteUpload }) => {
  const [showModal, setShowModal] = useState(true)
  const closeModal = () => setShowModal(prev => !prev)
  const isSoilUpload = siteUpload.matrix_id === 1
  const sampleRows = isSoilUpload ? createSoilSampleRows(csvEmptyRows) : createGroundwaterSampleRows(csvEmptyRows)

  return (
    <ModalWrapper isOpen={showModal}>
      <div className='m-3'>
        <h1 className='text-xl block font-semibold text-gray-600 uppercase text-center'>Missing Data Detected</h1>
        <p className='sv-label text-center'>The following rows are missing required data</p>
        <table className='dataVaultTable'>
          <thead>
            <tr>
              <th>CSV Row</th>
              <th>Sample Location Name</th>
              <th>Sample Date</th>
              {isSoilUpload && <th>Sample Depth</th>}
            </tr>
          </thead>
          <tbody>
            {sampleRows}
          </tbody>
        </table>
        <p className='sv-label'>The following samples will not be imported to Statvis if continued</p>
        <div className='flex justify-between items-center w-full'>
          <button className='cursor-pointer shadow-xl px-2 py-1 inline-block text-sm mt-2 mr-1 bg-red-600 hover:bg-red-500 text-gray-100 hover:text-white rounded' onClick={() => abortUpload(siteUpload)}>Abort Upload</button>
          <div>
            <button className='cursor-pointer shadow-xl px-2 py-1 inline-block text-sm mt-2 mr-1 bg-blue-600 hover:bg-blue-500 text-blue-100 hover:text-white rounded' onClick={() => exportTable(siteUpload, isSoilUpload, csvEmptyRows)}>Download Table</button>
            <button className='cursor-pointer shadow-xl px-2 py-1 inline-block text-sm mt-2 mr-1 bg-blue-600 hover:bg-blue-500 text-blue-100 hover:text-white rounded' onClick={() => exportCSV(siteUpload)}>Download CSV</button>
            <button className='cursor-pointer shadow-xl px-2 py-1 inline-block text-sm mt-2 mr-1 bg-blue-600 hover:bg-blue-500 text-blue-100 hover:text-white rounded' onClick={closeModal}>Continue</button>
          </div>
        </div>
      </div>
    </ModalWrapper> 
  )
}

export default EmptyRowModal
