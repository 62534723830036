import React, { useState, useEffect } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import axios from 'axios'
import { usePahFingerprinting, usePahFingerprintingAPI } from '../../../../contexts/pahFingerprinting'
import buildDoubleRatioPlot from './chartHelper'
import AnnotationToggle from './annotationToggle'

const DoubleRatioChart = () => {
  const { selectedSampleAnnotation, site } = usePahFingerprinting()
  const { setSelectedSampleAnnotationLog } = usePahFingerprintingAPI()
  const [data, setData] = useState([])
  const [scatterData, setScatterData] = useState([])
  const [ratioChartOptions, setRatioChartOptions] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setRatioChartOptions(buildDoubleRatioPlot(scatterData, scatterData.length > 0 ? `${selectedSampleAnnotation.length} Samples Selected` : 'No Samples Selected'))
  }, [scatterData])

  useEffect(() => {
    if (selectedSampleAnnotation) {
      setData([])
      setScatterData([])
      setLoading(true)
      Promise.all(selectedSampleAnnotation.map(sample => {
        return axios.get(`/sites/${site.id}/reports/double_ratio_plot/ratios`, {
          params: { site_id: site.id, sample_id: sample.id }
        })
        .then(res => ({
          id: sample.id,
          data: res.data,
          sampleName: sample.name,
          ratio_1_value: res.data.ratio_1.value,
          ratio_4_value: res.data.ratio_4.value
        }))
      }))
      .then(results => {
        const sampleRatios = results.filter(result => result && result.data && result.data.ratio_1.value && result.data.ratio_4.value)
        const sampleScatterData = sampleRatios.map(result => ({
          data: [[result.data.ratio_1.value, result.data.ratio_4.value]],
          name: result.sampleName
        }))
        setData(results)
        setScatterData(sampleScatterData)
      })
      .finally(() => setLoading(false))
    }
  }, [selectedSampleAnnotation])
  
  
  useEffect(() => {
    if (data.length > 0) {
      const updatedSelectedSamples = selectedSampleAnnotation.map(sample => {
        const sampleData = data?.find(sampleData => sampleData.id === sample.id)
        return {...sample, data: sampleData?.data}
      })
      setSelectedSampleAnnotationLog(updatedSelectedSamples)
    } else {
      setSelectedSampleAnnotationLog([])
    }
  }, [data])

  return (
    <div className='h-full w-full flex-col items-center justify-center border-2'>
      {loading ?
        <div className='h-full flex items-center justify-center'>
          <i className="fas fa-spinner fa-spin text-gray-500 text-4xl"></i>
        </div>
        :
        <>
          <AnnotationToggle mode={'chart'}/>
          <HighchartsReact
            highcharts={Highcharts}
            options={ratioChartOptions}
          />
        </>
      }
    </div>
  )
}

export default DoubleRatioChart
