import React from 'react';
import { usePahFingerprinting, usePahFingerprintingAPI } from '../../../../../contexts/pahFingerprinting';

const ExportSampleList = () => {
  const { selectedSamples } = usePahFingerprinting()
  const { onSampleSelect } = usePahFingerprintingAPI()
  const hasSelectedSamples = selectedSamples.length > 0
  const sampleList = selectedSamples.map(sample => {
    return (
      <tr key={sample.id} className='text-xs uppercase font-semibold text-center'>
        <td className='text-left'>{sample.sample_name_for_json}</td>
        <td><i className='fa fa-times text-red-500 hover:text-red-600 text-xs cursor-pointer' onClick={() => onSampleSelect(selectedSamples.filter(selectedSample => sample.id !== selectedSample.id))}/></td>
      </tr>
    )
  })

  return (
    <>
      {hasSelectedSamples ? 
        <table className='dataVaultTable'>
          <thead>
            <tr>
              <th>Sample Name</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {sampleList}
          </tbody>
        </table>
        :
        <div className='w-96 m-2'>
          <p className='text-xs font-semibold text-center'>No Samples Selected</p>
        </div>
      }
    </>
  )
}

export default ExportSampleList
