import React from 'react'
import axios from 'axios'

export const createSoilSampleRows = (csvEmptyRows) => {
  return csvEmptyRows.map((row) => {
    return (
      <tr key={row.line} className='text-xs text-center'>
        <td>{row.line}</td>
        <td>{row.sample_location_name || '-'}</td>
        <td>{row.sample_date || '-'}</td>
        <td>{row.sample_depth || '-'}</td>
      </tr>
    )
  })
}

export const createGroundwaterSampleRows = (csvEmptyRows) => {
  return csvEmptyRows.map((row) => {
    return (
      <tr key={row.line} className='text-xs text-center'>
        <td>{row.line}</td>
        <td>{row.sample_location_name || '-'}</td>
        <td>{row.sample_date || '-'}</td>
      </tr>
    )
  })
}

export const abortUpload = (siteUpload) => {
  const siteId = siteUpload.site_id
  const uploadId = siteUpload.id

  axios.delete(`/sites/${siteId}/site_uploads/${uploadId}`)
    .then(() => window.location.href = `/sites/${siteId}/site_uploads/new`)
    .catch(error => console.error(error))
}

export const exportCSV = (siteUpload) => {
  const siteId = siteUpload.site_id
  const uploadId = siteUpload.id

  axios.get(`/sites/${siteId}/site_uploads/${uploadId}/data`)
    .then((response) => {
      const filename = response.headers['content-disposition'].split("filename*=UTF-8''")[1]
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url;
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    })
    .catch((err) => console.log(err))
}

const soilCSVSampleRow = row => `"${row.line}",${row.sample_location_name || '-'}, ${row.sample_date || '-'}, ${row.sample_depth || '-'}\n`
const groundwaterCSVSampleRow = row => `"${row.line}",${row.sample_location_name || '-'}, ${row.sample_date || '-'}\n`

export const exportTable = (siteUpload, isSoilUpload, csvEmptyRows) => {
  let csvContent = isSoilUpload ? "CSV Row, Sample Location Name, Sample Date, Sample Depth\n" : "CSV Row, Sample Location Name, Sample Date\n"
  csvEmptyRows.forEach((row) => {
    const sampleRow = isSoilUpload ? soilCSVSampleRow(row) : groundwaterCSVSampleRow(row)
    csvContent += sampleRow
  })

  const element = document.createElement("a")
  const file = new Blob([csvContent], { type: "text/csv;charset=utf-8" })
  element.href = URL.createObjectURL(file)
  element.download = `${siteUpload.name} empty_rows.csv`
  document.body.appendChild(element)
  element.click()
  document.body.removeChild(element)
}
