import React, { useEffect, useState } from 'react'
import BarChart from "../../doubleRatioPlot/concentrationChart"
import ExtendedConcentrationChart from "./extendedConcentrationChart"
import { usePahFingerprinting, usePahFingerprintingAPI } from "../../../../contexts/pahFingerprinting"

const SelectSample = ({ sample, additionalClasses, children }) => {
  let [selected, setSelected] = useState(false)
  const { site, currentSamples, mode, headerTab } = usePahFingerprinting()
  const { updateCurrentSamples } = usePahFingerprintingAPI()

  useEffect(() => {
    setSelected(currentSamples.includes(sample.id))
  }, [])

  const getDisplayedChart = () => {
    if (mode === 'Parent') {
      return <BarChart key={sample.id} sample={sample} site={site} />
    } else if (mode === 'Alkylated' && headerTab === 2) {
      return <ExtendedConcentrationChart key={sample.id} sample={sample} site={site} noTitle={true} />
    } else {
      return <BarChart key={sample.id} sample={sample} site={site} />
    }
  }
  
  const updateSampleList = () => {
    if (selected) {
      updateCurrentSamples(currentSamples.filter(id => id != sample.id))
      setSelected(false)
    } else {
      updateCurrentSamples([...currentSamples, sample.id])
      setSelected(true)
    }
  }

  return <>
    <div
      key={sample.id}
      className={`flex flex-col my-2 gap-y-2 hover:bg-blue-400 rounded-lg py-4 px-4 ${selected ? 'bg-blue-300' : 'bg-blue-50'}`}
      onClick={updateSampleList}
    >
      <p className={`font-semibold rounded-lg ${additionalClasses}`} htmlFor={sample.sample_location.name}>{sample.sample_name_for_json}</p>
      {children}
      {getDisplayedChart()}
    </div>
  </>
}

export default SelectSample
