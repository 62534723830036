import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { usePahFingerprinting, usePahFingerprintingAPI } from '../../../../../contexts/pahFingerprinting'
import Select from 'react-select'

const SourcesModal = () => {
  const { site, sourceModal, samples, sourceReferences, chemicals } = usePahFingerprinting()

  const { setSourceModal, setSourceReferences } = usePahFingerprintingAPI()
  const [backgroundSample, setBackgroundSample] = useState(null)
  const [keySample, setKeySample] = useState(null)

  const FLRN = chemicals.find(chemical => chemical.name === 'Fluoranthene')?.id
  const PYR = chemicals.find(chemical => chemical.name === 'Pyrene')?.id
  const BghiP = chemicals.find(chemical => chemical.name === 'Benzo(ghi)perylene')?.id
  const IcdP = chemicals.find(chemical => chemical.name === 'Indeno(1,2,3-cd)pyrene')?.id

  // remove samples that can't be plotted in double ratio plot
  const validSamples = samples.map(sample => {
    const FLRNValue = sample.sample_values.find(value => value.chemical_id === FLRN)
    const PYRValue = sample.sample_values.find(value => value.chemical_id === PYR)
    const BghiPValue = sample.sample_values.find(value => value.chemical_id === BghiP)
    const IcdPValue = sample.sample_values.find(value => value.chemical_id === IcdP)

    const hasLessThan = [FLRNValue, PYRValue, BghiPValue, IcdPValue].map(value => value?.less_than).some(value => value === true)

    if (hasLessThan) {
      return false
    } else {
      const FLRNValue = sample.sample_values.find(value => value.chemical_id === FLRN)?.float_value
      const PYRValue = sample.sample_values.find(value => value.chemical_id === PYR)?.float_value
      const BghiPValue = sample.sample_values.find(value => value.chemical_id === BghiP)?.float_value
      const IcdPValue = sample.sample_values.find(value => value.chemical_id === IcdP)?.float_value

      const FLRNValuePYRValue = FLRNValue && PYRValue ? FLRNValue / PYRValue : null
      const IcdPValueBghiPValue = IcdPValue && BghiPValue ? IcdPValue / BghiPValue : null

      if (FLRNValuePYRValue > 2 || IcdPValueBghiPValue > 2) return false
      else if (!FLRNValuePYRValue || !IcdPValueBghiPValue) return false
      else return sample
    }
  }).filter(sample => sample)

  const options = validSamples.map(sample => ({ value: sample.id, label: sample.sample_name_for_json }))

  useEffect(() => {
    if (sourceReferences) {
      setBackgroundSample(sourceReferences?.background_sample?.id)
      setKeySample(sourceReferences?.key_sample?.id)
    }
  }, [sourceReferences])

  const handleSave = () => {
    axios.post(`/sites/${site.id}/pah_source_references/create_or_update`, { pah_source_reference: {site_id: site.id, background_sample_id: backgroundSample, key_sample_id: keySample }})
      .then(res => {
        const { background_sample, key_sample } = res.data
        setSourceReferences({ background_sample, key_sample })
      })
      .catch(err => console.log(err))
    setSourceModal(false)
  }

  const customStyles = {
    menuPortal: base => ({ ...base, zIndex: 9999 }),
    menu: provided => ({ ...provided, position: 'absolute', fontSize: '10px' }),
    singleValue: provided => ({
      ...provided,
      fontSize: '10px',
      whiteSpace: 'normal'
    }),
    control: provided => ({
      ...provided,
      width: '350px'
    }),
  }

  return (
    sourceModal && 
    <div className="fixed inset-0 flex items-center justify-center z-40">
      <div className="absolute inset-0 bg-gray-100 opacity-40" onClick={() => setSourceModal(false)}></div>
      <div className="bg-white max-w-full h-auto rounded-lg shadow-lg z-50 overflow-y-auto flex justify-center items-center">
        <div className="relative w-full p-5 overflow-visible flex flex-col items-center jusitfy-center">
          <button className="border bg-gray-600 rounded-full w-8 h-8 flex items-center justify-center absolute top-3 right-3 -m-2 z-50" onClick={() => setSourceModal(false)}>
            <i className='fa fa-times text-white font-extrabold'/>
          </button>
          <h1 className="text-lg font-semibold text-center">Key Samples</h1>
          <p className='text-xs fonr-semibold uppercase text-center mb-3 text-gray-600'>Only samples that can be plotted are listed as options</p>
          <div className='mb-2'>
            <h1 className="text-sm font-semibold">Background Sample</h1>
            <Select
              className='text-xs'
              placeholder='Select Background Sample'
              options={options}
              defaultValue={options.find(option => option.value === sourceReferences?.background_sample?.id)}
              menuPortalTarget={document.body}
              styles={customStyles}
              onChange={selected => setBackgroundSample(selected.value)}
            />
          </div>
          <div>
            <h1 className="text-sm font-semibold">Source Sample</h1>
            <Select
              className='text-xs'
              placeholder='Select Source Sample'
              options={options}
              defaultValue={options.find(option => option.value === sourceReferences?.key_sample?.id)}
              menuPortalTarget={document.body}
              styles={customStyles}
              onChange={selected => setKeySample(selected.value)}
            />
          </div>
          {backgroundSample && keySample && <button className='border rounded text-xm uppercase font-semibold p-1 bg-blue-300 text-xs mt-3' onClick={handleSave}>Save</button>}
        </div>
      </div>
    </div>
  )
}

export default SourcesModal
