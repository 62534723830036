import MapboxDraw from "@mapbox/mapbox-gl-draw"
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css"
import * as turf from "@turf/turf"

export const initializeMapDrawPah = (map, mapDrawRef, annotationMode, setPahSelectedSampleIds) => {
  if (!mapDrawRef.current && annotationMode) {
    mapDrawRef.current = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        polygon: true,
        trash: true,
      },
    })
    map.addControl(mapDrawRef.current, "top-left")
    annotationFunctionsPah(map, mapDrawRef, setPahSelectedSampleIds)
  } else if (mapDrawRef.current && !annotationMode) {

    map.removeControl(mapDrawRef.current)
    mapDrawRef.current = null
  }
}

const attachDrawEventListener = (map, eventType, handler) => {
  if (map._listeners[eventType]) map._listeners[eventType] = []
  map.on(eventType, (e) => handler(e, map))
}

const getSelectedSamples = (features, polygon) => {
  return features
    .filter((feature) => {
      const point = turf.point(feature.geometry.coordinates)
      return turf.booleanPointInPolygon(point, polygon)
    })
    .map((feature) => {
      return { 
        id: feature.properties.sample_id, 
        name: feature.properties.sample_name, 
        depth: feature.properties.depth, 
        date: feature.properties.sample_date
      }
    })
}

const processSelectedSamples = (features, drawnPolygons) => {
  const polygon = turf.polygon(drawnPolygons[0].geometry.coordinates)
  const selectedSamples = getSelectedSamples(features, polygon)
  return selectedSamples
}

const onDrawCreate = (e, map, mapDrawRef, setPahSelectedSampleIds) => {
  const drawnPolygons = mapDrawRef.current.getAll().features

  if (drawnPolygons.length > 1) {
    mapDrawRef.current.delete(drawnPolygons[0].id)
  }

  const features = map.getSource("sampleLocations")._data.features
  setPahSelectedSampleIds(processSelectedSamples(features, e.features))
}

const onDrawUpdate = (e, map, setPahSelectedSampleIds) => {
  const features = map.getSource("sampleLocations")._data.features
  setPahSelectedSampleIds(processSelectedSamples(features, e.features))
}

const onDrawDelete = (e, setPahSelectedSampleIds) => {
  setPahSelectedSampleIds([])
}


const annotationFunctionsPah = (map, mapDrawRef, setPahSelectedSampleIds) => {
  attachDrawEventListener(map, "draw.create", (e) => onDrawCreate(e, map, mapDrawRef, setPahSelectedSampleIds))
  attachDrawEventListener(map, "draw.update", (e) => onDrawUpdate(e, map, setPahSelectedSampleIds))
  attachDrawEventListener(map, "draw.delete", (e) => onDrawDelete(e, setPahSelectedSampleIds))
}
