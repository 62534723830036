import React from 'react'
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import './toastify-success.css'

const DeleteWithConfirm = ({ deleteRoute, message, successMessage }) => {

  const deleteItem = () => {
    axios.delete(deleteRoute).then(_ => toast.success(successMessage))
  }

  return <>
    <ToastContainer />
    <i className='fa fa-trash-alt cursor-pointer text-red-400 hover:text-red-800 m-1' onClick={() => {
      if (window.confirm(message)) deleteItem()
    }} /></>
}

export default DeleteWithConfirm
