export const headerWidth = (header) => {
  let width = 32

  if (header == 'group') {
    width = 44
  } else if (header == 'chemical' || header == 'pathway') {
    width = 96
  } else if (header.length > 12) {
    width = 52
  }

  return width
}

export const displayHeader = (header) => {
  if (header.includes("_")) {
    return header.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
  }

  if (header == 'residentialparkland') {
    return 'Residential Parkland'
  }

  if (header == 'commercialindustrial') {
    return 'Commercial Industrial'
  }

  return header
}

export const unique = (value, index, array) => {
  return array.indexOf(value) === index;
}
