import React, { useState } from "react"
import SampleDataRow from "../shared/table/sampleDataRow";
import TableControls from "./tableHelpers/tableControls";
import TableHeaders from "../shared/table/tableHeaders";
import GuidelineDataRows from "../shared/table/guidelinesDataRow"
import { useDataVaultContext, useDataVaultAPI } from "../../contexts/dataVault"
import { formatSampleData } from "./filterHelpers/formatData";

const DataVaultTable = () => {
  const { siteData, showTable, displayedSamples, filteredSamples, geoJSON, highlightSampleIds, tableFullscreen } = useDataVaultContext()
  const { setShowTable, setFilteredGeoJSON, setFilteredSamples, setHighlightSampleIds, setTableFullscreen } = useDataVaultAPI()
  const searchProps={geoJSON, setFilteredGeoJSON, setFilteredSamples}
  const [showGuidelines, setShowGuidelines] = useState(false)
  const sampleData = filteredSamples || siteData;
  const chemicalHeaders = sampleData?.chemicals;
  const hasUniqueIds = sampleData?.samples.filter(sample => sample.name).length > 0;
  const displayedSampleData = displayedSamples || sampleData?.samples;
  const formattedSamples = formatSampleData(displayedSampleData, siteData.site_info)
  const renderSampleDataRows = formattedSamples?.map((sample, i) => {
    return <SampleDataRow key={i} sample={sample} chemicalHeaders={chemicalHeaders} setHighlightSampleIds={setHighlightSampleIds} highlightSampleIds={highlightSampleIds} hasUniqueIds={hasUniqueIds} samples={sampleData?.samples} />
  })

  const tableUpHandler = () => {
    showTable ? setTableFullscreen(true) : setShowTable(true)
  }

  const tableDownHandler = () => {
    if (tableFullscreen) setTableFullscreen(false)
    if (!tableFullscreen && showTable) setShowTable(false)
  }

  const showGuidlineHandler = () => {
    setShowGuidelines(!showGuidelines)
  }

  return (
    <>
      <TableControls tableFullscreen={tableFullscreen} tableDownHandler={tableDownHandler} tableUpHandler={tableUpHandler} searchProps={searchProps} showGuidelines={showGuidelines} showGuidlineHandler={showGuidlineHandler}/>
      {showTable &&
        <div className={tableFullscreen ? "h-tableFull" : "h-1/4"}>
          <div className="h-full overflow-auto">
            <table className="dataVaultTable" onClick={() => highlightSampleIds.length ? setHighlightSampleIds([]) : null}>
              <TableHeaders samples={sampleData?.samples} chemicals={chemicalHeaders} hasUniqueIds={hasUniqueIds}/>
              <GuidelineDataRows chemicalHeaders={chemicalHeaders} showGuidelines={showGuidelines} hasUniqueIds={hasUniqueIds}/>
              <tbody>
                {renderSampleDataRows}
              </tbody>
            </table>
          </div>
        </div>
      }
    </>
  );
}

export default DataVaultTable;
