import React from "react";
import Select from "react-select";

const SiteConditionsDropdown = ({ title, options, defaultValue, state, setState}) => {
  const currentValue = options.filter((option) => option.value == state)

  return<>
    <div className='flex flex-col px-2'>
      <label>{title}:</label>
      <Select
        className='w-1/4'
        defaultValue={defaultValue}
        value={currentValue}
        onChange={(event) => setState(event.value)}
        options={options}
      />
    </div>
    <hr className='my-2'/>
  </>
}

export default SiteConditionsDropdown
