import fixedGridData from "./fixedGridData";

const buildDoubleRatioPlot = (data, title, name) => {
  return {
    title: {
      text: title,
      style: {
        fontSize: '20px',
        fontWeight: 'bold',
      }
    },
    chart: {
      height: '700',
      width: '700',
    },
    legend: {
      enabled: true,
      layout: 'vertical',
      align: 'center',
      symbolWidth: 4,
      borderColor: 'white',
      itemStyle: {
        fontSize: '8px',
      },
      itemWidth: 150,
      maxHeight: 150,
    },
    xAxis: {
      title: {
        text: 'FLRN/PYR'
      },
      max: 2,
      min: 0,
      gridLineWidth: 1,
      tickInterval: 0.1,
      lineWidth: 0,
    },
    yAxis: {
      title: {
        text: 'IcdP/BghiP'
      },
      max: 2,
      min: 0,
      gridLineWidth: 1,
      tickInterval: 0.1,
      lineWidth: 0,
    },
    series: [
      ...fixedGridData(),
      {
        name: name,
        data: [data],
        marker: {
          symbol: 'circle',
          radius: 5,
          fillColor: '#0095ED',
          lineColor: 'black',
          lineWidth: 1,
        },
        dataLabels: [{
          enabled: true,
          format: name,
          align: 'center',
          style: {
            textOutline: 'transparent',
          },
        }],
        showInLegend: false,
      }
    ],
    colors: [
      "#4E79A7", "#76B7B2", "#E15759", "#F28E2B", 
      "#59A14F", "#EDC948", "#B07AA1", "#FF9DA7", 
      "#9C755F", "#BAB0AB", "#b35504", "#000000",
      "#90ee90",
    ],
    tooltip: {
      formatter: function() {
        return `(${this.x}, ${this.y})`
      }
    },
    credits: {
      enabled: true,
      text: 'Powered by Statvis',
      href: 'https://www.statvis.com/'
    }
  }
}

export default buildDoubleRatioPlot
