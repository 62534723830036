import React, { useState } from 'react'
import NewGuidelineModal from "./newGuidelineModal";
import RenderModal from "../../../shared/modal/RenderModal";

const AddGuideline = () => {
  const [showModal, setShowModal] = useState(false)

  return <>
    <button
      onClick={() => setShowModal(true)}
      className="btn btn-primary"
    >
      Add
    </button>
    <RenderModal selector="#modal-root">
      <NewGuidelineModal showModal={showModal} setShowModal={setShowModal} />
    </RenderModal>
  </>
}

export default AddGuideline
