import React from "react";

const PathwayRow = ({ pathway, values, unit }) => {
  const valuesList = values.map((value) => {
    return (<p className='w-1/4 text-right'>{value}</p>)
  })

  return(<>
    <div className='flex flex-row my-1 px-2'>
      <p className='font-semibold w-3/12'>{pathway}</p>
      <p className='w-1/12'>{unit}</p>
      <div className='w-8/12 flex flex-row'>
        {valuesList}
      </div>
    </div>
  </>)
}

export default PathwayRow
