export const formatGeojson = (geojson, site) => {
  if (!site.apply_guidelines) {
    geojson.features.forEach(feature => {
      feature.properties.color = "#00ff59"
      feature.properties.highest_exceedance = { }
      feature.properties.soil_exceedance_chemicals = []
      feature.properties.groundwater_exceedance_chemicals = []
      feature.properties.stroke_color = ""
      feature.properties.sample_values.forEach(value => removeGuidelines(value))
    })
  }

  return geojson
}

export const formatSampleData = (sampleData, site) => {
  if (!site.apply_guidelines) {
    sampleData.forEach(sample => {
      sample.sample_values.forEach(value => removeGuidelines(value))
    })
  }
  return sampleData
}

const removeGuidelines = (sampleValue) => {
  sampleValue.exceedance = null
  sampleValue.guideline = null
  sampleValue.guideline_range_max = null
  sampleValue.guideline_range_min = null

  return
}
