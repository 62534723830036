import React, { useEffect } from "react";
import axios from "axios"
import mapboxgl from '!mapbox-gl';
import DataVaultMap from "./dataVaultMap";
import DataVaultTable from "./dataVaultTable";
import FilterContainer from "./filterContainer";
import UploadCSVModal from "./uploadCSVModal";
import { tableDataSorter } from "../shared/table/tableFunctions";
import { useDataVaultContext, useDataVaultAPI } from "../../contexts/dataVault";
import StatvisFlask from "./StatvisFlask.gif";
import { formatGeojson } from "./filterHelpers/formatData";

const boundsHelper = (geojson) => {
  if (!geojson) return;
  const bounds = new mapboxgl.LngLatBounds()
    geojson?.features.forEach(feature => {
      bounds.extend(feature.geometry.coordinates)
    })

  return bounds
}

const DataVault = ({siteId, selectedSampleIds}) => {
  const { siteData, geoJSON, loading, highlightSampleIds, tableFullscreen } = useDataVaultContext()
  const { setGeoJSON, setSiteData, setGeospatialFeatures, setBounds, setLoading, setHighlightSampleIds } = useDataVaultAPI()

  const initializeSelectedSampleIds = selectedSampleIds[0] ? selectedSampleIds[0].split(',').map(Number) : selectedSampleIds
  const hasSamples = (siteData) => siteData?.samples?.length != 0

  useEffect(() => {
    if (highlightSampleIds.length > 0) {
      const queryParams = new URLSearchParams()
      queryParams.set("selectedSampleIds", highlightSampleIds.join(','))
      const newUrl = `${window.location.pathname}?${queryParams.toString()}`
      window.history.pushState({}, "", newUrl)
    } else {
      const newUrl = `${window.location.pathname}`
      window.history.pushState({}, "", newUrl)
    }
  }, [highlightSampleIds])

  useEffect(() => {
    setLoading(true)
    setHighlightSampleIds(initializeSelectedSampleIds)

    const getSiteGeoJson = axios.get(`/sites/${siteId}.geojson`)
    const getSiteData = axios.get(`/sites/${siteId}/site_info`)
    const getGeospatialFeatures = axios.get(`/sites/${siteId}/geospatial_features`)

    Promise.all([getSiteGeoJson, getSiteData, getGeospatialFeatures])
      .then((responses) => {
        const geojson = responses[0].data
        const siteData = responses[1].data
        const geospatialFeatures = responses[2].data
        tableDataSorter(siteData.samples)
        if (geojson && geojson.features.length) {
          setGeoJSON(formatGeojson(geojson, siteData.site_info))
          setBounds(boundsHelper(geojson))
        }

        if (siteData) setSiteData(siteData)

        if (geospatialFeatures) setGeospatialFeatures(geospatialFeatures)
      })
      .finally(() => setLoading(false))
  }, [siteId])

  return (
    <div className="flex flex-col h-full z-50">
      {loading && (
        <div className="bg-gray-100 h-full w-full flex items-center justify-center">
          <div className="border-black border rounded-full">
            <div className="bg-white flex flex-col justify-center items-center rounded-full" style={{height: '280px', width: '280px'}}>
              <img src={StatvisFlask} alt='Processing...' className="-mt-6"/>
              <p className='text-black text-lg font-semibold text-center uppercase text-semibold -mt-2'>Loading data...</p>
            </div>
          </div>
        </div>
      )}
      {!loading && <>
        {hasSamples(siteData) && geoJSON?.features.length != 0 && <FilterContainer/>}
        {!tableFullscreen && <DataVaultMap/>}
        {hasSamples(siteData) && !siteData.calculating && <DataVaultTable/>}
        {!hasSamples(siteData) && <UploadCSVModal />}
      </>}
    </div>
  )
}

export default DataVault
