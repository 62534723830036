import React from "react"
import Select from "react-select";

const OptionSelect = ({ title, state, options, onSelect }) => {
  return <>
    <label>{title}:</label>
    <Select
      className='my-2 w-1/4'
      value={state}
      options={options}
      onChange={(option) => onSelect(option)}
    />
  </>
}

export default OptionSelect
