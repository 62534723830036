import React, { useState } from "react";
import TableRows from "./tableRows";
import { toast } from "react-toastify";
import DownloadAllButton from "../downloadAllButton";

const UploadTable = ({ 
  site, 
  siteUploads, 
  samplesCount
 }) => {
  const [uploads, setUploads] = useState(siteUploads);
  const hasSamples = samplesCount > 0;
  const uploadRows = uploads.map((upload) => (
    <TableRows
      key={upload.id}
      site={site}
      upload={upload}
      hasSamples={hasSamples}
      uploads={uploads}
      setUploads={setUploads}
      toast={toast}
    />
  ));

  return (
    <>
      <div className="panel">
        <div className="panel-header">
          <h1>Sample Data Uploads</h1>
        </div>
        <div className="panel-body">
          <table className="w-full text-center p-1">
            <thead>
              <tr className="w-full">
                <th className="text-left">Name</th>
                <th># of Data Rows</th>
                <th>Element Import?</th>
                <th></th>
                <th>Parameter Selection</th>
                <th>
                  <div className="flex justify-center mb-2">
                    <DownloadAllButton
                      siteId={site.id}
                      siteTitle={site.title}
                    />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>{uploadRows}</tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default UploadTable;
