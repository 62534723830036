const filterGeoJSONBySampleIds = (geoJSON, sampleIds) => {
  return {
    type: "FeatureCollection",
    features: (geoJSON?.features || []).filter(feature => sampleIds.includes(feature.properties.sample_id))
  }
}

const filterByMatrix = (geoJSON, samples, matrix) => {
  const filteredGeoJSONMatrix = {
    type: "FeatureCollection",
    features: (geoJSON?.features || []).filter(feature => feature.properties.matrix === matrix)
  }
  const filteredSamplesMatrix = (samples || []).filter(sample => sample.matrix.title === matrix)
  return { filteredGeoJSONMatrix, filteredSamplesMatrix }
}

const filterByArea = (geoJSON, samples, area) => {
  const filteredGeoJSONArea = {
    type: "FeatureCollection",
    features: (geoJSON?.features || []).filter(feature => feature.properties.sample_location_group_name === area)
  }
  const filteredSamplesArea = (samples || []).filter(sample => sample.sample_location_group_name === area)
  return { filteredGeoJSONArea, filteredSamplesArea }
}

const filterByParameter = (geojson, samples, parameter) => {
  const filteredSamplesParameters = samples.filter(sample =>
    sample.sample_values.some(sampleValue => sampleValue.chemical_id === parameter.id)
  )
  const sampleIds = filteredSamplesParameters.map(sample => sample.id)
  const filteredGeoJSONParameters = filterGeoJSONBySampleIds(geojson, sampleIds)

  return { filteredGeoJSONParameters, filteredSamplesParameters }
}

const filterByDate = (geoJSON, samples, dateRange) => {
  const filteredSamplesDate = samples.filter(sample => {
    const sampleDate = sample.sample_date

    if (dateRange.start && dateRange.end) return sampleDate >= dateRange.start && sampleDate <= dateRange.end
    if (dateRange.start) return sampleDate >= dateRange.start
    if (dateRange.end) return sampleDate <= dateRange.end
  })
  const sampleIds = filteredSamplesDate.map(sample => sample.id)
  const filteredGeoJSONDate = filterGeoJSONBySampleIds(geoJSON, sampleIds)

  return { filteredGeoJSONDate, filteredSamplesDate }
}

const filterByDepth = (geojson, samples, depthRange) => {
  const filteredSamplesDepth = (samples || []).filter(sample => {
    const sampleDepth = sample.sample_depth

    if (depthRange.start && depthRange.end) return sampleDepth >= depthRange.start && sampleDepth <= depthRange.end
    if (depthRange.start) return sampleDepth >= depthRange.start
    if (depthRange.end) return sampleDepth <= depthRange.end
  })
  const sampleIds = filteredSamplesDepth.map(sample => sample.id)
  const filteredGeoJSONDepth = filterGeoJSONBySampleIds(geojson, sampleIds)

  return { filteredGeoJSONDepth, filteredSamplesDepth }
}

const filterByValue = (geojson, samples, valueRange, parameter) => {
  const filteredSamplesValue = (samples || []).filter(sample => {
    const matchedSample = sample.sample_values.find(sampleValue => sampleValue.chemical_id == parameter.id)
    const sampleValue = matchedSample ? matchedSample.float_value : null

    if (valueRange.start && valueRange.end) return sampleValue >= valueRange.start && sampleValue <= valueRange.end
    if (valueRange.start) return sampleValue >= valueRange.start
    if (valueRange.end) return sampleValue <= valueRange.end
  })
  const sampleIds = filteredSamplesValue.map(sample => sample.id)
  const filteredGeoJSONValue = filterGeoJSONBySampleIds(geojson, sampleIds)

  return { filteredGeoJSONValue, filteredSamplesValue }
}

export const filterSamplesAndGeojson = (geoJSON, siteData, matrix, area, parameter, dateRange, depthRange, valueRange) => {
  let updatedSamples = [...siteData]
  let updatedGeoJSON = {...geoJSON}

  if (matrix) {
    const {filteredGeoJSONMatrix, filteredSamplesMatrix} = filterByMatrix(updatedGeoJSON, updatedSamples, matrix)
    updatedGeoJSON = filteredGeoJSONMatrix
    updatedSamples = filteredSamplesMatrix
  }

  if (area) {
    const { filteredGeoJSONArea, filteredSamplesArea } = filterByArea(updatedGeoJSON, updatedSamples, area)
    updatedGeoJSON = filteredGeoJSONArea
    updatedSamples = filteredSamplesArea
  }

  if (Object.entries(parameter).length !== 0) {
    const {filteredGeoJSONParameters, filteredSamplesParameters} = filterByParameter(updatedGeoJSON, updatedSamples, parameter)
    updatedGeoJSON = filteredGeoJSONParameters
    updatedSamples = filteredSamplesParameters
  }

  if (dateRange.start || dateRange.end) {
    const {filteredGeoJSONDate, filteredSamplesDate} = filterByDate(updatedGeoJSON, updatedSamples, dateRange)
    updatedGeoJSON = filteredGeoJSONDate
    updatedSamples = filteredSamplesDate
  }

  if (depthRange.start || depthRange.end) {
    const {filteredGeoJSONDepth, filteredSamplesDepth} = filterByDepth(updatedGeoJSON, updatedSamples, depthRange)
    updatedGeoJSON = filteredGeoJSONDepth
    updatedSamples = filteredSamplesDepth
  }

  if (valueRange.start || valueRange.end) {
    const {filteredGeoJSONValue, filteredSamplesValue} = filterByValue(updatedGeoJSON, updatedSamples, valueRange, parameter)
    updatedGeoJSON = filteredGeoJSONValue
    updatedSamples = filteredSamplesValue
  }

  return {updatedGeoJSON, updatedSamples}
}

export const filterByLocation = (geojson, samples, highlightSampleIds) => {
  const filteredGeojsonLocation = {
    type: "FeatureCollection",
    features: (geojson?.features || []).filter(feature => highlightSampleIds.includes(feature.properties.sample_id))
  }
  const filteredSamplesLocation = (samples || []).filter(sample => highlightSampleIds.includes(sample.id))

  return { filteredGeojsonLocation, filteredSamplesLocation }
}
