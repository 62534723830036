import React from "react";

const TableHeader = ({ headerGroup }) => (
  <tr {...headerGroup.getHeaderGroupProps()}>
    {headerGroup.headers.map(column => (
      <th {...column.getHeaderProps(column.getSortByToggleProps())}>
        {column.render('Header')}
        {column.isSorted ? (
          <i className={`fa fa-sort-${column.isSortedDesc ? 'down' : 'up'} ml-1`} />
        ) : (
          <i className="fa fa-sort ml-1" />
        )}
      </th>
    ))}
  </tr>
);

export default TableHeader;
