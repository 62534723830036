import React, { useContext } from "react";
import ParameterRow from "./parameterRow";
import { TierTwoContext } from "../tierTwoContext";
import { createSymbol, createUnit, isFineGrain } from "../../helpers";

const AdjustableParameters = () => {
  const { grainSize, sourceLength, setSourceLength, sourceWidth, setSourceWidth, surfaceFoundationDistance, setSurfaceFoundationDistance, subsoilFoundationDistance, setSubsoilFoundationDistance, contaminationThickness, setContaminationThickness, groundwaterDepth, setGroundwaterDepth, surfaceWaterDistance, setSurfaceWaterDistance, hydraulicConductivity, setHydraulicConductivity, hydraulicGradient, setHydraulicGradient, organicCarbonFraction, setOrganicCarbonFraction, bulkDensity, setBulkDensity, moisturePorosity, setMoisturePorosity, vapourPorosity, setVapourPorosity, contaminationDepth, setContaminationDepth } = useContext(TierTwoContext)
  
  const grain = isFineGrain(grainSize)
  
  return <>
  <h1 className='my-4'>Alberta Tier 2 Adjustable Parameters</h1>
    <div className='flex flex-row px-3 py-2 bg-gray-700 text-white font-semibold px-2 py-1 my-2'>
      <h3 className='w-1/2'>Parameter</h3>
      <div className='flex flex-row w-1/2'>
        <h3 className='w-1/6'>Symbol</h3>
        <h3 className='w-1/6'>Unit</h3>
        <h3 className='w-1/2'>Select Guideline</h3>
        <h3 className='w-1/4'>Default</h3>
        <h3 className='w-1/4'>Adjusted</h3>
      </div>
    </div>
    <ParameterRow
      title={'Source Length - Parallel to GW Flow'}
      symbol={'X'}
      unit={'m'}
      reference={10}
      state={sourceLength}
      setState={setSourceLength}
    />
    <ParameterRow
      title={'Source Width - Perpendicular to GW Flow'}
      symbol={'Y'}
      unit={'m'}
      reference={10}
      state={sourceWidth}
      setState={setSourceWidth}
    />
    <ParameterRow
      title={'Distance From Source to Foundation - Surface Soil'}
      symbol={createSymbol('L', 'T')}
      unit={'cm'}
      reference={10}
      state={surfaceFoundationDistance}
      setState={setSurfaceFoundationDistance}
    />
    <ParameterRow
      title={'Distance From Source to Foundation - Subsoil'}
      symbol={createSymbol('L', 'T')}
      unit={'cm'}
      reference={30}
      state={subsoilFoundationDistance}
      setState={setSubsoilFoundationDistance}
    />
    <ParameterRow
      title={'Thickness of Contamination'}
      symbol={`Z`}
      unit={'m'}
      reference={3}
      state={contaminationThickness}
      setState={setContaminationThickness}
    />
    <ParameterRow
      title={'Depth to Groundwater'}
      symbol={`d`}
      unit={'m'}
      reference={3}
      state={groundwaterDepth}
      setState={setGroundwaterDepth}
    />
    <ParameterRow
      title={'Distance to Surface Water - Parallel to GW Flow'}
      symbol={`d`}
      unit={'x'}
      reference={10}
      state={surfaceWaterDistance}
      setState={setSurfaceWaterDistance}
    />
    <ParameterRow
      title={'Hydraulic Gradient'}
      symbol={`i`}
      unit={'m/m'}
      reference={0.028}
      state={hydraulicGradient}
      setState={setHydraulicGradient}
    />
    <ParameterRow
      title={'Saturated Hydraulic Conductivity'}
      symbol={`K`}
      unit={'m/yr'}
      reference={grain ? 32 : 320}
      state={hydraulicConductivity}
      setState={setHydraulicConductivity}
    />
    <ParameterRow
      title={'Fraction of Organic Carbon'}
      symbol={createSymbol('f', 'oc')}
      unit={'mass/mass'}
      reference={0.005}
      state={organicCarbonFraction}
      setState={setOrganicCarbonFraction}
    />
    <ParameterRow
      title={'Soil Bulk Density'}
      symbol={createSymbol('ρ', 'B')}
      unit={createUnit('gr', undefined, 'cm', 3)}
      reference={grain ? 1.4 : 1.7}
      state={bulkDensity}
      setState={setBulkDensity}
    />
    <ParameterRow
      title={'Soil Moisture-Filled Porosity'}
      symbol={createSymbol('θ', 'w')}
      unit={createUnit('cm', 3, 'cm', 3)}
      reference={grain ? 0.168 : 0.119}
      state={moisturePorosity}
      setState={setMoisturePorosity}
    />
    <ParameterRow
      title={'Soil Vapour-Filled Porosity'}
      symbol={createSymbol('θ', 'a')}
      unit={createUnit('cm', 3, 'cm', 3)}
      reference={grain ? 0.302 : 0.241}
      state={vapourPorosity}
      setState={setVapourPorosity}
    />
    <ParameterRow
      title={'Depth from Surface to Bottom of Contamination'}
      symbol={createSymbol('Z', 'T')}
      unit={'m'}
      reference={3}
      state={contaminationDepth}
      setState={setContaminationDepth}
    />
  </>
}

export default AdjustableParameters
