import { showClusteredPopup, showUnclusteredPointPopup, showClusterChildrenPopup } from './popupsLabelFunctions';
import mapboxgl from 'mapbox-gl';

const showFunctions = (map, setHighlightSampleIds, mapType) => {
    
  map.on('click', 'unclustered-point', function (e) {
    const sampleId = e.features[0].properties.salt_print_sample_id || e.features[0].properties.sample_id;
    if (setHighlightSampleIds) {
      setHighlightSampleIds([sampleId]);
    } else {
      showUnclusteredPointPopup(map, e.features[0]);
    }
  });
  
  const getClusterChildrenAsync = (clusterId) => {
    return new Promise((resolve, reject) => {
      map.getSource('sampleLocations').getClusterChildren(clusterId, (err, feature) => {
        if (err) reject(err);
        resolve(feature);
      });
    });
  };
  
  map.on('click', 'clusters', (e) => {
    const clusterFeatures = map.queryRenderedFeatures(e.point, {
      layers: ['clusters'],
    });
    
    if (clusterFeatures.length) {
      const clusterId = clusterFeatures[0].id;
      getClusterChildrenAsync(clusterId)
        .then((children) => {
          const features = [...children]
          const hasClusterChildren = features.some(feature => feature.properties.cluster === true);
          if (!hasClusterChildren) {
            if (mapType === 'dioxinsAndFurans' || mapType === 'pah' || mapType === 'pcb') {
              showClusterChildrenPopup(map, features, setHighlightSampleIds)
            } else {
              showClusteredPopup(map, features)
              const clusterChildrenIds = features.map(feature => feature.properties.salt_print_sample_id || feature.properties.sample_id)
              if (setHighlightSampleIds) setHighlightSampleIds(clusterChildrenIds)
            }
          }
          else {
            map.getSource('sampleLocations').getClusterExpansionZoom(clusterId, (err, zoom) => {
              if (err) return
              map.flyTo({
                center: clusterFeatures[0].geometry.coordinates,
                zoom: zoom,
              });
            });
          }
        })
        .catch(err => console.log(err));
    }
  });
  
  map.on('click', (e) => {
    const features = map.queryRenderedFeatures(e.point);
    if (!features.length && setHighlightSampleIds) {
      setHighlightSampleIds([]);
    }
  });
  
  map.on('click', 'sites-clusters', (e) => {
    const clusterFeatures = map.queryRenderedFeatures(e.point, {
      layers: ['sites-clusters'],
    });
  
    if (clusterFeatures.length) {
      const clusterId = clusterFeatures[0].id;
      
      getClusterChildrenAsync(clusterId)
        .then((children) => {
          const features = [...children]
          const hasClusterChildren = features.some(feature => feature.properties.cluster === true);
          if (!hasClusterChildren) {
            const siteAnchors = features.map(child => {
              return (
                `<div class='border bg-gray-100 border-gray-500 mb-0 p-1 rounded-lg m-1 hover:bg-gray-300'>
                  <a class='font-semibold' href="/sites/${child.properties.site_id}">${child.properties.site_title}</a>
                </div>`
              )
            });
            const popupContent = siteAnchors.join('');
            const popup = new mapboxgl.Popup({ closeButton: false })
              .setLngLat(e.lngLat)
              .setHTML(`<div class='flex flex-col'>${popupContent}</div>`)
            
            popup.addTo(map);
          }
          else {
            map.getSource('sampleLocations').getClusterExpansionZoom(clusterId, (err, zoom) => {
              if (err) return;
              map.flyTo({
                center: clusterFeatures[0].geometry.coordinates,
                zoom: zoom,
              });
            });
          }
        })
        .catch(err => console.log(err));
    }
  });
};

export default showFunctions;
