import React from 'react'

const SampleLocation = ({ sampleLocation, toggleSelection }) => {
  return (
    <tr className='text-center'>
      <td>
        <input
          className='h-5 w-5 mt-2'
          type='checkbox'
          id={sampleLocation.id}
          onChange={(e) => toggleSelection(sampleLocation.id)}
        />
      </td>
      <td>{sampleLocation.name}</td>
      <td>{sampleLocation.samples_count}</td>
      <td>{sampleLocation.matrix}</td>
      <td>{sampleLocation.max_depth}</td>
      <td>{sampleLocation.group}</td>
    </tr>
  )
}

export default SampleLocation
