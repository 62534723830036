import React from "react"
import axios from "axios";
import { useCustomGuidelinesContext } from "../../../contexts/customGuidelines";

const ApplyGuidelines = () => {
  const { site } = useCustomGuidelinesContext()
  const onClick = () => {
    // Triggering a background job, needs to navigate back to site to mimic original behaviour
    axios.get(`/sites/${site.id}/recalculate.json`)
      .then(res => window.location.href = res.data)
  }

  return <button
    onClick={() => onClick()}
    className="btn btn-default"
  >
    Apply Guidelines
  </button>
}

export default ApplyGuidelines
