const fixedGridData = () => {
  return [
    {
      name: 'Pyrogenic',
      type: 'polygon',
      data: [
        { x: 1, y: 0.75, id: 'id-1'},
        { x: 1, y: 2, id: 'id-2'},
        { x: 2, y: 2, id: 'id-3'},
        { x: 2, y: 0.75, id: 'id-4'}
      ],
      color: '#EEC1C2',
      opacity: 0.8,
      enableMouseTracking: false,
      showInLegend: false,
    },
    {
      name: 'Petrogenic',
      type: 'polygon',
      data: [
        { x: 0, y: 0, id: 'id-1'},
        { x: 0, y: 0.75, id: 'id-2'},
        { x: 0.5, y: 0.75, id: 'id-3'},
        { x: 0.5, y: 0, id: 'id-4'}
      ],
      color: '#A5DEFF',
      opacity: 0.8,
      enableMouseTracking: false,
      showInLegend: false,
    },
    {
      name: 'Labels',
      type: 'scatter',
      data: [
        { x: 1.5, y: 2, label: 'Pyrogenic Region'},
        { x: 0.25, y: 0, label: 'Petrogenic Region'},
      ],
      marker: {
        enabled: false,
      },
      dataLabels: {
        enabled: true,
        format: '{point.label}',
        style: {
          color: 'gray',
          textOutline: 'transparent',
          fontSize: '10px',
          fontWeight: 'bolder',
        }
      },
      showInLegend: false,
      enableMouseTracking: false,
    },
  ]
}

export default fixedGridData
