import React from "react";

const TableSummary = ({ data }) => {
  return <table className="table my-4">
    <thead>
    <tr>
      <th colSpan="9" className="text-center">
        {data.chemical.name}
      </th>
    </tr>
    <tr>
      <th className="text-center"># Samples</th>
      <th className="text-center">Maximum</th>
      <th className="text-center">Upper Quantile</th>
      <th className="text-center">Median</th>
      <th className="text-center">Lower Quantile</th>
      <th className="text-center">Minimum</th>
      <th className="text-center">Mean</th>
      <th className="text-center">Std. Deviation</th>
      <th className="text-center">IQR</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td className="text-center">
        {data.rows.length}
      </td>
      <td className="text-center">
        {data.maximum}
      </td>
      <td className="text-center">
        {data.upper_quartile}
      </td>
      <td className="text-center">
        {data.median}
      </td>
      <td className="text-center">
        {data.lower_quartile}
      </td>
      <td className="text-center">
        {data.minimum}
      </td>
      <td className="text-center">
        {data.mean}
      </td>
      <td className="text-center">
        {data.standard_deviation}
      </td>
      <td className="text-center">
        {data.iqr}
      </td>
    </tr>
    <tr>
      <td colSpan="9" className="text-center p-1">
        {data.normality_result}
      </td>
    </tr>
    </tbody>
  </table>
}

export default TableSummary
