import React from "react";

const Pagination = ({
  canPreviousPage,
  canNextPage,
  pageCount,
  pageOptions,
  gotoPage,
  nextPage,
  previousPage,
  setPageSize,
  pageIndex,
  pageSize,
}) => {
  if (!pageOptions || pageOptions.length === 0) return null;

  return (
    <div className="flex items-center space-x-2">
      <button
        onClick={() => gotoPage(0)}
        disabled={!canPreviousPage}
        className="px-2 py-1 border rounded text-sm"
      >
        {"<<"}
      </button>
      <button
        onClick={() => previousPage()}
        disabled={!canPreviousPage}
        className="px-2 py-1 border rounded text-sm"
      >
        {"<"}
      </button>
      <span className="text-sm">
        Page{" "}
        <strong>
          {pageIndex + 1} of {pageOptions.length}
        </strong>{" "}
      </span>
      <button
        onClick={() => nextPage()}
        disabled={!canNextPage}
        className="px-2 py-1 border rounded text-sm"
      >
        {">"}
      </button>
      <button
        onClick={() => gotoPage(pageCount - 1)}
        disabled={!canNextPage}
        className="px-2 py-1 border rounded text-sm"
      >
        {">>"}
      </button>
      <select
        value={pageSize}
        onChange={(e) => {
          setPageSize(Number(e.target.value));
        }}
        className="text-sm border rounded"
      >
        {[10, 20, 30, 40, 50].map((pageSize) => (
          <option key={pageSize} value={pageSize}>
            Show {pageSize}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Pagination;
