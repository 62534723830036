import molecularLabels from "../../molecularLabels";
import zeroLabels from "../../zeroLabels";

const buildPahChart = (chartTitle, yAxisTitle, data, categories, unit, color) => {
  return {
    title: {
      text: chartTitle,
      style: {
        fontSize: '12px',
        marginBottom: '10px',
        fontWeight: 'bold'
        
      }
    },
    chart: {
      type: 'column',
      events: molecularLabels(),
      height: 270,
      width: 450,
    },
    legend: {
      enabled: false
    },
    xAxis: {
      title: {
        text: 'Parameters',
        enabled: false
      },
      labels: {
        style: {
          fontSize: '11px'
        }
      },
      categories: categories,
      plotLines: [{
        color: '#999999',
        width: 2,
        value: 5.5,
        dashStyle: 'dash'
      }],
      lineWidth: 0,
    },
    yAxis: {
      title: {
        text: yAxisTitle
      },
      lineWidth: 0,
    },
    series: [
      {
        data: data,
        color: color || '#7cb5ec'
      }
    ],
    tooltip: {
      formatter: function() {
        return `${this.x}: ${this.y}${unit}`
      }
    },
    credits: {
      enabled: true,
      text: 'Powered by Statvis',
      href: 'https://www.statvis.com/'
    }
  }
}

export default buildPahChart
