import React from "react"

const NoSite = ({ newSitePath }) => {
  return (
    <div className="ml-4 font-semibold ">
      <p>Looks like you haven't added any sites yet!</p>
      <button className="btn btn-default -ml-1 w-32 mt-5 mb-4" onClick={ () => window.location.href = newSitePath}> Add a site</button>        
      <p> For Element users please go back to your dashboard and export your report to Statvis. It will seamlessly import if you stay logged in.</p>
  </div>
  )
}

export default NoSite 
