import React from "react"
import { useAdminGuidelinesContext } from "../../../contexts/adminGuidelines";

const ParameterRow = ({ data }) => {
  const { headers } = useAdminGuidelinesContext()

  return <>
    <tr>
      {headers?.map((header, index) => {
        return <td key={index} className={`border border-gray-600`}>{data[header]}</td>
      })}
    </tr>
  </>
}

export default ParameterRow
