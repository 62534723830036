import React from 'react'
import { useDataVaultContext, useDataVaultAPI } from '../../../../contexts/dataVault'
const aerPipelineToggle = () => {
  const { showAerPipelineLayer } = useDataVaultContext()
  const { toggleAerPipelineLayer } = useDataVaultAPI()
  
  return (
    <button className={`border w-full ${showAerPipelineLayer ? 'bg-blue-300' : 'bg-gray-300'} text-xs rounded p-1 my-1 hover:${showAerPipelineLayer ? 'bg-blue-200' : 'bg-gray-200'}`} onClick={() => toggleAerPipelineLayer()}>AB - AER Pipelines</button>
  )
}

export default aerPipelineToggle
