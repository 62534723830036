import React, { useMemo } from "react"
import { useDioxinsAndFuransAPI, useDioxinsAndFuransContext } from "../../contexts/dioxinsAndFurans"

const SampleDropdown = () => {
  const { selectedSample, samples } = useDioxinsAndFuransContext()
  const { setSelectedSample } = useDioxinsAndFuransAPI()
  
  const sampleOptions = useMemo(() => samples?.map((sample) => {
    return (
      <option key={sample.id} value={JSON.stringify(sample)}>
        {sample.sample_name_for_json}
      </option>
    )
  }), [samples])

  const handleInputChange = (e) => {
    const selectedSampleObject = JSON.parse(e.target.value)
    setSelectedSample(selectedSampleObject)
  }

  return (
    <div className="flex items-center pr-2 m-1">
      <label className="sv-label">Sample: </label>
      <select
        className="w-full p-1 text-gray-700 focus:outline-none border border-gray-200 focus:shadow-inner rounded text-xs ml-1"
        value={JSON.stringify(selectedSample)}
        onChange={handleInputChange}
      >
        {sampleOptions}
      </select>
    </div>
  )
}

export default SampleDropdown
