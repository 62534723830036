import React, { createContext, useReducer, useMemo, useContext } from 'react'
import { addGuidelineHelper, deleteGuidelineHelper } from "../helpers/customGuidelines";

/* Actions */
const UPDATE_GUIDELINES = 'UPDATE_GUIDELINES'
const DELETE_GUIDELINE = 'DELETE_GUIDELINE'
const ADD_GUIDELINE = 'ADD_GUIDELINE'
const SET_NEW_GUIDELINES = 'SET_NEW_GUIDELINES'
const UPDATE_NEW_GUIDELINES = 'UPDATE_NEW_GUIDELINES'

/* Initial States */
const initialState = (site, existingGuidelines) => ({
  site: site,
  customGuidelines: existingGuidelines,
  newGuidelines: []
})

/* Reducer */
const customGuidelinesReducer = (originalState, action) => {
  const state = { ...originalState }

  switch (action.type) {
    case ADD_GUIDELINE:
      return addGuidelineHelper(state, action.payload)
    case UPDATE_GUIDELINES:
      return { ...state, customGuidelines: action.payload }
    case DELETE_GUIDELINE:
      return deleteGuidelineHelper(state, action.payload)
    case SET_NEW_GUIDELINES:
      return { ...state, newGuidelines: action.payload }
    case UPDATE_NEW_GUIDELINES:
      let newArray = [...state.newGuidelines]
      const index = newArray.findIndex((element) => element['Index'] === action.payload.chemicalIndex)
      newArray[index]['Chemical'] = action.payload.selectedOption?.label
      newArray[index]['ChemicalID'] = action.payload.selectedOption?.value

      return { ...state, newGuidelines: newArray }
    default:
      return state
  }
}

/* Contexts */
const CustomGuidelinesContext = createContext(initialState({}, []))
const CustomGuidelinesAPIContext = createContext({
  updateGuidelines: () => {},
  deleteGuideline: () => {},
  addGuideline: () => {},
  setNewGuidelines: () => {},
  updateNewGuidelines: () => {}
})

/* Providers */
export const CustomGuidelinesProvider = ({ children, site, existingGuidelines }) => {
  const [state, dispatch] = useReducer(customGuidelinesReducer, initialState(site, existingGuidelines))

  const api = useMemo(() => {
    const updateGuidelines = guidelines => dispatch({ type: UPDATE_GUIDELINES, payload: guidelines  })

    const deleteGuideline = (chemicalName, guideline) => () => dispatch({ type: DELETE_GUIDELINE, payload: { chemicalName, guideline } })

    const addGuideline = guideline => dispatch({ type: ADD_GUIDELINE, payload: { guideline } })

    const setNewGuidelines = guidelines => dispatch({ type: SET_NEW_GUIDELINES, payload: guidelines  })

    const updateNewGuidelines = (chemicalIndex, selectedOption) => dispatch({ type: UPDATE_NEW_GUIDELINES, payload: { chemicalIndex, selectedOption }})

    return { updateGuidelines, deleteGuideline, addGuideline, setNewGuidelines, updateNewGuidelines }
  }, [])


  return (
    <CustomGuidelinesAPIContext.Provider value={api}>
      <CustomGuidelinesContext.Provider value={state}>
        {children}
      </CustomGuidelinesContext.Provider>
    </CustomGuidelinesAPIContext.Provider>
  )
}

/* Custom Context Hooks */
export const useCustomGuidelinesContext = () => useContext(CustomGuidelinesContext)
export const useCustomGuidelinesAPI = () => useContext(CustomGuidelinesAPIContext)
