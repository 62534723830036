import React, { useContext } from "react";
import { TierTwoContext } from "../tierTwoContext";
import ParameterRow from "./parameterRow";
import { createSymbol, createUnit, isFineGrain } from "../../helpers";

const InputParameters = () => {
  const { grainSize, sourceLength, sourceWidth, surfaceFoundationDistance, subsoilFoundationDistance, contaminationThickness, groundwaterDepth, surfaceWaterDistance, hydraulicConductivity, hydraulicGradient, organicCarbonFraction, bulkDensity, moisturePorosity, vapourPorosity, contaminationDepth } = useContext(TierTwoContext)
  
  const grain = isFineGrain(grainSize)
  
  return <>
    <div className='px-2 w-full'>
      <div className='flex flex-row justify-between items-center font-semibold bg-gray-700 text-white font-semibold px-2 mt-2'>
        <h2 className='text-md my-1'>Alberta Tier 2 Adjustable Parameters:</h2>
      </div>
      <div className='flex flex-row font-semibold bg-blue-200 px-2 py-1 text-sm'>
        <h4 className='w-1/2'>Parameter</h4>
        <div className='flex flex-row w-1/2 text-right'>
          <h3 className='w-1/6'>Symbol</h3>
          <h3 className='w-1/6'>Unit</h3>
          <h3 className='w-1/4'>Default</h3>
          <h3 className='w-1/4'>Adjusted</h3>
        </div>
      </div>
      <ParameterRow
        title={'Source Length - Parallel to GW Flow'}
        symbol={'X'}
        unit={'m'}
        reference={10}
        state={sourceLength}
      />
      <ParameterRow
        title={'Source Width - Perpendicular to GW Flow'}
        symbol={'Y'}
        unit={'m'}
        reference={10}
        state={sourceWidth}
      />
      <ParameterRow
        title={'Distance From Source to Foundation - Surface Soil'}
        symbol={createSymbol('L', 'T')}
        unit={'cm'}
        reference={10}
        state={surfaceFoundationDistance}
      />
      <ParameterRow
        title={'Distance From Source to Foundation - Subsoil'}
        symbol={createSymbol('L', 'T')}
        unit={'cm'}
        reference={30}
        state={subsoilFoundationDistance}
      />
      <ParameterRow
        title={'Thickness of Contamination'}
        symbol={`Z`}
        unit={'m'}
        reference={3}
        state={contaminationThickness}
      />
      <ParameterRow
        title={'Depth to Groundwater'}
        symbol={`d`}
        unit={'m'}
        reference={3}
        state={groundwaterDepth}
      />
      <ParameterRow
        title={'Distance to Surface Water - Parallel to GW Flow'}
        symbol={`d`}
        unit={'x'}
        reference={10}
        state={surfaceWaterDistance}
      />
      <ParameterRow
        title={'Hydraulic Gradient'}
        symbol={`i`}
        unit={'m/m'}
        reference={0.028}
        state={hydraulicGradient}
      />
      <ParameterRow
        title={'Saturated Hydraulic Conductivity'}
        symbol={`K`}
        unit={'m/yr'}
        reference={grain ? 32 : 320}
        state={hydraulicConductivity}
      />
      <ParameterRow
        title={'Fraction of Organic Carbon'}
        symbol={createSymbol('f', 'oc')}
        unit={'mass/mass'}
        reference={0.005}
        state={organicCarbonFraction}
      />
      <ParameterRow
        title={'Soil Bulk Density'}
        symbol={createSymbol('ρ', 'B')}
        unit={createUnit('gr', undefined, 'cm', 3)}
        reference={grain ? 1.4 : 1.7}
        state={bulkDensity}
      />
      <ParameterRow
        title={'Soil Moisture-Filled Porosity'}
        symbol={createSymbol('θ', 'w')}
        unit={createUnit('cm', 3, 'cm', 3)}
        reference={grain ? 0.168 : 0.119}
        state={moisturePorosity}
      />
      <ParameterRow
        title={'Soil Vapour-Filled Porosity'}
        symbol={createSymbol('θ', 'a')}
        unit={createUnit('cm', 3, 'cm', 3)}
        reference={grain ? 0.302 : 0.241}
        state={vapourPorosity}
      />
      <ParameterRow
        title={'Depth from Surface to Bottom of Contamination'}
        symbol={createSymbol('Z', 'T')}
        unit={'m'}
        reference={3}
        state={contaminationDepth}
      />
    </div>
  </>
}

export default InputParameters
