import React from "react"
import GuidelineData from "./guidelineData";
import { useAdminGuidelinesAPI, useAdminGuidelinesContext } from "../../../contexts/adminGuidelines";
import InitialSelect from "./initialSelect";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const GuidelinesBody = () => {
  const { viewGuidelines, jurisdiction, matrix, filename } = useAdminGuidelinesContext()
  const { setViewGuidelines } = useAdminGuidelinesAPI()

  const showSelection = jurisdiction && matrix && viewGuidelines

  return <>
    <ToastContainer />
    <div className='flex justify-between items-center'>
      <h1>Guidelines {showSelection && <span>({jurisdiction.label} & {matrix.label})</span>}</h1>
      {viewGuidelines && <div className='flex gap-x-2 items-center'>
        {filename.length > 0 && <div className='mt-3'><span className='font-semibold'>Filename: </span><span>{filename}</span></div>}
        <button className='btn btn-primary w-32' onClick={() => setViewGuidelines(false)}>Back</button>
      </div>}
    </div>

    {viewGuidelines ? <GuidelineData /> : <InitialSelect />}
  </>
}

export default GuidelinesBody
