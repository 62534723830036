import React from "react";
import { useDataVaultContext, useDataVaultAPI } from "../../../contexts/dataVault";

const AnnotationControl = () => {
  const { showAnnotations } = useDataVaultContext()
  const { toggleAnnotations } = useDataVaultAPI()
  return (
    <div className='flex items-center'>
      <label htmlFor="show-popups-checkbox" className='text-xs text-black font-semibold tracking-tight ml-2'>Show Annotations: </label>
      <input
        className='ml-2 h-4 w-4 cursor-pointer'
        id="show-popups-checkbox"
        type="checkbox"
        checked={showAnnotations}
        onChange={toggleAnnotations}
      />
    </div>
  )
}

export default AnnotationControl
