import React, { useEffect, useState } from "react";
import Select from "react-select";
import axios from "axios";
import { useParameterSelectApi, useParameterSelectContext } from "../../contexts/parameterSelection";
import { formattedUnit, unitOptions } from "../../helpers/parameterSelection";

const ChemicalSelect = ({ column, chemicals, units, guidelineChemicals, hasTemplate }) => {
  const [options, setOptions] = useState([])
  const [selected, setSelected] = useState(null)
  const [input, setInput] = useState(null)
  const [unit, setUnit] = useState(null)
  const [guidelineUnit, setGuidelineUnit] = useState(null)
  const [isMatched, setIsMatched] = useState(true)
  const { changedParameters, unmappedParameters, analyticalMethods } = useParameterSelectContext()
  const { setParameterColumns, setSelectedUnits, setChangedParameters, removeChangedParameter, setChangedParameterUnits, removeUnmappedParameter } = useParameterSelectApi()

  const result = () => {
    switch (guidelineUnit) {
      case "":
        return '-'
      case null:
        return 'No Guideline'
      case 'mg/kg':
        return 'mg/kg or µg/g'
      case 'µg/g':
        return 'µg/g or mg/kg'
      default:
        return guidelineUnit
    }
  }

  useEffect(() => {
    const matchingChemicals = chemicals.filter(chemical => chemical.name === column[1] || chemical.aliases?.includes(column[1]))

    if (matchingChemicals.length > 0) {
      setSelected({ label: matchingChemicals[0].name, value: matchingChemicals[0].id })
      setParameterColumns(column[0], matchingChemicals[0].id)

      if (unmappedParameters?.includes(column[0])) {
        removeUnmappedParameter(column[0])
      }
    }

    const newUnit = formattedUnit(units[column[0]])

    const matchingUnit = unitOptions.find(unit => unit.value === newUnit)

    if (matchingUnit) {
      setUnit(matchingUnit)
      setSelectedUnits(column[0], newUnit)
    }
  }, [])

  useEffect(() => {
    if (input) {
      axios.get(`/chemicals/autocomplete_chemical_name?q=${input}`).then(res => {
        const formattedData = res.data.map(chemical => {
          return { label: chemical.name, value: chemical.id }
        })
        setOptions(formattedData)
      })
    }
  }, [input])
  
  useEffect(() => {
    if (selected) {
      const selectedGuidelineUnit = guidelineChemicals[selected?.label]
      
      if (selectedGuidelineUnit !== undefined) {
        setGuidelineUnit(selectedGuidelineUnit || '')
      } else {
        setGuidelineUnit(null)
      }
    } else {
      setGuidelineUnit(null)
    }
  }, [selected, guidelineChemicals])
  
  useEffect(() => {
    if (selected && guidelineUnit) {
      const normalizeMicrograms = unit => unit?.replace(/[µμ]/g, 'µ')
      const normalizedGuidelineUnit = normalizeMicrograms(guidelineUnit)
      const normalizedUnit = normalizeMicrograms(unit?.label)
      const matched = 
        (normalizedGuidelineUnit.toLowerCase() === 'µg/g' && normalizedUnit?.toLowerCase() === 'mg/kg') ||
        (normalizedGuidelineUnit.toLowerCase() === 'mg/kg' && normalizedUnit?.toLowerCase() === 'µg/g') ||
        (normalizedGuidelineUnit.toLowerCase() === normalizedUnit?.toLowerCase())
        
      matched ? setIsMatched(true) : setIsMatched(false)
    } else {
      setIsMatched(true)
    }
  }, [guidelineUnit, unit, selected])

  const onChangeHandler = (option) => {
    setChangedParameters(column[0], column[1], option)

    if (option) {
      setSelected(option)
      setParameterColumns(column[0], option.value)
    } else {
      setSelected(null)
      setParameterColumns(column[0], '')
    }
  }

  const onUnitChangeHandler = (option) => {
    setChangedParameterUnits(column[0], column[1], units[column[0]], option)

    if (option) {
      setUnit(option)
      setSelectedUnits(column[0], option.value)
    } else {
      setUnit(null)
      setSelectedUnits(column[0], '')
    }
  }

  useEffect(() => {
    if (changedParameters[column[0]]) {
      if (changedParameters[column[0]].newUnit == changedParameters[column[0]].originalUnit ) {
        if (changedParameters[column[0]].newParameter == changedParameters[column[0]].originalParameter || !changedParameters[column[0]].newParameter) {
          removeChangedParameter(column[0])
        }
      }
    }
  }, [changedParameters])

  return <>
    <tr className={`border-dashed border-t border-gray-400 ${!isMatched && 'bg-red-100'}`}>
      <td className='px-2 text-center'>{column[1]}</td>
      <td className='text-center'>{units[column[0]]}</td>
      <td className='text-center'>{analyticalMethods[column[0]]}</td>
      <td className='py-0.5'>
        <Select
          placeholder={'Start typing to select'}
          options={options}
          value={selected}
          isClearable={true}
          onInputChange={setInput}
          onChange={(option) => onChangeHandler(option)}
          maxMenuHeight={150}
        />
      </td>
      <td>
        <Select
          placeholder={'Units...'}
          options={unitOptions}
          value={unit}
          isClearable={true}
          onChange={(option) => onUnitChangeHandler(option)}
          maxMenuHeight={150}
        />
      </td>
      {hasTemplate && <td className='text-center'>{result()}</td>}
    </tr>
  </>
}

export default ChemicalSelect
