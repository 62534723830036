import React, { useState } from "react";
import PathwayRow from "./pathwayRow";

const ReceptorTable = ({ title, pathways, chemicals, unit }) => {
  let [show, setShow] = useState(true)

  const chemicalsList = chemicals.map((chemical) => {
    return (<h4 className='w-1/4 text-right'>{chemical}</h4>)
  })

  const pathwaysList = pathways.map((pathway, index) => {
    return(<PathwayRow key={index} pathway={pathway.title} values={pathway.values} unit={unit}/>)
  })

  return(<>
    <div className='px-2'>
      <div className='flex flex-row justify-between items-center font-semibold bg-gray-700 text-white font-semibold px-2 mt-2'>
        <h2>{title}</h2>
        <div
          className='text-2xl cursor-pointer'
          onClick={() => setShow(!show)}
        >{show ? <span>&uarr;</span> : <span>&darr;</span>}</div>
      </div>
      {show && <div className='text-sm'>
        <div className='flex flex-row font-semibold bg-blue-200 px-2 py-1'>
          <h4 className='w-3/12'>Pathway</h4>
          <h4 className='w-1/12'>Unit</h4>
          <div className='w-8/12 flex flex-row'>
            {chemicalsList}
          </div>
        </div>
        {pathwaysList}
      </div>}
    </div>
  </>)
}

export default ReceptorTable
