import React from "react";

const SiteName = ({ site, setSite }) => {
  return <>
    <div className='flex flex-col w-1/2 mt-4'>
      <label
        htmlFor={'name'}
      >
        Address/Legal Location/Site Name:
      </label>
      <input
        name={'name'}
        id={'name'}
        className={`px-2 my-2 border rounded-md border-gray-300 h-8`}
        value={site.siteIdentification.name}
        onChange={(event) => setSite((prevState) => ({...prevState, siteIdentification: {...prevState.siteIdentification, name: event.target.value}}))}
      />
    </div>
  </>
}

export default SiteName
