import React, { useState } from "react";
import MatrixDropdown from "./filterInputs/matrixDropdown";
import ParameterDropdown from "./filterInputs/parameterDropdown";
import DateSelectors from "./filterInputs/dateSelectors";
import DepthSelectors from "./filterInputs/depthSelectors";
import AreaDropdown from "./filterInputs/areaDropdown";
import ValueSelectors from "./filterInputs/valueSelectors";
import FilterPanel from "./filterPanel";
import { filterData } from "../../dataVault/filterHelpers/filterFunctions";
import { filterSaltPrints } from "../../saltPrints/filterFunctions";
import { filterPAHFingerprints } from "../../reports/pahFingerprinting/helpers/filterFunctions";
import { filterSamplesAndGeojson } from "../filters/filterFunctions";
import { usePahFingerprintingAPI } from "../../../contexts/pahFingerprinting";
import { formatGeojson } from "../../dataVault/filterHelpers/formatData";

const Filter = ({geoJSON, siteData, setFilteredGeoJSON, setFilteredSamples, chemicals = null, mapType, setGeoJSON, setFilteredChemical}) => {
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const [matrix, setMatrix] = useState("");
  const [area, setArea] = useState("");
  const [parameter, setParameter] = useState({});
  const [dateRange, setDateRange] = useState({start: "", end: ""});
  const [depthRange, setDepthRange] = useState({start: "", end: ""});
  const [valueRange, setValueRange] = useState({start: "", end: ""});

  const inDataVault = mapType === 'dataVault';
  const inSaltPrints = mapType === 'saltPrints';
  const inPAH = mapType === 'pah';
  const inDioxinsAndFurans = mapType === 'dioxinsAndFurans';
  const inPCB = mapType === 'pcb';
  const { onFilterData } = usePahFingerprintingAPI();

  const handleFilterClick = () => {
    setShowFilterOptions(!showFilterOptions);
  };

  const handleFilter = () => {
    if (inDataVault) {
      const { updatedGeoJSON, updatedSamples } = filterData(geoJSON, siteData, matrix, area, parameter, dateRange, depthRange, valueRange);
      setFilteredGeoJSON(formatGeojson(updatedGeoJSON, siteData.site_info));
      setFilteredSamples(updatedSamples);
    }

    if (inSaltPrints) {
      const { updatedGeoJSON, updatedSaltPrintSamples } = filterSaltPrints(siteData, geoJSON, matrix, parameter, area, dateRange, depthRange, valueRange)
      const hasSelectedParameter = Object.entries(parameter).length > 0;
      setGeoJSON(updatedGeoJSON);
      setFilteredSamples(updatedSaltPrintSamples);
      if (hasSelectedParameter) setFilteredChemical([parameter]);
    }

    if (inPAH) {
      const hasParameter = Object.entries(parameter).length > 0;
      const { updatedGeoJSON, updatedPAHSamples } = filterPAHFingerprints(siteData, geoJSON, matrix, parameter, area, dateRange, depthRange, valueRange);
      hasParameter ? onFilterData(updatedPAHSamples, updatedGeoJSON, parameter) : onFilterData(updatedPAHSamples, updatedGeoJSON);
    }

    if (inDioxinsAndFurans || inPCB) {
      const { updatedGeoJSON, updatedSamples } = filterSamplesAndGeojson(geoJSON, siteData, matrix, area, parameter, dateRange, depthRange, valueRange)
      const hasParameter = Object.entries(parameter).length > 0
      setFilteredGeoJSON(updatedGeoJSON)
      setFilteredSamples(updatedSamples)
      if (hasParameter) setFilteredChemical([parameter])
    }
  };

  const resetFilter = () => {
    if (inDataVault) {
      setFilteredGeoJSON(formatGeojson(geoJSON, siteData.site_info));
      setFilteredSamples(siteData);
    }

    if (inSaltPrints) {
      setFilteredChemical(null);
      setGeoJSON(geoJSON);
      setFilteredSamples(siteData);
    }

    if (inPAH) {
      onFilterData(null, null, null);
    }

    if (inDioxinsAndFurans || inPCB) {
      setFilteredGeoJSON(null)
      setFilteredSamples(null)
      setFilteredChemical(null)
    }

    setMatrix("");
    setArea("");
    setParameter({});
    setDateRange({start: "", end: ""});
    setDepthRange({start: "", end: ""});
    setValueRange({start: "", end: ""});
  };

  return (
    <>
      {showFilterOptions &&
        <div className="absolute top-0 z-30 w-full bg-gray-200">
          <FilterPanel handleFilterClick={handleFilterClick} showFilterOptions={showFilterOptions} mapType={mapType} setShowFilterOptions={setShowFilterOptions}/>
          <div className="flex justify-center">
            <MatrixDropdown matrix={matrix} setMatrix={setMatrix} siteData={siteData} />
            <ParameterDropdown parameter={parameter} setParameter={setParameter} siteData={siteData} chemicals={chemicals} />
            <AreaDropdown area={area} setArea={setArea} siteData={siteData} />
            <DateSelectors dateRange={dateRange} setDateRange={setDateRange} />
            <DepthSelectors depthRange={depthRange} setDepthRange={setDepthRange} matrix={matrix} />
            <ValueSelectors valueRange={valueRange} setValueRange={setValueRange} parameter={parameter} />
            <div className="flex justify-center items-center">
              <button className="btn btn-default btn-small" onClick={handleFilter}>Apply</button>
              <button className="btn btn-default btn-small" onClick={resetFilter}>Reset</button>
            </div>
          </div>
        </div>
      }
      <FilterPanel handleFilterClick={handleFilterClick} showFilterOptions={showFilterOptions} mapType={mapType}/>
    </>
  );
};

export default Filter;
