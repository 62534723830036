import React, { useEffect } from 'react'
import Filter from '../shared/filters/filter'
import { useDataVaultContext, useDataVaultAPI } from '../../contexts/dataVault'
import { formatGeojson } from "./filterHelpers/formatData";

const FilterContainer = ({}) => {
  const { geoJSON, siteData, mapType, filteredGeoJSON, filteredSamples, loading, highlightSampleIds } = useDataVaultContext()
  const { setDisplayedGeojson, setDisplayedSamples, setFilteredGeoJSON, setFilteredSamples } = useDataVaultAPI()

  useEffect(() => {
    const hasHightlightedSamples = highlightSampleIds.length > 0
    if (hasHightlightedSamples && !loading) {
      const baseGeojson = filteredGeoJSON || geoJSON
      const baseSample = filteredSamples?.samples || siteData?.samples
      const displaySamples = baseSample?.filter(sample => highlightSampleIds.includes(sample.id))
      const displayGeojson = {type: 'FeatureCollection', features:baseGeojson?.features?.filter(feature => highlightSampleIds.includes(feature.properties.sample_id)) }
      setDisplayedSamples(displaySamples)
      setDisplayedGeojson(formatGeojson(displayGeojson, siteData.site_info))
    } else {
      setDisplayedSamples(null)
      setDisplayedGeojson(null)
    }
  }, [loading, highlightSampleIds])

  return (
    <div className="absolute z-40 w-full">
      <Filter
        geoJSON={geoJSON}
        siteData={siteData}
        setFilteredGeoJSON={setFilteredGeoJSON}
        setFilteredSamples={setFilteredSamples}
        mapType={mapType}
      />
    </div>
  )
}

export default FilterContainer
