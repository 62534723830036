import mapboxgl from "mapbox-gl"

export const surfaceWaterLayerHandler = (map, showWaterLayer) => {
  const layers = ['surface-water-subset-0', 'surface-water-subset-1', 'surface-water-subset-2']
  if (showWaterLayer) {
    layers.forEach(layer => map.setLayoutProperty(layer, 'visibility', 'visible'))
  } else {
    layers.forEach(layer => map.setLayoutProperty(layer, 'visibility', 'none'))
  }
}

export const surficialGeologyLayerHandler = (map, showSurficialGeologyLayer) => {
  const layers = ['alberta-surficial-geology-b0ojv3', 'alberta-boundary-2e153n']
  if (showSurficialGeologyLayer) {
    layers.forEach(layer => map.setLayoutProperty(layer, 'visibility', 'visible'))
    
    map.on('click', 'alberta-surficial-geology-b0ojv3', (e) => {
      const hasPipeline = map.queryRenderedFeatures(e.point, { layers: ['aer-pipelines-layer-09kz6l'] }).length > 0
      if (hasPipeline) return
      
      const properties = e.features[0].properties
      new mapboxgl.Popup({closeOnMove: true})
        .setLngLat(e.lngLat)
        .setHTML(
          `<div class="max-h-28 overflow-auto">
            <p class="font-semibold text-xs"Surficial >Geology:</p>
            <p class="font-semibold text-sm text-center">${properties.GENETIC_GR}</p>
            <p class="text-xs"><span class="font-semibold">Age:</span> ${properties.AGE}</p>
            <p class="text-xs"><span class="font-semibold">Texture:</span> ${properties.TEXTURE}</p>
            <p class="text-xs"><span class="font-semibold">Description:</span> ${properties.UNIT_DESC}</p>
          </div>`
        )
        .addTo(map)
        
        map.setPaintProperty('alberta-surficial-geology-b0ojv3', 'fill-opacity', ['case', ['==', ['get', 'FID'], e.features[0].properties.FID], 0.5, 0.33])
    })
    
    map.on('mousemove', 'alberta-surficial-geology-b0ojv3', (e) => {
      map.getCanvas().style.cursor = 'pointer'
      map.setPaintProperty('alberta-surficial-geology-b0ojv3', 'fill-color', ['case', ['==', ['get', 'FID'], e.features[0].properties.FID], 'rgb(162, 232, 132)', 'rgb(105, 245, 46)'])
    })
    
    map.on('moveend', () => {
      map.setPaintProperty('alberta-surficial-geology-b0ojv3', 'fill-opacity', 0.33)
    })

  } else {
    layers.forEach(layer => map.setLayoutProperty(layer, 'visibility', 'none'))
    
    map.off('click', 'alberta-surficial-geology-b0ojv3')
    map.off('mouseenter', 'alberta-surficial-geology-b0ojv3')
    map.off('mouseleave', 'alberta-surficial-geology-b0ojv3')
  }
}

export const aerPipelineHandler = (map, showAerPipelineLayer) => {
  if (showAerPipelineLayer) {
    map.setLayoutProperty('aer-pipelines-layer-09kz6l', 'visibility', 'visible')
    
    map.on('click', 'aer-pipelines-layer-09kz6l', (e) => {
      const properties = e.features[0].properties
      const substances = [properties.SUBSTANCE1, properties.SUBSTANCE2, properties.SUBSTANCE3].filter(substance => substance)
      new mapboxgl.Popup({closeOnMove: true})
        .setLngLat(e.lngLat)
        .setHTML(
          `<div class="max-h-32 overflow-auto">
            <p class="font-semibold text-xs">Pipeline:</p>
            <p class="font-semibold text-sm text-center">${properties.COMP_NAME}</p>
            <p class="text-xs text-center">${properties.FROM_FAC} - ${properties.TO_FAC}</p>
            <p class="text-xs"><span class="font-semibold">License:</span> ${properties.LIC_LI_NO}</p>
            <p class="text-xs"><span class="font-semibold">Status:</span> ${properties.SEG_STATUS}</p>
            <p class="text-xs"><span class="font-semibold">Field Center:</span> ${properties.FLD_CTR_NM}</p>
            <p class="text-xs"><span class="font-semibold">Substance:</span> ${substances}</p>
            <p class="text-xs"><span class="font-semibold">Material:</span> ${properties.PIP_MATERL}</p>
            <p class="text-xs"><span class="font-semibold">Internal Coating:</span> ${properties.INT_COAT}</p>
            <p class="text-xs"><span class="font-semibold">Joint Method:</span> ${properties.JOINTMETHD}</p>
            <p class="text-xs"><span class="font-semibold">Wall Thickness:</span> ${properties.WALL_THICK} mm</p>
            <p class="text-xs"><span class="font-semibold">Diameter:</span> ${properties.OUT_DIAMET} mm</p>
            <p class="text-xs"><span class="font-semibold">Segment Length:</span> ${properties.SEG_LENGTH}</p>
            <p class="text-xs"><span class="font-semibold">Pipe:</span> ${properties.PIPE_TYPE} - ${properties.PIP_MATERL}</p>
          </div>`
        )
        .addTo(map)
    })
    
    map.on('mousemove', 'aer-pipelines-layer-09kz6l', (e) => {
      map.getCanvas().style.cursor = 'pointer'
      map.setPaintProperty('aer-pipelines-layer-09kz6l', 'line-width', ['case', ['==', ['get', 'OBJECTID'], e.features[0].properties.OBJECTID], 4, 1.5]);
    })
    
    map.on('moveend', () => {
      map.getCanvas().style.cursor = 'grab'
    })
    
  } else {
    map.setLayoutProperty('aer-pipelines-layer-09kz6l', 'visibility', 'none')
    
    map.off('click', 'aer-pipelines-layer-09kz6l')
    map.off('mouseenter', 'aer-pipelines-layer-09kz6l')
    map.off('mouseleave', 'aer-pipelines-layer-09kz6l')
  }
}
