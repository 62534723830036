import React from "react";

export const columns = [
  {
    Header: "Title",
    accessor: "title",
    Cell: ({ value, row }) => (
      <a href={`/sites/${row.original.id}`}>{value}</a>
    ),
  },
  {
    Header: "LSD",
    accessor: "lsd",
    Cell: ({ value }) => <div className="text-center">{value || "-"}</div>,
  },
  {
    Header: "Created at",
    accessor: "created_at",
    Cell: ({ value }) => {
      const formattedDate = new Date(value).toLocaleDateString(undefined, {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      });
      return <div className="text-center">{formattedDate}</div>;
    },
    className: "text-red-500",
  },
  {
    Header: "Actions",
    accessor: "id",
    Cell: ({ value }) => (
      <div className="flex justify-center items-center">
        <a href={`/sites/${value}/edit`}>
          <button className="btn-action primary mr-1"><i className="fa fa-edit"/> Edit</button>
        </a>
        <a href={`/sites/${value}`}>
          <button className="btn-action primary mr-1"><i className="fa fa-eye"/> View</button>
        </a>
        <a href={`/sites/${value}`} data-method="delete" data-confirm="Are you sure?">
          <button className="btn-action delete"><i className="fa fa-trash-alt text-red-500"/> Delete</button>
        </a>
      </div>
    ),
  },
];
