import fixedGridData from "../../doubleRatioPlot/fixedGridData"

const buildDoubleRatioPlot = (data) => {
  return {
    title: {
      text: 'Double Ratio Chart',
      style: {
        fontSize: '15px',
      },
    },
    chart: {
      type: 'scatter',
      height: '460',
      width: '730',
    },
    legend: {
      enabled: true,
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'middle',
      symbolWidth: 4,
      borderColor: 'white',
      itemStyle: {
        fontSize: '8px',
      },
      itemWidth: 180,
      maxHeight: 450,
    },
    xAxis: {
      title: {
        text: 'FLRN/PYR'
      },
      max: 2,
      min: 0,
      gridLineWidth: 1,
      tickInterval: 0.1,
      lineWidth: 0,
    },
    yAxis: {
      title: {
        text: 'IcdP/BghiP'
      },
      max: 2,
      min: 0,
      gridLineWidth: 1,
      tickInterval: 0.1,
      lineWidth: 0,
    },
    series: [
      ...fixedGridData(),
      ...data,
    ],
    colors: [
      "#4E79A7", "#76B7B2", "#E15759", "#F28E2B", 
      "#59A14F", "#EDC948", "#B07AA1", "#FF9DA7", 
      "#9C755F", "#BAB0AB", "#b35504", "#000000",
      "#90ee90",
    ],
    tooltip: {
      formatter: function() {
        return `${this.series.name} : (${this.x}, ${this.y})`
      }
    },
    credits: {
      enabled: true,
      text: 'Powered by Statvis',
      href: 'https://www.statvis.com/'
    }
  }
}

export default buildDoubleRatioPlot
