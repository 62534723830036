import React from 'react'
import Select from "react-select";
import { useSiteAPI, useSiteContext } from "../../../contexts/site";

const SubsoilUsage = () => {
  const { usageOptions, subsoilUsage } = useSiteContext()
  const { setSubsoilUsage } = useSiteAPI()

  return (
    <div className='w-1/4'>
    <label className='font-semibold'>Subsoil Land Use</label>
    <Select
      placeholder={'Subsoil Land Use'}
      options={usageOptions}
      value={subsoilUsage}
      onChange={selectedOption => setSubsoilUsage(selectedOption)}
    />
    </div>
  )
}

export default SubsoilUsage
