import React, { useState } from 'react'

const CoeluteRow = ({ chemical, value }) => {
  const [hovered, setHovered] = useState(false)
  const handleMouseEnter = () => setHovered(true)
  const handleMouseLeave = () => setHovered(false)
  const maxWidth = hovered ? '100%' : '180px'

  return (
    <tr key={chemical} className='text-xs font-semibold uppercase border border-dashed border-gray-200'>
      <td className='sticky left-0 bg-white' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <div className={`mx-1 block truncate`} style={{ maxWidth: maxWidth }}>{chemical}</div>
      </td>
      <td key={chemical} className='text-xs text-center'>{value.toPrecision(3)}</td>
    </tr>
  )
}

export default CoeluteRow
