import React, { useState } from "react"
import { useCookies } from "react-cookie"
import { TierTwoProvider } from "./tierTwoContext"
import Input from "./input"
import DisclaimerModal from "../../shared/modal/DisclaimerModal"
import RenderModal from "../../shared/modal/RenderModal"

const TierTwo = () => {
  let [showStartPage, setShowStartPage] = useState(false)
  const [isOpen, setIsOpen] = useState(true)
  const [cookies, setCookie] = useCookies(['guideline-terms-and-conditions'])

  const handleClick = () => {
    setShowStartPage(false)
  }

  return <>
    <TierTwoProvider>
      {showStartPage ? <div className='ml-4 mt-72'>
        <h1 className='mb-4'>Tier 2 Guideline Calculator</h1>
        <button 
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold p-2 border border-blue-700 rounded w-32"
          onClick={handleClick}
        >
          Get Started
        </button>
      </div> : <Input />}
    </TierTwoProvider>
    {
      !cookies.completed && <RenderModal selector="#modal-root">
        <DisclaimerModal isOpen={isOpen} setIsOpen={setIsOpen} onConfirm={() => setCookie('completed', true)} />
      </RenderModal>
    }

  </>
}

export default TierTwo
