import React, { useState } from "react"
import UploadErrorRow from "./uploadErrorRow";

const UploadInfo = ({ upload }) => {
  const [showErrorRows, setShowErrorRows] = useState(false);

  const uploadSuccess = upload.row_count.imported === upload.row_count.total

  const errorRows = upload.error_rows.map((errorRow, index) => {
    return <UploadErrorRow key={errorRow.row + index} errorRow={errorRow} />
  })

  const hasErrors = errorRows.length > 0

  return(
    <>
      <div className='flex flex-row items-end gap-x-4'>
        <div className='flex flex-col mt-4 mb-2 text-sm'>
          <div className='flex flex-col'>
            <div className='flex flex-row items-center gap-x-2'>
              <p className='text-base font-semibold'>{upload.name}</p>
              {uploadSuccess ?
                <div className='text-green-500 flex flex-row gap-x-2 items-baseline'>
                <i className='fa fa-check-circle' />
                <p>Upload successful!</p>
                </div>
                :
                <i className='text-red-500 fa fa-exclamation-triangle' />
              }
            </div>
            <p>Uploaded at: {upload.upload_date}</p>
          </div>
          <div className='flex flex-row gap-x-5'>
            <p>Total Rows: {upload.row_count.total}</p>
            <p>Imported: {upload.row_count.imported}</p>
            <p className={`${hasErrors && 'text-red-500'}`}>Not Imported: {upload.row_count.not_imported}</p>
          </div>
        </div>
        {hasErrors && <button
          className='h-6 w-24 text-xs bg-red-500 hover:bg-red-700 active:bg-red-700 focus:outline-none focus:ring focus:ring-red-300 text-white py-1 px-2 rounded mb-2'
          onClick={() => setShowErrorRows(!showErrorRows)}
        >
          {hasErrors && showErrorRows ? 'Close' : 'View Errors'}
        </button>}
      </div>
      {showErrorRows && <table className='text-sm'>
        <thead>
        <tr>
          <th>Row</th>
          <th className='px-3 text-left'>Errors</th>
        </tr>
        </thead>
        <tbody>
          {errorRows}
        </tbody>
      </table>}
    </>
  )
}

export default UploadInfo
