import React from 'react'
import { resultTextColor } from '../helpers/analysisHelper'

const AnalysisRow = ({ ratioName, ratio }) => {
  return (
    <tr className='text-xs text-left'>
      <td className='w-1/2'>{ratioName}</td>
      <td className={`w-1/4 font-semibold ${resultTextColor(ratio.result)}`}>{ratio.value || 'N/A'}</td>
      <td className={`w-1/4 font-semibold ${resultTextColor(ratio.result)}`}>
        {ratio.result}
      </td>
    </tr>
  )
}

export default AnalysisRow
