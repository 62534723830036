import React from 'react'
import { useDioxinsAndFuransContext, useDioxinsAndFuransAPI } from '../../contexts/dioxinsAndFurans'

const ExportButton = ({sampleId, setShowOption = null}) => {
  const { sampleIdsForExport } = useDioxinsAndFuransContext()
  const { setSampleIdsForExport } = useDioxinsAndFuransAPI()
  const isSelected = sampleIdsForExport?.includes(sampleId)
  const buttonLabel = isSelected ? 'Remove from export' : 'Add to export'
  const buttonColor = isSelected ? 'bg-red-500 hover:bg-red-600' : 'bg-green-500 hover:bg-green-600'

  const addToExport = () => {
    setSampleIdsForExport([...sampleIdsForExport, sampleId])
    if (setShowOption) setShowOption(false)
  }

  const removeFromExport = () => {
    const updatedSampleIdsForExport = sampleIdsForExport.filter(id => id !== sampleId)
    setSampleIdsForExport(updatedSampleIdsForExport)
    if (setShowOption) setShowOption(false)
  }

  const handleExport = () => isSelected ? removeFromExport() : addToExport()

  return (
    <button className={`text-xs ${buttonColor} text-white rounded px-2 py-1 cursor-pointer z-0`} onClick={(handleExport)}>{buttonLabel}</button>
  )
}

export default ExportButton
