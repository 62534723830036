import React from 'react';
import { ParameterSelectionProvider } from "../../../contexts/parameterSelection";
import ParameterSelectionBody from "./parameterSelectionBody"

const ParameterSelection = ({ columnNames, chemicals, units, samples, duplicatedSamplesCSV, site, deleteRoute, headers, matrix, analytical_methods, csvEmptyRows, siteUpload }) => {
  return <>
    <ParameterSelectionProvider columnNames={columnNames} units={units} headers={headers} matrix={matrix} chemicals={chemicals} analyticalMethods={analytical_methods} site={site} deleteRoute={deleteRoute} siteUpload={siteUpload}>
      <ParameterSelectionBody samples={samples} duplicatedSamplesCSV={duplicatedSamplesCSV} csvEmptyRows={csvEmptyRows}/>
    </ParameterSelectionProvider>
  </>
}

export default ParameterSelection
