import React from "react"
import { useDioxinsAndFuransContext } from "../../contexts/dioxinsAndFurans"

const colorSelector = (exceedanceLevel) => {
  switch (exceedanceLevel) {
    case 0:
      return 'exceedance-green'
    case 2:
      return 'exceedance-gray'
    case 3:
      return 'exceedance-yellow'
    case 10:
      return 'exceedance-orange'
    case 11:
      return 'exceedance-red'
    default:
      return 'exceedance-green'
}}

const NoCoordSamplePopup = ({matchedReference}) => {  
  const { highlightSampleIds, selectedSample, geojson, selectedSampleExceedanceInfo } = useDioxinsAndFuransContext()
  const sampleNoCoordinates = !geojson.features.find(feature => feature.properties.sample_id === highlightSampleIds[0])
  const noHighlightedSample = highlightSampleIds.length > 0
  const isSoilBased = selectedSample.matrix.id === 1 || selectedSample.matrix.id === 2
  const exceedanceTitle = isSoilBased ? 'Soil Exceedances' : 'Groundwater Exceedances'
  const exceedanceChemicals = selectedSampleExceedanceInfo?.chemical_exceedances.join(', ')
  const color = colorSelector(selectedSampleExceedanceInfo?.highest_exceedance)

  return (
    sampleNoCoordinates && noHighlightedSample &&
      <div className={`absolute flex flex-col bg-white mr-1.5 top-0 right-0 z-20 p-1.5 mt-1 rounded-md text-xs`}>
        <p className="text-gray-500 text-center">Sample has no coordinates</p>
        <div className={`font-bold text-center border rounded bg-${color}`}>{selectedSample.sample_location.name}</div>
        <p className='font-bold'>Top Reference Standard:</p>
        <p>{matchedReference.name}</p>
        <p><span className='font-bold'>Sample Similarity: </span><span>{matchedReference.similarity}</span></p>
        <p><span className='font-bold'>Depth:</span> <span>{selectedSample.sample_depth}</span></p>
        <p><span className='font-bold'>Sample Date: </span><span>{selectedSample.sample_date}</span></p>
        <p><span className='font-bold'>{exceedanceTitle}: </span>{exceedanceChemicals || 'N/A'}</p>
      </div>
  )
}

export default NoCoordSamplePopup
