import React from 'react'
import { usePahFingerprintingAPI, usePahFingerprinting } from "../../../../contexts/pahFingerprinting"


const ModeToggle = () => {
  const { mode, headerTab } = usePahFingerprinting()
  const { toggleMode } = usePahFingerprintingAPI()
  const inCompare = headerTab === 2
  const isAlkylated = mode === 'Alkylated'
  const toggleSwitch = () => {
    toggleMode(mode === 'Parent' ? 'Alkylated' : 'Parent')
  }
  const message = inCompare ? 'Click to compare Parent or Alkylated PAH charts.' : 'Fingerprint only shows Parent PAHs.'
  return (
    <>
      {inCompare && 
        <>
          <p className='font-semibold'>Analysis Type</p>
          <input
            className="hidden"
            id={`toggle-switch`}
            type="checkbox"
            checked={isAlkylated}
            onChange={toggleSwitch}
          />
          <label
            className={`flex items-center cursor-pointer w-28 h-6 bg-gray-400 rounded-full relative transition duration-200 ${
              mode === 'Alkylated' ? 'bg-green-400' : 'bg-blue-300'
            }`}
            htmlFor={`toggle-switch`}
          >
            <span className={`${isAlkylated ? 'ml-3' : 'ml-9'} text-gray-700 font-semibold uppercase text-xs`}>
              {mode}
            </span>
            <span
              className={`absolute ${
                isAlkylated ? 'right-1' : 'left-1'
              } w-5 h-5 bg-white rounded-full shadow-md transition duration-200`}
            />
          </label>
        </>
      }
      <p className='text-xs mb-1'>{message}</p>
    </>
  )
}

export default ModeToggle
