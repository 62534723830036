import axios from 'axios';
import { toast } from "react-toastify";

export const deleteAttachment = (attachment, attachments, setAttachments, setSampleLocation) => {
  const confirm = window.confirm('Are you sure you want to delete this attachment?');
  if (!confirm) return;
  
  axios.delete(`/sites/${attachment.site_id}/site_attachments/${attachment.id}`)
    .then((response) => {
      toast.success('Attachment was successfully deleted.');
      setSampleLocation("");
      setAttachments(response.data);
    })
    .catch((error) => console.log(error));
};

export const downloadAttachment = (attachment) => {
  axios.get(`/sites/${attachment.site_id}/site_attachments/${attachment.id}`)
    .then((response) => {
      const filename = response.headers['content-disposition'].split("filename*=UTF-8''")[1];
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch((err) => console.log(err));
};

export const downloadCSV = (upload, site, controller) => {
  axios.get(`/sites/${site.id}/${controller}/${upload.id}/data`)
    .then((response) => {
      const filename = response.headers['content-disposition'].split("filename*=UTF-8''")[1];
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch((err) => console.log(err));
};

export const deleteUpload = (upload, site, controller, uploads, setUploads) => {
  const message = controller === 'location_uploads' ? 
    'Are you sure you want to delete this upload? This action will delete all coordinates of the associated sample locations!' 
    : 
    'Are you sure you want to delete this upload? This action will delete all associated samples!';

  const confirm = window.confirm(message);
  if (!confirm) return;
  
  axios.delete(`/sites/${site.id}/${controller}/${upload.id}`)
    .then((_response) => {
      toast.success('Upload deleted!');
      setUploads(uploads.filter((u) => u.id !== upload.id));
      window.location.reload();
    })
    .catch((error) => {
      console.log(error);
    });
};
