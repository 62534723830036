import React from 'react'
import { usePahFingerprinting, usePahFingerprintingAPI } from "../../../../../contexts/pahFingerprinting"

const ExportButton = ({ selectedSample }) => {
  const { selectedSamples, sourceReferences } = usePahFingerprinting()
  const { onSampleSelect } = usePahFingerprintingAPI()
  const inExport = selectedSamples.some(sample => sample?.id === selectedSample?.id)
  const buttonLabel = inExport ? 'Remove from export' : 'Add to export'
  const buttonColor = inExport ? 'bg-red-500 hover:bg-red-600' : 'bg-green-500 hover:bg-green-600'

  const addToExport = () => inExport ? onSampleSelect(selectedSamples.filter(sample => sample?.id !== selectedSample?.id)) : onSampleSelect([...selectedSamples, selectedSample])
  
  return (
    sourceReferences && 
      <button className={`text-xs ${buttonColor} text-white rounded px-2 py-1 cursor-pointer z-0`} onClick={addToExport}>
        {buttonLabel}
      </button>
  )
}

export default ExportButton
