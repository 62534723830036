import React from 'react'
import { usePahFingerprinting } from '../../../../contexts/pahFingerprinting'

const HelpLabels = ({label, direction}) => {
  const { showHelperTexts } = usePahFingerprinting()
  const arrowClass = direction === "left" ? "fa-arrow-left ml-3" : "fa-arrow-right mr-3";
  const margin = direction === "left" ? "ml-3" : "mr-3";
  
  return (
    showHelperTexts && (
      <div className='flex'>
        {direction === "left" && (
          <i className={`fa ${arrowClass} text-xs text-black animate-bounce-sideways`} />
        )}
        <p className={`text-xs text-gray-800 font-semibold ${margin} bg-blue-200 px-1 rounded border border-black`}>{label}</p>
        {direction === "right" && (
          <i className={`fa ${arrowClass} text-xs text-black animate-bounce-sideways`} />
        )}
      </div>
    )
  )
}

export default HelpLabels
