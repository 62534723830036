import React from "react";
import { formatTitle } from "../../helpers";

const SiteIdentificationLabel = ({ field, row}) => {
  return (
    <label
      htmlFor={field}
      className={`my-4 col-start-1 row-start-${row} capitalize`}
    >
      {formatTitle(field)}:
    </label>
  )
}

export default SiteIdentificationLabel
