import React, { useState } from 'react';
import albertaMap from "./albertMap.png"
import MandatoryGuidelines from './forms/mandatoryGuidelines';
import Contaminants from './forms/contaminants';
import SiteDetails from './forms/siteDetails';
import GeneralSiteInformation from "./forms/generalSiteInformation";

const Questions = ({ setShowAnswers }) => {
  let [step, setStep] = useState(1);

  const stepCount = 4
  const progressPercentage = step/stepCount * 100

  return (
    <>
    <div className='container ml-4'>
      <div className='mb-4'>
        <h1>Tier 1 & 2 Guideline Calculator</h1>
      </div>
      <div className="relative pt-1 w-11/12">
        <div className="flex mb-2 items-center justify-between">
          <div>
            <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blue-600 bg-blue-200">
              Step {step}
            </span>
          </div>
          <div className="text-right">
            <span className="text-xs font-semibold inline-block text-blue-600">
              {step}/{stepCount}
            </span>
          </div>
        </div>
        <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-blue-200">
          <div style={{ width: `${progressPercentage}%`}} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-500"></div>
        </div>
      </div>
      <div className="flex flex-row mt-12">
        <div className="w-1/2">
          <form 
          >
            {step === 1 && (
              <GeneralSiteInformation step={step} setStep={setStep} />
            )}
            {step === 2 && (
              <SiteDetails step={step} setStep={setStep} />
            )}
            {step === 3 && (
              <MandatoryGuidelines step={step} setStep={setStep} setShowAnswers={setShowAnswers} />
            )}
            {step === 4 && (
              <Contaminants step={step} setStep={setStep} setShowAnswers={setShowAnswers} />
            )}
          </form>
        </div>
        <div className="w-1/2">
          <img src={albertaMap} alt='Data Summary Legend' className='w-3/4 ml-4' />
        </div>
      </div>
    </div>
    </>
  )
}

export default Questions
