import React from 'react'

const TableRow = ({ row, prepareRow }) => {
  prepareRow(row);
  return (
    <tr {...row.getRowProps()}>
      {row.cells.map(cell => (
        <td className="text-sm" {...cell.getCellProps()}>
          {cell.render('Cell')}
        </td>
      ))}
    </tr>
  );
};

export default TableRow;
