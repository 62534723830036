import React from 'react'

export const LegendList = ({legendList, inDataVault}) => {
  const legendPosition = inDataVault ? 'top-9' : 'top-2'
  return (
    <div className={`bg-gray-100 border-black p-1 text-center flex items-center absolute left-2 rounded-md justify-center z-20 w-auto ${legendPosition}`}>
      <ul className={`flex flex-col`}>
        {legendList}
      </ul>
    </div>
  )
}

export default LegendList
