import React from 'react'
import { useDioxinsAndFuransContext, useDioxinsAndFuransAPI } from '../../contexts/dioxinsAndFurans'

const ExportSampleButton = () => {
  const { sampleIdsForExport } = useDioxinsAndFuransContext()
  const { setShowExportModal } = useDioxinsAndFuransAPI()

  return (
    <button className='flex items-center justify-center border border-black rounded h-7 p-1 m-1 text-xs font-semibold uppercase' onClick={() => setShowExportModal(true)}>
      Export {sampleIdsForExport.length} Samples
    </button>
  )
}

export default ExportSampleButton
