import React from "react";
import BaseMapContainer from "./baseMapContainer";
import Table from "./table";
import FilterContainer from "./filterContainer";
import SampleCharts from "./sampleCharts";

const HomePage = () => {  
  return (
    <>
     <FilterContainer/>
     <div className="flex h-3/5">
        <div className={`relative w-9/12`}>
          <BaseMapContainer/>
        </div>
        <div className="w-3/12">
          <Table/>
        </div>
      </div>
      <div className="h-2/5">
        <SampleCharts/>
      </div>
    </>
  )
}

export default HomePage
