import React from "react";
import SampleRows from "./sampleRows";

const SamplesTable = ({ saltSamples, chemicals, isDisabled, typeOptions, setSaltSamples}) => {
  const chemicalHeaders = chemicals?.map((chemical) => {
    return <th key={chemical.id}>{chemical.name}</th>
  });
  
  const onSampleTypeChangeHandler = (sample, option) => {
    const updatedSamples = saltSamples.map((saltSample) => {
      if (saltSample.id === sample) return {...saltSample, type: option.value}
      return saltSample
    })
    setSaltSamples(updatedSamples)
  };
  
  const sampleRows = saltSamples?.map((sample) => {
    return <SampleRows key={sample.id} sample={sample} chemicals={chemicals} isDisabled={isDisabled} typeOptions={typeOptions} onSampleTypeChangeHandler={onSampleTypeChangeHandler}/>
  });
  
  return (
    <>
      <h2 className="sv-label mt-2">Map by Samples:</h2>
      <div className="relative overflow-y-auto border rounded-lg h-5/6">
        <table className="table">
          <thead>
            <tr className='sticky top-0 z-10'>
              <th>Name</th>
              <th>Sample Group</th>
              <th>Date</th>
              <th>Depth</th>
              {chemicalHeaders}
              <th>Type</th>
            </tr>
          </thead>
          <tbody>{sampleRows}</tbody>
        </table>
      </div>
    </>
  );
};

export default SamplesTable;
