import React, {useState} from 'react';
import ModalWrapper from '../../shared/modal/modalWrapper';

const OverwriteConfirmationModal = ({samples, abortUpload}) => {
  const [showModal, setShowModal] = useState(true)
  const columns = Math.ceil(samples.length / 10);
  const closeModal = () => setShowModal(false);
  const sampleList = samples.map((sample) => <p key={sample.id}>{sample.sample_location.name}</p>);
  const referrer = document.referrer;
  const backHandler = () => window.location.href = referrer;
  const abortHandler = () => referrer.includes('preview') ? abortUpload() : backHandler();
  
  return (
    <>
      {showModal && 
        <ModalWrapper isOpen={showModal}>
          <div className='p-5'>
            <div className='flex flex-col justify-center items-center'>
              <h1 className='text-xl font-semibold text-center'>Confirmation Required</h1>
              <p className='mb-1'>You are about to overwrite the following samples:</p>
              <div className={`grid grid-cols-${columns} gap-4 m-1 overflow-y-auto max-h-36`}>
                {sampleList}
              </div>
              <p className='m-1'>Overwriting the samples will permanently delete the existing samples and replace them with the new ones.</p>
              <div className='flex justify-center items-center'>
                <button className='btn btn-primary' onClick={closeModal}>Continue Upload</button>
                <button className='btn btn-default' onClick={abortHandler}>Abort Upload</button>
              </div>
            </div>
          </div>
        </ModalWrapper>
      }
    </>
  );
};

export default OverwriteConfirmationModal
