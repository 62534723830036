import React from "react";
import { components } from "react-select";

const Option = props => {
  return (
    <div
      className={`${props.data.backgroundColor} ${props.data.pointer}`}
    >
      <components.Option {...props} />
    </div>
  );
};

export default Option
