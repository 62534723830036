import React from 'react'
import { usePahFingerprintingAPI } from '../../../../contexts/pahFingerprinting'

const AnnotationToggle = ({ mode }) => {
  const { setAnnotationMode, setSelectedSampleAnnotation, setSelectedSampleAnnotationLog } = usePahFingerprintingAPI()
  
  const annotationOn = () => {
    setAnnotationMode(true)
  }
  
  const annotationOff = () => {
    setSelectedSampleAnnotation([])
    setSelectedSampleAnnotationLog([])
    setAnnotationMode(false)
  }
  
  return (
    <div className='flex'>
      <button 
        className={`flex items-center justify-center cursor-pointer border border-black w-1/2 text-xs font-semibold ${mode == 'chart' ? 'bg-blue-100' : 'bg-gray-100'} rounded-l-md`} 
        title="Annotate" 
        onClick={annotationOn}
      >
        <p>Display Double Ratio Chart</p>
      </button>
      <button 
        className={`flex items-center justify-center cursor-pointer border border-black w-1/2 text-xs font-semibold ${mode == 'table' ? 'bg-blue-100' : 'bg-gray-100'} rounded-r-md`} 
        title="Data Table" 
        onClick={annotationOff}
      >
        <p>Display Data Table</p>
      </button>
    </div>
  )
}

export default AnnotationToggle
