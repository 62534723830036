import React, { useState, useEffect } from "react";
import axios from "axios";
import SaltPrintSamples from "./saltPrintSamples";
import Table from "./table";
import FilterContainer from "./filterContainer";
import BaseMapContainer from "./baseMapContainer";
import ChartToggle from "../shared/chartToggle";
import DisplayChartButton from "./displayChartButton";
import { SaltPrintProvider } from "../../contexts/saltPrint";

const Index = ({ site, saltPrint, chemicals }) => {
  const initialGeoJSON = saltPrint.visualization_data;
  const [saltPrintSamples, setSaltPrintSamples] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showChart, setShowChart] = useState(true);
  
  const seeChartHandler = () => {
    window.open(`/sites/${site.id}/salt_prints/${saltPrint.id}/print`, "_blank");
  };
  
  useEffect(() => {
    axios.get(`/sites/${site.id}/salt_prints/${saltPrint.id}/salt_print_samples.json`)
      .then((res) => {
        setLoading(false);
        setSaltPrintSamples(res.data)
      });
  }, []);
  
  return (
    <div className='relative flex flex-col h-full'>
      {loading ? (
        <div className="flex items-center justify-center h-full">
          <span className="text-3xl text-gray-400 font-semibold">
            <i className="fa fa-spinner fa-spin" /> Loading Salt Prints...
          </span>
        </div>
      ) : (
        <>
        <SaltPrintProvider initialData={{initialGeoJSON, saltPrintSamples, saltPrint, chemicals, site}}>
          <FilterContainer/>
          <div className="h-map flex">
            <div className={`relative h-map ${showChart ? 'w-1/3':'w-full'}`}>
              <BaseMapContainer showChart={showChart}/>
              <ChartToggle showChart={showChart} setShowChart={setShowChart}/>
            </div>
            {showChart && 
              <div className="w-2/3 h-map overflow-hidden">
                <div className="h-full overflow-y-auto">
                  <DisplayChartButton saltPrint={saltPrint} seeChartHandler={seeChartHandler}/>
                  <SaltPrintSamples/>
                </div>
              </div>
            }
          </div>
          <div className="h-1/2 overflow-auto">
            <div className="h-full">
              <Table/>
            </div>
          </div>
        </SaltPrintProvider>
        </>
      )}
    </div>
  );
};

export default Index;
