import React, { useEffect, useState } from 'react'
import Select from "react-select";
import axios from "axios";

const MatrixSelect = ({ setMatrix }) => {
  const [options, setOptions] = useState([])

  useEffect(() => {
    axios.get(`/matrices.json`)
      .then(res => {
        const formattedOptions = res.data.map(matrix => {
          return { label: matrix.title, value: matrix.id }
        })
        setOptions(formattedOptions)
      })
  }, [])

  return <>
    <label>Matrix</label>
    <Select
      className='w-full'
      options={options}
      onChange={setMatrix}
    />
  </>
}

export default MatrixSelect
