import React, { memo } from "react"
import { Line } from "react-chartjs-2"
import NoData from "../shared/noData"
import "chartjs-adapter-moment"
import { useGroundwaterContext, useGroundwaterAPI } from "../../contexts/groundwater"
import { allDataWithinYear } from "./chartHelpers/chartFunctions"

const LineChart = () => {
  const { highlightedChartData, chart, highlightSampleIds } = useGroundwaterContext()
  const { setHighlightSampleIds } = useGroundwaterAPI()
  const displayedChartData = highlightedChartData.datasets ? highlightedChartData : chart
  const hasData = displayedChartData?.datasets.length > 0

  const emphasizeData = (dataset) => {
    const isActive = dataset.data.some((data => highlightSampleIds.includes(data.sample_id)))
    const radius = isActive ? 5 : 3
    const borderWidth = isActive ? 2 : 1
  return { ...dataset, radius: radius, borderWidth: borderWidth }
  }

  const highlightData = {
    datasets: displayedChartData?.datasets.map(emphasizeData),
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: "time",
        time: { unit: allDataWithinYear(displayedChartData.datasets) ? 'month' : 'year' },
      },
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: function (context) {
            const formattedDate = new Date(context[0].parsed.x).toISOString().split("T")[0]
            return formattedDate
          },
        },
      },
    },
    onClick: (_, elements) => {
      const elementIds = []
      if (elements.length > 0) {
        elements.forEach((element) => {
          const target = displayedChartData.datasets[element.datasetIndex]
          target.data.forEach((data) => {
            elementIds.push(data.sample_id)
          })
        })
      }
    
      if (highlightSampleIds.toString() === elementIds.toString()) {
        setHighlightSampleIds([])
      } else {
        setHighlightSampleIds(elementIds)
      }
    }
  }

  return (
    <div className="h-full">
      { hasData ? <Line data={highlightData} options={options} /> :  <NoData message={'No Data to Display'}/>}
    </div>
  )
}

export default memo(LineChart)
