import React from "react"
import LineChart from "./lineChart"
import BaseMapContainer from "./baseMapContainer"
import Filters from "./filters"
import NoData from "../shared/noData"
import Table from "./table"
import { GroundwaterProvider } from "../../contexts/groundwater"
import { applyColor } from "./helpers"

const Index = ({ chartData, geoJSON, mapType, parameters, chemicalId, locationIds, siteId }) => {
  const initialParameter = parameters?.find((parameter) => parameter.id === chemicalId)
  const initializedChartData = applyColor(chartData)
  
  return (
    <GroundwaterProvider initialData={{ initialParameter, siteId, locationIds, initializedChartData, geoJSON }}>
      <div className="relative flex flex-col h-full">
        {initializedChartData?.datasets ? (
          <>
            <Filters parameters={parameters} />
            <div className="flex">
              <div className="w-2/5">
                <BaseMapContainer geoJSON={geoJSON} mapType={mapType} />
              </div>
              <div className="w-3/5 ml-2">
                <LineChart />
              </div>
            </div>
            <Table />
          </>
        ) : (
          <NoData message={'No Water Data Available'} />
        )}
      </div>
    </GroundwaterProvider>
  )
}

export default Index
