import axios from "axios";
import { Radar } from "react-chartjs-2";
import React, { useEffect, useState } from "react";
import Select from "react-select";

const SaltPrintSample = ({ site, saltPrintSample, saltPrint, deleteSaltPrintSample, setHighlightSampleIds, highlight }) => {
  const [type, setType] = useState(null)
  
  let options = { scale: { ticks: { beginAtZero: true, min: 0, max: 60, stepSize: 10 } }, }
  const selectOptions = [{ label: 'Target', value: 'Target' }, { label: 'Background', value: 'Background' }]
  const highlighted = highlight ? 'border-8 border-blue-500 rounded-lg' : '';

  useEffect(() => {
    if (saltPrintSample) {
      const filteredType = selectOptions.filter(option => option.value == saltPrintSample.type )
      setType(filteredType[0])
    }
  }, [saltPrintSample])

  const updateSaltPrintSample = (value) => {
    axios.put(`/sites/${site.id}/salt_prints/${saltPrint.id}/salt_print_samples/${saltPrintSample.id}`, { salt_print_sample: { type: value } })
      .then(res => {
        const filteredType = selectOptions.filter(option => option.value == res.data.type )
        setType(filteredType[0])
      })
      .catch(err => console.log(err))
  }

  const handleClick = () => {
    if (highlight) {
      setHighlightSampleIds([])
      return
    }
    setHighlightSampleIds([saltPrintSample.id])
  };
  

  return (
    <div id={`sp-${saltPrintSample.id}`} className={`p-3 mb-1 hover:bg-blue-100 ${highlighted}`} style={{ scrollMarginTop: "3rem" }}>
      <div className='flex flex-col justify-center items-center' onClick={handleClick}>
        <h4 className='font-bold'>{saltPrintSample.sample.sample_location.name}</h4>
        <div className="flex">
          <div className="text-xs mx-1"><span className="font-semibold">Depth:</span> {saltPrintSample.sample.sample_depth}</div>
          <div className="text-xs mx-1"><span className="font-semibold">Date:</span> {saltPrintSample.sample.sample_date}</div>
        </div>
      <Radar data={saltPrintSample.radar_data}
            options={options} />
      </div>
      <div className='flex flex-col'>
        <div className='flex flex-row items-center gap-x-2'>
          <label className='font-bold'>Type:</label>
          <Select
            value={type}
            onChange={(selectedOption) => updateSaltPrintSample(selectedOption.value)}
            options={selectOptions}
            className='w-full'
          />
        </div>
        <button className='btn btn-default' onClick={() => deleteSaltPrintSample(saltPrintSample)}>Exclude this sample</button>
      </div>
    </div>
  )
}

export default SaltPrintSample

