import React, { useState } from "react";
import AdjustableParameters from "./adjustableParameters/adjustableParameters";
import GeneralParameters from "./generalParameters/generalParameters";
import Guidelines from "./guidelines/main";

const Input = () => {
  let [step, setStep] = useState(1);

  const nextStep = (event) => {
    event.preventDefault();
    if (step < 3) {
      setStep(prevStep => prevStep + 1)
    }
  }

  const prevStep = (event) => {
    event.preventDefault();
    if (step > 1) {
      setStep(prevStep => prevStep - 1)
    }
  }

  return<>
    <div className='flex flex-row justify-between mx-4 font-semibold'>
      <div className={`flex flex-row items-start ${ step != 1 && 'text-gray-300'}`}>
        <h1 className='text-2xl'>1</h1>
        <p className='ml-2'>General Input Parameters</p>
      </div>
      <div className={`flex flex-row items-start ${ step != 2 && 'text-gray-300'}`}>
        <h1 className='text-2xl'>2</h1>
        <p className='ml-2'>AB Tier 2 Adjustable Parameters</p>
      </div>
      <div className={`flex flex-row items-start ${ step != 3 && 'text-gray-300'}`}>
        <h1 className='text-2xl'>3</h1>
        <p className='ml-2'>Results</p>
      </div>
    </div>

    <div className="mt-12 mx-4">
      <div className='my-3'>
        {step === 1 && (
          <GeneralParameters />
        )}
        {step === 2 && (
          <AdjustableParameters />
        )}
        {step === 3 && (
          <Guidelines />
        )}
      </div>
      <div className='flex flex-row justify-between'>
        {step != 1 && <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded w-24"
          onClick={prevStep}
        >
          Back
        </button>}
        {step != 3 && <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded w-24"
          onClick={nextStep}
        >
          Next
        </button>}
        {step == 3 && <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded w-24"
          onClick={() => window.print()}
        >
          Print
        </button>}
      </div>
    </div>
  </>
}

export default Input
