const chemicalsMatch = (guidelineOne, guidelineTwo) => guidelineOne.toLowerCase() === guidelineTwo.toLowerCase()

export const findChemicalIndex = (customGuidelines, chemicalName) => customGuidelines.findIndex(guideline => chemicalsMatch(guideline.chemical_name, chemicalName))

export const addReferenceChemicalProperty = inputArray => inputArray.map(input => ({ ...input, DisplayChemical: input["Chemical"] }))

export const addGuidelineHelper = (state, payload) => {
  const addingChemicalIndex = findChemicalIndex(state.customGuidelines, payload.guideline?.chemical_name)

  if (addingChemicalIndex > -1) {
    Object.assign(state.customGuidelines[addingChemicalIndex], payload.guideline)
    return { ...state }
  } else {
    return { ...state, customGuidelines: [ ...state.customGuidelines, payload.guideline ] }
  }
}

export const deleteGuidelineHelper = (state, payload) => {
  const deleteChemicalIndex = findChemicalIndex(state.customGuidelines, payload.chemicalName)

  if (deleteChemicalIndex > -1) {
    const { [payload.guideline]: removedProperty, ...afterDelete } = state.customGuidelines[deleteChemicalIndex]

    state.customGuidelines[deleteChemicalIndex] = afterDelete

    const shouldNotDelete = Object.keys(state.customGuidelines[deleteChemicalIndex]).some(key => {
      const isGuidelineKey = key === 'soil_guideline' || key === 'subsoil_guideline' || key === 'groundwater_guideline'
      if (isGuidelineKey) {
        return !!state.customGuidelines[deleteChemicalIndex][key]
      }
    })

    if (shouldNotDelete) {
      return { ...state }
    } else {
      state.customGuidelines.splice(deleteChemicalIndex, 1)
      return { ...state, customGuidelines: state.customGuidelines }
    }
  }

  return { ...state }
}

export const formattedExistingGuidelines = (newGuidelines, customGuidelines) => {
  const newGuidelineChemicals = newGuidelines.map(guideline => guideline["Chemical"])

  const matchedChemicalGuidelines = customGuidelines.filter(guideline => newGuidelineChemicals.includes(guideline.chemical_name))

  const formattedGuidelines = matchedChemicalGuidelines.map(guideline => {

    const newGuideline = newGuidelines.filter(newGuideline => newGuideline["Chemical"] == guideline.chemical_name)[0]

    const surfaceSoilGuideline = newGuideline && newGuideline['Surface Soil Guideline'] && guideline.soil_guideline ? guideline.soil_guideline.value : ''

    const subSoilGuideline = newGuideline && newGuideline['Subsoil Guideline'] && guideline.subsoil_guideline ? guideline.subsoil_guideline.value : ''

    const groundwaterSoilGuideline = newGuideline && newGuideline['Groundwater Guideline'] && guideline.groundwater_guideline ? guideline.groundwater_guideline.value : ''

    return {
      'DisplayChemical' : guideline.chemical_name,
      'Soil Units' : guideline.soil_unit,
      'Surface Soil Guideline' : surfaceSoilGuideline,
      'Subsoil Guideline' : subSoilGuideline,
      'Groundwater Units' : guideline.groundwater_unit,
      'Groundwater Guideline' : groundwaterSoilGuideline
    }
  })

  return formattedGuidelines
}
