import axios from 'axios';
import { buildCongenerSeries, buildParentSeries } from '../../helpers/buildExtendedPahChart';

export const fetchParentReferenceData = (siteId, selectedSampleId, setReferences, setReferenceScatterData) => {
  axios.get(`/sites/${siteId}/reports/double_ratio_plot/parent_pah_similarity?sample_id=${selectedSampleId}`)  
    .then(res => {
      setReferences(res.data);
      setReferenceScatterData(res.data.map(reference => {
        return {
          name: reference.name,
          data: [[reference.x, reference.y]],
          sourceType: reference?.source_type || null,
        };
      }));
    })
    .catch(err => console.error(err));
}

export const fetchHistogramData = (siteId, selectedSampleId, setSampleHistogramData) => {
  axios.get(`/sites/${siteId}/reports/pah_fingerprinting/concentrations?sample_id=${selectedSampleId}`)
    .then(res => {
      const seriesData = res.data.map(item => {
        if (item.less_than) return { name: item.name, y: item.percentage, color: '#fcbc5b' };
        return { name: item.name, y: item.percentage };
      });
      setSampleHistogramData(seriesData);
    })
    .catch(err => console.error(err));
}

export const fetchAlkylatedData = (mode, site, selectedSample, labSource, setAlkylatedReferences, setSampleExtendedHistogramData) => {
  if (mode === 'Parent') return;

  axios.get(`/sites/${site?.id}/reports/double_ratio_plot/alkylated_pah_similarity?sample_id=${selectedSample?.id}&lab_source=${labSource}`)
    .then(res => setAlkylatedReferences(res.data))
    .catch(err => console.error(err));

  axios.get(`/sites/${site.id}/reports/pah_fingerprinting/alkylated_concentrations?sample_id=${selectedSample?.id}&lab_source=${labSource}`)
    .then(res => {
      const categoryList = res.data.chemical_group.map(item => item.label);
      const seriesData = buildParentSeries(res.data.chemical_group).concat(buildCongenerSeries(res.data.chemical_group));
      setSampleExtendedHistogramData({ categories: categoryList, series: seriesData, total_concentration: res.data.total_concentration });
    })
    .catch(err => console.error(err));
}

export const fetchDiagnosticRatiosData = (siteId, selectedSampleId, setData, setScatterData) => {
  axios.get(`/sites/${siteId}/reports/double_ratio_plot/ratios?sample_id=${selectedSampleId}`)
    .then(res => {
      setData(res.data);
      if (res.data.ratio_1.value && res.data.ratio_4.value) {
        const seriesData = [parseFloat(res.data.ratio_1.value), parseFloat(res.data.ratio_4.value)];
        setScatterData(seriesData);
      }
    })
    .catch(err => console.error(err));
}
