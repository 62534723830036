import React, { useState } from 'react'

const findChemicalValue = (chemical, sample) => {
  const sampleValue = sample.sample_values.find(value => value.chemical_id === chemical.id && value.unit === chemical.unit)
  let value = sampleValue?.string_value || sampleValue?.float_value?.toPrecision(2)
  if (sampleValue?.less_than) value = `<${value}` 
  return value || '-'
}

const ChemicalRow = ({ chemical, samples }) => {
  const [hovered, setHovered] = useState(false)
  const handleMouseEnter = () => setHovered(true)
  const handleMouseLeave = () => setHovered(false)
  const maxWidth = hovered ? '100%' : '100px'

  return (
    <tr className='text-xs font-semibold uppercase border border-dashed border-gray-200'>
      <td className='sticky left-0 bg-white' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <div className={`mx-1 block truncate`} style={{ maxWidth: maxWidth }}>{chemical.name}</div>
      </td>
      <td className='normal-case text-xs text-center'>{chemical.unit || '-'}</td>
      {samples?.map((sample) => {
        const chemicalValue = findChemicalValue(chemical, sample)
        return <td key={sample.id} className='text-xs text-center'>{chemicalValue}</td>
      })}
    </tr>
  )
}

export default ChemicalRow
