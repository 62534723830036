import React, { useEffect, useState } from 'react'
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import axios from "axios";
import zeroLabels from "../zeroLabels"
import molecularLabels from "../molecularLabels";
import buildPahChart from "../pahFingerprinting/helpers/buildPahChart";

const BarChart = ({ site, sample }) => {
  let [categories, setCategories] = useState()
  let [data, setData] = useState()
  let [chartOptions, setChartOptions] = useState()

  useEffect(() => {
    axios.get(`/sites/${site.id}/reports/pah_fingerprinting/concentrations?sample_id=${sample.id}`).then(res => {
      let seriesData = res.data.map(item => {
        if (item.less_than) {
          return { name: item.name, y: item.effective_value, color: '#fcbc5b' }
        } else {
          return { name: item.name, y: item.effective_value }
        }
      })
      setData(seriesData)
    })
  }, [])

  useEffect(() => {
    data && setCategories(data.map(item => item.name))
  }, [data])

  useEffect(() => {
    data && categories && setChartOptions(buildPahChart('', 'Concentration (mg/kg)', data, categories, 'mg/kg'))
  }, [categories])

  return categories && categories.length > 0 && chartOptions ? <div>
    <HighchartsReact
      highcharts={Highcharts}
      options={chartOptions} />
    </div> : <div className='flex flex-row justify-center items-center bg-white rounded-xl h-4/5 w-full'>
    <p>No Data Available</p></div>
}

export default BarChart
