import React, { useState, useEffect } from 'react'
import CustomSelect from './customSelect'
import DepthSelects from './depthSelects'
import { toast } from 'react-toastify'

const Form = ({ isSoilUpload, isGroundwaterUpload, updateSiteUpload, collectionKVS, setFormData }) => {
  const [showStartDepth, setShowStartDepth] = useState(false)

  const metaDataOptions = collectionKVS.filter(collectionKV => collectionKV[0]).map((collectionKV) => ({value: collectionKV[1], label: collectionKV[0]}))
  const options = [{ value: '', label: '-' }, ...metaDataOptions]
  
  const handleInputChange = (e) => setFormData(prevState => ({...prevState, [e.id]: e.value}))
  
  useEffect(() => {
    if (!showStartDepth) {
      setFormData(prevState => ({...prevState, start_depth_index: ''}))
    }
  }, [showStartDepth])

  useEffect(() => {
    toast.info('CSV column mapping may take some time, please wait')
  }, [])
  
  
  return (
    <form onSubmit={updateSiteUpload} className='mt-1 overflow-auto'>
      <CustomSelect id='longitude_index' label='Longitude' options={options} keyword='Longitude' collectionKVS={collectionKVS} required={false} handleInputChange={handleInputChange}/>
      <CustomSelect id='latitude_index' label='Latitude' options={options} keyword='Latitude' collectionKVS={collectionKVS} required={false} handleInputChange={handleInputChange}/>
      <CustomSelect id='sample_location_name_index' label='Sample Location Name' options={options} keyword='SampleLocationName' collectionKVS={collectionKVS} required={true} handleInputChange={handleInputChange}/>
      <CustomSelect id='sample_name_index' label='Unique Sample Id' options={options} keyword='SampleName' collectionKVS={collectionKVS} required={false} handleInputChange={handleInputChange}/>
      <CustomSelect id='sample_date_index' label='Sample Date' options={options} keyword='SampleDate' collectionKVS={collectionKVS} required={true} handleInputChange={handleInputChange}/>
      <CustomSelect id='sample_time_index' label='Sample Time' options={options} keyword='SampleTime' collectionKVS={collectionKVS} required={false} handleInputChange={handleInputChange}/>
      <CustomSelect id='group_index' label='Sample Group' options={options} keyword='GroupName' collectionKVS={collectionKVS} required={false} handleInputChange={handleInputChange}/>
      <CustomSelect id='status_index' label='Status' options={options} keyword='Status' collectionKVS={collectionKVS} required={false} handleInputChange={handleInputChange}/>
      {isSoilUpload && <DepthSelects showStartDepth={showStartDepth} options={options} collectionKVS={collectionKVS} handleInputChange={handleInputChange} setShowStartDepth={setShowStartDepth}/>}
      {isGroundwaterUpload && <CustomSelect id='install_date_index' label='Groundwater Well Install Date' options={options} keyword='InstallDate' collectionKVS={collectionKVS} required={false} handleInputChange={handleInputChange}/>}
      <button className='btn btn-default'>Next &gt;</button>
    </form>
  )
}

export default Form
