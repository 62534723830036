export const INITIALIZE_SAMPLE_DATA = 'INITIALIZE_SAMPLE_DATA'
export const CHANGE_HEADER = 'CHANGE_HEADER'
export const SET_CONSTANTS = 'SET_CONSTANTS'
export const TOGGLE_COMPARE = 'TOGGLE_COMPARE'
export const SET_CURRENT_SAMPLES = 'SET_CURRENT_SAMPLES'
export const SELECT_SAMPLES = 'SELECT_SAMPLES'
export const SET_REFERENCE_LIST = 'SET_REFERENCE_LIST'
export const SET_REFERENCE_DATA = 'SET_REFERENCE_DATA'
export const SET_ADDITIONAL_DATA = 'SET_ADDITIONAL_DATA'
export const SET_ADDITIONAL_HISTOGRAM_DATA = 'SET_ADDITIONAL_HISTOGRAM_DATA'
export const SET_EXPORT_DATA = 'SET_EXPORT_DATA'
export const TOGGLE_ANALYSIS = 'TOGGLE_ANALYSIS'
export const FILTER_DATA = 'FILTER_DATA'
export const SET_DISPLAYED_DATA = 'SET_DISPLAYED_DATA'
export const UPDATE_DISPLAYED_SAMPLES = 'UPDATE_DISPLAYED_SAMPLES'
export const TOGGLE_MODE = 'TOGGLE_MODE'
export const SET_ALKYLATED_CONSTANTS = 'SET_ALKYLATED_CONSTANTS'
export const SET_ALKYLATED_REFERENCE_DATA = 'SET_ALKYLATED_REFERENCE_DATA'
export const TOGGLE_POPUPS = 'TOGGLE_POPUPS'
export const TOGGLE_LEGENDS = 'TOGGLE_LEGENDS'
export const TOGGLE_SHOW_MODAL = 'TOGGLE_SHOW_MODAL'
export const TOGGLE_PREVIEW = 'TOGGLE_PREVIEW'
export const SET_LAB_SOURCE = 'SET_LAB_SOURCE'
export const SET_ANNOTATION_MODE = 'SET_ANNOTATION_MODE'
export const SET_SELECTED_SAMPLE_ANNOTATION = 'SET_SELECTED_SAMPLE_ANNOTATION'
export const SET_SELECTED_SAMPLE_ANNOTATION_LOG = 'SET_SELECTED_SAMPLE_ANNOTATION_LOG'
export const SET_SOURCE_MODAL = 'SET_SOURCE_MODAL'
export const SET_SOURCE_REFERENCES = 'SET_SOURCE_REFERENCES'
export const TOGGLE_HELPER_TEXTS = 'TOGGLE_HELPER_TEXTS'
