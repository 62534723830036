import React, { useEffect } from "react";
import ChemicalValue from "./chemicalValue";

const SampleDataRow = ({ sample, chemicalHeaders, highlightSampleIds, setHighlightSampleIds, hasUniqueIds, samples }) => {
  const chemicalValues = sample?.sample?.sample_values || sample.sample_values;
  const sampleInfo = sample?.sample || sample;

  const getLocationId = (sample) => sample?.sample?.sample_location.name || sample?.sample_location.name;
  const locationIds = samples ? samples.filter((s) => getLocationId(s)  === sampleInfo.sample_location.name).map((s) => s.id) : null;

  const handleClick = () => locationIds ? setHighlightSampleIds(locationIds) : setHighlightSampleIds([sample.id]);

  useEffect(() => {
    if (!highlightSampleIds.includes(sample.id)) return;
    const rowElement = document.getElementById(`tr-${Math.min(...highlightSampleIds)}`);

    if (rowElement) {
      rowElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [highlightSampleIds]);

  const highlightRow = () => highlightSampleIds.includes(sample.id) ? "bg-blue-200 hover:bg-blue-200" : "bg-white";

  const sampleDepth = sampleInfo.sample_depth !== null ? sampleInfo.sample_depth : '-'
  const displayedDepth = sampleInfo.depth_range ? sampleInfo.depth_range : sampleDepth

  return (
    <tr
      key={sampleInfo.id}
      id={`tr-${sample.id}`}
      onClick={handleClick}
      className={`${highlightRow()} cursor-pointer text-xs`}
      style={{ scrollMarginTop: "3rem" }}
    >
      <td className= {`sticky left-0 z-10 hover:bg-blue-200 ${highlightRow()}`}>{sampleInfo.sample_location.name}</td>
      {hasUniqueIds && <td>{sampleInfo.name || "-"}</td>}
      <td className="text-center">{displayedDepth}</td>
      <td>{sampleInfo.sample_location_group_name || "-"}</td>
      <td>{sampleInfo.sample_location.status || "-"}</td>
      <td className="text-center">{sampleInfo.sample_date || "-"}</td>
      <td className="text-center">{sampleInfo.matrix.title || "-"}</td>
      {chemicalHeaders.map((chemical, i) => {
        const chemicalValue = chemicalValues.find(value => value.chemical_id === chemical.id && value.unit === chemical.unit && value.analytical_method === chemical.analytical_method);
        return <ChemicalValue key={i} chemicalValue={chemicalValue} />
      })}
    </tr>
  )
}

export default SampleDataRow
