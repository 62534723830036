import React from "react";

const GeneralInformationTable = ({ site }) => {
  return <>
    <div className='my-4'>
      <h3 className="font-semibold">Site General Information</h3>
      <table className='w-full text-left'>
        <tbody>
        <tr>
          <td className="w-1/3">Address/Legal Location/Site Name:</td>
          <td>{site.siteIdentification.name}</td>
        </tr>
        <tr>
          <td className="w-1/3">Land Use Classification:</td>
          <td>{site.siteIdentification.land_use}</td>
        </tr>
        <tr>
          <td className="w-1/3">Soil Type - Grain Size:</td>
          <td>{site.siteIdentification.grain_size}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </>
}

export default GeneralInformationTable
