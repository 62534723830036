import React from "react";
import { useDataVaultContext } from "../../../contexts/dataVault";
import { fitBounds } from "./helperFunctions";

const HomeZoom = ({map, bounds}) => {
  return (
    <div 
      className="absolute flex items-center justify-center bg-white left-2.5 bottom-28 z-10 p-1.5 mt-3 rounded-md cursor-pointer hover:bg-gray-100"
      style={{ width: '28px', height: '29px' }} 
      onClick={() => fitBounds(map, bounds)}>
        <i className='fa fa-home text-black text-sm'/>
    </div>
  )
}

export default HomeZoom
