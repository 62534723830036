import React from 'react'
import Tooltip from "./tooltip";
import axios from "axios";
import { useCustomGuidelinesContext } from "../../../../contexts/customGuidelines";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const DownloadTemplate = () => {
  const { site } = useCustomGuidelinesContext()

  const downloadTemplate = () => {
    toast.success('File will be downloaded shortly')
    axios({
      method: 'GET',
      url: `/sites/${site.id}/download_template.json`,
    }).then((response) => {
      let blob = new Blob(["\ufeff" + response.data], { type: 'text/csv' })
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'custom_guidelines_template.csv'
      link.click()
    }).catch((err) => console.log(err))
  }

  return <div className='flex flex-row items-center gap-x-3 -ml-3'>
    <ToastContainer />
    <button
      onClick={downloadTemplate}
      className="bg-blue-500 hover:bg-blue-700 text-white font-semibold py-1 px-4 border border-blue-700 rounded text-center w-60 my-2"
    >
      Download Template
    </button>
    <Tooltip />
  </div>
}

export default DownloadTemplate
