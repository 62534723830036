import React from 'react'
import Pathway from "./pathway";
import { useSiteAPI, useSiteContext } from "../../../contexts/site";

const SubsoilPathways = () => {
  const { subsoilPathwayOptions, subsoilPathways } = useSiteContext()
  const { togglePathway } = useSiteAPI()

  return <div className='flex flex-col gap-y-3'>
    <h3 className='font-semibold'>Subsoil Site-Specific Factors</h3>
    {subsoilPathwayOptions.map(pathway => <Pathway key={pathway.id} pathway={pathway} checked={subsoilPathways?.includes(pathway.id)} togglePathway={togglePathway('subsoilPathways')} />)}
  </div>
}

export default SubsoilPathways
