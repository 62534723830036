import React, { useState } from "react"
import { useCookies } from "react-cookie"
import { TierOneProvider } from "./tierOneContext"
import TierOneBody from "./tierOneBody"
import Welcome from "./welcome"
import DisclaimerModal from "../../shared/modal/DisclaimerModal"
import RenderModal from "../../shared/modal/RenderModal"


const Main = () => {
  let [start, setStart] = useState(true)
  const [isOpen, setIsOpen] = useState(true)
  const [cookies, setCookie] = useCookies(['guideline-terms-and-conditions'])

  const handleClick = () => {
    setStart(false)
  }

  return (
    <>
      <TierOneProvider>
        {start ? <Welcome handleClick={handleClick} /> : <TierOneBody />}
      </TierOneProvider>
      {
        !cookies.completed && <RenderModal selector="#modal-root">
          <DisclaimerModal isOpen={isOpen} setIsOpen={setIsOpen} onConfirm={() => setCookie('completed', true)} />
        </RenderModal>
      }
    </>
  )
}

export default Main
