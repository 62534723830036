import React, { useState } from 'react'
import PreviewTable from './previewTable'
import Form from './form'
import EmptyHeaderModal from './modals/emptyHeaderModal'
import axios from 'axios'
import { soilCondition, groundwaterCondition } from './helper'
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const Index = ({siteUpload, firstRow, collectionKVS, csvSheet}) => {
  const [formData, setFormData] = useState({})
  const [isOpen, setIsOpen] = useState(collectionKVS.some(label => label[0] === null))
  const isSoilUpload = siteUpload.matrix_id === 1
  const isGroundwaterUpload = siteUpload.matrix_id === 3
  
  const updateSiteUpload = (e) => {
    e.preventDefault()
    
    if (isSoilUpload && !soilCondition(formData)) {
      return alert('Please complete all required fields (Sample Location Name, Sample Date, Sample Depth)')
    }
    
    if (isGroundwaterUpload && !groundwaterCondition(formData)) {
      return alert('Please complete all required fields (Sample Location Name, Sample Date)')
    }

    toast.info('Please wait while chemical mapping is being processed')
    
    axios.patch(`/sites/${siteUpload.site_id}/site_uploads/${siteUpload.id}/map_metadata`, {site_upload: formData}, {headers: {'Accept': 'application/json', 'Content-Type': 'application/json'}})
      .then((response) => window.location.href = response.data.path)
      .catch((error) => console.error(error))
  }

  return (
    <div className='h-full flex flex-col items-center'>
      <ToastContainer />
      <p className='font-semibold uppercase text-xl'>CSV Column Mapping</p>
      <PreviewTable firstRow={firstRow} csvSheet={csvSheet} />
      <div className='flex flex-col justify-start w-11/12 mt-1 h-3/5'>
        <div className='flex flex-col text-sm font-semibold w-11/12 mt-1'>
          <p>If any of the rows in your CSV do not have values for the required fields marked below, the row will skipped and not imported into Statvis.</p>
          <p>Which column in your CSV contains...</p>
        </div>
        <Form isSoilUpload={isSoilUpload} isGroundwaterUpload={isGroundwaterUpload} updateSiteUpload={updateSiteUpload} collectionKVS={collectionKVS} setFormData={setFormData} />
      </div>
      <EmptyHeaderModal isOpen={isOpen} firstRow={firstRow} collectionKVS={collectionKVS} setIsOpen={setIsOpen} siteUpload={siteUpload} />
    </div>
  )
}

export default Index
