import React, { useState, useEffect } from 'react'
import { usePahFingerprinting, usePahFingerprintingAPI } from '../../../../contexts/pahFingerprinting'
import Charts from './charts'
import ReferenceValues from './referenceValues'
import RatioDescriptionTable from './diagnosticReport/ratioDescriptionTable'
import { generatePdfReport, getPdfReportData } from './helpers/pdfHelper'

const Index = () => {
  const { selectedSamples, site } = usePahFingerprinting()
  const { toggleShowPreview, onGetExportData } = usePahFingerprintingAPI()
  const [exporting, setExporting] = useState(false)
  const [exportedData, setExportedData] = useState([])
  const [samplesProcessed, setSamplesProcessed] = useState(0)
  const [gathering, setGathering] = useState(false)

  const processed = () => setSamplesProcessed(prev => prev + 1)
  const dataIsLoaded = samplesProcessed >= selectedSamples.length
  const buttonLabel = dataIsLoaded ? 'Export' : `Processing ${samplesProcessed} / ${selectedSamples.length}`
  const buttonStyle = dataIsLoaded && !gathering ? 'bg-green-300' : 'bg-red-200 cursor-not-allowed'
  const gatheringLabel = gathering && ' - Gathering Data'
  const getExportedData = (data) => setExportedData(prev => [...prev, data])
  const chartsList = selectedSamples?.map(sample => <Charts key={sample.id} selectedSample={sample} site={site} processed={processed} exporting={exporting} getExportedData={getExportedData}/>)

  useEffect(() => {
    if (exportedData.length === selectedSamples.length) {
      setGathering(false)
      onGetExportData(exportedData)
      const latestExportData = exportedData
      generatePdfReport(latestExportData, site, getPdfReportData)
      setExportedData([])
      toggleShowPreview()
    }
  }, [exportedData, exporting])

  return (
    <div className='h-full overflow-auto'>
      <div className='sticky top-0 flex justify-between items-center w-full bg-gray-200 m-0 z-30'>
          <button className='flex items-center justify-center border border-black rounded h-7 p-1 m-1 text-xs font-semibold uppercase bg-gray-300' onClick={toggleShowPreview}>Back</button>
          <h1>Design Diagnostic PAH Report</h1>
          <button className={`${buttonStyle} flex items-center justify-center border border-black rounded h-7 p-1 m-1 text-xs font-semibold uppercase`} onClick={() => {
            setGathering(true)
            setExporting(true)
          }} disabled={!dataIsLoaded}
          >
            {buttonLabel}{gatheringLabel}
          </button>
        </div>
      <div className='m-2'>
        <ReferenceValues />
        <RatioDescriptionTable />
        {chartsList}
      </div>
    </div>
  )
}

export default Index
