import axios from 'axios'

const getPdfReportData = (siteId, chartData) => {
  try {
    return axios({
      method: 'POST',
      url: `/sites/${siteId}/dioxins_and_furans_exports`,
      responseType: 'arraybuffer',
      data: chartData
    })
  }
  catch (e) {
    console.error(e)
  }
}

const downloadReport = (reportData) => {
  const blob = new Blob([reportData], { type: 'application/pdf' })
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.download = 'Report.pdf'
  link.click()
}

export const generatePdfReport = (data, site) => {
  const encodedExportData = data.map(data => {
    return { 
      sample_id: data.sample_id,
      reference: data.reference,
      sum_2378: encodeURIComponent(data.sum_2378),
      relative_hom: encodeURIComponent(data.relative_hom),
      relative_teq: encodeURIComponent(data.relative_teq),
      total_hom: encodeURIComponent(data.total_hom),
      unstandardized: encodeURIComponent(data.unstandardized),
      mole_analyte: encodeURIComponent(data.mole_analyte),
      hexa_cdd_cdf: encodeURIComponent(data.hexa_cdd_cdf),
      penta_hexa_cdd_cdf: encodeURIComponent(data.penta_hexa_cdd_cdf)
    }
  })

  getPdfReportData(site.id, encodedExportData).then((response) => downloadReport(response.data))
}
