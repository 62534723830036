import React from 'react'
import { usePcbContext, usePcbAPI } from '../../../contexts/pcb'

const ExportModalButton = () => {
  const { sampleIdsForExport } = usePcbContext()
  const { setShowExportModal } = usePcbAPI()

  return (
    <button className='flex items-center justify-center border border-black rounded h-7 p-1 m-1 text-xs font-semibold uppercase' onClick={() => setShowExportModal(true)}>
      Export {sampleIdsForExport.length} Samples
    </button>
  )
}

export default ExportModalButton
