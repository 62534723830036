import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import UploadErrorModal from "./uploadErrorModal";

export const UploadForm = ({ site, matrices, coordinates }) => {
  const [data, setData] = useState(null);
  const [name, setName] = useState('');
  const [matrix, setMatrix] = useState(1);
  const [coordinate, setCoordinate] = useState(1);
  const [zone, setZone] = useState('');
  const [showModal, setShowModal] = useState(false)
  const [format, setFormat] = useState(1)
  const [formatOptions, setFormatOptions] = useState([])
  
  const handleFileInput = (e) => {
    setData(e.target.files[0]);
    setName(e.target?.files[0]?.name?.replace('.csv', '') || '');
  };
  
  const handleCoordinateChange = (e) => {
    setCoordinate(Number(e.target.value));
    if (e.target.value == 1) setZone('');
  };

  useEffect(() => {
    axios.get(`/data_formats.json`)
      .then(res => setFormatOptions(res.data))
  }, [])

  const handleFormatChange = (e) => {
    setFormat(Number(e.target.value));
    if (e.target.value == 1) setZone('');
  };
  
  const createUpload = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('site_upload[name]', name);
    formData.append('site_upload[matrix_id]', matrix);
    formData.append('site_upload[coordinate_type_id]', coordinate);
    formData.append('site_upload[zone]', zone);
    formData.append('site_upload[data]', data);
    formData.append('site_upload[data_format_id]', format)

    axios.post(`/sites/${site.id}/site_uploads.json`, formData)
      .then((response) => {
        window.location.href = response.data.path
      })
      .catch(_ => {
        setShowModal(true)
      });
  };

  const matrixOptions = matrices.map((matrix) => <option key={matrix.id} value={matrix.id}>{matrix.title}</option>);
  const coordinatesOptions = coordinates.map((coordinate) => <option key={coordinate.id} value={coordinate.id}>{coordinate.name}</option>);
  const isDecimalDegrees = coordinate === 1
  
  return (
    <>
      <UploadErrorModal showModal={showModal} setShowModal={setShowModal} />
      <h1 className="text-xl font-semibold mb-1">New Sample Data Upload</h1>
      <form className='panel' onSubmit={createUpload} autoComplete='off'>
        <p className='my-1 text-sm'>Choose a name that is descriptive of the data you are uploading (e.g., “2017 hydrocarbon data”) so you will be able to easily remember what it is later.</p>
        <label className="sv-label">Data</label>
        <input className="sv-file-input my-1" accept=".xlsx,.csv" type="file" onChange={handleFileInput} required/>
        <div className='flex gap-x-2'>
          <div className='w-2/3'>
            <label className="sv-label">Name</label>
            <input className="sv-input my-1" type="text" value={name} onChange={(e) => setName(e.target.value)} required/>
          </div>
          <div className='w-1/3'>
            <label className="sv-label">CSV Format</label>
            <select className="sv-select" value={format} onChange={handleFormatChange}>
              {formatOptions?.map((format) => <option key={format.id} value={format.id}>{format.name}</option>)}
            </select>
          </div>
        </div>
        <div className='flex justify-between my-1'>
          <div className='w-2/6 mr-1'>
            <label className="sv-label">Matrix</label>
            <select className="sv-select" value={matrix} onChange={(e) => setMatrix(Number(e.target.value))}>
              {matrixOptions}
            </select>
          </div>
          <div className='w-2/6 mx-1'>
            <label className="sv-label">Coordinate Type</label>
            <select className="sv-select" value={coordinate} onChange={handleCoordinateChange}>
              {coordinatesOptions}
            </select>
          </div>
          <div className='w-2/6 ml-1'>
            <label className="sv-label">Zone (if applicable)</label>
            <input className="sv-input" value={zone} type="number"onChange={(e) => setZone(Number(e.target.value))}  required={!isDecimalDegrees} disabled={isDecimalDegrees}/>
          </div>
        </div>
        <div className="form-actions">
          <button type="submit" className="btn btn-default">Upload</button>
        </div>
      </form>
    </>
  )
}


export default UploadForm
