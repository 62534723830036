const getTickPositions = (maxValue, tickCount) => {
  const isBelowOne = maxValue > 1
  const roundTick = (value) => (isBelowOne ? Math.round(value) : Math.round(value * 100) / 100)
  
  return [...Array(tickCount + 1)].map((_, i) => {
    const tickValue = (maxValue / tickCount) * i
    return roundTick(tickValue)
  })
}

export const generateChartOptions = (sampleData, title, subtitle, height= null) => {
  const categories = sampleData.map((data) => data.category)
  const maxValue = Math.max(...sampleData.map((data) => data.y))

  return {
    chart: {
      type: "column",
      width: 450,
      height: height || 250,
    },
    title: {
      text: title,
      style: {
        fontSize: '15px',
        fontWeight: 'bold' 
      }
    },
    subtitle: {
      text: subtitle,
      style: {
        fontSize: '12px',
        fontWeight: 'normal' 
      }
    },
    legend: {
      enabled: false,
    },
    xAxis: {
      categories: categories,
      labels: {
        rotation: -90,
        style: {
          fontSize: '9px'
        }
      },
    },
    yAxis: {
      title: {
        text: '',
      },
      tickPositions: getTickPositions(maxValue, 4),
    },
    tooltip: {
      formatter: function () {
        return `${this.x}: ${this.y}`
      },
      followPointer: true,
    },
    series: [
      {
        data: sampleData.map((data) => data.y),
      },
    ],
    credits: {
      enabled: true,
      text: 'Powered by Statvis',
      href: 'https://www.statvis.com/'
    },
  }
}
