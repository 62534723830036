import React from 'react'
import { usePcbContext, usePcbAPI } from '../../../contexts/pcb'
import Select from 'react-select'
import axios from 'axios'
import { findPcbGroup } from '../helpers/coEluteHelper'

const Index = () => {
  const { site, showCoElution, chemicals, rangeMap, coElutionData, coElutionOptions, uploadsData } = usePcbContext()
  const { setShowCoElution, setCoElutionData, setCoElutionOptions } = usePcbAPI()
  const pcbColumn = uploadsData[0].map((upload) => upload[1].trim()).filter(element => /\d/.test(element))

  const handleBack = () => setShowCoElution(!showCoElution)
  const handleSave = () => {
    axios.post(`/sites/${site.id}/pcb_coelutions/create_or_update`, { pcb_coelution: { site_id: site.id, coelutions: coElutionData } })
    setShowCoElution(!showCoElution)
  }
  
  const onChangeHandler = (selected, action, chemical) => {
    setCoElutionData({ ...coElutionData, [chemical]: selected.map((option) => option.value) });
    if (action.action === 'remove-value') {
      const removed = action.removedValue.value
      const newOptions = [{value: removed, label: `${removed} (${findPcbGroup(removed, rangeMap)})`}, ...coElutionOptions]
      setCoElutionOptions(newOptions)
    }
    
    if (action.action === 'select-option') {
      const newOptions = coElutionOptions.filter((option) => !selected.includes(option));
      setCoElutionOptions(newOptions)
    }
  }
  
  const chemicalSelects = chemicals.map((chemical) => (
    <tr key={chemical.id}>
      <td>{chemical.name}</td>
      <td>{`${findPcbGroup(chemical.name, rangeMap).charAt(0).toUpperCase()}${findPcbGroup(chemical.name, rangeMap).slice(1)}chloro Biphenyl`}</td>
      <td>
        <Select
          options={coElutionOptions.filter((option) => option.label.includes(findPcbGroup(chemical.name, rangeMap))).sort((a, b) => a.label.localeCompare(b.label))}
          isMulti
          placeholder='Select Co-elute or type PCB number / type to search...'
          value={coElutionData[chemical.name]?.map((coElute) => ({ value: coElute, label: `${coElute} (${findPcbGroup(coElute, rangeMap)})` }))}
          onChange={(selected, action) => onChangeHandler(selected, action, chemical.name)}
          closeMenuOnSelect={false}
          isClearable={false}
          noOptionsMessage={() => 'No PCB options found for this type'}
        />
      </td>
      <td>{pcbColumn.find(pcb => pcb.includes(chemical.name))}</td>
    </tr>
  ))

  return (
    <div className='h-full'>
      <div className='sticky top-0 flex justify-between px-2 z-20 bg-gray-200 border border-black py-1'>
        <div className='w-1/3 flex items-center justify-start'>
          <button className='rounded p-1 text-xs bg-gray-300 hover:bg-gray-400 uppercase font-semibold cursor-pointer w-24 border border-black' onClick={handleBack}>Back</button>
        </div>
        <div className='w-1/3 flex items-center justify-center'>
          <h1 className='font-semibold uppercase'>Manage Co-elutions</h1>
        </div>
        <div className='w-1/3 flex items-center justify-end'>
          <button className='rounded p-1 text-xs bg-gray-300 hover:bg-gray-400 uppercase font-semibold cursor-pointer w-24 border border-black' onClick={handleSave}>Save</button>
        </div>
      </div>
      <div className='flex flex-col h-5/6 p-2'>
        <p className='text-xs text-gray-500 p-2'>Assign chemicals to PCB to make co-elutions</p>
        <div className='flex overflow-auto w-full'>
          <table className='dataVaultTable'>
            <thead>
              <tr className='sticky top-0 z-20'>
                <th className='w-1/5'>PCB</th>
                <th className='w-1/5'>Type</th>
                <th className='w-3/5'>Co-elute</th>
                <th className='w-1/5'>CSV CoElution</th>
              </tr>
            </thead>
            <tbody>
              {chemicalSelects}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default Index
