import React, { useState } from 'react'
import { getChemicalValue } from '../../shared/table/tableFunctions'
import { usePcbContext } from '../../../contexts/pcb'

const ChemicalRow = ({chemical, samplesData}) => {
  const { coElutionData } = usePcbContext()
  const [hovered, setHovered] = useState(false)
  const handleMouseEnter = () => setHovered(true)
  const handleMouseLeave = () => setHovered(false)
  const maxWidth = hovered ? '100%' : '80px'

  const data = samplesData.map((sample) => {
    const chemicalValue = getChemicalValue(sample, chemical)
    return (
      <td key={sample.id} className='text-xs text-center'>{chemicalValue}</td>
    )
  })

  const chemicalCoElution = coElutionData[chemical.name]
  const hasChemicalCoElution = chemicalCoElution && chemicalCoElution.length
  const chemicalName = hasChemicalCoElution ? `${chemical.name},${chemicalCoElution.map(coElution => coElution.replace('PCB ', '')).join(',')}` : chemical.name

  return (
    <tr key={chemical.id} className='text-xs font-semibold uppercase border border-dashed border-gray-200'>
      <td className='sticky left-0 bg-white' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <div className={`mx-1 block truncate`} style={{ maxWidth: maxWidth }}>{chemicalName}</div>
      </td>
      {data}
    </tr>
  )
}

export default ChemicalRow
