import React, { createContext, useReducer, useMemo, useContext, useEffect } from 'react'
import axios from "axios";
import { unique } from "../helpers/adminGuidelines";

/* Actions */
const SET_JURISDICTION = 'SET_JURISDICTION'
const SET_MATRIX = 'SET_MATRIX'
const SET_VIEW_GUIDELINES = 'SET_VIEW_GUIDELINES'
const SET_INITIAL_DATA = 'SET_INITIAL_DATA'
const SET_FILTERED_DATA = 'SET_FILTERED_DATA'
const SET_GROUP = 'SET_GROUP'
const SET_GROUP_OPTIONS = 'SET_GROUP_OPTIONS'
const SET_CHEMICAL = 'SET_CHEMICAL'
const SET_CHEMICAL_OPTIONS = 'SET_CHEMICAL_OPTIONS'
const SET_HEADERS = 'SET_HEADERS'
const SET_LOADING = 'SET_LOADING'
const SET_FILENAME = 'SET_FILENAME'

/* Initial States */
const initialState = {
  jurisdiction: null,
  matrix: null,
  viewGuidelines: false,
  initialData: [],
  filteredData: [],
  group: null,
  groupOptions: [],
  chemical: null,
  chemicalsOptions: [],
  headers: [],
  loading: true,
  filename: ''
}

/* Reducer */
const adminGuidelinesReducer = (state, action) => {
  switch(action.type) {
    case SET_JURISDICTION:
      return { ...state, jurisdiction: action.payload }
    case SET_MATRIX:
      return { ...state, matrix: action.payload }
    case SET_VIEW_GUIDELINES:
      return { ...state, viewGuidelines: action.payload }
    case SET_INITIAL_DATA:
      return { ...state, initialData: action.payload }
    case SET_FILTERED_DATA:
      return { ...state, filteredData: action.payload }
    case SET_GROUP:
      return { ...state, group: action.payload }
    case SET_GROUP_OPTIONS:
      return { ...state, groupOptions: action.payload }
    case SET_CHEMICAL:
      return { ...state, chemical: action.payload }
    case SET_CHEMICAL_OPTIONS:
      return { ...state, chemicalsOptions: action.payload }
    case SET_HEADERS:
      return { ...state, headers: action.payload }
    case SET_LOADING:
      return { ...state, loading: action.payload }
    case SET_FILENAME:
      return { ...state, filename: action.payload }
    default:
      return state
  }
}

/* Contexts */
const AdminGuidelinesContext = createContext(initialState)
const AdminGuidelinesAPIContext = createContext({
  setJurisdiction: () => {},
  setMatrix: () => {},
  setViewGuidelines: () => {},
  setInitialData: () => {},
  setFilteredData: () => {},
  setGroup: () => {},
  setGroupOptions: () => {},
  setChemical: () => {},
  setChemicalsOptions: () => {},
  setHeaders: () => {},
  setLoading: () => {},
  setFilename: () => {}
})

/* Providers */
export const AdminGuidelinesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(adminGuidelinesReducer, initialState)

  const api = useMemo(() => {
    const setJurisdiction = jurisdiction => dispatch({ type: SET_JURISDICTION, payload: jurisdiction })

    const setMatrix = matrix => dispatch({ type: SET_MATRIX, payload: matrix })

    const setViewGuidelines = viewGuidelines => dispatch({ type: SET_VIEW_GUIDELINES, payload: viewGuidelines })

    const setInitialData = data => dispatch({ type: SET_INITIAL_DATA, payload: data })

    const setFilteredData = data => dispatch({ type: SET_FILTERED_DATA, payload: data })

    const setGroup = group => dispatch({ type: SET_GROUP, payload: group })

    const setGroupOptions = groupOptions => dispatch({ type: SET_GROUP_OPTIONS, payload: groupOptions })

    const setChemical = chemical => dispatch({ type: SET_CHEMICAL, payload: chemical })

    const setChemicalsOptions = chemicalsOptions => dispatch({ type: SET_CHEMICAL_OPTIONS, payload: chemicalsOptions })

    const setHeaders = headers => dispatch({ type: SET_HEADERS, payload: headers })

    const setLoading = loading => dispatch({ type: SET_LOADING, payload: loading })

    const setFilename = filename => dispatch({ type: SET_FILENAME, payload: filename })

    return { setJurisdiction, setMatrix, setViewGuidelines, setInitialData, setFilteredData, setGroup, setGroupOptions, setChemical, setChemicalsOptions, setHeaders, setLoading, setFilename }
  }, [])

  useEffect(() => {
    if (state.jurisdiction && state.matrix) {
      api.setLoading(true)
      axios.get("/guidelines/data.json", { params: { jurisdiction: state.jurisdiction?.value, matrix: state.matrix?.value } })
        .then(res => {
          api.setHeaders(Object.keys(res.data.data[0]))
          api.setInitialData(res.data.data)
          api.setLoading(false)
          api.setFilename(res.data.filename)

          const uniqueGroups = res.data.data.map(row => row.group).filter(unique)
          api.setGroupOptions(uniqueGroups.map(group => {
            return { label: group, value: group }
          }))

          const uniqueChemicals = res.data.data.map(row => row.chemical).filter(unique)
          api.setChemicalsOptions(uniqueChemicals.map(chemical => {
            return { label: chemical, value: chemical }
          }))
        })
        .catch(err => console.log(err))
    }
  }, [state.jurisdiction, state.matrix])

  useEffect(() => {
    if (state.group && state.initialData.length > 0) {
      const groupedData = state.initialData.filter(row => row.group == state.group.value)
      const uniqueChemicals = groupedData.map(row => row.chemical).filter(unique)
      api.setChemicalsOptions(uniqueChemicals.map(chemical => {
        return { label: chemical, value: chemical }
      }))
    }
  }, [state.group, state.initialData])

  useEffect(() => {
    if (state.initialData.length > 0) {
      api.setFilteredData(state.initialData)

      if (state.group && state.chemical) {
        api.setFilteredData(state.initialData.filter(row => row.group == state.group.value && row.chemical == state.chemical.value))
      }

      if (state.group) {
        api.setFilteredData(state.initialData.filter(row => row.group == state.group.value))
      }

      if (state.chemical) {
        api.setFilteredData(state.initialData.filter(row => row.chemical == state.chemical.value))
      }

    }
  }, [state.group, state.chemical, state.initialData])

  return (
    <AdminGuidelinesAPIContext.Provider value={api}>
      <AdminGuidelinesContext.Provider value={state}>
        {children}
      </AdminGuidelinesContext.Provider>
    </AdminGuidelinesAPIContext.Provider>
  )
}

/* Custom Context Hooks */
export const useAdminGuidelinesContext = () => useContext(AdminGuidelinesContext)
export const useAdminGuidelinesAPI = () => useContext(AdminGuidelinesAPIContext)
