import React, { useMemo } from "react";
import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter,
} from "react-table";
import SearchFilter from "./tableComponents/searchFilter";
import Table from "./tableComponents/table";
import Pagination from "./tableComponents/pagination";
import { columns } from "./tableComponents/tableHelper";

const TableContainerSites = ({ sites }) => {
  const data = useMemo(() => sites, []);
  const tableColumns = useMemo(() => columns, []);
  const tableInstance = useTable(
    { columns: tableColumns, data },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { state, setGlobalFilter } = tableInstance;
  const { globalFilter, pageIndex, pageSize } = state;
  const {
    canPreviousPage,
    canNextPage,
    pageCount,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
  } = tableInstance;

  return (
    <>
      <div className="flex justify-between items-center my-1 mr-4">
        <SearchFilter filter={globalFilter} setFilter={setGlobalFilter} />
        <Pagination
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          pageCount={pageCount}
          pageOptions={pageOptions}
          gotoPage={gotoPage}
          nextPage={nextPage}
          previousPage={previousPage}
          setPageSize={setPageSize}
          pageIndex={pageIndex}
          pageSize={pageSize}
        />
      </div>
      <div className="h-1/3 overflow-auto">
        <Table tableInstance={tableInstance} />
      </div>
    </>
  );
};

export default TableContainerSites;
