import React from "react";
import UserAction from "./userAction";

const UserTable = ({
  users,
  onDelete,
  onImpersonate,
  onSuspend,
  onUnsuspend,
}) => (
  <table className="min-w-full bg-white">
    <thead>
      <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
        <th className="py-3 px-6 text-center">E-mail</th>
        <th className="py-3 px-6 text-center"># of Sites</th>
        <th className="py-3 px-6 text-center"># of Samples</th>
        <th className="py-3 px-6 text-center">Plan</th>
        <th className="py-3 px-6 text-center">Admin?</th>
        <th className="py-3 px-6 text-center">Suspended?</th>
        <th className="py-3 px-6 text-center">Date Created</th>
        <th className="py-3 px-6 text-center">Actions</th>
      </tr>
    </thead>
    <tbody className="text-gray-600 text-sm font-light">
      {users.map((user) => (
        <tr
          key={user.id}
          className="border-b border-gray-200 hover:bg-gray-100"
        >
          <td className="py-3 px-6 text-center">{user.email}</td>
          <td className="py-3 px-6 text-center">{user.sites?.length || 0}</td>
          <td className="py-3 px-6 text-center">{user.samples?.length || 0}</td>
          <td className="py-3 px-6 text-center">
            {user.plan?.charAt(0).toUpperCase() + user.plan?.slice(1)}
          </td>
          <td className="py-3 px-6 text-center">{user.admin ? "✅" : "❌"}</td>
          <td className="py-3 px-6 text-center">
            {user.suspended ? "✅" : "❌"}
          </td>
          <td className="py-3 px-6 text-center">
            {new Date(user.created_at).toISOString().split("T")[0]}
          </td>
          <td className="py-3 px-6 text-center">
            <UserAction
              user={user}
              onDelete={onDelete}
              onImpersonate={onImpersonate}
              onSuspend={onSuspend}
              onUnsuspend={onUnsuspend}
            />
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);

export default UserTable;