import React from 'react'
import Pathway from "./pathway";
import { useSiteAPI, useSiteContext } from "../../../contexts/site";

const SoilPathways = () => {
  const { soilPathwayOptions, soilPathways } = useSiteContext()
  const { togglePathway } = useSiteAPI()

  return <div className='flex flex-col gap-y-3'>
    <h3 className='font-semibold'>Soil Site-Specific Factors</h3>
    {soilPathwayOptions.map(pathway => <Pathway key={pathway.id} pathway={pathway} checked={soilPathways?.includes(pathway.id)} togglePathway={togglePathway('soilPathways')} />)}
  </div>
}

export default SoilPathways
