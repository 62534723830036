import React, { useMemo } from 'react'
import SampleHeaders from './sampleHeaders'
import ChemicalRow from './chemicalRow'
import AnnotationToggle from '../../annotationDoublePlotRatio/annotationToggle'

const ReviewTable = ({ samples, chemicals, highlightSampleIds, setHighlightSampleIds }) => {
  const sampleHeaders = useMemo(() => samples?.map((sample) => <SampleHeaders key={sample.id} sample={sample} highlightSampleIds={highlightSampleIds} setHighlightSampleIds={setHighlightSampleIds} />), [samples])
  const chemicalRows = useMemo(() => chemicals?.map((chemical, i) => <ChemicalRow key={i} chemical={chemical} samples={samples} />), [chemicals, samples])

  return (
    <div className='h-full overflow-auto flex flex-col'>
      <div className='sticky top-0 left-0 z-30'>
        <AnnotationToggle mode={'table'} />
      </div>
      <table className='dataVaultTable flex-1'>
        <thead>
          <tr className='sticky top-4 z-10'>
            <th className='sticky left-0 z-20 bg-white'>Parameter</th>
            <th>Unit</th>
            {sampleHeaders}
          </tr>
        </thead>
        <tbody>
          {chemicalRows}
        </tbody>
      </table>
    </div>
  )
}

export default ReviewTable
