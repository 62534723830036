export const tableDataSorter = (samples) => {
  samples?.sort((a, b) => {
    const sampleLocationA = getSampleLocationName(a);
    const sampleLocationB = getSampleLocationName(b);

    if (startsWithNumber(sampleLocationA) && !startsWithNumber(sampleLocationB)) {
      return 1;
    } else if (!startsWithNumber(sampleLocationA) && startsWithNumber(sampleLocationB)) {
      return -1;
    } else {
      return sampleLocationA.localeCompare(sampleLocationB);
    }
  });
};

const getSampleLocationName = (sample) => {
  return sample?.sample?.sample_location.name?.toLowerCase() || sample?.sample_location?.name.toLowerCase() || sample?.name?.toLowerCase();
};

const startsWithNumber = (str) => {
  return /^\d/.test(str);
};

export const getChemicalValue = (sample, chemical) => {
  const chemicalValue = sample.sample_values.find(value => value.chemical_id === chemical.id && value.unit === chemical.unit)?.float_value
  return chemicalValue || '-'
}
