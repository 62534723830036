import React from 'react'
import { formatReferenceName } from '../helpers/referenceSourceHelper'

const SampleMetadata = ({ sample, referenceSource, topReference }) => {
  return (
    <div className='w-1/4 mr-1 bg-white p-1 rounded h-full'>
      <p className='font-semibold mb-2'>Sample Metadata</p>
      <table>
        <tbody className='font-semibold text-sm'>
          <tr>
            <td>Unique Identifier:</td><td className='font-normal'>{sample.id}</td>
          </tr>
          <tr>
            <td>Sample Location:</td><td className='font-normal'>{sample.sample_location.name}</td>
          </tr>
          <tr>
            <td>Date:</td><td className='font-normal'>{sample.sample_date}</td>
          </tr>
          <tr>
            <td>Matrix:</td><td className='font-normal'>{sample.matrix.title}</td>
          </tr>
          {sample.sample_depth && (
            <tr>
              <td>Depth:</td><td className='font-normal'>{sample.sample_depth}</td>
            </tr>
          )}
          <tr>
            <td>Reference Source:</td><td className='font-normal'>{formatReferenceName(referenceSource)}</td>
          </tr>
          <tr>
            <td>Reference:</td><td className='font-normal'>{topReference.name}</td>
          </tr>
          <tr>
            <td>Similarity:</td><td className='font-normal'>{topReference.similarity}%</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default SampleMetadata
