import React from "react"
import { useAdminGuidelinesAPI, useAdminGuidelinesContext } from "../../../contexts/adminGuidelines";
import { toast } from "react-toastify";
import OptionSelect from "./select";

const InitialSelect = () => {
  const { setJurisdiction, setMatrix, setViewGuidelines } = useAdminGuidelinesAPI()
  const { jurisdiction, matrix } = useAdminGuidelinesContext()

  const jurisdictionOptions = [{ label: 'Alberta', value: 'alberta' }, { label: 'British Columbia', value: 'british columbia' }]

  const matrixOptions = [{ label: 'Soil', value: 'soil' }, { label: 'Groundwater', value: 'groundwater' }]

  const onClick = () => {
    if (jurisdiction && matrix) {
      return setViewGuidelines(true)
    }

    return toast.error('Both fields must be selected')
  }

  return (
    <div className='flex flex-col my-4'>
      <OptionSelect title={'Jurisdiction'} state={jurisdiction} options={jurisdictionOptions} onSelect={setJurisdiction} />
      <OptionSelect title={'Matrix'} state={matrix} options={matrixOptions} onSelect={setMatrix} />
      <button className='btn btn-primary w-32' onClick={onClick}>Next</button>
    </div>
  )
}

export default InitialSelect
