import React, { useEffect, useState } from 'react'
import Chart from "./chart"
import 'react-toastify/dist/ReactToastify.css';
import { canvasToPng, Lazy } from "../helpers/histogramExportHelpers";
import { downloadZip } from "client-zip"

const HiddenCharts = ({ samples, site, setChartCount, setChartData }) => {
  const [charts, setCharts] = useState([])
  const [status, setStatus] = useState({ started: 0, finished: 0 })
  // const [finishedSamples, setFinishedSamples] = useState({})
  const finishedSamples = {}

  const addChart = (chart) => {
    setCharts(prevState => [...prevState, chart])
  }

  const removeChart = (chartKey) => {
    setCharts(prevState => prevState.filter(x => x.key != chartKey))
  }

  const [link, setLink] = useState({ disabled: true, message: 'Loading...' })

  const asPngDataUrlPromise = (svg) => {
    return new Promise((resolve) => {
      const pngCallback = (pngDataUrl) => {
        resolve(pngDataUrl)
      }
      canvasToPng(svg, pngCallback)
    })
  }

  // richard 2023-03 does this function belong on a helpers js file?
  function toPngBytes(dataUrl) {
    // richard 2023-03 should this fail fast if the data url doesnt begin with "data:image/png;base64," ?
    const base64 = dataUrl.substring("data:image/png;base64,".length)
    return Uint8Array.from(window.atob(base64), (v) => v.charCodeAt(0));
  }

  const svgPromiseToPngFile = async (chartKeySvgPromise) => {
    const {chartKey, svg} = await chartKeySvgPromise
    const input = toPngBytes(await asPngDataUrlPromise(svg));
    setStatus(prevState => {return { finished: prevState.finished + 1, started: prevState.started }})
    return {name: `${chartKey}.png`, lastModified: new Date(), input}
  }

  const buildSvgPromise = (sample, options) => {
    const newSampleName = sample.sample_name_for_json.replace(" @ ", "_").replace(".", "_")
    const sampleType = options.type === 'Concentration' ? '_conc' : '_perc'
    setStatus(prevState => {return { finished: prevState.finished, started: prevState.started + 1}})
    const chartKey = newSampleName + sampleType
    return new Promise(resolve => {
      const handleSvg = (svg, _ignore1, _ignore_2) => {
        removeChart(chartKey)
        resolve({ chartKey, svg})
      }

      addChart(<Chart
        key={chartKey}
        id={options.index}
        sample={sample}
        site={site}
        getChartInfo={handleSvg}
        setChartCount={setChartCount}
        type={options.type}
        units={options.units}
        field={options.field}
      />)
    })
  }

  const asSvgPromises = (sample, index) => {
    const concentrationPromise = buildSvgPromise(sample, {
      type: 'Concentration', units: 'mg/kg', field: 'effective_value', index: index
    });
    const contributionPromise = buildSvgPromise(sample, {
      type: 'Contribution', units: '%', field: 'percentage', index: index
    });
    return [concentrationPromise, contributionPromise]
  }

  const zipDownload = async () => {
    let files = Lazy(samples).map(asSvgPromises).flat().chunk(5).flat().map(svgPromiseToPngFile);
    const blob = await downloadZip(files).blob()
    setLink({ href: URL.createObjectURL(blob), disabled: false, download: `Histogram_Export_${site.title}.zip`, message: "download" })
  }

  useEffect(() => {zipDownload()}, [])

  return <>
    <div className='flex flex-col gap-y-4'>
      <div className='flex flex-row space-between gap-x-4'>
        <h2 className='font-semibold text-lg'>Export Status:</h2>
        <p className='text-lg'>Started: {status.started}</p>
        <p className='text-lg'>Loading: {status.started - status.finished}</p>
        <p className='text-lg'>Finished: {status.finished}</p>
      </div>
      <a
        className={"w-1/5 text-center font-bold uppercase p-3 shadow-lg rounded block leading-normal " +
          `${link?.disabled ? 'cursor-not-allowed bg-gray-200 text-gray-800' : 'cursor-pointer text-white bg-blue-300 '}`}
        href={link?.href}
        disabled={link?.disabled}
        download={link?.download}
      >
        {link?.message}
      </a>
      <div id="allCharts">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {charts}
        </div>
      </div>
    </div>
  </>
}

export default HiddenCharts
