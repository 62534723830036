import React, { useEffect, useState } from "react";
import Select from "react-select";

const ParameterRow = ({ title, symbol, unit, reference, state, setState}) => {
  const [background, setBackground] = useState('')
  let options = [{ label: 'Default', value: false }, { label: 'Site Specific', value: true }]

  const checkIfDecimal = (value) => {
    const re = /^\d*\.?\d*$/

    if (value === '' || re.test(value)) {
      setState((prevState) => ({...prevState, value: value}))
    }
  }

  useEffect(() => {
    setState({ value: reference, site_specific: false })
  }, [])

  useEffect(() => {
    if (Number(state.value) !== reference) {
      setBackground('bg-yellow-200')
    } else {
      setBackground('')
    }
  }, [state])

  return <>
    <div className='flex flex-row items-center px-2'>
      <h3 className='w-1/2'>{title}</h3>
      <div className='flex flex-row w-1/2'>
        <h3 className='w-1/6'>{symbol}</h3>
        <h3 className='w-1/6'>{unit}</h3>
        <div className='w-1/2'>
          <Select
            className='w-3/4'
            defaultValue={options[0]}
            onChange={(event) => setState((prevState) => ({...prevState, site_specific: event.value}))}
            options={options}
          />
        </div>
        <div className='w-1/4 flex items-center'><p>{reference}</p></div>
        <div className='w-1/4 flex items-center'>
          {state.site_specific && <input
            placeholder={reference}
            className={`px-2 border rounded-md border-gray-300 w-2/3 ${background}`}
            value={state.value}
            onChange={(event) => checkIfDecimal(event.target.value)}
          />}
        </div>
      </div>
    </div>
    <hr className='my-2'/>
  </>
}

export default ParameterRow
