import React, { createContext, useReducer, useMemo, useContext } from 'react'

/* Actions */
const SET_PARAMETER_COLUMNS = 'SET_PARAMETER_COLUMNS'
const SET_UNITS = 'SET_UNITS'
const SET_SELECTED_UNITS = 'SET_SELECTED_UNITS'
const SET_SHOW_MODAL = 'SET_SHOW_MODAL'
const SET_CHANGED_PARAMETERS = 'SET_CHANGED_PARAMETERS'
const SET_CHANGED_PARAMETERS_UNITS = 'SET_CHANGED_PARAMETERS_UNITS'
const REMOVE_CHANGED_PARAMETER = 'REMOVE_CHANGED_PARAMETER'
const SET_UNMAPPED_PARAMETERS = 'SET_UNMAPPED_PARAMETERS'
const REMOVE_UNMAPPED_PARAMETER = 'REMOVE_UNMAPPED_PARAMETER'
const SET_FORMATTED_HEADERS = 'SET_FORMATTED_HEADERS'
const SET_GROUNDWATER_META_DATA = 'SET_GROUNDWATER_META_DATA'

/* Initial States */
const initialState = (columnNames, units, headers, matrix, chemicals, analyticalMethods, site, deleteRoute, siteUpload) => {
  const updatedHeaders = headers?.map(header => {
    return { value: header[0], label: header[1] }
  })

  return {
    parameterColumns: {},
    selectedUnits: units || {},
    showModal: false,
    changedParameters: {},
    unmappedParameters: columnNames?.map(column => column[0]),
    formattedHeaders: updatedHeaders || [],
    groundwaterMetaData: {},
    columnNames: columnNames || [],
    units: units || [],
    headers: headers || [],
    matrix: matrix || {},
    chemicals: chemicals || [],
    analyticalMethods: analyticalMethods || [],
    site: site,
    deleteRoute: deleteRoute,
    siteUpload: siteUpload,
  }
}

/* Reducer */
const parameterSelectionReducer = (state, action) => {
  switch (action.type) {
    case SET_PARAMETER_COLUMNS:
      return { ...state, parameterColumns: {...state.parameterColumns, [action.field]: action.value }}
    case SET_UNITS:
      return { ...state, selectedUnits: action.value }
    case SET_SELECTED_UNITS:
      return { ...state, selectedUnits: {...state.selectedUnits, [action.field]: action.value }}
    case SET_SHOW_MODAL:
      return { ...state, showModal: action.value }
    case SET_CHANGED_PARAMETERS:
      return { ...state, changedParameters:
        {...state.changedParameters, [action.field]: {
            ...state.changedParameters[action.field],
            originalParameter: action.label,
            newParameter: action.option?.label
          }
        }
      }
    case SET_CHANGED_PARAMETERS_UNITS:
      return { ...state, changedParameters:
        {...state.changedParameters, [action.field]: {
            ...state.changedParameters[action.field],
            originalParameter: action.label,
            originalUnit: action.unit,
            newUnit: action.option?.value
          }
        }
      }
    case REMOVE_CHANGED_PARAMETER:
      return { ...state, changedParameters: {...state.changedParameters, [action.field]: null } }
    case SET_UNMAPPED_PARAMETERS:
      return { ...state, unmappedParameters: action.value }
    case REMOVE_UNMAPPED_PARAMETER:
      return { ...state, unmappedParameters: [...state.unmappedParameters.filter(parameterColumn => parameterColumn !== action.value)]}
    case SET_FORMATTED_HEADERS:
      return { ...state, formattedHeaders: action.value }
    case SET_GROUNDWATER_META_DATA:
      return { ...state, groundwaterMetaData: {...state.groundwaterMetaData, [action.field]: action.value }}
    default:
      return state
  }
}

/* Contexts */
const ParameterSelectionContext = createContext(initialState)
const ParameterSelectionApiContext = createContext({
  setParameterColumns: () => {},
  setUnits: () => {},
  setSelectedUnits: () => {},
  setShowModal: () => {},
  setChangedParameters: () => {},
  setChangedParameterUnits: () => {},
  removeChangedParameter: () => {},
  removeUnmappedParameter: () => {},
  setUnmappedParameters: () => {},
  setFormattedHeaders: () => {},
  setGroundwaterMetaData: () => {},
})

/* Providers */
export const ParameterSelectionProvider = ({ columnNames, units, headers, matrix, chemicals, children, analyticalMethods, site, deleteRoute, siteUpload }) => {
  const [state, dispatch] = useReducer(parameterSelectionReducer, initialState(columnNames, units, headers, matrix, chemicals, analyticalMethods, site, deleteRoute, siteUpload))

  const api = useMemo(() => {
    const setParameterColumns = (field, value) => dispatch({ type: SET_PARAMETER_COLUMNS, field, value})

    const setUnits = (value) => dispatch({ type: SET_UNITS, value})

    const setSelectedUnits = (field, value) => dispatch({ type: SET_SELECTED_UNITS, field, value})

    const setShowModal = (value) => dispatch({ type: SET_SHOW_MODAL, value})

    const setChangedParameters = (field, label, option) => dispatch({ type: SET_CHANGED_PARAMETERS, field, label, option})

    const setChangedParameterUnits = (field, label, unit, option) => dispatch({ type: SET_CHANGED_PARAMETERS_UNITS, field, label, unit, option})

    const removeChangedParameter = (field) => dispatch({ type: REMOVE_CHANGED_PARAMETER, field })

    const setUnmappedParameters = (value) => dispatch({ type: SET_UNMAPPED_PARAMETERS, value})

    const removeUnmappedParameter = (value) => dispatch({ type: REMOVE_UNMAPPED_PARAMETER, value})

    const setFormattedHeaders = (value) => dispatch({ type: SET_FORMATTED_HEADERS, value})

    const setGroundwaterMetaData = (field, value) => dispatch({ type: SET_GROUNDWATER_META_DATA, field, value})

    return { setParameterColumns, setUnits, setSelectedUnits, setShowModal, setChangedParameters, setChangedParameterUnits, removeChangedParameter, setUnmappedParameters, removeUnmappedParameter, setFormattedHeaders, setGroundwaterMetaData }
  }, [])

  return (
    <ParameterSelectionApiContext.Provider value={api}>
      <ParameterSelectionContext.Provider value={state}>
        {children}
      </ParameterSelectionContext.Provider>
    </ParameterSelectionApiContext.Provider>
  )
}

/* Custom Context Hooks */
export const useParameterSelectContext = () => useContext(ParameterSelectionContext)
export const useParameterSelectApi = () => useContext(ParameterSelectionApiContext)


