import axios from "axios"

export const numberToLetter = (number) => {
  if (number <= 0) return ''

  const modulo = (number - 1) % 26
  const letter = String.fromCharCode(65 + modulo)
  const remainingNumber = Math.floor((number - modulo) / 26)

  return numberToLetter(remainingNumber) + letter
}

export const downloadCSV = (siteUpload) => {
  const siteId = siteUpload.site_id
  const uploadId = siteUpload.id
  axios.get(`/sites/${siteId}/site_uploads/${uploadId}/data`)
    .then((response) => {
      const filename = response.headers['content-disposition'].split("filename*=UTF-8''")[1]
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url;
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    })
    .catch((err) => console.log(err))
}

export const abortUpload = (siteUpload) => {
  const siteId = siteUpload.site_id
  const uploadId = siteUpload.id
  axios.delete(`/sites/${siteId}/site_uploads/${uploadId}`)
    .then(() => window.location.href = `/sites/${siteId}/site_uploads/new`)
    .catch(error => console.error(error))
}
