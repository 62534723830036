import React, { useState } from 'react';
import axios from 'axios';
import SamplesTable from './formComponents/samplesTable';
import SampleGroupTable from './formComponents/sampleGroupTable';

const saltPrintForm = ({ samples, chemicals, siteId, saltPrintId }) => {
  const initializedSamples = samples?.map((sample) => {
    if (sample.sample_values.length < 6) return {...sample, type: 'Excluded'}
    return {...sample, type: 'Target'}
  });
  
  const [saltSamples, setSaltSamples] = useState(initializedSamples);
  const isDisabled = (sample) => sample.sample_values.length < 6;
  const typeOptions = [
    {label: 'Target', value: 'Target'},
    {label: 'Background', value: 'Background'},
    {label: 'Excluded', value: 'Excluded'}
  ];
  
  const createParamsFromSaltSamples = (saltSamples) => {
    const params = {
      salt_print: {}
    };
  
    saltSamples.forEach((sample) => {
      const sampleId = sample.id;
      const sampleType = sample.type;
      
      params.salt_print[sampleId] = sampleType;
    });
  
    return params;
  };
  
  const onSubmitHandler = () => {
    const saltPrintParams = createParamsFromSaltSamples(saltSamples);
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    
    axios.defaults.headers.common['X-CSRF-Token'] = csrfToken;
    axios.patch(`/sites/${siteId}/salt_prints/${saltPrintId}/update_samples`, saltPrintParams)
      .then((_res) => {
        window.location.href = `/sites/${siteId}/salt_prints/${saltPrintId}`
      })
      .catch((err) => {
        console.log(err)
      })
  };
  
  return (
    <div className="h-full flex flex-col">
      <h1>Map Salt Sample Prints</h1>
      <SampleGroupTable saltSamples={saltSamples} typeOptions={typeOptions} isDisabled={isDisabled} setSaltSamples={setSaltSamples}/>
      <SamplesTable saltSamples={saltSamples} chemicals={chemicals} isDisabled={isDisabled} typeOptions={typeOptions} setSaltSamples={setSaltSamples}/>
      <button className='btn btn-default w-1/6' onClick={onSubmitHandler}>Submit</button>
    </div>
  )
};

export default saltPrintForm;
