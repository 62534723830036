export const formattedUnit = (unit) => {
  switch (true) {
    case unit?.includes("Â"):
      return unit?.replace("Â", '')
    case unit === '% by weight':
      return '%'
    case unit === 'T/ac':
      return 't/ac'
    case unit?.includes('ug'):
      return unit?.replace('ug', 'µg')
    default:
      return unit
  }
}

export const unitOptions = [
  { label: 'mg/kg', value: 'mg/kg' },
  { label: 'µg/kg', value: 'µg/kg' },
  { label: 'µg/g', value: 'µg/g' },
  { label: 'g/L', value: 'g/L'},
  { label: 'mg/L', value: 'mg/L' },
  { label: 'µg/L', value: 'µg/L' },
  { label: '%', value: '%' },
  { label: 'dS/m', value: 'dS/m' },
  { label: 'µS/cm', value: 'µS/cm' },
  { label: 'pH', value: 'pH' },
  { label: 't/ha', value: 't/ha' },
  { label: 't/ac', value: 't/ac' },
]
