import React from "react";

const SiteLocationTable = ({ site }) => {
  return <>
    <div className='my-4'>
      <h3 className="font-semibold">Site Location</h3>
      <table className='w-full text-left'>
        <tbody>
        <tr>
          <td className="w-1/6">LSD:</td>
          <td>{site.siteLocation.lsd}</td>
        </tr>
        <tr>
          <td className="w-1/6">Quarter:</td>
          <td>{site.siteLocation.quarter}</td>
        </tr>
        <tr>
          <td className="w-1/6">Section:</td>
          <td>{site.siteLocation.section}</td>
        </tr>
        <tr>
          <td className="w-1/6">Township:</td>
          <td>{site.siteLocation.township}</td>
        </tr>
        <tr>
          <td className="w-1/6">Range:</td>
          <td>{site.siteLocation.range}</td>
        </tr>
        <tr>
          <td className="w-1/6">Meridian:</td>
          <td>{site.siteLocation.meridian}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </>
}

export default SiteLocationTable
