import React, { useContext, useState } from "react";
import { TierOneContext } from "../tierOneContext"
import QuestionYesNo from "../shared/questionYesNo";
import AdjacentLandUse from "../shared/adjacentLandUse";
import ValidateSubmitButton from "../shared/validateSubmitButton";

const SiteDetails = ({ step, setStep }) => {
  let [site, setSite] = useContext(TierOneContext);
  let [message, setMessage] = useState('')

  const hasError = () => {
    const answers = Object.values(site.siteDetails).filter((value) => value != '').length

    if (answers < 4) {
      setMessage('Please answer all questions');
      return true
    }

    if (site.siteDetails.sensitive === 'Yes' && !site.siteDetails.adjacentLandUse) {
      setMessage('Please select adjacent land use');
      return true
    }

    return false
  }

  return (
    <>
    <h1>Site Details</h1>
    <ValidateSubmitButton
      message={message}
      step={step}
      setStep={setStep}
      hasError={() => hasError()}
    >
      <QuestionYesNo type='wellsite' />
      <QuestionYesNo type='boundary' />
      <QuestionYesNo type='organic' />
      <QuestionYesNo type='sensitive' />
      {site.siteDetails.sensitive === 'Yes' && <div className='my-4'>
        <p>Please Indicate Land Use:</p>
        <AdjacentLandUse title='Natural Area'/>
        <AdjacentLandUse title='Agricultural' />
        <AdjacentLandUse title='Residential' />
        <AdjacentLandUse title='Commercial/Industrial' />
        </div>}
    </ValidateSubmitButton>
    </>
  )
}

export default SiteDetails
