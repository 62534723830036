import React from 'react';
import { PahFingerprintingProvider } from "../../../contexts/pahFingerprinting";
import PahFingerprintingBody from "./pahFingerprintingBody";

const PahFingerprinting = ({ sampleData, siteInfo, geoJSON, chemicals, sourceReferences }) => {
  return (
    <>
    <div className='h-full'>
      <PahFingerprintingProvider>
        <PahFingerprintingBody siteInfo={siteInfo} sampleData={sampleData} geoJSON={geoJSON} chemicals={chemicals} sourceReferences={sourceReferences}/>
      </PahFingerprintingProvider>
    </div>
    </>
  )
}

export default PahFingerprinting
