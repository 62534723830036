import React from "react";

const ValueSelectors = ({valueRange, setValueRange}) => {
  const handlStartValueRangeChange = (e) => {
    setValueRange({...valueRange, start: e.target.value})
  };
  
  const handleEndValueRangeChange = (e) => {
    setValueRange({...valueRange, end: e.target.value})
  };
  
  return (
    <>
      <div className="flex flex-col mr-2">
        <label className="sv-label">Start Value:</label>
        <input className="sv-filters-select" type="number" onChange={handlStartValueRangeChange} value={valueRange.start} placeholder="Enter Start Value"/>
      </div>
      <div className="flex flex-col mr-2">
        <label className="sv-label">End Value:</label>
        <input className="sv-filters-select" type="number" onChange={handleEndValueRangeChange} value={valueRange.end} placeholder="Enter End Value"/>
      </div>
    </>
  )
};

export default ValueSelectors;
