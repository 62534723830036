const resultTextColor = (result) => {
  if (result.includes("Pyrogenic")) {
    return 'text-red-500'
  } else if (result.includes("Indeterminate")) {
    return 'text-gray-500'
  }

  return
}

export default resultTextColor
