import React from 'react'

export const LegendExpandButton = ({setExpandLegend}) => {
  return (
    <li>
      <div className="flex items-center justify-center border border-black w-4 h-4 pt-0.5 hover:bg-gray-300 cursor-pointer rounded" onMouseEnter={() => setExpandLegend(true)} onMouseLeave={() => setExpandLegend(false)}>
        <span className='text-xs font-semibold'><i className='fa fa-chevron-down'/></span>
      </div>
    </li>
  )
}

export default LegendExpandButton
