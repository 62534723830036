import React from "react";
import ChemicalHeader from "./chemicalHeader"

const TableHeaders = ({chemicals, hasUniqueIds}) => {
  const hasMethods = chemicals.map(chemical => chemical?.analytical_method).filter(value => value != undefined).length > 0

  const renderChemicalHeaders = chemicals?.map((chemical, index) => {
    return <ChemicalHeader key={index} chemical={chemical} hasMethods={hasMethods} />
  })

  return (
    <thead className= "uppercase sticky left-0 top-0 z-20">
      <tr>
        <th className= "uppercase sticky left-0 top-0 z-10 bg-gray-200 border-none px-2 py-1">Sample Name</th>
        {hasUniqueIds && <th className= "border-none uppercase px-2 py-1">Unique Sample ID</th>}
        <th className= "border-none uppercase px-2 py-1">Depth</th>
        <th className= "border-none uppercase px-2 py-1">Sample Group</th>
        <th className= "border-none uppercase px-2 py-1">Status</th>
        <th className= "border-none uppercase px-2 py-1">Sample Date</th>
        <th className= "border-none uppercase px-2 py-1">Matrix</th>
        {renderChemicalHeaders}
      </tr>
    </thead>
  )
};

export default TableHeaders;
