import React, { useEffect, useState } from "react"
import AliasInput from "./aliasInput";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const Chemical = ({ chemical }) => {
  const [inputList, setInputList] = useState([]);
  const [aliases, setAliases] = useState({})

  useEffect(() => {
    if (chemical) {
      if (chemical.aliases?.length > 0) {
        setInputList(chemical.aliases.map((alias, index) => {
          return <AliasInput key={index} setAliases={setAliases} index={index} defaultValue={alias} />
        }))
      } else {
        setInputList([<AliasInput key={inputList.length} setAliases={setAliases} index={inputList.length} />]);
      }
    }
  }, [chemical])

  const onAddBtnClick = () => {
    setInputList(inputList.concat(<AliasInput key={inputList.length} setAliases={setAliases} index={inputList.length} />))
  }

  const onSubmitClick = () => {
    const filteredAliases = Object.values(aliases).filter(alias => alias && alias.trim().length > 0 )
    axios.patch(`/chemicals/${chemical.id}.json`, { aliases: Object.values(filteredAliases) })
      .then(res => {
        toast.success('Aliases updated!')
        setInputList(res.data.aliases.map((alias, index) => {
          return <AliasInput key={index} setAliases={setAliases} index={index} defaultValue={alias} />
        }))
      })
  }

  return <>
    <ToastContainer />
    <h1 className='font-semibold'>Alias Names</h1>
    <div className='flex flex-col w-1/4 gap-y-3'>
      {inputList}
      <div className='flex flex-row justify-between'>
        <button
          className="btn btn-default"
          onClick={onAddBtnClick}
        >
          Add Alias
        </button>
        <button
          className="btn btn-default"
          onClick={onSubmitClick}
        >
          Submit
        </button>
      </div>
    </div>
  </>
}

export default Chemical
