import React from "react";

const ChemicalValue = ({ chemicalValue }) => {
  const valueStyling = (chemicalValue) => {
    if (chemicalValue?.exceedance) {
      if (chemicalValue?.less_than) return 'bg-gray-200'

      if (chemicalValue?.float_value > 10 * chemicalValue?.guideline) return 'bg-red-300'

      if (chemicalValue?.float_value > 3 * chemicalValue?.guideline) return 'bg-yellow-400'

      if (chemicalValue?.float_value > chemicalValue?.guideline) return 'bg-yellow-100'
    }

    return ''
  }

  const formattedValue = (chemicalValue) => {
    if (chemicalValue) {
      if (chemicalValue.less_than) return '<' + chemicalValue.float_value
      return chemicalValue.float_value || chemicalValue.string_value
    }

    return '-'
  }

  return <>
    <td className={`text-center ${valueStyling(chemicalValue)}`}>{formattedValue(chemicalValue)}</td>
  </>
}

export default ChemicalValue
