import React from "react"
import Label from "./Label"

export const Input = (props) => {
  return (
    <div className="my-3 text-center sm:mt-5 flex items-start flex-col">
      <Label title={props.title} requiredField={props.required} />
      <input
        type={props.type}
        pattern={props.pattern}
        id={props.name}
        className={props.disabled ? inputClass + ' opacity-50' : inputClass}
        {...props}
      />
    </div>
  )
}

const inputClass = "block bg-white w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300  placeholder:text-gray-400  focus:outline-none focus:ring focus:ring-raspberrySorbet  sm:text-sm sm:leading-6 px-2"

export default Input
