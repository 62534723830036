import React, { useContext, useState } from "react";
import { TierOneContext } from "../tierOneContext";
import ValidateSubmitButton from "../shared/validateSubmitButton";

const Contaminants = ({ step, setStep, setShowAnswers }) => {
  let [site, setSite] = useContext(TierOneContext);
  let [message, setMessage] = useState('');

  const pH = 'pH';
  const SALINITY = 'salinity';
  const METALS = 'metals';
  const AMINES = 'amines';

  const handleChange = ({ target: { checked, value }}) => {
    if (checked) {
      setSite((prevState) => ({...prevState, contaminants: [...prevState.contaminants, value]}))
    } else {
      setSite((prevState) => ({...prevState, contaminants: prevState.contaminants.filter((contaminant) => contaminant !== value)}))
    }
  }

  const hasError = () => {
    return false
  }

  return (
    <>
    <ValidateSubmitButton
      message={message}
      step={step}
      setStep={setStep}
      setShowAnswers={setShowAnswers}
      hasError={() => hasError()}
    >
      <h1>Contaminants of Concern</h1>
      <p className='mt-2'>In addition to generic Tier 1 guidelines, the parameters and contaminant groups below have additional regulatory documents that apply. Select all that apply to your site:</p>
      <div className="my-16">
        <div className='my-1'>
        <input 
          type="checkbox" 
          id={pH}
          name={pH}
          value={pH}
          onChange={handleChange}
        />
        <label className="mx-2" htmlFor="pH">pH</label>
        </div>
        <div className='my-1'>
        <input 
          type="checkbox" 
          id={SALINITY} 
          name={SALINITY}
          value={SALINITY}
          onChange={handleChange}
          />
        <label className="mx-2" htmlFor={SALINITY}>Salinity</label>
        </div>
        <div className='my-1'>
        <input 
          type="checkbox" 
          id={METALS} 
          name={METALS}
          value={METALS}
          onChange={handleChange}  
        />
        <label className="mx-2" htmlFor={METALS}>Metals</label>
        </div>
        <div className='my-1'>
        <input 
          type="checkbox" 
          id={AMINES} 
          name={AMINES}
          value={AMINES}
          onChange={handleChange}
        />
        <label className="mx-2" htmlFor={AMINES}>Amines and/or Glycols</label>
        </div>
      </div>
    </ValidateSubmitButton>
    </>
  )

}

export default Contaminants
