import React from 'react'

const SampleHeaders = ({ sample, highlightSampleIds, setHighlightSampleIds }) => {
  const highlighted = highlightSampleIds?.includes(sample.id) ? 'bg-blue-300' : 'bg-transparent'
  const headerTitle = sample.sample_depth ? `${sample.matrix.title} @ ${sample.sample_depth} - ${sample.sample_date}` : `${sample.matrix.title} - ${sample.sample_date}`
  const hasSelectedSample = highlightSampleIds?.length > 0
  
  return (
    <th key={sample.id} className='sticky top-0 z-10 p-1'>
      <div className='flex flex-col justify-center items-center mx-2 hover:bg-blue-200 cursor-pointer p-1 rounded'  onClick={()=> setHighlightSampleIds([sample.id])}>
        <div className={`text-center text-black rounded-lg px-1 ${highlighted}`}>{sample.sample_location.name}</div>
        <div className='text-center'>{headerTitle}</div>
      </div>
    </th>
  )
}

export default SampleHeaders
