export const formatReferenceName = (referenceSource) => {
  switch(referenceSource) {
    case 'frame':
      return 'Frame'
    case 'rushneck':
      return 'Rushneck'
    case 'pacific':
      return 'Pacific Rim'
    default:
      return ''
  }
}
