import React, { useState, useEffect } from 'react'
import axios from 'axios'

const NonDetectAlert = ({ chemical_id, site_id, non_detect_percent }) => {
  const [isModal, setIsModal] = useState(false)
  const [selectedMethod, setSelectedMethod] = useState(null)
  const [appliedMethod, setAppliedMethod] = useState(false)
  const [icon, setIcon] = useState(false)
  const token = document.querySelector('[name=csrf-token]').content
  axios.defaults.headers.common['X-CSRF-TOKEN'] = token

  const [validOptions, setValidOptions] = useState([])

  const applyMethod = (e) => {
    e.preventDefault()
    axios({
      method: 'POST',
      url: `/sites/${site_id}/non_detect_replacements/`,
      data: {
        non_detect_replacement: {
          chemical_id: chemical_id,
          replacement_method: selectedMethod,
        },
      },
      header: { 'Content-Type': 'application/json' },
    })
      .then((res) => {
        setIsModal(false)
        setIcon(true)
      })
      .catch((err) => console.log(err))
  }

  useEffect(() => {
    axios({
      method: 'GET',
      url: `/sites/${site_id}/non_detect_replacements/find?chemical_id=${chemical_id}`,
      header: { 'Content-Type': 'application/json' },
    })
      .then((res) => {
        setAppliedMethod(res.data)
      })
      .catch((err) => console.log(err))
  }, [selectedMethod])

  useEffect(() => {
    axios({
      method: 'GET',
      url: `/sites/${site_id}/non_detect_replacements/valid_methods?chemical_id=${chemical_id}`,
      header: { 'Content-Type': 'application/json' },
    })
      .then((res) => {
        setValidOptions(res.data)
      })
      .catch((err) => console.log(err))
  }, [])

  const HalfDetectOption = () => {
    return validOptions.includes('half_detection_limit') && <div className='flex flex-row items-center'>
      <input
        onChange={(e) => setSelectedMethod(e.target.value)}
        id='replacement_half_detect'
        name='method_select'
        className='h-4 w-4'
        value='half_detection_limit'
        type='radio'
        checked={selectedMethod === 'half_detection_limit'}
        required
      />
      <label className='pl-1 text-lg' htmlFor='replacement_half_detect'>
        Use 1/2 Detection Limit
      </label>
    </div>
  }

  const KaplanMeierOption = () => {
    return <div className='flex flex-col'>
      <div className='flex flex-row items-center'>
        <input
          onChange={(e) => setSelectedMethod(e.target.value)}
          id='replacement_kaplan_meier'
          name='method_select'
          className='h-4 w-4'
          value='kaplan_meier'
          type='radio'
          checked={selectedMethod === 'kaplan_meier'}
          disabled={!validOptions.includes('kaplan_meier')}
          required
        />
        <label className='pl-1 text-lg' htmlFor='replacement_kaplan_meier'>
          Use Kaplan Meier Algorithm
        </label>
      </div>
      <div>
        {!validOptions.includes('kaplan_meier') &&
        <sub>No non-detect found or not enough unique values to use Kaplan Meier.</sub>}
      </div>
    </div>
  }

  const NonDetectModal = () => {
    return (
      <>
        <div
          className='justify-center items-center flex  overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
          <div className='relative w-auto my-6 mx-auto max-w-3xl'>
            <form onSubmit={(e) => applyMethod(e)}>
              <div
                className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                <div className='flex items-center justify-between p-5 rounded-t'>
                  <h3 className='text-xl font-semibold'>Non-Detect Replacement Method</h3>
                  <button
                    className='p-1 ml-auto border-0 text-black text-3xl leading-none font-semibold outline-none focus:outline-none'
                    onClick={() => setIsModal(false)}
                  >
                    <div className='text-black text-2xl items-center'>
                      ×
                    </div>
                  </button>
                </div>
                <div className='relative p-6 flex-auto border-t border-gray-200'>
                  <div className='flex flex-col '>
                    <div className='flex items-center'>
                      <HalfDetectOption />
                    </div>
                    <div className='flex items-center'>
                      <KaplanMeierOption />
                    </div>
                  </div>
                </div>
                <div className='flex items-center justify-end p-6  rounded-b'>
                  <button
                    className='text-red-500 border border-red-500 shadow hover:shadow-xl background-transparent font-bold uppercase px-6 py-3 rounded text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                    type='button'
                    onClick={() => setIsModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className=' border border-gray-300 font-bold uppercase shadow hover:shadow-xl text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                    type='submit'
                  >
                    Apply
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
      </>
    )
  }
  return (
    <>
      <span>{non_detect_percent}</span>
      <i
        title={
          appliedMethod
            ? appliedMethod.replacement_method === 'half_detection_limit'
            ? '1/2 Detection Limit applied'
            : 'Kaplan Meier Algorithm applied'
            : 'Replacement method needed'
        }
        onClick={() => setIsModal(!isModal)}
        className={
          appliedMethod || icon
            ? ' fas fa-check text-blue-700 text-xs cursor-pointer px-1'
            : `fas fa-exclamation text-blue-700 text-xs cursor-pointer px-1`
        }
      />

      {isModal ? <NonDetectModal /> : null}
    </>
  )
}

export default NonDetectAlert
