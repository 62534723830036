import React, { useState } from 'react';
import axios from 'axios';

const AttachmentModal = ({site, sampleLocations, showModal, setShowModal}) => {
  const [name, setName] = useState('');
  const [location, setLocation] = useState('');
  const [data, setData] = useState(null);
  const locationOptions = sampleLocations.map((location) => <option key={location.id} value={location.id}>{location.name}</option>);
  
  const createAttachment = (e) => {
    e.preventDefault();
    
    const formData = new FormData();
    formData.append('site_attachment[name]', name);
    formData.append('site_attachment[sample_location_id]', location);
    formData.append('site_attachment[data]', data);
    
    axios.post(`/sites/${site.id}/site_attachments`, formData)
      .then((_response) => {
        window.location.reload();
        setShowModal(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  
  return (
    <>
       {showModal && (
        <div className="modal-container fixed inset-0 flex items-center justify-center z-20">
          <div className="modal-overlay absolute inset-0 bg-gray-900 opacity-50"></div>
          <div className="modal-content-container bg-white w-1/4 h-auto rounded-lg shadow-lg z-50 overflow-y-auto flex justify-center items-center">
            <div className="modal-content relative p-5 overflow-visible">
              <button className="border bg-gray-600 rounded-full w-8 h-8 flex items-center justify-center absolute top-3 right-3 -m-2 z-50" onClick={() => setShowModal(false)}>
                <i className='fa fa-times text-white font-extrabold'/>
              </button>
              <h1 className="text-xl font-semibold text-center">Upload Attachment</h1>
              <form onSubmit={createAttachment}>
                <label className="sv-label">Name</label>
                <input className="sv-input" type="text" onChange={(e) => setName(e.target.value)} required/>
                <label className="sv-label">Location</label>
                <select className="sv-select" onChange={(e) => setLocation(e.target.value)}>
                  <option value="">Site-wide</option>
                  {locationOptions}
                </select>
                <label className="sv-label">Data</label>
                <input className="sv-file-input" type="file" onChange={(e) => setData(e.target.files[0])} required/>
                <div className="form-actions">
                  <button type="submit" className="btn btn-default">Upload</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AttachmentModal
