import React, { useState, useEffect } from "react";
import axios from "axios";
import SummaryRow from "./summaryRow";
import UploadInfo from "./uploadInfo";

const UploadLog = ({ site_id }) => {
  const [uploads, setUploads] = useState();
  const [showUploads, setShowUploads] = useState(false);

  useEffect(() => {
    axios({
      method: "GET",
      url: `/sites/${site_id}/uploads_log.json`,
      params: { id: site_id },
    })
      .then((response) => {
        setUploads(response.data.site_uploads);
      })
      .catch((err) => console.log(err));
  }, []);
  const uploadList =
    uploads &&
    uploads.map((upload, index) => {
      return <UploadInfo key={upload.name + index} upload={upload} />;
    });

  return (
    <>
      <div className="w-full">
        {uploads && (
          <>
            <SummaryRow
              uploads={uploads}
              setShowUploads={setShowUploads}
              showUploads={showUploads}
            />
            {showUploads && (
              <div className="px-2 py-2 border-t-2 border-blue-400 rounded w-full">
                <p className="text-lg text-medium">Upload Error Log</p>
                <p className="text-xs"> * Total row count does not include the header row</p>
                <p className="text-xs"> * Row errors are the exact row #</p>
                <p className="text-xs"> * Please review the upload document for details</p>
                {uploadList}
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default UploadLog;
