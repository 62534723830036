import React from "react";

const TableRow = ({ rowData, setHighlightSampleIds, highlightSampleIds, tableData, hasUniqueIds }) => {
  const { id, name, date, group, status, value, sampleUniqueId, lessThan } = rowData;
  const boreholeIds = tableData.filter((row) => row.name === name).map((row) => row.id);
  const handleClick = () => setHighlightSampleIds(boreholeIds);
  const highlightRow = (id) => highlightSampleIds.includes(id) ? "bg-blue-200 hover:bg-blue-200" : "bg-white";
  const valueDisplay = lessThan ? `<${value}` : value;
  return (
    <tr
      key={id}
      id={`tr-${id}`}
      onClick={handleClick}
      className={`${highlightRow(id)} cursor-pointer text-center text-xs`}
      style={{ scrollMarginTop: "2rem" }}
    >
      <td className="text-left">{name}</td>
      {hasUniqueIds && <td className="text-left">{sampleUniqueId}</td>}
      <td>{group || "-"}</td>
      <td>{status || "-"}</td>
      <td>{date}</td>
      <td>{valueDisplay}</td>
    </tr>
  );
};

export default TableRow;
