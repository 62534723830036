import React from 'react'
import TableRow from './tableRow';
import TableHeader from './tableHeader';

const Table = ({ tableInstance }) => {
  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } = tableInstance;
  const headers = headerGroups.map((headerGroup, i) => <TableHeader key={i} headerGroup={headerGroup}/>);
  const rows = page.map((row, i) => <TableRow  key={i} row={row} prepareRow={prepareRow} />);
  
  return (
    <table className='table' {...getTableProps()}>
      <thead>
        {headers}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows}
      </tbody>
    </table>
  )
}

export default Table;
