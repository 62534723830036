import React from 'react'
import SoilAndGroundwaterUsage from "./SoilAndGroundwaterUsage";
import SubsoilUsage from "./subsoilUsage";
import WaterType from "./waterType";
import SoilPathways from "./soilPathways";
import SubsoilPathways from "./subsoilPathways";
import { useSiteContext } from "../../../contexts/site";
import axios from "axios";
import GroundwaterPathways from "./groundwaterPathways";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const SiteBody = () => {
  const { site, soilUsage, subsoilUsage, waterType, soilPathways, subsoilPathways, groundwaterPathways } = useSiteContext()

  const submitSite = () => {
    if (!waterType || !soilUsage || !subsoilUsage || soilPathways.length < 1 || subsoilPathways.length < 1) {
      return toast.error('Please fill out all required fields')
    } else {
      return axios.put(`/sites/${site.id}/update_pathways_and_usages.json`, { site: { water_type: waterType.value, soil_and_groundwater_usage_id: soilUsage.value, templated_guidelines_subsoil_usage_id: subsoilUsage.value, soil_pathway_ids: soilPathways, subsoil_pathway_ids: subsoilPathways, groundwater_pathway_ids: groundwaterPathways } } )
        .then(res => window.location.href = res.data)
        .catch(err => console.log(err))
    }
  }

  return <>
    <ToastContainer />
    <div className='flex flex-col gap-y-4'>
      <div className='flex flex-row justify-between items-center w-full'>
        <h1>Generic Site Characteristics</h1>
        <p className='text-gray-400 font-semibold pr-4'>(* All fields are required)</p>
      </div>
      <WaterType />
      <h1>Land Use</h1>
      <SoilAndGroundwaterUsage />
      <SubsoilUsage />
      <h1>Site-Specific Factors</h1>
      <div className='flex flex-row gap-x-4'>
        <SoilPathways />
        <SubsoilPathways />
        <GroundwaterPathways />
      </div>
      <button
        className='btn btn-primary w-24'
        onClick={() => submitSite()}
      >Next</button>
    </div>
  </>
}

export default SiteBody
