import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserTable from "./userTable";
import api from "./api";

const Index = ({ users: initialUsers }) => {
  const [users, setUsers] = useState(initialUsers);

  const handleDelete = (userId) => {
    if (window.confirm("Are you sure you want to delete this user?")) {
      api
        .delete(`/users/${userId}`)
        .then((response) => {
          setUsers(response.data);
          toast.success("User deleted successfully");
        })
        .catch((error) => {
          console.error("Error deleting user:", error);
          toast.error("Failed to delete user");
        });
    }
  };

  const handleImpersonate = (userId) => {
    api
      .get(`/users/${userId}/masquerade_token`)
      .then((response) => {
        window.location.href = response.data.path;
      })
      .catch((error) => {
        console.error("Error fetching masquerade token:", error);
        toast.error("Failed to impersonate user");
      });
  };

  const handleSuspend = (userId) => {
    if (window.confirm("Are you sure you want to suspend this user?")) {
      api
        .post(`/users/${userId}/suspend`)
        .then((response) => {
          setUsers(response.data);
          toast.success("User suspended successfully");
        })
        .catch((error) => {
          console.error("Error suspending user:", error);
          toast.error(error.response?.data?.error || "Failed to suspend user");
        });
    }
  };

  const handleUnsuspend = (userId) => {
    if (window.confirm("Are you sure you want to unsuspend this user?")) {
      api
        .post(`/users/${userId}/unsuspend`)
        .then((response) => {
          setUsers(response.data);
          toast.success("User unsuspended successfully");
        })
        .catch((error) => {
          console.error("Error unsuspending user:", error);
          toast.error(
            error.response?.data?.error || "Failed to unsuspend user"
          );
        });
    }
  };

  const sortedUsers = users.length
    ? users.sort((a, b) => a.email.localeCompare(b.email))
    : [];

  return (
    <div className="p-2">
      <ToastContainer />
      <div className="flex items-center justify-between mb-4 -mt-6">
        <div className="w-1/3"></div>
        <h1 className="text-2xl font-bold text-center w-1/3">Users Admin</h1>
        <div className="w-1/3 text-right">
          <a
            href="/users/invite"
            className="bg-black text-white font-medium p-2.5 rounded hover:bg-gray-800 inline-block"
          >
            Invite New User
          </a>
        </div>
      </div>
      {users.length === 0 ? (
        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <div
            className="bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4"
            role="alert"
          >
            <p className="font-bold">
              Looks like you haven't added any users yet!
            </p>
            <p>
              <a href="/users/invite" className="text-blue-500 hover:underline">
                Invite a User
              </a>
            </p>
          </div>
        </div>
      ) : (
        <UserTable
          users={sortedUsers}
          onDelete={handleDelete}
          onImpersonate={handleImpersonate}
          onSuspend={handleSuspend}
          onUnsuspend={handleUnsuspend}
        />
      )}
    </div>
  );
};

export default Index;