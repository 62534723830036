import React, { useContext} from "react";
import { TierTwoContext } from "../tierTwoContext";
import SiteInformationInput from "./siteInformationInput";
import SiteConditionsDropdown from "./siteConditionsDropdown";
import { landUses, soilTypes } from "../../helpers";

const GeneralParameters = () => {
  let { siteInformation, setSiteInformation, landUse, setLandUse, grainSize, setGrainSize } = useContext(TierTwoContext);

  return (<>
    <h1 className='my-4'>General Input Parameters</h1>
    <div>
      <h2 className='bg-gray-700 text-white font-semibold px-2 py-1 my-2'>General Project Information</h2>
      <SiteInformationInput
        title={'Address/Legal Location/Site Name'}
        placeholder={" "}
        state={siteInformation}
        setState={setSiteInformation}
      />
    </div>
    <div>
      <h2 className='bg-gray-700 text-white font-semibold px-2 py-1 my-2'>General Site Conditions</h2>
      <SiteConditionsDropdown
        title={'Land Use Classification'}
        options={landUses}
        defaultValue={landUses[3]}
        state={landUse}
        setState={setLandUse}
      />
      <SiteConditionsDropdown
        title={'Soil Type - Grain Size'}
        options={soilTypes}
        defaultValue={soilTypes[0]}
        state={grainSize}
        setState={setGrainSize}
      />
    </div>
  </>)
}

export default GeneralParameters
