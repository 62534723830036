import React from 'react'
import ChemicalSelect from "./chemicalSelect";

const GuidelineRow = ({ guideline }) => {
  return <>
    <tr className='text-center border-dashed border-t border-gray-400 px-3'>
      <td className='text-left px-3'>{guideline['DisplayChemical']}</td>
      <td className='text-left'><ChemicalSelect chemical={guideline["Chemical"]} chemicalIndex={guideline["Index"]} /></td>
      <td>{guideline['Soil Units']}</td>
      <td>{guideline['Surface Soil Guideline']}</td>
      <td>{guideline['Subsoil Guideline']}</td>
      <td>{guideline['Groundwater Units']}</td>
      <td>{guideline['Groundwater Guideline']}</td>
    </tr>
  </>
}

export default GuidelineRow
