import React, { useContext } from "react";
import SiteIdentificationDropdown from "./siteIdentificationDropdown";
import { landUses, soilTypes } from "../../helpers";
import { TierOneContext } from "../tierOneContext";
import ValidateSubmitButton from "../shared/validateSubmitButton";
import SiteName from "../shared/siteName";

const GeneralSiteInformation = ({ step, setStep }) => {
  let [site, setSite] = useContext(TierOneContext);

  const hasError = () => {
    return false
  }

  return <>
    <ValidateSubmitButton
      step={step}
      setStep={setStep}
      hasError={() => hasError()}
    >
      <div className='mb-4'>
        <h1>General Site Information</h1>
        <SiteName site={site} setSite={setSite} />
        <SiteIdentificationDropdown title={'Land Use Classification'} field={'land_use'} options={landUses}/>
        <SiteIdentificationDropdown title={'Soil Type - Grain Size'} field={'grain_size'} options={soilTypes}/>
      </div>
    </ValidateSubmitButton>
  </>
}

export default GeneralSiteInformation
