import React from 'react'

const GuidelineRow = ({ guideline }) => {
  return <>
    <tr className='border-dashed border-t border-gray-400'>
      <td className='text-left px-3'>{guideline['DisplayChemical']}</td>
      <td>{guideline['Soil Units']}</td>
      <td>{guideline['Surface Soil Guideline']}</td>
      <td>{guideline['Subsoil Guideline']}</td>
      <td>{guideline['Groundwater Units']}</td>
      <td>{guideline['Groundwater Guideline']}</td>
    </tr>
  </>
}

export default GuidelineRow
