export const filterPAHFingerprints = (siteData, geoJSON, matrix, parameter, area, dateRange, depthRange, valueRange) => {
  let updatedPAHSamples = siteData;
  
  if (matrix) {
    updatedPAHSamples = updatedPAHSamples.filter(sample => sample.matrix.title === matrix);
  }
  
  if (area) {
    updatedPAHSamples = updatedPAHSamples.filter(sample => sample.sample_location_group_name === area);
  }
  
  if (Object.entries(parameter).length !== 0) {
    updatedPAHSamples = updatedPAHSamples.filter(sample => {
     const sampleChemicalIds = sample.sample_values.map(value => value.chemical_id);
     return sampleChemicalIds.includes(parameter.id);
    });
  }
  
  if (dateRange.start) {
    updatedPAHSamples = updatedPAHSamples.filter(sample => new Date(sample.sample_date) >= new Date(dateRange.start));
  }
  
  if (dateRange.end) {
    updatedPAHSamples = updatedPAHSamples.filter(sample => new Date(sample.sample_date) <= new Date(dateRange.end));
  }
  
  if (depthRange.start) {
    updatedPAHSamples = updatedPAHSamples.filter(sample => sample.sample_depth >= depthRange.start);
  }
  
  if (depthRange.end) {
    updatedPAHSamples = updatedPAHSamples.filter(sample => sample.sample_depth <= depthRange.end);
  }
   
  if (valueRange.start) {
    updatedPAHSamples = updatedPAHSamples.map(sample => {
      const sampleValues = sample.sample_values.filter(value => value.float_value >= valueRange.start && value.chemical_id === parameter.id);
      return {...sample, sample_values: sampleValues}
    });
  }
  
  if (valueRange.end) {
    updatedPAHSamples = updatedPAHSamples.map(sample => {
      const sampleValues = sample.sample_values.filter(value => value.float_value <= valueRange.end && value.chemical_id === parameter.id);
      return {...sample, sample_values: sampleValues}
    });
  }
  
  updatedPAHSamples = updatedPAHSamples.filter(sample => sample.sample_values.length > 0);
  const pahSamplesIds = updatedPAHSamples.map(sample => sample.id);
  const updatedGeoJSON = {
    type: "FeatureCollection",
    features: geoJSON.features.filter(feature => pahSamplesIds.includes(feature.properties.sample_id))
  }
  return { updatedGeoJSON, updatedPAHSamples };
};

export const filterByLocation = (geoJSON, samples, highlightSampleIds) => {
  
  const displayedSamples = samples.filter(sample => highlightSampleIds.includes(sample.id));
  const displayedGeojson = {
    type: 'FeatureCollection', 
    features: geoJSON.features.filter(feature => highlightSampleIds.includes(feature.properties.sample_id))
  }
  
  return { displayedSamples, displayedGeojson };
};
