import React from 'react'

const SampleMetadata = ({ selectedSample }) => {
  return (
    <div className='rounded-lg bg-white py-2 px-2 h-full'>
      <h3 className='font-semibold mb-1'>Sample Metadata</h3>
      <table className="table-fixed w-full">
        <tbody className='text-sm'>
          <tr>
            <td className='font-bold'>Sample Location:</td>
            <td>{selectedSample.sample_location.name}</td>
          </tr>
          <tr>
            <td className='font-bold'>Unique Identifier:</td>
            <td>{selectedSample.id}</td>
          </tr>
          <tr>
            <td className='font-bold'>Sample Depth:</td>
            <td>{selectedSample.sample_depth} meters</td>
          </tr>
          <tr>
            <td className='font-bold'>Sample Date:</td>
            <td>{selectedSample.sample_date}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default SampleMetadata
