import React, { useState } from "react";
import MetaDataSelect from "../metaDataSelect";
import { useParameterSelectContext } from "../../../contexts/parameterSelection";

const GroundwaterMetaData = () => {
  const { formattedHeaders, matrix, groundwaterMetaData } = useParameterSelectContext()
  const [openMapping, setOpenMapping] = useState(false)

  return <>
    {matrix.title == 'Groundwater' && <>
      <div className='container'>
        <h1 className="rounded w-1/3 p-1 cursor-pointer hover:bg-gray-200" onClick={() => setOpenMapping(!openMapping)}>Additional Groundwater Metadata Mapping <i className={`fa fa-chevron-${openMapping ? 'up' : 'down'}`}/></h1>
        {openMapping && 
          <div className='flex flex-wrap my-4'>
            <div className='sheet sheet-condensed w-full'>
              <div className='sheet-inner'>
                <p className='sv-label'>If any of the rows in your CSV do not have values for the required fields marked below, the row will be skipped and not imported into Statvis.
                </p>
                <MetaDataSelect
                  title={'Stick-up Height'}
                  options={formattedHeaders}
                  field={'stickup_height_index'}
                  keyword={'StickupHeight'}
                />
                <MetaDataSelect
                  title={'Depth - Total'}
                  options={formattedHeaders}
                  field={'depth_total_index'}
                  keyword={'DepthTotal'}
                />
                <MetaDataSelect
                  title={'Depth - Top of Well Screen'}
                  options={formattedHeaders}
                  field={'depth_top_index'}
                  keyword={'DepthToTop'}
                />
                <MetaDataSelect
                  title={'Depth - Bottom of Well Screen'}
                  options={formattedHeaders}
                  field={'depth_bottom_index'}
                  keyword={'DepthToBottom'}
                />
                <MetaDataSelect
                  title={'Depth to Groundwater'}
                  options={formattedHeaders}
                  field={'depth_to_groundwater_index'}
                  keyword={'DepthToGroundwater'}
                />
                <MetaDataSelect
                  title={'Elevation - Ground'}
                  options={formattedHeaders}
                  field={'elevation_ground_index'}
                  keyword={'ElevationGroundwater'}
                />
                <MetaDataSelect
                  title={'Elevation - Groundwater'}
                  options={formattedHeaders}
                  field={'elevation_groundwater_index'}
                  keyword={'ElevationGroundwater'}
                />
                <MetaDataSelect
                  title={'Elevation - Bedrock'}
                  options={formattedHeaders}
                  field={'elevation_bedrock_index'}
                  keyword={'ElevationBedrock'}
                />
                <MetaDataSelect
                  title={'Elevation - Top of Casing'}
                  options={formattedHeaders}
                  field={'elevation_top_casing_index'}
                  keyword={'ElevationTopOfCasing'}
                />
                <MetaDataSelect
                  title={'Elevation - Top of Screen'}
                  options={formattedHeaders}
                  field={'elevation_top_screen_index'}
                  keyword={'ElevationTopOfScreen'}
                />
                <MetaDataSelect
                  title={'Elevation - Bottom of Screen'}
                  options={formattedHeaders}
                  field={'elevation_bottom_screen_index'}
                  keyword={'ElevationBottomScreen'}
                />
              </div>
            </div>
          </div>
        }
      </div>
    </>}
  </>
}

export default GroundwaterMetaData
