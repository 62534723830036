import React, { useMemo } from 'react'
import { usePcbAPI, usePcbContext } from '../../../contexts/pcb'
import SampleExportButton from '../export/sampleExportButton'

const SampleHeaders = ({ sample }) => {
  const { selectedSample } = usePcbContext()
  const { setSelectedSample, setHighlightSampleIds } = usePcbAPI()
  const sampleDepth = useMemo(() => sample.sample_depth ? `@ ${sample.sample_depth}` : '', [sample])
  const highlight = useMemo(() => sample.id === selectedSample?.id && 'bg-blue-300 px-1 rounded-lg' || '', [sample, selectedSample?.id])
  const handleClick = () => {
    setSelectedSample(sample.id)
    setHighlightSampleIds([sample.id])
  }

  return (
    <th key={sample.id} className='overflow-x-auto'>
      <div className='text-xs cursor-pointer mx-3 hover:bg-blue-100 p-1 rounded' onClick={handleClick}>
        <div className={highlight}>{sample.sample_location.name}</div>
        <div className='text-gray-500'>{`${sample.matrix.title} ${sampleDepth} - ${sample.sample_date}`}</div>
        <div className='text-gray-500'>{sample.sample_location_group_name}</div>
      </div>
      { !selectedSample && <SampleExportButton sampleId={sample.id} /> }
    </th>
  )
}

export default SampleHeaders
