import React from "react";

const RadioInput = ({ type, value }) => {
  return <>
    <input
      className='ml-4'
      type="radio"
      id={type.name + value}
      name={type.name + value}
      value={value}
      checked={type.value === value}
      onChange={(event) => type.stateUpdate(event)}
    />
    <label className='mx-1 capitalize' htmlFor={type.name + value}>{value}</label>
  </>
}

export default RadioInput
