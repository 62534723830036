
const updateCursor = (map, showAnnotations, rulerMode) => {
  map.getCanvas().style.cursor = showAnnotations || rulerMode ? 'crosshair' : 'grab'
}

const restartMouseListeners = (map) => {
  map.off('mouseenter', 'clusters')
  map.off('mouseleave', 'clusters')
  map.off('mouseenter', 'unclustered-point')
  map.off('mouseleave', 'unclustered-point')
}

const updateMouseListeners = (map, showAnnotations, rulerMode) => {
  map.on('mouseenter', 'clusters', () => {map.getCanvas().style.cursor = 'pointer'})
  map.on('mouseleave', 'clusters', () => {updateCursor(map, showAnnotations, rulerMode)})
  map.on('mouseenter', 'unclustered-point', () => {map.getCanvas().style.cursor = 'pointer'})
  map.on('mouseleave', 'unclustered-point', () => {updateCursor(map, showAnnotations, rulerMode)})
}

const cursorFunctions = (map, showAnnotations, rulerMode) => {
  if (showAnnotations || rulerMode) map.getCanvas().style.cursor = 'crosshair'
  else map.getCanvas().style.cursor = 'grab'
  
  restartMouseListeners(map)
  updateMouseListeners(map, showAnnotations, rulerMode)
}

export default cursorFunctions
