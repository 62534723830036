import React from "react";

const ParameterDropdown = ({parameter, setParameter, siteData, chemicals}) => {
  const handleParameterChange = (e) => {
    setParameter(JSON.parse(e.target.value));
  };
  
  const parameters = chemicals ? 
    chemicals 
    : 
    siteData?.chemicals.map((chemical) => {
      return {id: chemical.id, name: chemical.name, unit: chemical.unit}
    })

  const parameterOptions = parameters.map(parameter => <option key={parameter.id} value={JSON.stringify(parameter)}>{parameter.name}</option>)

  return (
    <div className="bg-gray-200 p-2">
      <label className='block text-gray-600 font-bold tracking-wider uppercase text-xs mb-1'>Parameter: </label>
      <select
        className='w-40 h-6 bg-gray-100 text-gray-800 rounded text-xs'
        value={JSON.stringify(parameter)}
        onChange={handleParameterChange}
      >
        <option value='' hidden>Select Parameter</option>
        {parameterOptions}
      </select>
    </div>
  );
}

export default ParameterDropdown;
