import React, { useState, useEffect, useMemo } from 'react'
import BaseMap from "../../../map/baseMap"
import ReviewTable from './table/reviewTable'
import Filter from '../../../shared/filters/filter'
import NoData from '../../../shared/noData'
import Charts from './charts'
import NoSampleSelected from './noSampleSelected'
import { usePahFingerprinting, usePahFingerprintingAPI } from "../../../../contexts/pahFingerprinting"
import { filterByLocation } from '../helpers/filterFunctions'
import ExportModal from './modal/exportModal'
import SourcesModal from './modal/sourcesModal'
import DoubleRatioChart from '../annotationDoublePlotRatio/annotationDoubleRatioChart'

const Review = () => {
  const { samples, site, geoJSON, chemicals, filteredSamples, filteredGeoJSON, filteredChemical, mapType, displayedGeojson, displayedSamples, showPopups, showLegends, annotationMode } = usePahFingerprinting()
  const { onSetDisplayedData } = usePahFingerprintingAPI()
  const [highlightSampleIds, setHighlightSampleIds] = useState([])
  const hasHightlightedSamples = highlightSampleIds.length > 0
  const displayedSampleData = useMemo(() => displayedSamples || (filteredSamples || samples), [displayedSamples, filteredSamples, samples])
  const displayedGeojsonData = useMemo(() => filteredGeoJSON || geoJSON, [filteredGeoJSON, geoJSON])
  const hasSamples = displayedSampleData ? displayedSampleData.length > 0 : false
  const selectedSample = useMemo(() => displayedSampleData?.find(sample => sample.id === highlightSampleIds[0]), [displayedSampleData, highlightSampleIds])

  useEffect(() => {
    if (samples) setHighlightSampleIds([samples[0].id])
  }, [samples])

  useEffect(() => {
    if (hasHightlightedSamples) {
      const baseSample = filteredSamples || samples
      const baseGeojson = filteredGeoJSON || geoJSON
      const { displayedSamples, displayedGeojson } = filterByLocation(baseGeojson, baseSample, highlightSampleIds)
      onSetDisplayedData(displayedSamples, displayedGeojson)

    } else {
      onSetDisplayedData(null, null)
    }
  }, [highlightSampleIds])

  if (!hasSamples) {
    return (
      <div className='flex items-center justify-center h-full w-3/5'>
        <NoData message={'No Samples Found'}/>
      </div>
    )
  }

  return (
    <>
      <div className='relative flex flex-col h-full'>
        <Filter geoJSON={geoJSON} siteData={samples} chemicals={chemicals} mapType={mapType}/>
        <div className='flex h-3/5'>
          <div className={`${annotationMode ? 'w-1/2' : 'w-2/3'} h-full relative`}>
            {geoJSON && <BaseMap geojson={displayedGeojsonData} highlightSampleIds={highlightSampleIds} setHighlightSampleIds={setHighlightSampleIds} mapType={mapType} showPopups={showPopups} showLegends={showLegends}/>}
          </div>
          <div className={`${annotationMode ? 'w-1/2' : 'w-1/3'} h-full relative`}>
            {annotationMode ?
              <DoubleRatioChart/>
              :
              <ReviewTable samples={displayedSampleData} chemicals={filteredChemical ? [filteredChemical] : chemicals} highlightSampleIds={highlightSampleIds} setHighlightSampleIds={setHighlightSampleIds} />
            }
          </div>
        </div>
        <div className='h-2/5 w-full'>
          { selectedSample && <Charts selectedSample={selectedSample} site={site} /> }
          { !selectedSample && <NoSampleSelected /> }
        </div>
        <ExportModal/>
        <SourcesModal/>
      </div>
    </>
  )
}

export default Review
