import React, { useState } from 'react';
import SelectSample from "../pahFingerprinting/shared/selectSample";

const MoreSamples = ({ samples, site , currentSamples, setCurrentSamples, setStep}) => {
  let [additionalSamples, setAdditionalSamples] = useState([])

  const moreSamplesList = (currentSamples) => {
    return samples.filter( sample => !currentSamples.includes(sample.id)).map( sample => {
      return (
        <SelectSample
          key={sample.id}
          site={site} 
          sample={sample}
          setSamples={setAdditionalSamples}
        />
      )
  })}

  const addSamples = () => {    
    if (additionalSamples.length == 0 ) {
      alert("You have not picked any samples");
    } else {
      setCurrentSamples((prevState) => [...prevState, ...additionalSamples])
      setStep('analysis')
    }
  }

  return <>
    <div className='w-full'>
      <div className='flex flex-row justify-between'>
        <h1>Add More Samples</h1>
        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={addSamples}>
          Add {additionalSamples.length} Samples
        </button>
      </div>
      <div className='flex flex-row gap-x-2 items-center'>
        <p className='font-semibold'>Legend:</p>
        <p className='rounded-lg py-2 px-2 text-center bg-blue-200 w-60'>Analysis Available</p>
        <p className='rounded-lg py-2 px-2 text-center bg-yellow-400 w-60'>Not Enough Sample Data</p>
      </div>
      <div className='grid grid-cols-3 gap-2'>
        {moreSamplesList(currentSamples)}
      </div>
    </div>
  </>
}

export default MoreSamples

