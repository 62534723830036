import React, { useEffect, useState } from "react"

const AliasInput = ({ setAliases, index, defaultValue }) => {
  const [value, setValue] = useState('')

  const updateAliases = (inputValue) => {
    setValue(inputValue)
    setAliases(prevState => {
      return { ...prevState, [index]: inputValue}
    })
  }

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue)
      setAliases(prevState => {
        return { ...prevState, [index]: defaultValue}
      })
    }
  }, [defaultValue])

  return <>
    <input
      className="focus:outline-none border border-gray-200 focus:shadow-inner m-0 rounded p-1 m-1"
      placeholder="Enter alias..."
      value={value}
      onChange={event => updateAliases(event.target.value)}
    />
  </>
}

export default AliasInput
