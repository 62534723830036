import MapboxDraw from '@mapbox/mapbox-gl-draw'
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import { convertToFeatureCollection, addPopupsForFeatures, onDrawCreate, onDrawUpdate, onDrawDelete, addRulerFunctions, removeRulerFunctions, createDrawLineString } from './annotationHelpers'

export const initializeMapDraw = (map, mapDrawRef, setMapDrawOpen) => {
  if (!mapDrawRef.current) {
    mapDrawRef.current = new MapboxDraw({
      displayControlsDefault: true,
      controls: {
        combine_features: false,
        uncombine_features: false,
      },
    })
    map.addControl(mapDrawRef.current, 'top-left')
    setMapDrawOpen(true)
  }
}

export const removeMapDraw = (map, mapDrawRef, mapDrawOpen, setMapDrawOpen) => {
  if (mapDrawRef.current && mapDrawOpen) {
    map.removeControl(mapDrawRef.current)
    mapDrawRef.current = null
    setMapDrawOpen(false)
  }
}

export const cleanPopups = (popups) => Object.values(popups).forEach((popup) => popup.remove())

const attachDrawEventListener = (map, eventType, handler) => {
  if (map._listeners[eventType]) map._listeners[eventType] = []
  map.on(eventType, (e) => handler(e))
}

export const annotationFunctions = (map, mapDraw, siteData, geoSpatialFeatures, setGeoSpatialFeatures, setGeoPopups, toast) => {
  const siteId = siteData.id
  const polygonPopups = addPopupsForFeatures(map, geoSpatialFeatures)
  mapDraw.add(convertToFeatureCollection(geoSpatialFeatures))

  attachDrawEventListener(map, 'draw.create', (e) => onDrawCreate(e, map, siteId, setGeoSpatialFeatures, setGeoPopups, toast))
  attachDrawEventListener(map, 'draw.delete', (e) => onDrawDelete(e, geoSpatialFeatures, setGeoSpatialFeatures, toast))
  attachDrawEventListener(map, 'draw.update', (e) => onDrawUpdate(e, geoSpatialFeatures, setGeoSpatialFeatures, toast))

  return polygonPopups
}

export const getPolygonArea = (map, turf, setPolygonArea) => {
  map.on('click', (e) => {
    const clickedFeature = map.queryRenderedFeatures(e.point)[0]
    const featureType = clickedFeature?.properties['meta:type']
    
    if (featureType === 'Polygon') {
      const area = turf.area(clickedFeature).toFixed(2)
      setPolygonArea(area)
    } else {
      setPolygonArea(null)
    }
  })
}

export const getPointsDistance = (map, turf, distanceMode, setDistance) => {
  const distanceGeojson = {
    type: 'FeatureCollection',
    features: [],
  }

  let drawLineString = createDrawLineString(turf, distanceGeojson, setDistance, map)

  if (distanceMode) {
    addRulerFunctions(map, distanceGeojson, drawLineString)
  } else {
    removeRulerFunctions(map, setDistance)
    drawLineString = () => {}
  }
}


