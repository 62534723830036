import React, { useState, useEffect, useRef, useMemo } from 'react'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import HC_more from "highcharts/highcharts-more"
import label from "highcharts/modules/series-label"
import HC_exporting from 'highcharts/modules/exporting'
import DiagnosticRatios from './diagnosticReport/diagnosticRatios'
import SampleMetadata from './sampleMetadata'
import buildDoubleRatioPlot from '../../doubleRatioPlot/buildDoubleRatioPlot'
import fixedGridData from '../../doubleRatioPlot/fixedGridData'
import { usePahFingerprinting } from '../../../../contexts/pahFingerprinting'
import { buildReferenceChartOptions, buildPahChart, sampleScatterData, buildAlkylatedReferenceChartOptions, buildAlkylatedChartOptions, referenceScatterPoints, titleBuilder } from '../helpers/chartHelpers'
import { resultTextColor } from './helpers/analysisHelper'
import { fetchParentReferenceData, fetchHistogramData, fetchAlkylatedData, fetchDiagnosticRatiosData } from './helpers/chartsApiHelper'
import { setExportData } from './helpers/pdfHelper'

HC_more(Highcharts)
label(Highcharts)
HC_exporting(Highcharts)

const Charts = ({ selectedSample, site, processed, exporting, getExportedData }) => {
  const { additionalData, labSource, mode } = usePahFingerprinting()
  const [sampleHistogramData, setSampleHistogramData] = useState(null)
  const [sampleExtendedHistogramData, setSampleExtendedHistogramData] = useState(null)
  const [references, setReferences] = useState(null)
  const [alkylatedReferences, setAlkylatedReferences] = useState(null)
  const [data, setData] = useState(null)
  const [scatterData, setScatterData] = useState(null)
  const [referenceScatterData, setReferenceScatterData] = useState(null)
  const [ratioChartOptions, setRatioChartOptions] = useState(null)
  const [sampleExportData, setSampleExportData] = useState(null)
  const isAlkylated = mode === 'Alkylated'
  
  // Refs
  const ratioPlotRef = useRef(null)
  const sampleHistogramRef = useRef(null)
  const alkylatedHistogramRef = useRef(null)
  const referenceHistogramRefs = Array.from({ length: 3 }, () => useRef(null))
  const alkylatedReferenceHistogramRefs = Array.from({ length: 3 }, () => useRef(null))

  const hasRatioPlot = (data) => {
    if (!data) return false
    return true
  } 

  useEffect(() => {
    if (selectedSample && site) {
      fetchParentReferenceData(site.id, selectedSample.id, setReferences, setReferenceScatterData)
      fetchHistogramData(site.id, selectedSample.id, setSampleHistogramData)
      fetchDiagnosticRatiosData(site.id, selectedSample.id, setData, setScatterData)
    }
  },[selectedSample, site])

  useEffect(() => {
    if (isAlkylated) fetchAlkylatedData(mode, site, selectedSample, labSource, setAlkylatedReferences, setSampleExtendedHistogramData)
  }, [mode])

  useEffect(() => {
    scatterData && setRatioChartOptions(buildDoubleRatioPlot(scatterData, selectedSample.sample_name_for_json, selectedSample.sample_location.name))
  }, [scatterData])

  useEffect(() => {
    if (referenceScatterData && scatterData && additionalData) {
      const referenceList = referenceScatterData.map(reference => reference.name)
      const additionalScatterData = additionalData.filter(data => !referenceList.includes(data.name))
      const hasAdditionalData = additionalScatterData.length > 0
      const referencePoints = referenceScatterData.map(reference => referenceScatterPoints(reference))
      setRatioChartOptions(prev => {
        return {
          ...prev,
          legend: {
            ...prev.legend,
            layout: !hasAdditionalData ? 'vertical' : 'horizontal',
            itemWidth: !hasAdditionalData > 0 ? 250 : 150,
          },
          series: [
            ...fixedGridData(),
            sampleScatterData(selectedSample, scatterData),
            ...referencePoints,
            ...additionalScatterData,
          ]
        }
      })
    }
  }, [referenceScatterData, scatterData, additionalData])

  useEffect(() => {
    const ratioPlotLoaded = hasRatioPlot(scatterData) ? ratioChartOptions && scatterData : true
    const alkylatedHistogramsLoaded = isAlkylated ? sampleExtendedHistogramData && alkylatedReferences : true
    const dataLoaded = sampleHistogramData && references && referenceScatterData && ratioPlotLoaded && alkylatedHistogramsLoaded ? true : false
    if (dataLoaded) processed()

    if (dataLoaded && exporting) {
      const exportData = setExportData(referenceHistogramRefs, selectedSample, sampleHistogramRef, ratioChartOptions, isAlkylated, alkylatedHistogramRef, alkylatedReferenceHistogramRefs, hasRatioPlot(scatterData), ratioPlotRef)
      setSampleExportData(exportData)
    }
  }, [references, sampleHistogramData, scatterData, referenceScatterData , ratioChartOptions, additionalData, exporting, sampleExtendedHistogramData, alkylatedReferences])

  useEffect(() => {
    if (sampleExportData) getExportedData(sampleExportData)
  }, [sampleExportData])

  const contributionChart = useMemo (() => (
    <div id={`ph-${selectedSample?.id}`} key={selectedSample?.id} className='sticky left-0 z-10 border-r-4'>
      <HighchartsReact
        highcharts={Highcharts}
        options={buildPahChart(selectedSample?.sample_name_for_json, 'Contribution (%)', sampleHistogramData, sampleHistogramData?.map(item => item.name), '%', null, 350)}
        ref={sampleHistogramRef}
      />
    </div>
  ), [sampleHistogramData])

  const referenceContributionCharts = useMemo (()=> references?.map((reference, i) => {
    const title = titleBuilder(reference)
    const data = reference?.contribution_data.map(item => { return { name: item.name, contribution: item.contribution }})
    return (
      <div id={`ph-${reference?.id}`} key={reference?.id}>
        <HighchartsReact
          highcharts={Highcharts}
          options={buildReferenceChartOptions(title, data, 'contribution', 'Contribution (%)', 350)}
          ref={referenceHistogramRefs[i]}
        />
      </div>
    )
  }), [references])

  const doubleRatioChart = useMemo (() => (
    <HighchartsReact
      ref={ratioPlotRef}
      highcharts={Highcharts}
      options={ratioChartOptions}
    />
  ), [ratioChartOptions])

  const extendedContributionChart = useMemo (() => (
    <div id={`ph-${selectedSample?.id}`} key={selectedSample?.id} className='sticky left-0 z-10 border-r-4'>
      <HighchartsReact
        highcharts={Highcharts}
        options={buildAlkylatedChartOptions(selectedSample?.sample_name_for_json, 'Contribution (%)', sampleExtendedHistogramData?.series, sampleExtendedHistogramData?.categories, sampleExtendedHistogramData?.total_concentration, true, labSource)}
        ref={alkylatedHistogramRef}
      />
    </div>
  ), [sampleExtendedHistogramData])

  const referenceExtendedContributionCharts = useMemo (() => isAlkylated && alkylatedReferences?.map((reference, i) => {
    const title = titleBuilder(reference)
    return (
      <div id={`ph-${reference?.id}`} key={reference?.id}>
        <HighchartsReact
          highcharts={Highcharts}
          options={buildAlkylatedReferenceChartOptions(title, 'Contribution (%)', reference?.contribution_data, true, labSource)}
          ref={alkylatedReferenceHistogramRefs[i]}
        />
      </div>
    )
  }), [alkylatedReferences])

  return  (
    <div className='border-2 px-5 pb-5 rounded bg-blue-300'>
      <div>
        <div className='flex justify-between'>
          <h1 className='text-lg font-semibold my-2 w-1/2'>{selectedSample.sample_name_for_json}</h1>
          <h1 className={`${resultTextColor(data?.formatted_overall_result)} text-lg font-semibold my-2 w-1/2 text-right`}>{data?.formatted_overall_result}</h1>
        </div>
        <div className='flex'>
          <div className='w-1/2 mr-1'>
            <SampleMetadata selectedSample={selectedSample} />
          </div>
          <div className='w-1/2 ml-1'>
            <DiagnosticRatios data={data} />
          </div>
        </div>
      </div>
      {hasRatioPlot(scatterData) && ratioChartOptions && (
        <div className='rounded-lg bg-white py-4 px-2 my-1'>
          <h3 className='font-semibold'>Double Ratio Plot</h3>
          <div className='flex justify-center'>
            {doubleRatioChart}
          </div>
        </div>
      )}
      <div className='bg-white border rounded my-1'>
        <p className='text-sm font-semibold m-1'>Histogram References</p>
        <div className='flex mt-1 overflow-x-auto border-t-2 border-b-2'>
          {sampleHistogramData && contributionChart}
          {referenceContributionCharts}
        </div>
      </div>
      {isAlkylated && <div className='bg-white border rounded my-1'>
        <p className='text-sm font-semibold m-1'>Alkylated Histogram References</p>
        <div className='flex mt-1 overflow-x-auto border-t-2 border-b-2'>
          {sampleExtendedHistogramData && extendedContributionChart}
          {referenceExtendedContributionCharts}
        </div>
      </div>}
    </div>
  )
}

export default Charts
