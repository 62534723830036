import React from 'react'
import Guideline from "./guideline";
import { useCustomGuidelinesAPI } from "../../../contexts/customGuidelines";

const GuidelineRow = ({ guideline }) => {
  const { deleteGuideline } = useCustomGuidelinesAPI()

  return <tr className='border-dashed border-t border-gray-400'>
    <td className='text-left px-3 py-2'>{guideline.chemical_name}</td>
    <td>{guideline.soil_unit}</td>
    <Guideline deleteCallback={deleteGuideline(guideline.chemical_name, 'soil_guideline')} chemical={guideline.chemical_name} guideline={guideline.soil_guideline} />
    <Guideline deleteCallback={deleteGuideline(guideline.chemical_name, 'subsoil_guideline')} guideline={guideline.subsoil_guideline} />
    <td>{guideline.groundwater_unit}</td>
    <Guideline deleteCallback={deleteGuideline(guideline.chemical_name, 'groundwater_guideline')} guideline={guideline.groundwater_guideline} />
  </tr>
}

export default GuidelineRow
