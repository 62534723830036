import React from "react"
import { useAdminGuidelinesAPI, useAdminGuidelinesContext } from "../../../contexts/adminGuidelines";
import OptionSelect from "./select";
import GuidelinesTable from "./guidelinesTable";

const GuidelineData = () => {
  const { setGroup, setChemical } = useAdminGuidelinesAPI()
  const { group, groupOptions, chemical, chemicalsOptions, loading } = useAdminGuidelinesContext()

  const onGroupSelect = (option) => {
    setGroup(option)
    setChemical(null)
  }

  return <>
    <div className='flex flex-row items-center gap-x-2'>
      <OptionSelect title={'Group'} state={group} options={groupOptions} onSelect={onGroupSelect} />
      <OptionSelect title={'Chemical'} state={chemical} options={chemicalsOptions} onSelect={setChemical} />
    </div>
    {loading ? <h3>Please wait while your data loads...</h3> : <GuidelinesTable />}
  </>
}

export default GuidelineData
