import React from "react";

const AreaDropdown = ({area, setArea, siteData}) => {
  const handleAreaChange = (e) => {
    setArea(e.target.value)
  }
  
  const areas = Array.isArray(siteData) ? [...new Set(siteData.map((sample) => sample?.sample?.sample_location_group_name || sample?.sample_location_group_name))].filter(group => group) : [...new Set(siteData.samples.map(sample => sample.sample_location_group_name))].filter(group => group);
  const areasOptions = areas.map((area,i) => <option key={i} value={area}>{area}</option>)
  const hasGroupOptions = areasOptions.length > 0
  const labelColor = hasGroupOptions ? 'text-gray-600' : 'text-gray-400'

  return (
    <div className='bg-gray-200 p-2'>
      <label className={`block font-bold tracking-wider uppercase text-xs mb-1 ${labelColor}`}>Sample Group: </label>
      <select
        className='w-36 h-6 bg-gray-100 text-gray-800 rounded text-xs'
        value={area}
        onChange={handleAreaChange}
        disabled={!hasGroupOptions}
      >
        <option value=''>Select Group</option>
        {areasOptions}
      </select>
    </div>
  )
}

export default AreaDropdown
