import React, { useState, useEffect, useMemo, useRef }  from 'react'
import axios from 'axios'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import HC_more from "highcharts/highcharts-more"
import label from "highcharts/modules/series-label"
import HC_exporting from 'highcharts/modules/exporting'
import SampleMetadata from './table/sampleMetadata'
import { pcbChartOptions, congenerContributionsChartOptions } from './helpers/chartBuilder'
import { usePcbContext, usePcbAPI } from '../../contexts/pcb'

HC_more(Highcharts)
label(Highcharts)
HC_exporting(Highcharts)

const PreviewChart = ( {sampleId, addGroupCongeners} ) => {
  const [congeners, setCongeners] = useState(null)
  const [congenerGroups, setCongenerGroups] = useState(null)
  const [sumPcb, setSumPcb] = useState(null)
  const [referenceCongeners, setReferenceConeners] = useState(null)
  const [referenceCongenerGroups, setReferenceCongenerGroups] = useState(null)
  const [topReference, setTopReference] = useState(null)
  const [maxPcb, setMaxPcb] = useState(null)
  const [congenerChartData, setCongenerChartData] = useState(null)
  const { site, samples, selectedSample, referenceSource, exportData } = usePcbContext()
  const { setExportData } = usePcbAPI()
  const sample = samples.find(sample => sample.id === sampleId)
  const dataLoaded = useMemo(() => sumPcb && congenerGroups && congeners && referenceSource && referenceCongeners && referenceCongenerGroups && topReference && maxPcb && congenerChartData, [sumPcb, congenerGroups, congeners, referenceSource, referenceCongeners, referenceCongenerGroups, topReference, maxPcb, congenerChartData])

  const samplePcbRef = useRef(null)
  const referencePcbRef = useRef(null)
  const congenerGroupContributionsRef = useRef(null)

  useEffect(() => {
    if (sampleId) {
      axios.get(`/sites/${site.id}/pcb_fingerprinting_charts?sample_id=${sampleId}&reference_source=${referenceSource}`)
        .then(res => {
          const sampleData = res.data.sample
          setCongenerGroups(sampleData.pcb_group_contributions)
          setCongeners(sampleData.chart_data)

          const referenceData = res.data.reference
          setReferenceConeners(referenceData.chart_data)
          setReferenceCongenerGroups(referenceData.pcb_group_contributions)

          setSumPcb(res.data.sample_sum_pcb)
          setTopReference(res.data.cosine_theta)

          const maxSamplePcb = Math.max(...sampleData.chart_data.map(congener => congener.value))
          const maxReferencePcb = Math.max(...referenceData.chart_data.map(congener => congener.value))
          setMaxPcb(Math.ceil(Math.max(maxSamplePcb, maxReferencePcb)))
          const congenerData = {sample: sample.sample_location.name, groupCongener: res.data.sample.pcb_group_contributions}
          const referenceCongenerData = {sample: res.data.cosine_theta.name, groupCongener: res.data.reference.pcb_group_contributions}
          addGroupCongeners(congenerData)
          setCongenerChartData([congenerData, referenceCongenerData])
        })
        .catch(err => console.log(err))
    }
  },[])

  useEffect(() => {
    if (dataLoaded) {
      const sampleExportData = {
        sample_id: sampleId,
        reference: topReference,
        sample_pcb: samplePcbRef.current?.chart?.getSVG(),
        reference_pcb: referencePcbRef.current?.chart?.getSVG(),
        congener_contribution: congenerGroupContributionsRef?.current?.chart?.getSVG(),
      }
      if (!exportData.find(data => data.sample_id === sampleId)) {
        setExportData([...exportData, sampleExportData])
      }
    }
  }, [dataLoaded])

  const histograms = (title, congenerGroups, congeners, showXLabel, maxPcb, inPreview, type, forExport) => {
    const ref = type === null ? null : type === 'sample' ? samplePcbRef : referencePcbRef
    return (
      <div>
        <HighchartsReact
          highcharts={Highcharts}
          options={pcbChartOptions(title, congenerGroups, congeners, showXLabel, maxPcb, inPreview, forExport)}
          ref={ref || null}
        />
      </div>
    )
  }

  return (
    dataLoaded ? (
      <div className='p-1 borde bg-blue-200 mb-1 rounded'>
        <p className='font-semibold'>{sample.sample_name_for_json}</p>
        <div className='mb-1 w-full flex items-center h-64'>
          <SampleMetadata sample={sample} referenceSource={referenceSource} topReference={topReference} />
          <div className='w-1/3 ml-1 bg-white p-1 rounded h-full'>
            <HighchartsReact
              highcharts={Highcharts}
              options={congenerContributionsChartOptions('Sample Homologs Contribution', congenerChartData, true)}
              ref={congenerGroupContributionsRef}
            />
          </div>
        </div>
        <div>
          {histograms(`Sum PCB: ${sumPcb.toFixed(2)}`, congenerGroups, congeners, true, maxPcb, true, null, false)}
          {histograms(`${topReference.name} - ${topReference.similarity}% similarity`, referenceCongenerGroups, referenceCongeners, true, maxPcb, true, null, false)}
          <div className='hidden'>
            {histograms(`Sum PCB: ${sumPcb.toFixed(2)}`, congenerGroups, congeners, true, maxPcb, true, 'sample', true)}
            {histograms(`${topReference.name} - ${topReference.similarity}% similarity`, referenceCongenerGroups, referenceCongeners, true, maxPcb, true, 'reference', true)}
          </div>
        </div>
      </div>
    ) : (
      <div className='p-1 border bg-blue-200 mb-1 rounded'>
        <p className='font-semibold text-center'>{sample?.sample_name_for_json}</p>
        <div className='w-full h-64 flex items-center justify-center'>
          <p className='text-xl font-semibold text-gray-500'><i className='fa fa-spinner fa-spin'/> Loading...</p>
        </div>
      </div>
    )
  )
}

export default PreviewChart
