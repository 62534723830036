import React from 'react'
import { deleteAttachment, downloadAttachment } from './helperFunctions';

const AttachmentRows = ({ attachments, setAttachments, setSampleLocation, sampleLocation }) => {
  const filteredAttachments = attachments.filter((attachment) => {
    if (sampleLocation) {
      return attachment.sample_location_id === parseInt(sampleLocation);
    } else {
      return !attachment.sample_location_id;
    }
  });
  
  const attachmentRows = filteredAttachments.map((attachment) => (
    <tr key={attachment.id} className='flex justify-between'>
      <td>{attachment.name}</td>
      <td>
        <button className='btn-action primary mr-1' onClick={() => downloadAttachment(attachment)}><i className="fa fa-download cursor-pointer text-gray-700 hover:text-gray-900"/> Download</button>
        <button className='btn-action delete' onClick={() => deleteAttachment(attachment, attachments, setAttachments, setSampleLocation)}><i className="fa fa-trash-alt text-red-500 cursor-pointer"/> Delete</button>
      </td>
    </tr>
  ));

  return (
    <>
      {attachmentRows}
    </>
  );
};

export default AttachmentRows;
