export const findPcbGroup = (pcbNumber, rangeMap) => {
  const pcbInt = parseInt(pcbNumber.replace('PCB ', ''), 10)

  for (const [group, pcbs] of Object.entries(rangeMap)) {
    if (pcbs.includes(pcbInt)) {
      return group
    }
  }
  return null
}
