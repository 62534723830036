import React, { useRef, useEffect, useState } from 'react';
import mapboxgl from '!mapbox-gl';
import Legends from '../dataVault/mapHelpers/legends';
import HomeZoom from '../dataVault/mapHelpers/homeZoom';
import NoCoordSamplePopup from '../dioxinsAndFurans/noCoordSamplePopup';
import Instructions from '../reports/pahFingerprinting/annotationDoublePlotRatio/instructions';
import HelpLabels from '../reports/pahFingerprinting/review/helpLabels';
import { boundsHelper, initializeDatasource, layerHelper } from './mapHelpers';
import { removeOldPopups, buildPopups } from '../dataVault/mapHelpers/popupsLabelFunctions';
import { showUnclusteredPointPopup, removeUnclusteredPopup, showClusteredPopup } from '../dataVault/mapHelpers/popupsLabelFunctions';
import showFunctions from '../dataVault/mapHelpers/showFunctions';
import { initializeMapDrawPah } from '../reports/pahFingerprinting/annotationDoublePlotRatio/annotationFunctions';
import { usePahFingerprinting, usePahFingerprintingAPI } from '../../contexts/pahFingerprinting';

// CONSTANTS
const [CANADA_LATTITUDE, CANADA_LONGITUDE, DEFAULT_ZOOM] = [56.1304, -106.3468, 3];

const fitBounds = (map, bounds) => {
  if (!bounds) return;
  map.fitBounds(bounds, {
    padding: {top: 100, bottom: 100, left: 100, right: 100}
  });
};

const BaseMap = ({geojson, mapType, highlightSampleIds, setHighlightSampleIds, showChart, showPopups, showLegends, matchedReference = null}) => {
  mapboxgl.accessToken = process.env.MAPBOX_API_KEY;
  
  const dataSource = initializeDatasource(mapType, geojson);
  const bounds = boundsHelper(dataSource);
  const { annotationMode } = usePahFingerprinting();
  const { setSelectedSampleAnnotation } = usePahFingerprintingAPI();
  
  let [latitude, longitude, defaultZoom] = [CANADA_LATTITUDE, CANADA_LONGITUDE, DEFAULT_ZOOM];

  if (bounds) {
    ({ lng: longitude, lat: latitude } = bounds.getCenter());
  }

  const mapContainer = useRef(null);
  const map = useRef(null);
  const mapDrawRef = useRef(null);
  
  const [popups, setPopups] = useState([]);
  const [showPopupIndex, setShowPopupIndex] = useState(false);
  const [pahSelectedSampleIds, setPahSelectedSampleIds] = useState([]);
  const showPopupControl = popups.length > 0 ? true : false;
  const inIndexDashboardDotplot = mapType === 'index' || mapType === 'dashboard' || mapType === 'dotplot'
  const displayLegend = (mapType === 'dashboard' || mapType === 'dotplot') || showLegends

  useEffect(() => {
    if (map.current) return;
      map.current = new mapboxgl.Map({
      container: mapContainer.current,
      projection: 'mercator',
      style: 'mapbox://styles/paulfuel/clg2wlxt2000e01qfli2w0agc',
      center: [longitude,latitude],
      zoom: defaultZoom
    });
    
    map.current.addControl(new mapboxgl.NavigationControl({showCompass: false}), 'bottom-left')
    
    map.current.on('load', () => {
      map.current.addSource('sampleLocations', {
        type: 'geojson',
        data: dataSource,
        cluster: true,
        clusterRadius: 10,
        clusterMaxZoom: 18,
      });

      layerHelper(map.current, mapType);
      showFunctions(map.current, setHighlightSampleIds, mapType)
    });

    fitBounds(map.current, bounds);
    
    return () => map.current.remove();
  }, []);
  
  useEffect(() => {
    if (map.current && geojson) {
      const mapSource = map.current.getSource('sampleLocations')
      if (mapSource) {
        mapSource.setData(dataSource);
      }
    }
  }, [dataSource]);
  
  useEffect(() => {
    if (map.current && dataSource) {
      removeOldPopups(popups, setPopups);
      setPopups(buildPopups(map.current, dataSource, showPopups, showPopupIndex));
    }
  }, [showPopups, showPopupIndex]);
  
  useEffect(() => {
    if (!(mapType === 'groundwater' || mapType === 'saltPrints' || mapType === 'pah' || mapType === 'dioxinsAndFurans' || mapType === 'pcb')) return;

    if (map.current && highlightSampleIds.length && geojson) {
      const mapFeatures = geojson.features.filter(feature => highlightSampleIds.includes(feature.properties.salt_print_sample_id || feature.properties.sample_id));
      const zoomLevel = map.current.getZoom() < 10 ? 10 : map.current.getZoom()
      if (mapFeatures.length !== 0) {
        map.current.flyTo({
          center: mapFeatures[0].geometry.coordinates,
          zoom: zoomLevel,
        })
        
        const isUnclustered = mapFeatures.length === 1
        if (isUnclustered){
          showUnclusteredPointPopup(map.current, mapFeatures[0], matchedReference)
        } else {
          showClusteredPopup(map.current, mapFeatures);
        }
      } else {
        removeUnclusteredPopup()
      }
    } else if (map.current && !highlightSampleIds.length && geojson) {
      removeUnclusteredPopup();
    }
  }, [highlightSampleIds, matchedReference]);
  
  useEffect(() => {
    if (map.current) {
      map.current.resize();
    }
  }, [showChart, annotationMode]);
  
  useEffect(() => {
    if (map.current && mapType == 'pah') {
      initializeMapDrawPah(map.current, mapDrawRef, annotationMode, setPahSelectedSampleIds)
    }
  }, [annotationMode, mapType, map.current]);

  useEffect(() => {
    if (map.current && mapType == 'pah' && annotationMode) {
      setSelectedSampleAnnotation(pahSelectedSampleIds)
    }
  }, [pahSelectedSampleIds, mapType, map.current]);

  return (
    <div className='relative h-full'>
      {showPopupControl && inIndexDashboardDotplot &&
        <div className={`bg-gray-100 border-black p-1 text-center flex items-center justify-center z-10 w-auto absolute top-${mapType === 'index' ? 2 : 28} left-2 rounded-md`}>
          <div className='flex items-center cursor-pointer' onClick={() => setShowPopupIndex(!showPopupIndex)}>
            <i className={`fa fa-comment`}/>
          </div>
        </div>
      }
    {matchedReference && <NoCoordSamplePopup matchedReference={matchedReference}/>}
    {mapType == 'pah' && annotationMode && <Instructions/>}
    {mapType == 'pah' && <div className='absolute right-0 top-1 z-10'><HelpLabels label={'View double ratio charts or the table of raw data'} direction={'right'} /></div>}
    <HomeZoom map={map.current} bounds={bounds}/>
    <div className={mapType == 'show' || mapType == 'dioxinsAndFurans' || mapType == 'pah' || mapType === 'pcb' ? 'relative h-full' : 'relative h-map'}>
      <div ref={mapContainer} className={mapType == 'show' || mapType == 'dioxinsAndFurans' || mapType == 'pah' || mapType === 'pcb' ? 'relative h-full' : 'relative h-map'} />
      {mapType !== 'index' && displayLegend && <Legends/>}
    </div>
    </div>
    );
}

export default BaseMap;
