import React, { useEffect, useState } from "react";
import Select from "react-select";
import axios from "axios";
import { useCustomGuidelinesAPI } from "../../../contexts/customGuidelines";

const ChemicalSelect = ({ chemical, chemicalIndex }) => {
  const [options, setOptions] = useState([])
  const [selected, setSelected] = useState(null)
  const [input, setInput] = useState(null)
  const { updateNewGuidelines } = useCustomGuidelinesAPI()

  useEffect(() => {
    if (input) {
      axios.get(`/chemicals/autocomplete_chemical_name?q=${input}`).then(res => {
        const formattedData = res.data.map(chemical => {
          return { label: chemical.name, value: chemical.id }
        })
        setOptions(formattedData)
      })
    }
  }, [input])

  const onChangeHandler = (option) => {
    if (option) {
      setSelected(option)
    } else {
      setSelected(null)
    }
  }

  useEffect(() => {
    updateNewGuidelines(chemicalIndex, selected)
  }, [selected])

  useEffect(() => {
    if (chemical) {
      axios.get(`/chemicals/autocomplete_chemical_name?q=${chemical}`).then(res => {
        if (res.data.length > 0) {
          const matchedChemical = res.data.find(chem => chem.name === chemical)
          if (matchedChemical) setSelected({ label: matchedChemical.name, value: matchedChemical.id })
        }
      })
    }
  }, [chemical])

  return <Select
      placeholder={'Enter the chemical name...'}
      options={options}
      value={selected}
      isClearable={true}
      onInputChange={setInput}
      onChange={(option) => onChangeHandler(option)}
    />
}

export default ChemicalSelect
