import React from 'react'
import { usePahFingerprintingAPI, usePahFingerprinting } from "../../../../contexts/pahFingerprinting";
import HelpLabels from './helpLabels';

const ModeToggle = () => {
  const { mode } = usePahFingerprinting()
  const { toggleMode } = usePahFingerprintingAPI()
  const isAlkylated = mode === 'Alkylated'
  const toggleSwitch = () => toggleMode(mode === 'Parent' ? 'Alkylated' : 'Parent')

  return (
    <div className='flex items-center justify-center'>
      <div className='mx-1'>
        <input
          className="hidden"
          id={`toggle-switch`}
          type="checkbox"
          checked={isAlkylated}
          onChange={toggleSwitch}
        />
        <label
          className={`flex items-center cursor-pointer w-28 h-6 bg-gray-400 rounded-full relative transition duration-500 ${
            mode === 'Alkylated' ? 'bg-green-400' : 'bg-blue-300'
          }`}
          htmlFor={`toggle-switch`}
        >
          <span className={`${isAlkylated ? 'ml-3' : 'ml-9'} text-gray-700 font-semibold uppercase text-xs`}>
            {mode}
          </span>
          <span className={`absolute ${isAlkylated ? 'right-1' : 'left-1'} w-5 h-5 bg-white rounded-full shadow-md transition duration-500`}/>
        </label>
      </div>
      <HelpLabels label={'Toggle between Parent and Alkylated PAHs'} direction={'left'}/>
    </div>
  )
}

export default ModeToggle
