import React, { useState } from 'react';
import Answers from './answers/main';
import Questions from './questions';

const TierOneBody = ( ) => {
  let [showAnswers, setShowAnswers] = useState(false);
  
  return (
    <>
    {showAnswers ? <Answers showAnswers={showAnswers} setShowAnswers={setShowAnswers} /> : <Questions showAnswers={showAnswers} setShowAnswers={setShowAnswers} />}
    </>
  )
}

export default TierOneBody
