import React from "react"
import { useDataVaultContext, useDataVaultAPI } from "../../../contexts/dataVault"

const DistanceRuler = () => {
  const { rulerMode } = useDataVaultContext()
  const { toggleRulerMode } = useDataVaultAPI()
  const rulerModeActive = rulerMode && 'bg-blue-200'
  
  return (
    <div 
      className={`absolute flex bg-white  mr-1.5 top-28 right-1 z-20 p-1.5 -mb-2 rounded-md ${rulerModeActive}`}
      style={{ width: '29px', height: '29px' }}
      onClick={toggleRulerMode}>
      <i className={`fa fa-ruler text-sm cursor-pointer text-black`}/>
    </div>
  )
}

export default DistanceRuler
