import React from 'react';

const DateSelectors = ({dateRange, setDateRange}) => {
  const handleStartDateChange = (e) => {
   setDateRange({...dateRange, start: e.target.value})
  };

  const handleEndDateChange = (e) => {
    setDateRange({...dateRange, end: e.target.value})
  };

  return (
    <div className='flex bg-gray-200 p-2'>
      <div className='flex flex-col mr-2'>
        <label className='text-gray-600 font-bold tracking-wider uppercase text-xs mb-1'>Start Date:</label>
        <input className='w-36 h-6 bg-gray-100 text-gray-800 rounded text-xs' value={dateRange.start} type='date' onChange={handleStartDateChange} />
      </div>
      <div className='flex flex-col'>
        <label className='text-gray-600 font-bold tracking-wider uppercase text-xs mb-1'>End Date:</label>
        <input className='w-36 h-6 bg-gray-100 text-gray-800 rounded text-xs' value={dateRange.end} type='date' onChange={handleEndDateChange} />
      </div>
    </div>
  );
}

export default DateSelectors;
