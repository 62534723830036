const zeroLabels = () => {
  return {
    enabled: true,
    formatter: function() {
      return this.y === 0 ? this.y : null;
    }
  }
}

export default zeroLabels
