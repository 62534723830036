import React, { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import Checkbox from "./Checkbox"
import Input from "./Input"
import ModalContent from "./ModalContent"
import axios from "axios"

const DisclaimerModal = ({ isOpen, setIsOpen, onConfirm }) => {
  const [email, setEmail] = useState('')

  const onFormSubmit = (e) => {
    e.preventDefault()

    axios.post('/guideline_calculator_users', { email: email })
      .then((res) => {
        setIsOpen(prev => !prev)
        onConfirm()
      })
      .catch((error) => {
        console.error(error)
      })
  }

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      {/* Requires onClose prop, but doing so allows users to close with esc key. This removes that ability. */}
      <Dialog as="div" className="relative z-10" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className="relative transform overflow-hidden rounded-lg bg-white sm:my-8 sm:w-full sm:max-w-xl overflow-visible shadow-xl text-left ">
                <div className="w-full bg-white text-black pb-2 pt-6 font-semibold rounded-t-md text-center">
                  <span className="text-3xl">Terms and Conditions</span>
                </div>
                <div className="px-8">
                  <ModalContent />
                </div>
                <form className='px-8 pt-1 pb-6 transition-all text-black' onSubmit={onFormSubmit}>
                  <div className='flex justify-between items-center gap-x-2 py-2'>
                    <div className='w-full'>
                      <Input
                        name='email'
                        title='Email'
                        type='text'
                        required
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                      />
                      <Checkbox
                        name='term_agreement'
                        type='checkbox'
                        required
                      />
                    </div>
                  </div>
                  <div className="mt-2">
                    <button
                      type="submit"
                      className="inline-flex w-full justify-center rounded-md rounded w-32 bg-blue-500 hover:bg-blue-700 font-bold p-2 text-base text-white border border-blue-700"
                    >
                      Agree and Continue
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default DisclaimerModal
