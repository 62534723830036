const extractYear = (dateString) => {
  const dateObject = new Date(dateString)
  return dateObject.getFullYear()
}

export const allDataWithinYear = (datasets) => {
  const years = datasets.map((dataset) => dataset.data.map((data) => extractYear(data.x))).flat()
  const firstYear = years[0]
  return years.every((year) => year === firstYear)
}
