import React from 'react'
import AnalysisRow from './analysisRow'

const DiagnosticRatios = ({ data }) => {
  return (
    <>
    {data &&
      <div className='rounded-lg bg-white py-2 px-2 h-full'>
        <h3 className='font-semibold'>Diagnostic Ratios</h3>
        <table className="table-fixed w-full">
          <thead>
            <tr>
              <th className='w-1/2 font-semibold text-sm text-left'>PAH Ratio</th>
              <th className='w-1/4 font-semibold text-sm text-left'>Result</th>
              <th className='w-1/4 font-semibold text-sm text-left'>Notes</th>
            </tr>
          </thead>
          <tbody>
            <AnalysisRow
              ratioName='Fluoranthene/Pyrene'
              ratio={data.ratio_1}
            />
            <AnalysisRow
              ratioName='Phenanthrene/Anthracene'
              ratio={data.ratio_2}
            />
            <AnalysisRow
              ratioName='Benzo(a)anthracene/Chrysene'
              ratio={data.ratio_3}
            />
            <AnalysisRow
              ratioName='Indeno(1,2,3-cd)pyrene/Benzo(g,h,i)perylene'
              ratio={data.ratio_4}
            />
          </tbody>
        </table>
      </div>
    }
    </>
  )
}

export default DiagnosticRatios
