import React from "react"

const IgnoredParameters = ({ data }) => {
  return <>
    <h1>Ignored Parameters</h1>
    <div className='flex flex-col gap-y-2 my-4'>
      {data?.map(parameter => <p key={parameter.id}>{parameter.name}</p>)}
    </div>
  </>
}

export default IgnoredParameters

