const downloadReport = (reportData) => {
  const blob = new Blob([reportData], { type: 'application/pdf' })
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.download = 'Report.pdf'
  link.click()
}

export const generatePdfReport = (data, site, getPdfReportData) => {
  const encodedExportData = data.map(data => {
    return { sample_id: data.sample_id, histogram: encodeURIComponent(data.histogram), ratio_plot: encodeURIComponent(data.ratio_plot), standard_histogram: encodeURIComponent(data.standard_histogram), alkylated_histogram: encodeURIComponent(data.alkylated_histogram), alkylated_standard_histogram: encodeURIComponent(data.alkylated_standard_histogram) }
  })

  getPdfReportData(site.id, encodedExportData).then((response) => downloadReport(response.data))
}
