import React from "react";
import flowChart from "./flowChart.png"

const Welcome = ({ handleClick }) => {
  return (
    <div className='container flex flex-row ml-4 mt-4'>
      <div className='flex flex-col gap-y-16 ml-12'>
        <h1 className='-mb-4'>Tier 1 & 2 Guideline Calculator</h1>
        <div className='flex flex-col gap-y-8 w-3/4'>
          <h1>Tier 1 Guidelines Module</h1>
          <p>Module 1 will highlight generic numeric guidelines that potentially apply at your site.</p>
          <p>You will be asked a series of questions that trigger the applicability of various guideline documents.</p>
          <p>The output will provide links to the relevant documents so you can confirm if they are, or are not, relevant based on your site knowledge.</p>
        </div>
        <div className='w-3/4 mx-2'>
          <img src={flowChart} alt='tier_one_flow_chart' />
          <div className='flex flex-col items-center text-center'>
            <p className='font-semibold mt-2'>Remediation Process Flow Chart from the Contaminated Sites Policy Framework</p>
            <div>
              <span className='text-xs'>(Alberta Environment and Sustainable Resource Development (ESRD). 2014. Contaminated Sites Policy Framework. Land and Forestry Policy Branch, Policy Division, 25pp.)</span>
              <span><a className='text-xs text-blue-500 underline mx-2' href='https://open.alberta.ca/publications/978146010579#summary' target='_blank'>(Source)</a></span>
            </div>
          </div>
        </div>
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold p-2 border border-blue-700 rounded w-32"
          onClick={handleClick}
        >
          Get Started
        </button>
      </div>

    </div>
  )
}

export default Welcome
