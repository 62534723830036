export const filterSaltPrints = (siteData, geoJSON, matrix, parameter, area, dateRange, depthRange, valueRange) => {
  let updatedSaltPrintSamples = siteData;
  
  if (matrix) {
    updatedSaltPrintSamples = updatedSaltPrintSamples.filter(sample => sample.sample.matrix.title === matrix);
  }
  
  if (area) {
    updatedSaltPrintSamples = updatedSaltPrintSamples.filter(sample => sample.sample.sample_location_group_name === area);
  }
  
  if (Object.entries(parameter).length !== 0) {
    updatedSaltPrintSamples = updatedSaltPrintSamples.filter(sample => {
     const sampleChemicalIds = sample.sample.sample_values.map(value => value.chemical_id);
     return sampleChemicalIds.includes(parameter.id);
    });
  }
  
  if (dateRange.start) {
    updatedSaltPrintSamples = updatedSaltPrintSamples.filter(sample => new Date(sample.sample.sample_date) >= new Date(dateRange.start));
  }
  
  if (dateRange.end) {
    updatedSaltPrintSamples = updatedSaltPrintSamples.filter(sample => new Date(sample.sample.sample_date) <= new Date(dateRange.end));
  }
  
  if (depthRange.start) {
    updatedSaltPrintSamples = updatedSaltPrintSamples.filter(sample => sample.sample.sample_depth >= depthRange.start);
  }
  
  if (depthRange.end) {
    updatedSaltPrintSamples = updatedSaltPrintSamples.filter(sample => sample.sample.sample_depth <= depthRange.end);
  }
   
  if (valueRange.start) {
    updatedSaltPrintSamples = updatedSaltPrintSamples.map(sample => {
      const sampleValues = sample.sample.sample_values.filter(value => value.float_value >= valueRange.start && value.chemical_id === parameter.id);
      return {...sample, sample: {...sample.sample, sample_values: sampleValues}}
    });
  }
  
  if (valueRange.end) {
    updatedSaltPrintSamples = updatedSaltPrintSamples.map(sample => {
      const sampleValues = sample.sample.sample_values.filter(value => value.float_value <= valueRange.end && value.chemical_id === parameter.id);
      return {...sample, sample: {...sample.sample, sample_values: sampleValues}}
    });
  }
  
  updatedSaltPrintSamples = updatedSaltPrintSamples.filter(sample => sample.sample.sample_values.length > 0);
  const saltPrintIds = updatedSaltPrintSamples.map(sample => sample.id);
  
  const updatedGeoJSON = {
    type: "FeatureCollection",
    features: geoJSON.features.filter(feature => saltPrintIds.includes(feature.properties.salt_print_sample_id))
  }
  return { updatedGeoJSON, updatedSaltPrintSamples };
};

export const filterByLocation = (geojson, saltPrintSamples, highlightSampleIds, filteredSamples) => {
  const filteredGeojsonLocation = {type: "FeatureCollection", features: []};
      geojson.features.forEach(feature => {
        if (highlightSampleIds.includes(feature.properties.salt_print_sample_id)) {
          filteredGeojsonLocation.features.push(feature);
        }
      });
  const samples = filteredSamples || saltPrintSamples;
  const filteredSamplesLocation = samples.filter(sample => highlightSampleIds.includes(sample.id));
  return {filteredGeojsonLocation, filteredSamplesLocation};
};
