import React from "react"

const Label = ({ title, requiredField }) => {
  return (
    <label>
      {title}
      {requiredField && reqAsterisk}
    </label>
  )
}

const reqAsterisk = <span className='text-red-600'>*</span>

export default Label
