import React, { useState } from 'react'
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsMore from "highcharts/highcharts-more";

HighchartsMore((Highcharts))

const BoxPlot = ({ data }) => {
  const buildChartOptions = () => {
    return {
      title: {
        text: ' '
      },
      chart: {
        type: 'boxplot'
      },
      legend: {
        enabled: false
      },
      xAxis: {
        categories: ['Distribution'],
        visible: false
      },
      yAxis: [
        {
          title: '# Samples',
          min: 0,
          plotLines: [{
            value: data.mean,
            color: 'red',
            width: 1
          }]
        },
      ],
      series: [
        {
          name: 'Samples',
          data: [[data.minimum, data.lower_quartile, data.median, data.upper_quartile, data.maximum]]
        }
      ]
    }
  }

  let [chartOptions, setChartOptions] = useState(buildChartOptions(data.bins, data.frequencies))

  return chartOptions && <div className='w-1/4'>
    <HighchartsReact
      highcharts={Highcharts}
      options={chartOptions} />
  </div>
}

export default BoxPlot
