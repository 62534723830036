import React from "react";
import SampleGroupRows from "./sampleGroupRows";

const SampleGroupTable = ({ saltSamples, setSaltSamples, isDisabled, typeOptions }) => {
  const sampleGroups = [...new Set(saltSamples?.map((sample) => sample.sample_location_group_name))].filter(Boolean);
  const hasSampleGroup = sampleGroups.length > 0;
  const onGroupTypeChangeHandler = (sampleGroup, option) => {
    const updatedSamples = saltSamples.map((saltSample) => {
      if (saltSample.sample_location_group_name === sampleGroup && !isDisabled(saltSample)) {
        return { ...saltSample, type: option.value };
      }
      return saltSample;
    });
    setSaltSamples(updatedSamples);
  };
  
  const sampleGroupRows = sampleGroups?.map((sampleGroup) => {
    return <SampleGroupRows key={sampleGroup} sampleGroup={sampleGroup} typeOptions={typeOptions} onGroupTypeChangeHandler={onGroupTypeChangeHandler} />;
  });
  
  return(
    <>
      {hasSampleGroup &&
      <>
        <h2 className="sv-label mt-2">Map by Sample Groups:</h2>
        <div className={`relative h-1/6 w-1/2 overflow-y-scroll border rounded-lg`}>
          <table className="table">
            <thead>
              <tr className='sticky top-0 z-10'>
                <th>Sample Group</th>
                <th>Type</th>
              </tr>
            </thead>
            <tbody>{sampleGroupRows}</tbody>
          </table>
        </div>
      </>
      }
    </>
  );
};

export default SampleGroupTable;
