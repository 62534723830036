import React, { useEffect, useState } from 'react'
import axios from 'axios'
import TableSummary from "./tableSummary";
import Histogram from "./histogram";
import BoxPlot from "./boxPlot";
import dataSummaryLegend from "./dataSummaryLegend.png"

const DataSummary = ({ siteId, chemicalId }) => {
  let [data, setData] = useState(null)

  useEffect(() => {
    axios.get(`/sites/${siteId}/data_summary/${chemicalId}.json`).then((res) => {
      setData(res.data)
    })
  }, [])


  return data && <>
    <div className='flex flex-row'>
      <Histogram data={data} />
      <BoxPlot data={data} />
      <img src={dataSummaryLegend} alt='Data Summary Legend' className='w-1/4 ml-4' />
    </div>
    <TableSummary data={data} />
  </>
}

export default DataSummary
