import React, { useEffect } from "react";
import BaseMap from "../map/baseMap";
import { useGroundwaterContext, useGroundwaterAPI } from "../../contexts/groundwater";

const BaseMapContainer = ({ geoJSON, mapType }) => {
  const { chart, geojson, parameter, highlightedGeojson, highlightSampleIds, showPopups, showLegends } = useGroundwaterContext();
  const { setGeojson, setHighlightSampleIds } = useGroundwaterAPI();
  
  useEffect(() => {
    const initialGeojson = {
      type: "FeatureCollection",
      features: [],
    };
    
    const chartSampleIds = [];
    chart?.datasets.forEach((set) => {
      set.data.forEach((sampleData) => {
        chartSampleIds.push(sampleData.sample_id);
      });
    });
    
    geoJSON.features.forEach((feature) => {
      const sampleParameterIds = feature.properties.sample_values.map((value) => value.chemical_id);
      if (chartSampleIds.includes(feature.properties.sample_id) && sampleParameterIds.includes(parameter.id)) {
        initialGeojson.features.push(feature);
      }
    });
    
    setGeojson(initialGeojson);
  }, [chart]);
  
  return <BaseMap geojson={highlightedGeojson ? highlightedGeojson : geojson} highlightSampleIds={highlightSampleIds} setHighlightSampleIds={setHighlightSampleIds} mapType={mapType} showPopups={showPopups} showLegends={showLegends} />
};

export default BaseMapContainer;
