const molecularLabels = (verticalPosition) => {
  return {
    load: function() {
      const fontSize = '12px'
      const color = '#787878'
      const bottomVerticalPosition = verticalPosition || 35
      const zIndex = 1000

      let leftPoint = this.series[0].points[3];
      let rightPoint = this.series[0].points[13];

      this.lowMolecularLabel = this.renderer.text('LMW', leftPoint.plotX, bottomVerticalPosition).css({ color, fontSize }).attr({ zIndex }).add();
      this.highMolecularLabel = this.renderer.text('HMW', rightPoint.plotX, bottomVerticalPosition).css({ color, fontSize }).attr({ zIndex }).add();
    },
    redraw: function() {
      let leftPoint = this.series[0].points[3];
      let rightPoint = this.series[0].points[11];

      this.lowMolecularLabel?.attr({
        x: leftPoint.plotX,
      });

      this.highMolecularLabel?.attr({
        x: rightPoint.plotX,
      });
    }
  }
}

export default molecularLabels

