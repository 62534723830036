import React from "react";
import DataVault from "./dataVault";
import { DataVaultProvider } from "../../contexts/dataVault";

const Index = ({siteId, selectedSampleIds}) => {
  return (
    <div className='relative w-full h-full'>
      <DataVaultProvider>
        <DataVault siteId={siteId} selectedSampleIds={selectedSampleIds}/>
      </DataVaultProvider>
    </div>
  )
}

export default Index
