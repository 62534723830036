import axios from "axios";

const api = axios.create({
  headers: {
    "X-CSRF-Token": document
      .querySelector('meta[name="csrf-token"]')
      ?.getAttribute("content"),
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

export default api;
