import React from 'react'

const HeaderTable = ({ emptyHeaderFirstRows }) => {
  const tableRows = emptyHeaderFirstRows.map((row, index) => {
    return (
      <tr key={index}>
        <td>{row.column}</td>
        <td>{row.value || '-'}</td>
      </tr>
    )
  })

  return (
    <div className='border rounded max-h-32 overflow-auto'>
      <table className='dataVaultTable'>
        <thead className='text-center'>
          <tr>
            <th>CSV Column</th>
            <th>First Sample Row in CSV</th>
          </tr>
        </thead>
        <tbody className='text-xs text-center'>
          {tableRows}
        </tbody>
      </table>
    </div>
  )
}

export default HeaderTable
