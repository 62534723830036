import React, { useState, useEffect } from "react";
import ChemicalRow from "./chemicalRow";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const UnmappedChemicals = () => {
  const [parameters, setParameters] = useState([]);
  const [selectedParameters, setSelectedParameters] = useState([]);
  const [checked, setChecked] = useState(false);

  const toggleSelection = (id) => {
    if (selectedParameters.includes(id)) {
      setSelectedParameters((prevState) =>
        prevState.filter((value) => value != id)
      );
    } else {
      setSelectedParameters((prevState) => [...prevState, id]);
    }
  };

  const toggleAll = () => {
    if (checked) {
      setSelectedParameters([]);
      setChecked(false);
    } else {
      setSelectedParameters(parameters.map((el) => el.id));
      setChecked(true);
    }
  };

  useEffect(() => {
    if (checked && selectedParameters.length < 1) setChecked(false);
  }, [checked, selectedParameters]);

  useEffect(() => {
    axios
      .get("/unmapped_chemicals.json")
      .then((res) => setParameters(res.data));
  }, []);

  const deleteSelected = () => {
    axios
      .delete(`/unmapped_chemicals/destroy_selected`, {
        params: { selected_ids: selectedParameters },
      })
      .then((res) => {
        setParameters(res.data);
        setSelectedParameters([]);
        toast.success("Selection successfully deleted");
      });
  };

  return (
    <>
      <ToastContainer />
      <div className="container mx-auto">
        <div className="flex justify-between items-center">
          <h1>Unmapped Chemicals</h1>
          <div className="flex flex-row gap-x-2">
            {selectedParameters.length > 0 && (
              <button
                className="btn btn-primary font-semibold"
                onClick={deleteSelected}
              >
                Delete Selected ({selectedParameters.length})
              </button>
            )}
            <button className="btn btn-primary font-semibold">
              <a href="/ignored_parameters" target="_blank" rel="noreferrer">
                Ignored Parameters
              </a>
            </button>
          </div>
        </div>
        <div className="flex flex-wrap my-4">
          <div className="sheet sheet-condensed">
            <div className="sheet-inner">
              <table className="border-collapse table-fixed w-full whitespace-nowrap bg-white">
                <thead className="bg-gray-100 sticky top-0 border-b border-gray-200 p-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                  <tr className="">
                    <th className="w-9">
                      <input
                        className="my-2"
                        type="checkbox"
                        checked={checked}
                        onChange={toggleAll}
                      />
                    </th>
                    <th>Parameter</th>
                    <th>Chemical</th>
                    <th>Site</th>
                    <th>Upload</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {parameters?.map((parameter, index) => {
                    const checked = selectedParameters.includes(parameter.id);
                    return (
                      <ChemicalRow
                        key={index}
                        parameter={parameter}
                        setParameters={setParameters}
                        toggleSelection={toggleSelection}
                        checked={checked}
                      />
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UnmappedChemicals;
