import React, { useEffect, useState } from 'react'
import Select from "react-select";
import axios from "axios";

const ParameterSelect = ({ site, setParameter }) => {
  const [options, setOptions] = useState([])

  useEffect(() => {
    axios.get(`/sites/${site.id}/chemicals.json`)
      .then(res => {
        const formattedOptions = res.data.map(chemical => {
          return { label: chemical.name, value: chemical.id }
        })
        setOptions(formattedOptions)
      })
  }, [])

  return <>
    <label>Parameter</label>
    <Select
      className='w-full'
      options={options}
      onChange={setParameter}
    />
  </>
}

export default ParameterSelect
