import React, { useMemo } from 'react'
import ChemicalRow from './chemicalRow'
import SampleHeaders from './sampleHeaders'
import { tableDataSorter } from '../shared/table/tableFunctions'
import { useDioxinsAndFuransContext, useDioxinsAndFuransAPI } from '../../contexts/dioxinsAndFurans'

const Table = () => {
  const { setSelectedSample } = useDioxinsAndFuransAPI()
  const { samples, chemicals, filteredSamples, filteredChemical, displayedSamples, selectedSample } = useDioxinsAndFuransContext()
  tableDataSorter(samples)
  const samplesData = useMemo(() => displayedSamples || filteredSamples || samples, [displayedSamples, filteredSamples, samples])
  const chemicalList = useMemo(() => filteredChemical || chemicals,[filteredChemical, chemicals])
  
  const tableHeaders = useMemo(() => {
    return samplesData.map((sample) => <SampleHeaders key={sample.id} sample={sample} selectedSampleId={selectedSample.id} setSelectedSample={setSelectedSample}/>)
  }, [samplesData, selectedSample, setSelectedSample])
  
  const tableRows = useMemo(() => {
    return chemicalList.map((chemical) => <ChemicalRow key={chemical.id} chemical={chemical} samplesData={samplesData} />)
  }, [chemicalList, samplesData])

  return (
    <div className="h-full overflow-auto">
      <table className='border table-auto w-full whitespace-nowrap bg-white relative'>
        <thead>
          <tr className='sticky top-0 z-20 bg-white'>
            <th className='sticky left-0 bg-white z-10'>Parameter</th>
            <th><span className='mx-3'>Units</span></th>
            {tableHeaders}
          </tr>
        </thead>
        <tbody className='px-3'>
          {tableRows}
        </tbody>
      </table>
    </div>
  )
}

export default Table
