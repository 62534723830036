import React, { useState, useEffect } from "react";
import axios from "axios";
import ChemicalSelect from "../chemicalSelect";
import { useParameterSelectContext } from "../../../contexts/parameterSelection";

const ChemicalParameters = () => {
  const [guidelineChemicals, setGuidelineChemicals] = useState({})
  const { columnNames, units, chemicals, siteUpload } = useParameterSelectContext()
  const hasTemplate = Object.keys(guidelineChemicals)?.length > 0
  const columnList = columnNames.map((column, index) => <ChemicalSelect key={index} column={column} chemicals={chemicals} units={units} guidelineChemicals={guidelineChemicals} hasTemplate={hasTemplate} />)
  
  useEffect(() => {
    axios.get(`/sites/${siteUpload.site_id}/site_uploads/${siteUpload.id}/guideline_template_chemicals`)
    .then(res => setGuidelineChemicals(res.data))
    .catch(error => console.log(error))
  }, [])
  
  return (
    <div className='container h-5/6 flex flex-col'>
      <div className="flex flex-col">
        <h1>CSV Parameter Selection</h1>
        <p className="block text-xs font-semibold text-gray-600">Parameter names similar to statvis are mapped automatically. For others, click on a cell, type the chemical name, and choose the correct parameter from the dropdown list.</p>
        {hasTemplate && <p className="block text-xs font-semibold text-gray-600">Row is highlighted if guideline unit and selected units do not match. It will not apply the guideline for that chemical</p>}
        <p className="block text-xs font-semibold text-gray-600">Unmapped chemicals will not be imported</p>
      </div>
      <div className='h-5/6 overflow-auto'>
        <table className='border-collapse table-fixed w-full whitespace-nowrap bg-white text-xs'>
          <thead className='bg-gray-100 border-b border-gray-200 text-gray-600 font-bold tracking-wider uppercase sticky top-0 z-20'>
            <tr>
              <th className='w-3/12'>CSV Parameter</th>
              <th className='w-1/12'>CSV Unit</th>
              <th className='w-2/12'>CSV Analytical Method</th>
              <th className='w-3/12'>Statvis Parameter</th>
              <th className='w-2/12'>Unit</th>
              {hasTemplate && <th className='w-1/12'>Guideline Unit</th>}
            </tr>
          </thead>
          <tbody className='overflow-auto'>{columnList}</tbody>
        </table>
      </div>
    </div>
  )
}

export default ChemicalParameters;
