import React from "react";
import Select from "react-select";

const SampleGroupRows = ({ sampleGroup, typeOptions, onGroupTypeChangeHandler }) => {
  return (
    <tr key={sampleGroup}>
      <td>{sampleGroup}</td>
      <td className='flex justify-center'>
        <Select
          placeholder='Select Type'
          menuPortalTarget={document.body}
          menuPosition={'fixed'}
          styles={{
            container: base => ({...base, width: '300px',}),
            menuPortal: base => ({ ...base, zIndex: 9999 })
          }}
          options={typeOptions}
          onChange={(option) => onGroupTypeChangeHandler(sampleGroup, option)}
        />
      </td>
    </tr>
  )
};

export default SampleGroupRows;
