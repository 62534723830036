import React from 'react'
import GuidelineRow from "./guidelineRow";
import AddGuideline from "./addGuideline";
import ApplyGuidelines from "./applyGuidelines";
import { useCustomGuidelinesContext } from "../../../contexts/customGuidelines";

const ExistingGuidelines = () => {
  const { customGuidelines } = useCustomGuidelinesContext()

  return <>
    <div className='panel'>
      <p className='px-3 mb-3'>When you are done adding/deleting custom guidelines, click the "Apply" button below.</p>
      <table className='text-center'>
        <thead>
          <tr className='border-b border-gray-600 text-gray-600'>
            <th className='px-3 text-left w-1/4'>Parameter</th>
            <th className='px-3'>Soil Units</th>
            <th className='px-3'>Soil Guideline</th>
            <th className='px-3'>Subsoil Guideline</th>
            <th className='px-3'>Groundwater Units</th>
            <th className='px-3'>Groundwater Guideline</th>
          </tr>
        </thead>
        <tbody>
          {customGuidelines.map((guideline, index) => <GuidelineRow key={index} guideline={guideline} />)}
        </tbody>
      </table>
      <div className='flex flex-row gap-x-3 my-3'>
        <AddGuideline />
        <ApplyGuidelines />
      </div>
    </div>
  </>
}

export default ExistingGuidelines
