import React from 'react'
import { useDataVaultContext, useDataVaultAPI } from '../../../../contexts/dataVault'

const SurficialGeologyToggle = () => {
  const { showSurficialGeologyLayer } = useDataVaultContext()
  const { toggleSurficialGeologyLayer } = useDataVaultAPI()
  
  return (
    <button className={`border w-full ${showSurficialGeologyLayer ? 'bg-blue-300' : 'bg-gray-300'} text-xs rounded p-1 my-1 hover:${showSurficialGeologyLayer ? 'bg-blue-200' : 'bg-gray-200'}`} onClick={() => toggleSurficialGeologyLayer()}>AB - Surficial Geology</button>
  )
}

export default SurficialGeologyToggle
