import React, { useState, createContext } from "react";

export const TierTwoContext = createContext({
  siteInformation: null,
  setSiteInformation: null,
  landUse: null,
  setLandUse: null,
  grainSize: null,
  setGrainSize: null,
  sourceLength: null,
  setSourceLength: null,
  sourceWidth: null,
  setSourceWidth: null,
  surfaceFoundationDistance: null,
  setSurfaceFoundationDistance: null,
  subsoilFoundationDistance: null,
  setSubsoilFoundationDistance: null,
  contaminationThickness: null,
  setContaminationThickness: null,
  groundwaterDepth: null,
  setGroundwaterDepth: null,
  surfaceWaterDistance: null,
  setSurfaceWaterDistance: null,
  hydraulicGradient: null,
  setHydraulicGradient: null,
  hydraulicConductivity: null,
  setHydraulicConductivity: null,
  organicCarbonFraction: null,
  setOrganicCarbonFraction: null,
  bulkDensity: null,
  setBulkDensity: null,
  moisturePorosity: null,
  setMoisturePorosity: null,
  vapourPorosity: null,
  setVapourPorosity: null,
  contaminationDepth: null,
  setContaminationDepth: null
});

export const TierTwoProvider = ({children}) => {
  let [siteInformation, setSiteInformation] = useState('')
  let [landUse, setLandUse] = useState('Commercial')
  let [grainSize, setGrainSize] = useState('Fine Grain')
  let [sourceLength, setSourceLength] = useState({ value: 10, site_specific: false })
  let [sourceWidth, setSourceWidth] = useState({ value: 10, site_specific: false })
  let [surfaceFoundationDistance, setSurfaceFoundationDistance] = useState({ value: 10, site_specific: false })
  let [subsoilFoundationDistance, setSubsoilFoundationDistance] = useState({ value: 30, site_specific: false })
  let [contaminationThickness, setContaminationThickness] = useState({ value: 3, site_specific: false })
  let [groundwaterDepth, setGroundwaterDepth] = useState({ value: 3, site_specific: false })
  let [surfaceWaterDistance, setSurfaceWaterDistance] = useState({ value: 10, site_specific: false })
  let [hydraulicGradient, setHydraulicGradient] = useState({ value: 0.028, site_specific: false })
  let [hydraulicConductivity, setHydraulicConductivity] = useState({ value: 32, site_specific: false })
  let [organicCarbonFraction, setOrganicCarbonFraction] = useState({ value: 0.005, site_specific: false })
  let [bulkDensity, setBulkDensity] = useState({ value: 1.4, site_specific: false })
  let [moisturePorosity, setMoisturePorosity] = useState({ value: 0.168, site_specific: false })
  let [vapourPorosity, setVapourPorosity] = useState({ value: 0.302, site_specific: false })
  let [contaminationDepth, setContaminationDepth] = useState({ value: 3, site_specific: false })

  return (
    <TierTwoContext.Provider
      value={{
        siteInformation: siteInformation,
        setSiteInformation: setSiteInformation,
        landUse: landUse,
        setLandUse: setLandUse,
        grainSize: grainSize,
        setGrainSize: setGrainSize,
        sourceLength: sourceLength,
        setSourceLength: setSourceLength,
        sourceWidth: sourceWidth,
        setSourceWidth: setSourceWidth,
        surfaceFoundationDistance: surfaceFoundationDistance,
        setSurfaceFoundationDistance: setSurfaceFoundationDistance,
        subsoilFoundationDistance: subsoilFoundationDistance,
        setSubsoilFoundationDistance: setSubsoilFoundationDistance,
        contaminationThickness: contaminationThickness,
        setContaminationThickness: setContaminationThickness,
        groundwaterDepth: groundwaterDepth,
        setGroundwaterDepth: setGroundwaterDepth,
        surfaceWaterDistance: surfaceWaterDistance,
        setSurfaceWaterDistance: setSurfaceWaterDistance,
        hydraulicGradient: hydraulicGradient,
        setHydraulicGradient: setHydraulicGradient,
        hydraulicConductivity: hydraulicConductivity,
        setHydraulicConductivity: setHydraulicConductivity,
        organicCarbonFraction: organicCarbonFraction,
        setOrganicCarbonFraction: setOrganicCarbonFraction,
        bulkDensity: bulkDensity,
        setBulkDensity: setBulkDensity,
        moisturePorosity: moisturePorosity,
        setMoisturePorosity: setMoisturePorosity,
        vapourPorosity: vapourPorosity,
        setVapourPorosity: setVapourPorosity,
        contaminationDepth: contaminationDepth,
        setContaminationDepth: setContaminationDepth
      }}
    >
        {children}
    </TierTwoContext.Provider>
  );
};

export default TierTwoProvider
