import React, { useState, useRef } from 'react'
import PreviewChart from './previewChart'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import HC_more from "highcharts/highcharts-more"
import label from "highcharts/modules/series-label"
import HC_exporting from 'highcharts/modules/exporting'
import { congenerContributionsChartOptions } from './helpers/chartBuilder'
import { generatePdfReport } from './helpers/exportFunctions'
import { usePcbContext, usePcbAPI } from '../../contexts/pcb'

HC_more(Highcharts)
label(Highcharts)
HC_exporting(Highcharts)

const PreviewPage = () => {
  const { site, sampleIdsForExport, exportData} = usePcbContext()
  const { setShowPreview, setExportData } = usePcbAPI()
  const [ totalGroupCongeners, setTotalGroupCongeners ] = useState([])

  const summaryChartRef = useRef(null)

  const addGroupCongeners = (sampleGroupCongeners) => setTotalGroupCongeners(prev => [...prev, sampleGroupCongeners])
  const sampleList = sampleIdsForExport.map(id => <PreviewChart key={id} sampleId={id} addGroupCongeners={addGroupCongeners} />)
  const completeCongenerGroup = totalGroupCongeners.length === sampleIdsForExport.length

  const handleBack = () => {
    setShowPreview(false)
    setExportData([])
  }

  const handleExport = () => {
    generatePdfReport(exportData, summaryChartRef.current?.chart?.getSVG(), site)
  }

  return (
    <>
      <div className='overflow-auto'>
        <div className='sticky top-0 flex justify-between px-2 z-20 bg-white border border-black py-1'>
          <button className='border-gray-400 rounded p-1 text-xs bg-gray-300 hover:bg-gray-400 uppercase font-semibold cursor-pointer w-24' onClick={handleBack}>Back</button>
          <h1 className='font-semibold uppercase'>Preview Samples</h1>
          <button className={`rounded p-1 text-xs bg-gray-300 hover:bg-gray-400 uppercase font-semibold w-24`} onClick={handleExport}>{completeCongenerGroup ? 'Export' : `${exportData.length} / ${sampleIdsForExport.length} Loaded`}</button>
        </div>
        <div>
          {completeCongenerGroup ?
            (<>
              <HighchartsReact
                highcharts={Highcharts}
                options={congenerContributionsChartOptions('Homologs Contribution', totalGroupCongeners, false, false)}
                ref={summaryChartRef}
              />
              <div className='hidden'>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={congenerContributionsChartOptions('', totalGroupCongeners, false, true)}
                  ref={summaryChartRef}
                />
              </div>
            </>)
            :
            (
              <div className='w-full h-24 flex items-center justify-center'>
                <p className='text-xl font-semibold text-gray-500'><i className='fa fa-spinner fa-spin'/> Loading...</p>
              </div>
            )
          }
        </div>
        <div className='p-1'>
          {sampleList}
        </div>
      </div>
    </>
  )
}

export default PreviewPage
