import React, { useState, useEffect } from "react";
import Filter from "../shared/filters/filter";
import { useSaltPrintContext, useSaltPrintAPI } from "../../contexts/saltPrint";
import { filterByLocation } from "./filterFunctions";

const FilterContainer = () => {
  const { chemicals, mapType, originalGeoJSON, saltPrintSamples, filteredSamples, highlightSampleIds, geojson } = useSaltPrintContext();
  const { setGeoJSON, setFilteredSamples, setFilteredChemical, setDisplayedGeojson, setDisplayedSamples } = useSaltPrintAPI();
  
  useEffect(() => {
    const hasHightlightedSamples = highlightSampleIds.length > 0;
    if (hasHightlightedSamples) {
      const {filteredGeojsonLocation, filteredSamplesLocation} = filterByLocation(geojson, saltPrintSamples, highlightSampleIds, filteredSamples);
      setDisplayedSamples(filteredSamplesLocation);
      setDisplayedGeojson(filteredGeojsonLocation);
    } else {
      setDisplayedSamples(null);
      setDisplayedGeojson(null);
    }
  },[highlightSampleIds]);
  
  return ( <Filter 
    geoJSON={originalGeoJSON}
    siteData={saltPrintSamples}
    chemicals={chemicals}
    mapType={mapType}
    setGeoJSON={setGeoJSON}
    setFilteredSamples={setFilteredSamples}
    filteredSamples={filteredSamples}
    setFilteredChemical={setFilteredChemical}
  /> )
};

export default FilterContainer;
