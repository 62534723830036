import React, { useState } from 'react'
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const Histogram = ({ data }) => {
  const buildChartOptions = () => {
    return {
      title: {
        text: 'Sample Distribution'
      },
      plotOptions: {},
      xAxis: [
        {
          id: 'histogram_x',
          categories: data.chart_bins
        }
      ],
      yAxis: [
        {
          id: 'histogram_y',
          title: {
            text: '# of Samples',
            enabled: true,
          },
          min: 0,
        }
      ],
      legend: {
        enabled: false
      },
      series: [
        {
          name: 'Samples',
          type: 'column',
          id: 'data_histogram',
          data: data.chart_values,
          yAxis: 'histogram_y',
          xAxis: 'histogram_x'
        }
      ]
    }

  }

  let [chartOptions, setChartOptions] = useState(buildChartOptions(data.bins, data.frequencies))

  return chartOptions && <HighchartsReact
    highcharts={Highcharts}
    options={chartOptions} />
}

export default Histogram
