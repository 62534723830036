import React from 'react'
import { PcbProvider } from '../../contexts/pcb'
import HomePage from './homePage'
import NoData from '../shared/noData'
import { findPcbGroup } from './helpers/coEluteHelper'

const Index = ({ site, chemicals, data, rangeMap, coelutions, uploadsData }) => {
  const allPcb = Array.from({ length: 209 }, (_, i) => `PCB ${(i + 1).toString().padStart(3, '0')}`)
  const coelutedPcb = Object.values(coelutions).flat()

  const pcbOptions = allPcb.filter((pcb) => !chemicals.find((chemical) => chemical.name === pcb || coelutedPcb.includes(pcb)))
  const coElutionOptions = pcbOptions.map((pcb) => ({ value: pcb, label: `${pcb} (${findPcbGroup(pcb, rangeMap)})` }))

  return (
    <div className='relative flex flex-col h-full'>
      {data ? 
        <PcbProvider initialData={{ site, chemicals, data, rangeMap, coElutionOptions, coelutions, uploadsData }}>
          <HomePage />
        </PcbProvider>
        :
        <NoData message="No PCB Data Available" />
      }
    </div>
  )
}

export default Index
