import React, { useState } from 'react'
import Label from "./Label"

export const Checkbox = (props) => {
  const [isChecked, setIsChecked] = useState(false)

  const handleChange = (e) => {
    e.target.setCustomValidity("")
    setIsChecked(!isChecked)
  }

  const handleInvalid = (e) => {
    e.target.setCustomValidity("You must agree to the terms of use and privacy policy to proceed.")
  }

  const termsOfUse =
    <a href="https://www.statvis.com/terms" target="_blank" rel="noreferrer"
       className="text-blue-800 hover:font-bold focus:outline-none">
      Terms of Use
    </a>

  const privacyPolicy =
    <a href="https://www.statvis.com/privacy" target="_blank" rel="noreferrer"
       className="text-blue-800 hover:font-bold">
      Privacy Policy
    </a>

  const title =
    <>
      I have read and agreed to the {termsOfUse} and {privacyPolicy}
    </>

  return (
    <div className="my-3 flex justify-center items-center">
      <input
        id={props.name}
        className="mr-3 h-4 w-4 rounded border-gray-300"
        checked={isChecked}
        onInvalid={handleInvalid}
        onChange={(e) => handleChange(e)}
        {...props}
      />
      <Label title={title} requiredField={props.required} />
    </div>
  )
}

export default Checkbox
