import React from 'react'
import { usePahFingerprinting, usePahFingerprintingAPI } from '../../../../contexts/pahFingerprinting'

export const HelpToggle = () => {
  const { toggleHelperTexts } = usePahFingerprintingAPI()
  const { showHelperTexts } = usePahFingerprinting()
  
  const icon = showHelperTexts ? <i className='fa fa-times text-xs text-red-500'/> : <i className='fa fa-question text-xs text-gray-800'/>
  const borderColor = showHelperTexts ? 'border-red-500' : 'border-gray-800'
  
  return (
    <button 
      className={`rounded-full border ${borderColor} text-xs h-5 w-5 flex items-center justify-center font-bold focus:outline-none mr-1`}
      onClick={toggleHelperTexts}
    >
      {icon}
    </button>
  )
}

export default HelpToggle
