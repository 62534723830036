import React, { useEffect, useMemo } from "react";
import TableRow from "./tableHelpers/tableRow";
import { useGroundwaterContext, useGroundwaterAPI } from "../../contexts/groundwater";
import { tableDataSorter } from "../shared/table/tableFunctions";

const Table = () => {
  const { currentParameter, highlightedTableData, chart, tableData, highlightSampleIds } = useGroundwaterContext();
  const { setTableData, setHighlightSampleIds } = useGroundwaterAPI();
  const displayedTableData = useMemo(() => highlightedTableData.length > 0 ? highlightedTableData : tableData, [highlightedTableData, tableData]);
  tableDataSorter(tableData);
  const hasUniqueIds = tableData?.filter(sample => sample.sampleUniqueId).length > 0;
  
  useEffect(() => {
    const initialTableData = []
    chart?.datasets.forEach((set) => {
      set.data.forEach((sampleData) => {
        const dataRow = {
          id: sampleData.sample_id,
          name: sampleData.sample_name,
          sampleUniqueId: sampleData.sample_unique_id,
          lessThan: sampleData.less_than,
          date: sampleData.x,
          value: sampleData.y,
          time: sampleData.sample_time,
          status: sampleData.sample_status,
          group : sampleData.sample_group
        }
        initialTableData.push(dataRow);
      });
    });
    
    setTableData(initialTableData);
  },[chart]);
  
  
  useEffect(() => {
    const rowElement = document.getElementById(`tr-${highlightSampleIds[0]}`);
    if (rowElement) {
      rowElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [highlightSampleIds]);
  
  const tableRows = displayedTableData.map((rowData) => {
    return (
      <TableRow 
        tableData={displayedTableData}
        rowData={rowData} 
        setHighlightSampleIds={setHighlightSampleIds} 
        highlightSampleIds={highlightSampleIds} 
        key={rowData.id}
        hasUniqueIds={hasUniqueIds}
      />
    )
  })

  return (
    <>
      <div className="max-h-1/3 overflow-y-auto">
        <table className="table">
          <thead>
            <tr>
              <th className= "sticky left-0 top-0 z-20 bg-gray-200">Sample Name</th>
              {hasUniqueIds && <th>Unique Sample ID</th>}
              <th>Sample Group</th>
              <th>Status</th>
              <th>Sample Date</th>
              <th>{currentParameter.name}<br/><span className="normal-case">{currentParameter.unit}</span></th>
            </tr>
          </thead>
          <tbody>
            {tableRows}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default Table;
