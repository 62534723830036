import React from 'react'
import Select from 'react-select'
import { usePcbContext, usePcbAPI } from '../../contexts/pcb'

const ReferenceSelect = () => {
  const { referenceSource } = usePcbContext()
  const { setReferenceSource } = usePcbAPI()

  const options = [
    { value: 'frame', label: 'Frame' },
    { value: 'rushneck', label: 'Rushneck' },
    { value: 'pacific', label: 'Pacific Rim' }
  ]

  const customStyles = {
    menuPortal: base => ({ ...base, zIndex: 9999 }),
    menu: provided => ({ ...provided, position: 'absolute', fontSize: '10px' }),
    singleValue: provided => ({ ...provided, fontSize: '12px'})
  };

  return (
    <div className='flex items-center mt-1'>
      <p className='text-xs font-semibold'>Reference Source:</p>
      <Select
        className='mx-1 w-48'
        options={options}
        defaultValue={options.find(option => option.value === referenceSource)}
        styles={customStyles}
        menuPortalTarget={document.body}
        onChange={option => setReferenceSource(option.value)}
      />
    </div>
  )
}

export default ReferenceSelect
