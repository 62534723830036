import { INITIALIZE_SAMPLE_DATA, CHANGE_HEADER, SET_CONSTANTS, TOGGLE_COMPARE, SET_CURRENT_SAMPLES, SELECT_SAMPLES, SET_REFERENCE_LIST, SET_REFERENCE_DATA, SET_EXPORT_DATA, SET_ADDITIONAL_DATA, SET_ADDITIONAL_HISTOGRAM_DATA, TOGGLE_ANALYSIS, FILTER_DATA, SET_DISPLAYED_DATA, UPDATE_DISPLAYED_SAMPLES, TOGGLE_MODE, SET_ALKYLATED_CONSTANTS, SET_ALKYLATED_REFERENCE_DATA, TOGGLE_POPUPS, TOGGLE_LEGENDS, TOGGLE_SHOW_MODAL, TOGGLE_PREVIEW, SET_LAB_SOURCE, SET_ANNOTATION_MODE, SET_SELECTED_SAMPLE_ANNOTATION, SET_SELECTED_SAMPLE_ANNOTATION_LOG, SET_SOURCE_MODAL, SET_SOURCE_REFERENCES, TOGGLE_HELPER_TEXTS } from "../actions";

export const initialPahFingerprintingState = {
  headerTab: 1,
  subHeaderTab: 1,
  samples: null,
  site: null,
  constants: [],
  compare: false,
  currentSamples: [],
  selectedSamples: [],
  referenceList: null,
  referenceData: null,
  additionalData: [],
  additionalHistogramData: [],
  exportData: [],
  analysis: false,
  geoJSON: null,
  chemicals: null,
  filteredSamples: null,
  filteredGeoJSON: null,
  filteredChemical: null,
  mapType: 'pah',
  displayedSamples: null,
  displayedGeojson: null,
  mode: 'Parent',
  alkylatedConstants: [],
  alkylatedReferenceData: [],
  showPopups: false,
  showLegends: false,
  showExportModal: false,
  showPreview: false,
  labSource: 'SGS',
  annotationMode: false,
  selectedSampleAnnotation: [],
  selectedSampleAnnotationLog: [],
  sourceModal: false,
  sourceReferences: null,
  showHelperTexts: false
}

export const initialPahFingerprintingApiState = {
  onDataInitialization: () => {},
  onHeaderChange: () => {},
  onGetConstants: () => {},
  toggleCompare: () => {},
  updateCurrentSamples: () => {},
  onSampleSelect: () => {},
  onGetReferenceList: () => {},
  onReferenceChange: () => {},
  onAdditionalDataChange: () => {},
  onAdditionalHistogramDataChange: () => {},
  onGetExportData: () => {},
  toggleAnalysis: () => {},
  onFilterData: () => {},
  onSetDisplayedData: () => {},
  onSampleSearch: () => {},
  toggleMode: () => {},
  onGetAlkylatedConstants: () => {},
  onSetAlkylatedReferenceData: () => {},
  togglePopups: () => {},
  toggleLegends: () => {},
  toggleShowModal: () => {},
  toggleShowPreview: () => {},
  setLabSource: () => {},
  setAnnotationMode: () => {},
  setSelectedSampleAnnotation: () => {},
  setSelectedSampleAnnotationLog: () => {},
  setSourceModal: () => {},
  setSourceReferences: () => {},
  showHelperTexts: () => {}
}

export const pahFingerprintingReducer = (state, action) => {
  switch (action.type) {
    case INITIALIZE_SAMPLE_DATA:
      return { ...state, site: action.site, samples: action.samples, geoJSON: action.geoJSON, chemicals: action.chemicals, sourceReferences: action.sourceReferences}
    case CHANGE_HEADER:
      return { ...state, [action.field]: action.value }
    case SET_CONSTANTS:
      return { ...state, constants: action.constants }
    case TOGGLE_COMPARE:
      return { ...state, compare: !state.compare }
    case SET_CURRENT_SAMPLES:
      return { ...state, currentSamples: action.currentSamples }
    case SELECT_SAMPLES:
      return { ...state, selectedSamples: action.selectedSamples }
    case SET_REFERENCE_LIST:
      return { ...state, referenceList: action.referenceList }
    case SET_REFERENCE_DATA:
      return { ...state, referenceData: action.referenceData }
    case SET_ADDITIONAL_DATA:
      return { ...state, additionalData: action.additionalData }
    case SET_ADDITIONAL_HISTOGRAM_DATA:
      return { ...state, additionalHistogramData: action.additionalHistogramData }
    case SET_EXPORT_DATA:
      return { ...state, exportData: action.exportData }
    case TOGGLE_ANALYSIS:
      return { ...state, analysis: !state.analysis }
    case FILTER_DATA:
      return { ...state, filteredSamples: action.filteredSamples, filteredGeoJSON: action.filteredGeoJSON, filteredChemical: action.filteredChemical }
    case SET_DISPLAYED_DATA:
      return { ...state, displayedSamples: action.displayedSamples, displayedGeojson: action.displayedGeojson }
    case UPDATE_DISPLAYED_SAMPLES:
      return { ...state, displayedSamples: action.searchSamples }
    case TOGGLE_MODE:
      return { ...state, mode: action.mode }
    case SET_ALKYLATED_CONSTANTS:
      return { ...state, alkylatedConstants: action.alkylatedConstants }
    case SET_ALKYLATED_REFERENCE_DATA:
      return { ...state, alkylatedReferenceData: action.alkylatedData}
    case TOGGLE_POPUPS:
      return { ...state, showPopups: !state.showPopups }
    case TOGGLE_LEGENDS:
      return { ...state, showLegends: !state.showLegends }
    case TOGGLE_SHOW_MODAL:
      return { ...state, showExportModal: !state.showExportModal }
    case TOGGLE_PREVIEW:
      return { ...state, showPreview: !state.showPreview }
    case SET_LAB_SOURCE:
      return { ...state, labSource: action.labSource }
    case SET_ANNOTATION_MODE:
      return { ...state, annotationMode: action.annotationMode }
    case SET_SELECTED_SAMPLE_ANNOTATION:
      return { ...state, selectedSampleAnnotation: action.selectedSampleAnnotation }
    case SET_SELECTED_SAMPLE_ANNOTATION_LOG:
      return { ...state, selectedSampleAnnotationLog: action.selectedSampleAnnotationLog }
    case SET_SOURCE_MODAL:
      return { ...state, sourceModal: action.sourceModal }
    case SET_SOURCE_REFERENCES:
      return { ...state, sourceReferences: action.sourceReferences }
    case TOGGLE_HELPER_TEXTS:
      return { ...state, showHelperTexts: !state.showHelperTexts }
    default:
      return state
  }
}
