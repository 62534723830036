import React from 'react'

const PreviewTable = ({firstRow, csvSheet}) => {

  const csvRows = csvSheet.map((row, index) => {
    const data = firstRow[index]
    return (
      <tr key={index} className='text-xs'>
        <td>{row}</td>
        <td className='w-1/3'>{data || '-'}</td>
      </tr>
    )
  })

  return (
    <div className='flex border h-2/6 w-11/12 border-black rounded overflow-auto'>
      <table className='dataVaultTable'>
        <thead>
          <tr>
            <th>Column Name</th>
            <th>First Row in CSV</th>
          </tr>
        </thead>
        <tbody>
          {csvRows}
        </tbody>
      </table>
    </div>
  )
}

export default PreviewTable
