import React from 'react'
import GuidelineRow from "./guidelineRow";

const ExistingGuidelines = ({ existingGuidelines }) => {
  return (
    <table className='text-center'>
      <thead className='border-b border-gray-600 text-gray-600'>
      <tr>
        <th className='px-3 text-left w-1/4'>Parameter</th>
        <th className='px-3'>Soil Units</th>
        <th className='px-3'>Soil Guideline</th>
        <th className='px-3'>Subsoil Guideline</th>
        <th className='px-3'>Groundwater Units</th>
        <th className='px-3'>Groundwater Guideline</th>
      </tr>
      </thead>
      <tbody>
      {existingGuidelines?.map(guideline => <GuidelineRow key={Object.values(guideline).join("-")} guideline={guideline} />)}
      </tbody>
    </table>
  )
}

export default ExistingGuidelines
