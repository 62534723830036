import React, { useState } from "react";

const ValidateSubmitButton = ({ children, message, step, setStep, hasError, setShowAnswers, isMandatory }) => {
  const [needValidation, setNeedValidation] = useState(false)

  const validateData = () => {
    if (hasError()) {
      setNeedValidation(true);
    } else {
      const steps = 4

      if (step < steps) {
        setStep(prevStep => prevStep + 1)
      }
      if (step == steps || isMandatory == 'Yes') {
        setShowAnswers(true);
      }
    }
  }

  const prevStep = () => {
    if (step > 1) {
      setStep(prevStep => prevStep - 1)
    }
  }

  return (
    <>
    {needValidation && <div><p className="text-red-700">{message}</p></div>}
    {children}
    <div className='flex flex-row justify-between w-5/6'>
      {step > 1 && <div
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded w-24 text-center"
        onClick={prevStep}
      >
        Back
      </div>}
      <div 
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded w-24 text-center"
        onClick={validateData}
      >
          Next
      </div>
    </div>
    </>
  )
}

export default ValidateSubmitButton
