import React from 'react'
import { SiteProvider } from "../../../contexts/site";
import SiteBody from "./siteBody";

const Site = ({ site, soilPathways, subsoilPathways, groundwaterPathways }) => {
  return (
    <SiteProvider site={site} soilPathways={soilPathways} subsoilPathways={subsoilPathways} groundwaterPathways={groundwaterPathways} >
      <SiteBody />
    </SiteProvider>
  )
}

export default Site
